import React, { CSSProperties, Ref } from "react";
import { View, StyleSheet } from "react-native";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggingStyle,
  NotDraggingStyle,
  DragStart,
  DragUpdate,
  DraggableLocation
} from "react-beautiful-dnd";
import { useHover } from "react-native-web-hooks";
import { isEmpty } from "lodash";
import { computed, makeObservable, observable, toJS } from "mobx";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";
import { Inject } from "@not-the-droids/exco-ts-inject";
import { Authenticator } from "../exco-lib/exco-auth";
import {
  BidModel,
  BudgetInput,
  BudgetMilestoneInput,
  BudgetMilestoneTask,
  BudgetModel,
  BudgetPhaseInput,
  Project,
  ProjectModel,
  UserModel,
} from "../../../data-model";
import { History, HistoryInjectable } from "../HistoryInjectable";
import { withInjectedFactory } from "../InjectorContext";
import { Notification } from "../NotificationInjectable";
import {
  sortOrderIndex,
  createBudgetInput,
  getTomorrowDate,
  reindexBudgetInput,
  convertTaskInputToTask,
} from "../utils/Budget";
import {
  formatCurrencyToString,
  isStringNumeric,
  parsePriceInput,
} from "../utils/Numbers";
import { UserViewModel } from "../viewModels/UserViewModel";
import { BudgetCategory } from "./BudgetCategory";
import { Task, WorkMilestone } from "./BudgetCategoryBody";
import { CommentBubble } from "./CommentBubble";
import { Icon, StyledButton, StyledText, StyledTextInput } from "./controls";
import { DateInput } from "./controls/DateInput";
import { LoadingIndicator } from "./LoadingIndicator";
import { InjectedBudgetOptionsView } from "./BudgetOptionsView";
import { ProjectDetailsFlow } from "../flows/ProjectDetailsFlow";
import { ProjectWidgetManager } from "../managers/ProjectWidgetManager";
import { Palette } from "./styles";
import { isEmptyString } from "../utils/Strings";
import { noop } from "../utils/Hooks";
import { StyledModal } from "./Modal";
import { AutoSaveManager } from "../managers/AutoSaveManager";
import { InjectedSaveIndicator } from "./SaveIndicator";

interface DraggablePlaceholder {
  clientHeight: number;
  clientWidth: number;
  clientY: number;
  clientX: number;
}

interface Props {
  authenticator: Authenticator;
  autoSaveManager: AutoSaveManager
  budgetModel: BudgetModel;
  history: History;
  projectDetailsFlow: ProjectDetailsFlow;
  projectWidgetManager: ProjectWidgetManager;
  projectModel: ProjectModel;
  notification: Notification;
  bidModel: BidModel;
  userModel: UserModel;
  userViewModel: UserViewModel;
}

interface CreateProps {
  project: Project;
  changeBudgetView: () => void;
}

const createStyleGetter = (props: CSSProperties) => (
  _isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
): CSSProperties => ({
  userSelect: "none",
  padding: 0,
  background: "transparent",
  ...props,
  ...draggableStyle,
});

export class ProjectBudgetEditViewFactory {
  static inject: Inject<ProjectBudgetEditViewFactory> = (injector) => {
    return () =>
      new ProjectBudgetEditViewFactory({
        authenticator: injector.get(Authenticator)(),
        autoSaveManager: injector.get(AutoSaveManager)(),
        budgetModel: injector.get(BudgetModel)(),
        history: injector.get(HistoryInjectable)(),
        projectDetailsFlow: injector.get(ProjectDetailsFlow)(),
        projectWidgetManager: injector.get(ProjectWidgetManager)(),
        projectModel: injector.get(ProjectModel)(),
        notification: injector.get(Notification)(),
        bidModel: injector.get(BidModel)(),
        userModel: injector.get(UserModel)(),
        userViewModel: injector.get(UserViewModel)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ProjectBudgetEditView {...this.props} {...props} />;
  }
}

@observer
class ProjectBudgetEditView extends React.Component<CreateProps & Props> {
  @observable private _initialized: boolean = false;
  @observable public budgetInput: BudgetInput[] = [];
  @observable public dateInput: Date | null = null;
  @observable public isPreviewSubmitted: boolean = false;
  @observable public selectedPhaseIndex: number = -1;
  @observable public selectedMilestoneIndex: number = -1;
  @observable public milestonesToDelete: BudgetMilestoneInput[] = [];
  @observable public phasesToDelete: BudgetPhaseInput[] = [];
  @observable public tasksToDelete: BudgetMilestoneTask[] = [];
  @observable public timeSaved: Date = new Date();
  @observable public isDragging: boolean = false;
  @observable private showPredeterminedPricingModal = false;
  @observable private milestoneSelectionProps?: Partial<BudgetMilestoneInput>;
  @observable private placeholderProps?: DraggablePlaceholder;
  private clearInterval: Function = noop;

  @computed public get budgetId(): string {
    return this.props.projectDetailsFlow.budget!.id;
  }

  @computed public get totalProjectTime(): number {
    let totalDays: number = 0;
    this.budgetInput?.forEach((item) => {
      if (item.type === "phase") {
        totalDays += Number(item.numDays);
      }
    });
    return totalDays;
  }

  @computed public get totalProjectPrice(): number {
    let totalCost: number = 0;
    this.budgetInput?.forEach((item) => {
      if (item.type === "phase") {
        totalCost += item.cost;
      }
    });
    return Math.round((totalCost + Number.EPSILON) * 100) / 100;
  }

  constructor(props: Props & CreateProps) {
    super(props);
    makeObservable(this);
  }

  readonly componentDidMount = async () => {
    const {
      project,
      bidModel,
      projectModel,
      budgetModel,
      autoSaveManager,
      projectWidgetManager,
      projectDetailsFlow,
    } = this.props;
    try {
      const bid = await bidModel.getBidByProjectId(project.id);
      projectDetailsFlow.bid = bid;

      const budgetResult = await budgetModel
        .getBudgetByProjectId(project.id)
        .catch(noop);
      const budget =
        budgetResult ??
        (await budgetModel.createBudget({
          projectId: project.id,
          startDate: getTomorrowDate(),
        }));

      projectDetailsFlow.budget = budget;

      const workCategories = await projectModel.getWorkCategoriesByProjectId(
        project.id
      );

      this.budgetInput = createBudgetInput(budget, bid.lines, workCategories);
      this.dateInput = new Date(budget.startDate);
      this.calculatePhaseCost();

      await projectWidgetManager.loadComments(
        project.id,
        "budget",
        this.budgetId
      );

      // Open first milestone
      this.selectedMilestoneIndex = this.budgetInput.findIndex(
        (v) => v.type === "milestone"
      );

      this.clearInterval = autoSaveManager.setAutoSave(
        () => this.budgetInput, 
        async () => await this.handleBudgetSave(),
        15000
      );

      this._initialized = true;
    } catch (error) {
      console.error(error);
    }
  };

  readonly componentWillUnmount = () => {
    this.clearInterval();
  }

  readonly handleCommentBubblePress = (
    bubbleIndex: number,
    tagName: string,
    parentId: string,
    itemId: string
  ) => {
    const { projectWidgetManager } = this.props;
    if (projectWidgetManager.activeCommentTag !== tagName) {
      projectWidgetManager.openChatWidget(tagName, parentId);
      this.props.projectWidgetManager.activeCommentIndex = bubbleIndex;
    } else {
      projectWidgetManager.closeWidget();
    }
  };

  readonly appendMilestone = () => {
    // append to list.
    var milestones = this.budgetInput.filter((input) => {
      return input.type === "milestone";
    });
    const newMilestone: BudgetMilestoneInput = {
      id: uuidv4(),
      csiCode: "",
      name: "",
      scopeOfWork: "",
      tasks: [],
      files: [],
      orderIndex: this.budgetInput.length,
      renderIndex: this.budgetInput.length,
      milestoneIndex: milestones.length + 1,
      type: "milestone",
      budgetId: this.budgetId,
    };
    // Update and select it
    const newBudget = reindexBudgetInput([...this.budgetInput, newMilestone]);
    this.budgetInput = newBudget;
    this.selectedMilestoneIndex = newBudget.length - 1;
  };

  readonly handleMilestoneInputSelect = (updatedMilestoneProps: Partial<BudgetMilestoneInput>) => {
    const milestone = this.budgetInput[this.selectedMilestoneIndex] as BudgetMilestoneInput;
    if (!!milestone.csiCode || !!milestone.scopeOfWork || milestone.tasks.length > 0) {
      this.milestoneSelectionProps = updatedMilestoneProps;
    } else {
      this.updateMilestoneFromInput(updatedMilestoneProps);
    }
  };

  readonly updateMilestoneFromInput = (updatedMilestoneProps?: Partial<BudgetMilestoneInput>) => {
    const milestoneProps = updatedMilestoneProps ?? this.milestoneSelectionProps;
    const milestoneInput = this.budgetInput[this.selectedMilestoneIndex] as BudgetMilestoneInput;

    milestoneInput.tasks.forEach((_, index) => {
      this.tasksToDelete.push(
        convertTaskInputToTask(milestoneInput.tasks[index])
      );
    });

    this.budgetInput[this.selectedMilestoneIndex] = {
      ...milestoneInput,
      ...milestoneProps,
    } as BudgetMilestoneInput;
    this.milestoneSelectionProps = undefined;
  };

  readonly updateMilestoneProp = <K extends keyof Omit<WorkMilestone, "tasks">>(
    text: WorkMilestone[K],
    property: K
  ) => {
    const budgetMilestoneInput = this.budgetInput[this.selectedMilestoneIndex] as BudgetMilestoneInput;
    budgetMilestoneInput[property] = text;
  };

  readonly calculatePhaseCost = () => {
    let currentPhaseIndex: number = -1;
    let currentPhaseTotal: number = 0;
    let milestoneTotal: number = 0;

    for (let i = 0; i < this.budgetInput.length; i++) {
      if (this.budgetInput[i].type === "phase") {
        currentPhaseIndex = i;
        milestoneTotal = 0;
        if (currentPhaseTotal !== 0) {
          (this.budgetInput[currentPhaseIndex] as BudgetPhaseInput).cost =
            currentPhaseTotal;
          currentPhaseTotal = 0;
        }
      } else if (this.budgetInput[i].type === "milestone") {
        (this.budgetInput[i] as BudgetMilestoneInput).tasks?.forEach(
          // eslint-disable-next-line no-loop-func
          (task) => (milestoneTotal += parsePriceInput(task.budget))
        );
        (this.budgetInput[currentPhaseIndex] as BudgetPhaseInput).cost =
          milestoneTotal;
      }
    }
  };

  readonly updateCategoryTask = <K extends keyof Task>(
    text: Task[K],
    property: K,
    index: number
  ) => {
    (this.budgetInput[this.selectedMilestoneIndex] as BudgetMilestoneInput).tasks[index][
      property
    ] = text;
    if (property === "budget") this.calculatePhaseCost();
  };

  readonly addTask = () => {
    (this.budgetInput[this.selectedMilestoneIndex] as BudgetMilestoneInput).tasks.push({
      id: uuidv4(),
      description: "",
      budget: "0",
      completed: false,
    });
  };

  readonly applyPredetermingPricing = async () => {
    const { userModel } = this.props;
    this.showPredeterminedPricingModal = false;
    const milestonePrices = await userModel.getMilestonePrices();
    this.budgetInput.forEach((item) => {
      if (item.type === "milestone") {
        const foundPrice = milestonePrices.find(
          (milestonePrice) => milestonePrice.milestoneName === item.name
        );
        if (foundPrice) {
          item.scopeOfWork = foundPrice.scope
            ? `${foundPrice.scope} (${item.workAreas?.join(", ")})`
            : item.scopeOfWork;
          item.tasks?.forEach((task) => {
            const foundTask = foundPrice.tasks?.find(
              (milestoneTask) => milestoneTask.description === task.description
            );
            if (foundTask) {
              task.budget = foundTask.budget || task.budget;
            }
          });
        }
      }
    });
    this.calculatePhaseCost();
  };

  readonly selectCategory = (index: number) => {
    if (index === this.budgetInput.length - 1)
      this.props.projectDetailsFlow.userSawLastBudgetMilestone = true;

    // if clicked once open, close again
    if (this.selectedMilestoneIndex === index) {
      this.selectedMilestoneIndex = -1;
    } else {
      this.selectedMilestoneIndex = index;
    }
  };

  readonly deleteMilestone = () => {
    this.milestonesToDelete.push(
      this.budgetInput[this.selectedMilestoneIndex] as BudgetMilestoneInput
    );
    this.budgetInput.splice(this.selectedMilestoneIndex, 1);
    this.selectedMilestoneIndex = -1;
    this.budgetInput = reindexBudgetInput(this.budgetInput);
    this.calculatePhaseCost();
  };

  readonly deleteMilestoneTask = (index: number) => {
    const budgetMilestoneInput = this.budgetInput[this.selectedMilestoneIndex] as BudgetMilestoneInput;
    this.tasksToDelete.push(
      convertTaskInputToTask(budgetMilestoneInput.tasks[index])
    );
    budgetMilestoneInput.tasks.splice(
      index,
      1
    );
    this.calculatePhaseCost();
  };

  readonly submitMilestone = () => {
    if (this.selectedMilestoneIndex < this.budgetInput.length - 1) {
      this.selectedMilestoneIndex++;
    } else {
      this.props.projectDetailsFlow.userSawLastBudgetMilestone = true;
      this.selectedMilestoneIndex = -1;
    }
  };

  readonly handleStartDateChange = (date: Date) => {
    this.dateInput = date;
  };

  readonly handleBudgetSave = async (showNotification?: boolean) => {
    const { autoSaveManager, budgetModel, project, notification } = this.props;

    const cleaned = this.budgetInput.map((budgetInput) => {
      if (budgetInput.type === "phase") return budgetInput;
      return {
        ...budgetInput,
        tasks: budgetInput.tasks.map((task) => {
          return {
            ...task,
            budget: parsePriceInput(task.budget),
          };
        }),
      };
    });

    if (this.budgetId !== "") {
      const updateObject = {
        budgetId: this.budgetId,
        deletedMilestones: toJS(this.milestonesToDelete),
        deletedPhases: toJS(this.phasesToDelete),
        deletedTasks: toJS(this.tasksToDelete),
        budgetItems: toJS(cleaned),
        startDate: this.dateInput!,
      };
      try {
        await budgetModel.updateBudget(updateObject);
        showNotification && notification.setNotification("success", "Budget Updated!");
      } catch (e) {
        throw new Error("Budget Failed to Update!");
      }
    } else {
      const newBudgetObject = {
        projectId: toJS(project.id),
        budgetItems: toJS(cleaned),
        startDate: this.dateInput!,
      };
      try {
        await budgetModel.createBudget(newBudgetObject);
        showNotification && notification.setNotification("success", "Budget Saved");
      } catch (e) {
        throw new Error("Budget Failed to Update");
      }
    }

    autoSaveManager.setTimeSaved(new Date());
  }

  readonly handleBudgetSubmit = async () => {
    try {
      await this.handleBudgetSave(true);
      this.props.changeBudgetView();
    } catch (e) {
      console.error(e);
    }
  };

  readonly handleOptionsViewChange = async () => {
    if (!this.dateInput) {
      return this.props.notification.setNotification(
        "error",
        "Please select a start date"
      );
    }
    if (this.dateInput < new Date()) {
      return this.props.notification.setNotification(
        "error",
        "Please select a start date in the future"
      );
    }
    if (this.budgetInput.some((item) => !item.name || item.name === "")) {
      return this.props.notification.setNotification(
        "error",
        "Please make sure all milestones and phases have a name"
      );
    }
    if (
      this.budgetInput.some((item) => item.type === "phase" && item.numDays < 0)
    ) {
      return this.props.notification.setNotification(
        "error",
        "Please make sure all phases have number of days"
      );
    }

    try {
      await this.handleBudgetSave(true);
      this.isPreviewSubmitted = true;
    } catch (e) {
      console.error(e)
    }
  };

  readonly handlePhaseAddOrEdit = (index: number) => {
    this.selectedPhaseIndex = index;
  };

  readonly handleSavePhase = (
    name: string,
    numDays: string,
    categoryStartIndex: number
  ) => {
    // if editing existing phase
    if (this.budgetInput[categoryStartIndex].type === "phase") {
      (this.budgetInput[categoryStartIndex]) = {
        ...this.budgetInput[categoryStartIndex],
        name,
        numDays: Number(numDays),
      } as BudgetPhaseInput;
      this.selectedPhaseIndex = -1;
      return;
    }

    const newPhase: BudgetPhaseInput = {
      id: uuidv4(),
      name,
      numDays: Number(numDays),
      milestoneIndices: [],
      orderIndex: categoryStartIndex,
      renderIndex: categoryStartIndex,
      cost: 0,
      type: "phase",
      budgetId: "",
      phaseIndex: this.budgetInput.length,
    };

    const originalBudgetLength = this.budgetInput.length;
    const newBudget = this.budgetInput;
    newBudget.splice(categoryStartIndex, 0, newPhase);
    this.budgetInput = reindexBudgetInput(newBudget);
    if (
      categoryStartIndex + 1 === originalBudgetLength ||
      originalBudgetLength < 2
    ) {
      this.appendMilestone();
    }
    this.selectedPhaseIndex = -1;
    this.calculatePhaseCost();
  };

  readonly handlePhaseDelete = async (categoryStartIndex: number) => {
    this.phasesToDelete.push(
      this.budgetInput[categoryStartIndex] as BudgetPhaseInput
    );
    const newBudget = this.budgetInput;
    newBudget.splice(categoryStartIndex, 1);
    this.budgetInput = reindexBudgetInput(newBudget);
  };

  readonly handlePhaseMove = (index: number, direction: string) => {
    const newBudget = toJS(this.budgetInput);
    if (direction === "up") {
      if (
        newBudget[index - 1].type === "milestone" &&
        newBudget[index - 2].type !== "phase"
      ) {
        newBudget[index].renderIndex--;
        newBudget[index - 1].renderIndex++;
      } else if (newBudget[index - 1].type === "phase") {
        (newBudget[index] as BudgetPhaseInput).phaseIndex--;
        (newBudget[index - 1] as BudgetPhaseInput).phaseIndex++;
      }
    } else if (direction === "down") {
      if (index + 2 < newBudget.length) {
        if (!!newBudget[index + 2] && newBudget[index + 2].type === "phase") {
          return;
        } else {
          newBudget[index].renderIndex++;
          newBudget[index + 1].renderIndex--;
        }
      }
    }
    this.selectedMilestoneIndex = -1;
    this.budgetInput = newBudget.sort(sortOrderIndex);
    this.calculatePhaseCost();
  };

  readonly createPhaseListItem = (categoryStartIndex?: number, ignoreHover?: boolean) => {
    let index = categoryStartIndex ? categoryStartIndex : 0;
    const isTouched = this.selectedPhaseIndex === index;
    if (isTouched) {
      return (
        <AddOrEditPhase
          index={index}
          handleSaveClick={this.handleSavePhase}
          handleCancelClick={() => this.selectedPhaseIndex = -1}
        />
      );
    } else {
      return (
        <AddPhaseCTA
          onPress={() => this.selectedPhaseIndex = index}
          isDragging={this.isDragging}
          ignoreHover={ignoreHover}
        />
      );
    }
  };

  readonly isDeletable = (index: number) => {
    const firstItem =
      index - 1 > -1 && this.budgetInput[index - 1].type === "phase";
    if (
      firstItem &&
      (this.budgetInput.length === index + 1 ||
        this.budgetInput[index + 1]?.type === "phase")
    ) {
      return false;
    } else {
      return true;
    }
  };

  readonly reorder = (budgetList: BudgetInput[], startIndex: number, endIndex: number) => {
    const result = Array.from(budgetList);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  readonly getMilestoneStyle = createStyleGetter({});
  readonly getPhaseStyle = createStyleGetter({});

  readonly getListStyle = (_isDraggingOver: boolean) => ({
    background: "transparent",
    padding: 0,
    width: "100%",
  });

  private manageDraggablePlaceholder = (draggableId: string, destination?: DraggableLocation, initial?: DraggableLocation) => {
    if (!destination) return;

    const destinationIndex = destination.index;
    const initialIndex = initial?.index ?? destination.index;

    const queryAttr = "data-rbd-drag-handle-draggable-id";
    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    if (!draggedDOM) return;
    const { lastChild, clientWidth } = draggedDOM;
    const clientMargin = 4;

    const parentNode = draggedDOM.parentNode as Element;
    const childArray = [...Array.from(draggedDOM.parentElement!.children)]
    childArray.splice(initialIndex, 1)
    
    const clientY = parseFloat(window.getComputedStyle(parentNode).paddingTop) + childArray
      .slice(0, destinationIndex)
      .reduce((total, curr) => {
        return total + curr.clientHeight;
      }, 20);

    const clientHeight = parseFloat(window.getComputedStyle(lastChild as Element).height); 

    this.placeholderProps = {
      clientHeight: clientHeight - clientMargin,
      clientWidth: clientWidth - clientMargin,
      clientY: clientY,
      clientX: parseFloat(window.getComputedStyle(parentNode).paddingLeft)
    };
  };

  readonly handleDragStart = (initial: DragStart) => {
    this.isDragging = true;
    this.manageDraggablePlaceholder(initial.draggableId, initial.source);
  };

  readonly handleDragUpdate = (initial: DragUpdate) => {
    this.manageDraggablePlaceholder(initial.draggableId, initial.destination, initial.source);
  };

  readonly handleDragEnd = (result: DropResult) => {
    this.isDragging = false;

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      toJS(this.budgetInput),
      result.source.index,
      result.destination.index
    );
    let disableMove = false;
    items?.forEach((item, index) => {
      if (
        item.type === "phase" &&
        (!items[index + 1] || items[index + 1].type === "phase")
      ) {
        disableMove = true;
      }
    });

    if (!disableMove) {
      this.budgetInput = reindexBudgetInput(items);
    }
    this.calculatePhaseCost();
    this.placeholderProps = undefined;
    if (this.selectedMilestoneIndex > -1) {
      this.selectedMilestoneIndex = result.destination.index;
    }
  };

  renderBudgetCategory = (item: BudgetMilestoneInput, index: number, ignoreHover: boolean) => {
    const isActive = this.selectedMilestoneIndex === index;
    return (
      <>
      {this.createPhaseListItem(index, ignoreHover)}
      <View style={[styles.milestone, styles.row]} key={index}>
        <View style={{ flex: 1 }}>
          <BudgetCategory
            key={item.renderIndex}
            milestone={item}
            updateMilestone={this.handleMilestoneInputSelect}
            addTask={this.addTask}
            deleteMilestone={this.deleteMilestone}
            isDeletable={this.isDeletable(index)}
            deleteTask={this.deleteMilestoneTask}
            index={item.renderIndex}
            displayIndex={item.milestoneIndex}
            isActive={isActive}
            onClick={() => this.selectCategory(index)}
            updateTask={this.updateCategoryTask}
            submitMilestone={this.submitMilestone}
            updateMilestoneProp={this.updateMilestoneProp}
          />
        </View>
        <CommentBubble
          isAlert={this.props.projectWidgetManager.determineTaggedCommentsUnread(
            this.props.userViewModel.currentUser!.id,
            item.name
          )}
          isFilled={
            this.props.projectWidgetManager.activeCommentIndex === index
          }
          onPress={() =>
            this.handleCommentBubblePress(
              index,
              item.name,
              item.budgetId,
              item.id!
            )
          }
          style={styles.commentBubble}
        />
      </View>
      </>
    );
  };

  renderEditablePhase = (item: BudgetPhaseInput, index: number) => (
    this.selectedPhaseIndex === index ? (
      <View style={{marginBottom: -20}}>
        <AddOrEditPhase
          index={index}
          name={item.name}
          numDays={`${item.numDays ?? ""}`}
          handleSaveClick={this.handleSavePhase}
          handleCancelClick={() => (this.selectedPhaseIndex = -1)}
        />
      </View>
    ) : (
      <View style={index !== 0 && {marginTop: 20}}>
        <SavedPhase
          isFirst={index === 0}
          phase={item}
          onDelete={() => this.handlePhaseDelete(index)}
          onMoveDown={() => this.handlePhaseMove(index, "down")}
          onMoveUp={() => this.handlePhaseMove(index, "up")}
          onEditPress={() => this.handlePhaseAddOrEdit(index)}
          indexToRender={item.phaseIndex}
        />
      </View>
    )
  );

  renderItems = () => {
    if (!this.budgetInput || this.budgetInput.length === 0) {
      return (
        <AddOrEditPhase
          index={0}
          handleSaveClick={this.handleSavePhase}
          handleCancelClick={() => (this.selectedPhaseIndex = -1)}
        />
      );
    }

    return (
      <View style={{zIndex: 1}}>
        {this.budgetInput.map((item, index) => {
          const isDraggedDisabled = item.type === "phase"
            || this.selectedMilestoneIndex === item.renderIndex
            || this.selectedPhaseIndex !== -1;
          const getItemStyle = item.type === "milestone" 
            ? this.getMilestoneStyle : this.getPhaseStyle;
          const ignoreHover = this.budgetInput[Math.max(0, index - 1)].type === "phase";

          if (index === 0 && item.type === "phase") {
            return this.renderEditablePhase(item, index);
          }

          return (
            <Draggable
              key={item.id}
              draggableId={item.id!}
              index={index}
              isDragDisabled={isDraggedDisabled}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style,
                  )}
                >
                  {item.type === "milestone" ? (
                    this.renderBudgetCategory(item, index, ignoreHover)
                  ) : (
                    this.renderEditablePhase(item, index)
                  )}
                </div>
              )}
            </Draggable>
          );
        })}
      </View>
    );
  };

  renderBudgetEdit = () => {
    const { projectDetailsFlow } = this.props;
    const placeholderProps = this.placeholderProps;

    if (!this._initialized) return <LoadingIndicator />;
    return (
      <>
        <StyledModal
          visible={this.showPredeterminedPricingModal}
          onClose={() => (this.showPredeterminedPricingModal = false)}
          title={"Are you sure?"}
          text={
            "This will replace the scope, tasks, and costs for all existing milestones, are you sure?"
          }
          confirmButtonText={"Apply"}
          closeButtonText={"Cancel"}
          onConfirm={this.applyPredetermingPricing}
        />
        <StyledModal
          visible={!!this.milestoneSelectionProps}
          onClose={() => (this.milestoneSelectionProps = undefined)}
          title={"Are you sure?"}
          text={
            "This will replace the scope, tasks, and costs for all existing milestones, are you sure?"
          }
          confirmButtonText={"Apply"}
          closeButtonText={"Cancel"}
          onConfirm={() => this.updateMilestoneFromInput()}
        />
        <View style={styles.sectionSmall}>
          <StyledText variant="heading3" isBold={true}>
            Project Budget Sheet
          </StyledText>
          <View
            style={[styles.row, styles.spaceBetween, styles.subTitle]}
          >
            <StyledText>
              This is the project's overall budget sheet. This sheet is where 
              you input all the wording for your contract scope of work. 
              Go through each Milestone and make sure it reflects the scope 
              of work you are doing for your project. In each milestone there 
              are checklists which are your to do list tasks in order to get 
              paid. Some are mandatory and you can't remove the task.
            </StyledText>
            <StyledButton
              variant="secondary"
              style={{ right: 25, width: 260, height: 34 }}
              text="Apply predetermined pricing"
              onPress={() => (this.showPredeterminedPricingModal = true)}
            />
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.costCol}>
            <View style={styles.header}>
              {/* Start date input */}
              <DateInput
                value={
                  this.dateInput && this.dateInput > new Date()
                    ? this.dateInput
                    : null
                }
                onChange={this.handleStartDateChange}
                placeholder={"Start Date"}
              />

              {/* Total time */}
              <View style={styles.headerRight}>
                <View style={styles.row}>
                  <StyledText
                    variant="heading3"
                    colorMode="dark"
                    style={{ marginBottom: 4 }}
                  >{`Total Time: `}</StyledText>
                  <StyledText variant="heading3" colorMode="dark" isBold={true}>
                    {this.totalProjectTime
                      ? `${this.totalProjectTime} Days`
                      : "--"}
                  </StyledText>
                </View>

                {/* Total cost */}
                <View style={[styles.row, { marginLeft: 24 }]}>
                  <StyledText
                    variant="heading3"
                    style={{ marginBottom: 4 }}
                  >{`Total Cost: `}</StyledText>
                  <StyledText variant="heading3" isBold={true}>
                    {formatCurrencyToString(this.totalProjectPrice)}
                  </StyledText>
                </View>
              </View>
            </View>
            <View style={styles.sectionLarge}>
              <View style={styles.sectionSmall}>
                {/* TODO: implement drag and drop feature on category rows - EY */}
                <DragDropContext
                  onDragStart={this.handleDragStart}
                  onDragUpdate={this.handleDragUpdate}
                  onDragEnd={this.handleDragEnd}
                >
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <View
                        {...provided.droppableProps}
                        ref={provided.innerRef as Ref<View>}
                        style={this.getListStyle(snapshot.isDraggingOver)}
                      >
                        {this.renderItems()}
                        {provided.placeholder}
                        {!isEmpty(this.placeholderProps) && snapshot.isDraggingOver && (
                          <div
                            className="placeholder"
                            style={{
                              position: "absolute",
                              top: placeholderProps?.clientY,
                              left: placeholderProps?.clientX,
                              height: placeholderProps?.clientHeight,
                              width: placeholderProps?.clientWidth,
                              zIndex: 0,
                              borderColor: "#a9aaa9",
                              borderStyle: "dashed",
                              borderRadius: 3,
                              borderWidth: 2,
                            }}
                          />
                        )}
                      </View>
                    )}
                  </Droppable>
                </DragDropContext>
              </View>
              <StyledButton
                disabled={
                  this.selectedPhaseIndex > -1 ||
                  this.selectedMilestoneIndex > -1
                }
                onPress={
                  this.budgetInput?.length > 0
                    ? this.appendMilestone
                    : this.createPhaseListItem
                }
                text={
                  this.budgetInput?.length > 0 ? "Add Milestone" : "Add Phase"
                }
                variant="secondary"
                alignSelf
              />
            </View>
            <View style={styles.submitButton}>
              <StyledButton
                onPress={() => this.handleBudgetSave(true)}
                text={"Save"}
                alignSelf
              />
              <StyledButton
                disabled={
                  this.budgetInput?.length < 1 ||
                  (!projectDetailsFlow.userSawLastBudgetMilestone &&
                    !projectDetailsFlow.budget?.submitted)
                }
                onPress={this.handleOptionsViewChange}
                text={"Save and Continue"}
                alignSelf
              />
              <InjectedSaveIndicator />
            </View>
          </View>
        </View>
      </>
    );
  };

  render() {
    if (!this.isPreviewSubmitted) {
      return this.renderBudgetEdit();
    } else {
      return (
        <InjectedBudgetOptionsView
          onSubmit={this.handleBudgetSubmit}
          totalProjectPrice={this.totalProjectPrice}
          totalProjectTime={this.totalProjectTime}
        />
      );
    }
  }
}

interface AddPhaseCTAProps {
  onPress: () => void;
  isDragging: boolean;
  ignoreHover?: boolean;
}
export const AddPhaseCTA: React.FunctionComponent<AddPhaseCTAProps> = (
  props
) => {
  const { onPress, isDragging, ignoreHover } = props;
  const ref = React.useRef(null);
  const isHover = useHover(ref);
  if (ignoreHover || !isHover || isDragging) {
    return <View ref={ref} style={styles.hoverableMargin} />;
  }
  return (
    <View ref={ref} style={[styles.row, styles.addPhaseCTA]}>
      <View style={[styles.phaseDivider, { marginRight: 16 }]} />
      <StyledButton
        iconLeft={{ name: "plus", type: "accent" }}
        onPress={onPress}
        text="Add Phase Here"
        variant="textOnly"
      />
      <View style={[styles.phaseDivider, { marginLeft: 16 }]} />
    </View>
  );
};

interface SavedPhaseProps {
  isFirst: boolean;
  phase: BudgetPhaseInput;
  onDelete: () => void;
  onMoveDown: () => void;
  onMoveUp: () => void;
  onEditPress: () => void;
  indexToRender: number;
}
export const SavedPhase: React.FunctionComponent<SavedPhaseProps> = (props) => {
  const {
    onDelete,
    onMoveDown,
    onMoveUp,
    onEditPress,
    phase,
    indexToRender,
    isFirst,
  } = props;
  const ref = React.useRef(null);
  const isHover = useHover(ref);
  return (
    <View ref={ref}>
      <View style={[styles.row, styles.phaseRow]}>
        <StyledText colorMode="light" isBold={true} style={styles.phaseIndex}>
          {indexToRender}
        </StyledText>
        <View style={styles.phaseMainColumn}>
          <StyledText colorMode="light" isBold={true}>
            {phase.name}
          </StyledText>
          <StyledText colorMode="light" isBold={true}>
            {phase.numDays < 0 ? "--" : phase.numDays} Days
          </StyledText>
        </View>
        {isHover ? (
          <View style={styles.phaseRightColumn}>
            <View style={styles.phaseEditContainer}>
              <Icon
                name="edit"
                type="accent"
                size={14}
                style={
                  isFirst ? styles.phaseEditFirstButton : styles.phaseEditButton
                }
                onClick={onEditPress}
              />
              {!isFirst && (
                <>
                  <Icon
                    name="arrow-up"
                    type="accent"
                    size={14}
                    style={styles.phaseMoveUpButton}
                    onClick={onMoveUp}
                  />
                  <Icon
                    name="arrow-down"
                    type="accent"
                    size={14}
                    style={styles.phaseMoveDownButton}
                    onClick={onMoveDown}
                  />
                  <View style={styles.phaseButtonsDivider} />
                  <Icon
                    name="x"
                    type="warning"
                    size={14}
                    style={styles.phaseDeleteButton}
                    onClick={onDelete}
                  />
                </>
              )}
            </View>
          </View>
        ) : (
          <View style={styles.phaseRightColumn}>
            <StyledText colorMode="light" isBold={true}>
              {formatCurrencyToString(phase.cost)}
            </StyledText>
          </View>
        )}
      </View>
    </View>
  );
};

interface AddOrEditPhaseProps {
  index: number;
  name?: string;
  numDays?: string;
  handleSaveClick: (name: string, days: string, index: number) => void;
  handleCancelClick: (index: number) => void;
}

@observer
class AddOrEditPhase extends React.Component<AddOrEditPhaseProps> {
  constructor(props: AddOrEditPhaseProps) {
    super(props);
    makeObservable(this);
  }
  @observable name = this.props.name ?? "";
  @observable numDays = this.props.numDays ?? "";

  readonly handlePhaseDaysUpdate = (value: string) => {
    if (!value) return (this.numDays = "");
    if (!isStringNumeric(value)) return;
    this.numDays = value.replace(/[^0-9]/, "");
  };

  readonly handlePhaseNameUpdate = (value: string) => {
    this.name = value;
  };

  render() {
    return (
      <View style={[styles.row, styles.phaseRow, styles.addPhase]}>
        <StyledText colorMode="light" isBold={true} style={styles.phaseIndex}>
          {/* {this.props.index + 1} */}
        </StyledText>
        <View style={styles.phaseMainColumn}>
          <StyledTextInput
            placeholder="Add Phase Name"
            value={`${this.name}`}
            onChangeText={this.handlePhaseNameUpdate}
            style={[styles.phaseNameTextInput]}
          />
          <StyledTextInput
            placeholder="0 Days"
            value={this.numDays}
            keyboardType="numeric"
            onChangeText={this.handlePhaseDaysUpdate}
            style={styles.phaseDaysNumberInput}
          />
          <StyledButton
            text="Save"
            disabled={isEmptyString(this.name)}
            style={styles.phaseSaveButton}
            onPress={() =>
              this.props.handleSaveClick(
                this.name,
                this.numDays,
                this.props.index
              )
            }
          />
        </View>
        <Icon
          name="x"
          type="warning"
          size={24}
          style={[styles.phaseRowTotal, { marginTop: 1 }]}
          onClick={() => this.props.handleCancelClick(this.props.index)}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  milestone: {
    display: "flex",
  },
  row: {
    flexDirection: "row",
  },
  costCol: {
    flex: 2,
    marginRight: 24,
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  subTitle: {
    alignItems: "center",
    gap: 80,
  },
  sectionLarge: {
    marginBottom: 64,
  },
  sectionSmall: {
    marginBottom: 24,
  },
  header: {
    flexDirection: "row",
    marginBottom: 8,
  },
  headerRight: {
    flex: 1,
    justifyContent: "flex-end",
    flexDirection: "row",
    marginTop: 5,
  },
  addPhaseCTA: {
    alignItems: "center",
  },
  phaseDivider: {
    flex: 1,
    borderBottomWidth: 2,
    borderColor: Palette.Accent,
  },
  hoverableMargin: {
    height: 20,
  },
  // phases
  phaseRow: {
    height: 48,
    borderRadius: 4,
    paddingVertical: 8,
    paddingHorizontal: 16,
    alignItems: "center",
    backgroundColor: Palette.Primary100Pct,
  },
  phaseRowTotal: {
    marginLeft: 24,
  },
  addPhase: {
    marginVertical: 20,
  },
  phaseIndex: {
    marginRight: 16,
  },
  phaseNameTextInput: {
    flex: 1,
    marginVertical: 8,
  },
  phaseDaysNumberInput: {
    width: 90,
    marginLeft: 8,
    marginVertical: 8,
  },
  phaseSaveButton: {
    height: 32,
    width: 90,
    minWidth: 0,
    marginLeft: 8,
    alignSelf: "center",
  },
  phaseMainColumn: {
    flex: 4,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  phaseRightColumn: {
    flex: 1,
    alignItems: "flex-end",
  },
  phaseEditContainer: {
    marginRight: -13,
    borderRadius: 4,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Palette.White,
  },
  phaseEditFirstButton: {
    paddingLeft: 14,
    paddingRight: 14,
    paddingVertical: 14,
  },
  phaseEditButton: {
    paddingLeft: 14,
    paddingRight: 3,
    paddingVertical: 14,
  },
  phaseMoveUpButton: {
    paddingLeft: 14,
    paddingRight: 8,
    paddingVertical: 14,
  },
  phaseMoveDownButton: {
    paddingRight: 14,
    paddingLeft: 8,
    paddingVertical: 14,
  },
  phaseDeleteButton: {
    paddingVertical: 14,
    paddingHorizontal: 14,
  },
  phaseButtonsDivider: {
    height: 24,
    borderLeftWidth: 1,
    borderLeftColor: Palette.Primary10Pct,
  },
  submitButton: {
    flexDirection: "row",
    alignItems: "center",
    gap: 24,
  },
  commentBubble: {
    marginTop: 13,
    left: 10,
    flex: 0,
  },
  permitButtons: {
    flexDirection: "row",
    gap: 30,
  },
});

export const InjectedProjectBudgetEditView = withInjectedFactory(
  ProjectBudgetEditViewFactory
);
