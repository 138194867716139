import { Inject, singletonInject } from "@not-the-droids/exco-ts-inject";
import { FirebaseFile } from "File";
import {
  Budget,
  ChangeRequest,
  CreateBudgetParams,
  CreateChangeRequestParams,
  DeleteBudgetMilestoneParams,
  DeleteBudgetPhaseParams,
  FileUploadParams,
  MilestoneCompletionType,
  PunchListItem,
  UpdateBudgetMilestoneParams,
  UpdateBudgetPhasesParams,
  UpdateDisclosureParams,
} from "../Budget";
import { BudgetModel } from "../BudgetModel";
import { RestClient } from "./RestClient";

export class RestBudgetModel extends BudgetModel {
  static inject: Inject<RestBudgetModel> = singletonInject((injector) => {
    return () => new RestBudgetModel(injector.get(RestClient)());
  });

  constructor(private readonly rest: RestClient) {
    super();
  }

  public getBudgetById(id: string): Promise<Budget> {
    return this.rest.get(`/budget/${id}`);
  }

  public getBudgetByProjectId(projectId: string): Promise<Budget> {
    return this.rest.get(`/project/${projectId}/budget`);
  }

  public createBudget(params: CreateBudgetParams): Promise<Budget> {
    return this.rest.post("/budget", params);
  }

  public updateBudget(params: any): Promise<any> {
    const { ...data } = params;
    return this.rest.put(`/budget/${params.budgetId}`, data);
  }

  public updateMilestone(params: UpdateBudgetMilestoneParams): Promise<void> {
    const { ...data } = params;
    return this.rest.put(`/budget/${params.budgetId}/milestone`, data);
  }

  public updateDisclosure(params: UpdateDisclosureParams): Promise<void> {
    const { budgetId, ...data } = params;
    return this.rest.put(`/budget/${params.budgetId}/disclosure`, data);
  }

  public completeContract(id: string): Promise<void> {
    return this.rest.put(`/budget/${id}/complete`);
  }

  public submitBudget(id: string): Promise<void> {
    return this.rest.put(`/budget/${id}/submit`);
  }

  public deleteMilestone(params: DeleteBudgetMilestoneParams): Promise<void> {
    return this.rest.delete(
      `/budget/${params.budgetId}/milestone/${params.orderIndex}`
    );
  }

  public getFilesById(budgetId: string): Promise<FirebaseFile[]> {
    return this.rest.get(`/budget/${budgetId}/file`);
  }

  public addFile(budgetId: string, params: any): Promise<void> {
    const { ...data } = params;
    return this.rest.post(`/budget/${budgetId}/file/${params.id}`, data);
  }

  public deleteFile(budgetId: string, id: string): Promise<void> {
    return this.rest.delete(`/budget/${budgetId}/file/${id}`);
  }

  public getMilestoneFilesById(milestoneId: string): Promise<FirebaseFile[]> {
    return this.rest.get(`/budget/milestone/${milestoneId}/file`);
  }

  public addMilestoneFile(milestoneId: string, fileParams: FileUploadParams): Promise<FirebaseFile> {
    const { ...data } = fileParams;
    return this.rest.post(
      `/budget/milestone/${milestoneId}/file/${fileParams.fileId}`,
      data
    );
  }

  public deleteMilestoneFile(milestoneId: string, id: string): Promise<void> {
    return this.rest.delete(`/budget/milestone/${milestoneId}/file/${id}`);
  }

  public updatePhases(params: UpdateBudgetPhasesParams): Promise<void> {
    const { budgetId, ...data } = params;
    return this.rest.put(`/budget/${budgetId}/phases`, data);
  }

  public deletePhase(params: DeleteBudgetPhaseParams): Promise<void> {
    const { budgetId, orderIndex } = params;
    return this.rest.delete(`/budget/${budgetId}/phase/${orderIndex}`);
  }

  public createChangeRequest(
    params: CreateChangeRequestParams
  ): Promise<ChangeRequest> {
    const { budgetId, ...data } = params;
    return this.rest.post(`/budget/${budgetId}/change-request`, data);
  }

  public getChangeRequestsByBudgetId(
    budgetId: string
  ): Promise<ChangeRequest[]> {
    return this.rest.get(`/budget/${budgetId}/change-request`);
  }

  public deleteChangeRequest(budgetId: string, id: string): Promise<void> {
    return this.rest.delete(`/budget/${budgetId}/change-request/${id}`);
  }

  public startMilestone(milestoneId: string): Promise<void> {
    return this.rest.put(`/budget/milestone/${milestoneId}/start`);
  }

  public updateMilestoneStatus(
    milestoneId: string,
    completionStatus: MilestoneCompletionType
  ): Promise<void> {
    return this.rest.put(
      `/budget/milestone/${milestoneId}/completion/${completionStatus}`
    );
  }

  public updateTaskStatus(
    milestoneId: string,
    taskId: string,
    isCompleted: boolean
  ): Promise<void> {
    return this.rest.put(
      `/budget/milestone/${milestoneId}/task/${taskId}/completed/${isCompleted}`
    );
  }

  public approveMilestoneCompletion(
    budgetId: string,
    milestoneId: string,
    changeRequestId: string
  ): Promise<void> {
    return this.rest.put(
      `/budget/${budgetId}/milestone/${milestoneId}/change-request/${changeRequestId}/approve-completion`
    );
  }

  public rejectChangeRequest(
    budgetId: string,
    milestoneId: string,
    changeRequestId: string,
    prevMilestoneStatus: MilestoneCompletionType
  ): Promise<void> {
    return this.rest.put(
      `/budget/${budgetId}/milestone/${milestoneId}/change-request/${changeRequestId}/reject`,
      { prevMilestoneStatus }
    );
  }

  public cancelChangeRequest(
    budgetId: string,
    milestoneId: string,
    changeRequestId: string,
    prevMilestoneStatus: MilestoneCompletionType
  ): Promise<void> {
    return this.rest.put(
      `/budget/${budgetId}/milestone/${milestoneId}/change-request/${changeRequestId}/cancel`,
      { prevMilestoneStatus }
    );
  }

  public createProjectCompletionRequest(
    projectId: string,
    budgetId: string,
  ): Promise<ChangeRequest> {
    return this.rest.post(`/budget/${budgetId}/project/${projectId}/completion-request`);
  }

  public createPunchListItem(
    milestoneId: string,
    description: string
  ): Promise<PunchListItem> {
    return this.rest.post(
      `/budget/milestone/${milestoneId}/punch-list-item`,
      { description }
    );
  }

  public updatePunchListItemStatus(
    milestoneId: string,
    punchListId: string,
    isCompleted: boolean
  ): Promise<void> {
    return this.rest.put(
      `/budget/milestone/${milestoneId}/punch-list-item/${punchListId}`,
      { isCompleted }
    );
  }

  public deletePunchListItem(
    milestoneId: string,
    punchListId: string
  ): Promise<void> {
    return this.rest.delete(
      `/budget/milestone/${milestoneId}/punch-list-item/${punchListId}`
    );
  }
}
