import { FC, useState } from "react";
import {
  Modal,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import { DemoModel } from "../../../data-model/DemoModel";
import { Icon, StyledButton, StyledText } from "./controls";
import { SectionBlock } from "./SectionBlock";
import { Palette } from "./styles";
import { History } from "../HistoryInjectable";

interface Props {
  demoModel: DemoModel;
  historyModel: History;
}

export const SideBarDemo: FC<Props> = ({ demoModel, historyModel }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleResetDemo = async () => {
    setLoading(true);
    try {
      await demoModel.restore();
      historyModel.go(0);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setShowModal(false);
  };

  return (
    <View
      style={{
        position: "absolute",
        bottom: 0,
      }}
    >
      <StyledButton
        style={styles.submitButton}
        text={"Reset demo"}
        onPress={() => setShowModal(true)}
      />
      <Modal
        animationType="fade"
        transparent={true}
        visible={showModal}
        onRequestClose={() => setShowModal(false)}
      >
        <View style={styles.centeredView}>
          {/* Background */}
          <TouchableWithoutFeedback onPress={() => setShowModal(false)}>
            <View style={styles.background} />
          </TouchableWithoutFeedback>

          {/* Modal */}
          <View style={styles.modalView}>
            <View style={styles.iconContainer}>
              <Icon
                name="alert-circle"
                size={24}
                // style={styles.modalIcon}
              />
            </View>

            <View style={[styles.section, { width: "100%" }]}>
              <SectionBlock title="Reset Demo">
                <StyledText style={styles.sectionText}>
                  Are you sure you want to reset the demo?
                </StyledText>
              </SectionBlock>
            </View>

            <StyledButton
              loading={loading}
              onPress={handleResetDemo}
              text="Reset Demo"
              style={styles.resetButton}
            />
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  submitButton: {},
  section: {
    marginBottom: 24,
  },
  sectionText: {
    lineHeight: 24,
  },
  resetButton: {
    marginTop: 30,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  iconContainer: {
    padding: 20,
    borderRadius: 30,
    position: "absolute",
    top: -27,
    backgroundColor: Palette.White,
  },
  background: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    width: "30vh",
    maxWidth: 500,
    borderRadius: 8,
    padding: 35,
    alignItems: "center",
    elevation: 5,
    minWidth: 400,
  },
});
