import { Inject, singletonInject } from "@not-the-droids/exco-ts-inject";
import { Contractor } from "Contractor";
import { Owner } from "Owner";
import { FirebaseFile } from "../File";
import { Media, Project, WorkCategoryAreas } from "../Project";
import {
  InviteContractorParams,
  ProjectModel,
  UpdateProjectParams,
  CreateProjectParams,
  UpdateWorkCategoriesParams,
  InviteOwnerParams,
  DeleteInviteParams,
  InvitedUser,
  RejectInviteParams,
  UpdatePhotoParams,
} from "../ProjectModel";
import { RestClient } from "./RestClient";

export class RestProjectModel extends ProjectModel {
  static inject: Inject<RestProjectModel> = singletonInject((injector) => {
    return () => new RestProjectModel(injector.get(RestClient)());
  });

  constructor(private readonly rest: RestClient) {
    super();
  }

  public getProjects(): Promise<Project[]> {
    return this.rest.get("/project");
  }
  public getNotCompletedCreation(): Promise<Project | undefined> {
    return this.rest.get("/project/not-completed-creation");
  }

  public getProjectsAsRole(): Promise<Project[]> {
    return this.rest.get("/project/contractor");
  }

  public getProjectById(id: string): Promise<Project> {
    return this.rest.get(`/project/${id}`);
  }

  public createProject(
    params: CreateProjectParams
  ): Promise<CreateProjectParams> {
    const { ...data } = params;
    return this.rest.post("/project", data);
  }

  public updateProject(params: UpdateProjectParams): Promise<void> {
    const { id, ...data } = params;
    return this.rest.put(`/project/${id}`, data);
  }

  public updateProjectName(params: UpdateProjectParams): Promise<Project> {
    const { id, ...data } = params;
    return this.rest.put(`/project/${id}/name`, data);
  }

  public updateProjectPhoto(
    id: string,
    params: UpdatePhotoParams
  ): Promise<Media> {
    return this.rest.put(`/project/${id}/update-photo`, params);
  }

  public completeCreation(id: string): Promise<void> {
    return this.rest.put(`/project/${id}/complete-creation`, {});
  }

  public getWorkCategoriesByProjectId(
    id: string
  ): Promise<WorkCategoryAreas[]> {
    return this.rest.get(`/project/${id}/work-categories`);
  }

  public getInvitesByProjectId(id: string): Promise<InvitedUser[]> {
    return this.rest.get(`/project/${id}/invites`);
  }

  public getMembersByProjectId(id: string): Promise<InvitedUser[]> {
    return this.rest.get(`/project/${id}/members`);
  }

  public getContractorByProjectId(id: string): Promise<Contractor | undefined> {
    return this.rest.get(`/project/${id}/contractor`);
  }

  public getOwnerByProjectId(id: string): Promise<Owner | undefined> {
    return this.rest.get(`/project/${id}/owner`);
  }

  public updateWorkCategories(
    params: UpdateWorkCategoriesParams
  ): Promise<void> {
    const { projectId, workCategoryAreas } = params;
    return this.rest.post(
      `/project/${projectId}/work-categories`,
      workCategoryAreas
    );
  }

  public publishProject(id: string): Promise<void> {
    return this.rest.post(`/project/${id}/publish`);
  }

  public inviteContractor(params: InviteContractorParams): Promise<void> {
    const { projectId, contractorId } = params;
    return this.rest.post(`/project/${projectId}/contractor`, {
      contractorId,
    });
  }

  public inviteOwner(params: InviteOwnerParams): Promise<void> {
    const { projectId, ownerId } = params;
    return this.rest.post(`/project/${projectId}/owner`, {
      ownerId,
    });
  }

  public acceptInvitation(projectId: string): Promise<void> {
    return this.rest.post(`/project/${projectId}/accept-invite`);
  }

  public deleteInvitation(params: DeleteInviteParams): Promise<void> {
    const { projectId, userId } = params;
    return this.rest.post(`/project/${projectId}/delete-invite`, {
      userId,
    });
  }

  public rejectInvitation(params: RejectInviteParams): Promise<void> {
    const { projectId } = params;
    return this.rest.post(`/project/${projectId}/reject-invite`);
  }

  public archiveProject(id: string): Promise<any> {
    return this.rest.post(`/project/${id}/archive-project`);
  }

  public getRolesByProjectId(id: string): Promise<InvitedUser[]> {
    return this.rest.get(`/project/${id}/roles`);
  }

  public getFilesByProjectId(id: string): Promise<FirebaseFile[]> {
    return this.rest.get(`/project/${id}/files`);
  }
}
