import { Inject } from "@not-the-droids/exco-ts-inject";
import { computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React, { ReactElement } from "react";
import { StyleSheet, View } from "react-native";
import { matchPath } from "react-router";
import { History, HistoryInjectable } from "../HistoryInjectable";
import {
  InjectedFactoryComponent,
  withInjectedProps,
} from "../InjectorContext";
import { getRoute, Screen } from "../routes";
import { UserViewModel } from "../viewModels/UserViewModel";
import { StyledButton } from "./controls";

import {
  MenuItem as DefaultMenuItem,
  SideBarMenuItemFactory,
} from "./SideBarMenuItem";

interface Props {
  history: History;
  userViewModel: UserViewModel;
}

interface MenuItem extends Omit<DefaultMenuItem, "isSelected"> {
  paths?: ReadonlyArray<Screen>;
  show: () => boolean;
}

@observer
class SideBarMainKlass extends React.Component<Props> {
  static inject: Inject<ReactElement> = (injector) => {
    return () => (
      <SideBarMainKlass
        history={injector.get(HistoryInjectable)()}
        userViewModel={injector.get(UserViewModel)()}
      />
    );
  };

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  @computed get user() {
    return this.props.userViewModel.currentUser;
  }

  @observable menuItems: Array<MenuItem> = [
    {
      name: "Login",
      icon: "login",
      screen: "root",
      paths: ["login"],
      show: () => !this.user,
    },
    {
      name: "Dashboard",
      icon: "home",
      screen: "dashboard",
      paths: ["dashboard"],
      show: () => !!this.user,
    },
    {
      name: "Projects",
      icon: "grid",
      screen: "projects",
      paths: ["projects", "project"],
      show: () => !!this.user,
    },
    {
      name: "Invitations",
      icon: "user-plus",
      screen: "invitations",
      paths: ["invitations"],
      show: () => !!this.user,
    },
    // { name: "Payments", icon: "dollar", screen: "dashboard" },
  ];


  handleCreateNewProjectClick = async () => {
    this.props.history.push(getRoute("newProject").path);
  };

  render() {
    const currentPath = this.props.history.location.pathname;
    const menuItemsToShow = this.menuItems.filter((item) => item.show())
    return (
      <>
        <View style={styles.menuItems}>
          {menuItemsToShow.map((props) => {
            return (
              <InjectedFactoryComponent
                factory={SideBarMenuItemFactory}
                key={props.name}
                props={{
                  ...props,
                  isSelected: props.paths?.some((screen) =>
                    matchPath(currentPath, getRoute(screen).path)
                  ),
                }}
              />
            );
          })}
        </View>
        
        {this.user && <StyledButton
          text="New Project"
          variant="primary"
          iconRight={{ name: "plus", type: "white", size: 24 }}
          style={styles.newProjectButton}
          onPress={this.handleCreateNewProjectClick}
        />}
      </>
    );
  }
}

const styles = StyleSheet.create({
  menuItems: {
    marginBottom: 48,
  },
  newProjectButton: {
    marginTop: 16,
  },
});

export const SideBarMain = withInjectedProps(SideBarMainKlass);
