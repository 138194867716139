import React from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import { Icon, StyledText, StyledTouchableOpacity } from "./controls";
import { Palette } from "./styles";

export interface Steps {
  name: string;
  isActive: boolean;
  isCompleted: boolean;
  step: number;
  onPress?: () => void;
}

interface Props {
  steps: Steps[];
  style?: ViewStyle;
}

export class ProgressSteps extends React.Component<Props> {
  render() {
    const { steps } = this.props;
    return (
      <View style={[styles.container, styles.row, this.props.style]}>
        {steps.map((step) => (
          <ProgressStep key={step.name} {...step} />
        ))}
      </View>
    );
  }
}

const ProgressStep: React.FunctionComponent<Steps> = (props) => {
  const { name, isCompleted, isActive, step, onPress } = props;
  const textColor = isActive ? "highlight" : isCompleted ? "dark" : "gray";
  const numberContainerStyle = isActive
    ? styles.activeNumberContainer
    : isCompleted && styles.completedNumberContainer;

  const handleOnPress = () => {
    if (onPress) {
      onPress();
    }
  };
  return (
    <StyledTouchableOpacity
      onPress={handleOnPress}
      disabled={isActive || !isCompleted}
      style={[styles.progressStepContainer, styles.row]}
    >
      <View style={[styles.numberContainer, numberContainerStyle]}>
        {!isActive && isCompleted ? (
          <View style={styles.selectedIconContainer}>
            <Icon name="check" type="white" size={16} />
          </View>
        ) : (
          <StyledText
            variant="body2"
            isBold={true}
            colorMode={isActive ? "highlight" : "dark"}
          >
            {step}
          </StyledText>
        )}
      </View>
      <StyledText variant="body2" isBold={true} colorMode={textColor}>
        {name}
      </StyledText>
    </StyledTouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  progressStepContainer: {
    marginRight: 48,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  numberContainer: {
    width: 32,
    height: 32,
    borderRadius: 16,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Palette.Primary25Pct,
    marginRight: 16,
  },
  activeNumberContainer: {
    borderWidth: 2,
    backgroundColor: "none",
    borderColor: Palette.Secondary100Pct,
  },
  completedNumberContainer: {
    backgroundColor: Palette.Affirm100Pct,
  },
  completedIcon: {
    height: 8,
    width: 10,
    left: 4,
  },
  selectedIconContainer: {
    width: 24,
    height: 24,
    borderRadius: 16,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Palette.Affirm100Pct,
  },
});
