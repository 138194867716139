import React, { FC, useEffect, useRef, useState } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { pdfjs } from "@mikecousins/react-pdf";
import { LoadingIndicator } from "./LoadingIndicator";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PdfDocInfo {
  numPages: number;
  doc: pdfjs.PDFDocumentProxy;
}

interface PdfViewerProps {
  file: string;
  style?: StyleProp<ViewStyle>;
}

export const PdfViewer: React.FunctionComponent<PdfViewerProps> = ({
  file,
  style,
}) => {
  const pdfPages = useRef<boolean[]>([]);
  const [allPagesLoaded, setAllPagesLoaded] = useState(false);
  const [pdfDoc, setPdfDoc] = useState<PdfDocInfo>();

  useEffect(() => {
    const loadingTask = pdfjs.getDocument(file);
    if (!loadingTask?.promise) return;
    loadingTask.promise.then(async (doc: pdfjs.PDFDocumentProxy) => {
      const numPages = doc._pdfInfo.numPages;
      pdfPages.current = Array.from(new Array(numPages), () => false);
      setPdfDoc({ doc: doc, numPages: numPages });
    });
  }, [file]);

  const handleRenderComplete = (index: number) => {
    pdfPages.current[index] = true;
    if (pdfPages.current.every((page) => page)) setAllPagesLoaded(true);
  };

  return (
    <View style={style}>
      <View>
        {pdfDoc && (
          Array.from(new Array(pdfDoc.numPages), (_, index) => (
            <PDFcanvas
              key={index}
              pdf={pdfDoc.doc}
              pageIndex={index + 1}
              onRenderComplete={() => handleRenderComplete(index)}
            />
          ))
        )}
      </View>
      {!allPagesLoaded && <LoadingIndicator />}
    </View>
  );
};

interface PDFcanvasProps {
  pdf: pdfjs.PDFDocumentProxy;
  pageIndex: number;
  onRenderComplete: () => void;
}

const PDFcanvas: FC<PDFcanvasProps> = ({
  pdf,
  pageIndex,
  onRenderComplete,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!canvasRef.current || !pdf) return;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    pdf.getPage(pageIndex).then(async (page: pdfjs.PDFPageProxy) => {
      // TODO: Add document scaling based on the size of the container
      const viewport = page.getViewport({ scale: 1.3 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      var renderTask = page.render(renderContext);
      renderTask.promise.then(onRenderComplete);
    });
  }, [canvasRef, pdf, pageIndex, onRenderComplete]);

  return (
    <View style={styles.pageStyle}>
      <canvas ref={canvasRef} />
    </View>
  );
};

const styles = StyleSheet.create({
  pageStyle: {
    borderWidth: 1,
    borderColor: "lightgrey",
    marginBottom: 8,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 2,
  },
});
