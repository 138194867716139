import { FunctionComponent, useEffect, useState } from "react";
import {
  Modal,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import {
  Contractor,
  ContractorModel,
  InvitedUser,
  ProjectModel,
} from "../../../data-model";
import { useMount } from "../utils/Hooks";
import { Icon, StyledButton, StyledText } from "./controls";
import { FuzzySearch } from "./FuzzySearch";
import { SectionBlock } from "./SectionBlock";
import { Palette } from "./styles";
import { UserInfo } from "./UserInfo";

interface InviteModalProps {
  contractorModel: ContractorModel;
  projectId: string;
  projectModel: ProjectModel;
}

export const InviteModal: FunctionComponent<InviteModalProps> = ({
  contractorModel,
  projectId,
  projectModel,
}) => {
  const [acceptedContractors, setAcceptedContractors] = useState<InvitedUser[]>(
    []
  );
  const [invites, setInvites] = useState<InvitedUser[]>([]);
  const [invitesRetrieved, setInvitesRetrieved] = useState<boolean>(false);

  useEffect(() => {
    const getInvites = async () => {
      if (projectId) {
        setInvites(await projectModel.getInvitesByProjectId(projectId));
        setAcceptedContractors(
          (await projectModel.getRolesByProjectId(projectId)).filter(
            (r) => r.role === "contractor"
          )
        );
        setInvitesRetrieved(true);
      }
    };

    getInvites();
  }, [contractorModel, projectId, projectModel]);

  const handleSendInvitation = async (newInvites: InvitedUser[]) => {
    try {
      newInvites.forEach((invite) => {
        projectModel.inviteContractor({
          projectId: projectId,
          contractorId: invite.userId,
        });
      });
      setInvites([...invites, ...newInvites]);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteInvitation = async (invite: InvitedUser) => {
    try {
      await projectModel.deleteInvitation({
        projectId: projectId,
        userId: invite.userId,
      });
      setInvites(invites.filter((i) => i.userId !== invite.userId));
    } catch (e) {
      console.log(e);
    }
  };

  const renderInvites = () => {
    const invited = invites.map((invite, idx) => (
      <View style={styles.selectedInviteContainer} key={idx}>
        <UserInfo variant="name-email" user={invite} />
        <View style={styles.selectedInviteStatusContainer}>
          <StyledText
            variant="body2"
            colorMode="gray"
            style={styles.selectedInviteStatus}
          >
            Invited
          </StyledText>
          <StyledText
            variant="body2"
            colorMode="warning"
            onPress={() => handleDeleteInvitation(invite)}
          >
            Remove
          </StyledText>
        </View>
      </View>
    ));

    const accepted = acceptedContractors.map((contractor, idx) => (
      <View style={styles.selectedInviteContainer} key={`acc${idx}`}>
        <UserInfo variant="name-email" user={contractor} />
      </View>
    ));

    return [...invited, ...accepted];
  };

  return (
    <View>
      {renderInvites()}
      {invitesRetrieved &&
        invites.length === 0 &&
        acceptedContractors.length === 0 && (
          <InviteContractorModal
            contractorModel={contractorModel}
            onSubmitInvite={handleSendInvitation}
          />
        )}
    </View>
  );
};

interface InviteContractorModalProps {
  contractorModel: ContractorModel;
  onSubmitInvite: (invites: InvitedUser[]) => void;
}

// TODO: Filter out currently invited users once multi selection is added
const InviteContractorModal: FunctionComponent<InviteContractorModalProps> = ({
  contractorModel,
  onSubmitInvite,
}) => {
  const [visible, setVisible] = useState(false);
  const [invites, setInvites] = useState<InvitedUser[]>([]);
  const [contractors, setContractors] = useState<Contractor[]>([]);

  useMount(() => {
    const execAsync = async () =>
      setContractors(await contractorModel.getContractors());
    execAsync();
  });

  const handleSelection = (invite: any) => {
    setInvites([invite]);
    // TODO: allow multi selection
    // setInvites([...invites, invite]);
  };

  const handleSendInviteClick = () => {
    onSubmitInvite(invites);
    hideModal();
  };

  const handleRemoveInvite = (index: number) => {
    invites.splice(index, 1);
    setInvites([...invites]);
  };

  const hideModal = () => {
    setVisible(false);
    setInvites([]);
  };

  return (
    <>
      {/* Button to open modal */}
      <StyledButton
        iconLeft={{
          name: "user-plus",
          type: "accent",
        }}
        variant="secondary"
        text="Invite Contractor"
        style={{ marginTop: 5, height: 48, maxWidth: 200 }}
        onPress={() => setVisible(true)}
      />
      <Modal
        animationType="fade"
        transparent={true}
        visible={visible}
        onRequestClose={hideModal}
      >
        <View style={styles.centeredView}>
          {/* Background */}
          <TouchableWithoutFeedback onPress={hideModal}>
            <View style={styles.background} />
          </TouchableWithoutFeedback>

          {/* Modal */}
          <View style={styles.modalView}>
            <View style={styles.iconContainer}>
              <Icon
                name="user-plus"
                size={24}
                // style={styles.modalIcon}
              />
            </View>

            <View style={[styles.section, { width: "100%" }]}>
              <SectionBlock title="Invite Contractor">
                <StyledText style={styles.sectionText}>
                  Add a contractor to this project.
                </StyledText>
              </SectionBlock>
            </View>
            <FuzzySearch
              onSelect={handleSelection}
              searchList={contractors}
              variant={"contractor"}
            />

            {/* List on newly selected invites */}
            {invites.map((invite, idx) => (
              <View style={styles.selectedInviteContainer} key={idx}>
                <UserInfo variant="name-email" user={invite} colorIndex={idx} />
                <Icon
                  name="x"
                  size={24}
                  style={styles.cancelIcon}
                  onClick={() => handleRemoveInvite(idx)}
                />
              </View>
            ))}

            <StyledButton
              onPress={handleSendInviteClick}
              text="Send Invitation"
              style={styles.inviteButton}
            />
          </View>
        </View>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  section: {
    marginBottom: 24,
  },
  sectionText: {
    lineHeight: 24,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  background: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    width: "30vh",
    maxWidth: 500,
    borderRadius: 8,
    padding: 35,
    alignItems: "center",
    elevation: 5,
    minWidth: 400,
  },
  iconContainer: {
    padding: 20,
    borderRadius: 30,
    position: "absolute",
    top: -27,
    backgroundColor: Palette.White,
  },
  inviteButton: {
    marginTop: 30,
  },
  selectedInviteContainer: {
    width: "100%",
    marginTop: 20,
  },
  cancelIcon: {
    position: "absolute",
    right: 0,
    marginTop: 5,
  },
  selectedInviteStatusContainer: {
    marginTop: 5,
    flexDirection: "row",
  },
  selectedInviteStatus: {
    marginRight: 15,
  },
});
