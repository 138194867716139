export function modifySearchResult<T extends unknown, K extends keyof T>(
  key: K
): (result: Array<T>, keyword: string) => Array<T> {
  return (
    (result: Array<T>, keyword: string) => {
      if (result.length === 0) {
        const newValue = keyword.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
        return [{
          [key]: newValue
        }] as Array<T>;
      }
      return result;
    }
  )
};
