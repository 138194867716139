import React from "react";
import { StyleSheet, View } from "react-native";
import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { Inject } from "@not-the-droids/exco-ts-inject";
import { withInjectedFactory } from "../InjectorContext";
import { formatCurrencyToString } from "../utils/Numbers";
import { StyledButton } from "./controls/StyledButton";
import { StyledText } from "./controls/StyledText";
import {
  BudgetOptionsRequired,
  ProjectDetailsFlow,
} from "../flows/ProjectDetailsFlow";
import { RadioButton } from "./RadioButton";

interface Props {
  projectDetailsFlow: ProjectDetailsFlow;
}

interface CreateProps {
  onSubmit: () => void;
  totalProjectPrice: number;
  totalProjectTime: number;
}

const budgetOptionsItem = ["Permits", "Inspection"] as const;
type BudgetOptionsItem = typeof budgetOptionsItem[number];

interface BudgetOptions {
  value: BudgetOptionsRequired;
  text: string;
}

interface BudgetOptionsInfo {
  title: string;
  description: string;
  options: Array<BudgetOptions>;
}

const permitItemInfo: Record<BudgetOptionsItem, BudgetOptionsInfo> = {
  Permits: {
    title: "Permits",
    description: "Do you already have permits for your project?",
    options: [
      {
        value: "Yes",
        text: "Yes",
      },
      {
        value: "No",
        text: "No",
      },
      {
        value: "Without",
        text: "Not required",
      },
    ],
  },
  Inspection: {
    title: "Inspection",
    description: "Is an inspection required for this project?",
    options: [
      {
        value: "Yes",
        text: "Yes",
      },
      {
        value: "No",
        text: "No",
      },
    ],
  },
};

class BudgetOptionsViewFactory {
  static inject: Inject<BudgetOptionsViewFactory> = (injector) => {
    return () =>
      new BudgetOptionsViewFactory({
        projectDetailsFlow: injector.get(ProjectDetailsFlow)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <BudgetOptionsView {...this.props} {...props} />;
  }
}

@observer
export default class BudgetOptionsView extends React.Component<
  CreateProps & Props
> {
  @observable private initialSelection: boolean = true;
  @observable private optionRequired: Record<
    BudgetOptionsItem,
    BudgetOptionsRequired | undefined
  > = {
    Permits: undefined,
    Inspection: undefined,
  };

  constructor(props: CreateProps & Props) {
    super(props);
    makeObservable(this);
  }

  handleSubmit = () => {
    if (this.initialSelection) {
      this.props.projectDetailsFlow.arePermitsRequired =
        this.optionRequired["Permits"]!;
      this.initialSelection = false;
    } else {
      this.props.projectDetailsFlow.isInspectionRequired =
        this.optionRequired["Inspection"]!;
      this.props.onSubmit();
    }
  };

  renderSelectionScreen = (permitItem: BudgetOptionsItem) => {
    const itemInfo = permitItemInfo[permitItem];
    const submitText = this.initialSelection
      ? "Save and Continue"
      : "Save and Preview";
    return (
      <>
        <View style={{ marginBottom: 100 }}>
          <View style={{ marginBottom: 24 }}>
            <StyledText variant="heading" style={{ marginBottom: 4 }}>
              {itemInfo.title}
            </StyledText>
            <StyledText variant="body" isBold={false}>
              {itemInfo.description}
            </StyledText>
          </View>
          <View style={styles.permitButtons}>
            {itemInfo.options.map((option) => {
              return (
                <RadioButton
                  style={{ flex: 1, marginRight: 15 }}
                  key={`budgetOption-${option.text}`}
                  checked={this.optionRequired[permitItem] === option.value}
                  onPress={() =>
                    (this.optionRequired[permitItem] = option.value)
                  }
                >
                  {option.text}
                </RadioButton>
              );
            })}
          </View>
        </View>
        <StyledButton
          style={{ width: 200 }}
          disabled={!this.optionRequired[permitItem]}
          text={submitText}
          onPress={this.handleSubmit}
        />
      </>
    );
  };

  render() {
    const { totalProjectPrice, totalProjectTime } = this.props;
    return (
      <View style={styles.container}>
        <View style={styles.headerTop}>
          <StyledText variant="heading3" isBold={true}>
            Budget
          </StyledText>
          <View style={[styles.row, styles.headerRight]}>
            <View style={styles.row}>
              <StyledText
                variant="heading3"
                colorMode="dark"
                style={{ marginBottom: 4 }}
              >{`Total Time: `}</StyledText>
              <StyledText variant="heading3" colorMode="dark" isBold={true}>
                {totalProjectTime ? `${totalProjectTime} Days` : "--"}
              </StyledText>
            </View>
            <View style={[styles.row, { marginLeft: 24 }]}>
              <StyledText
                variant="heading3"
                style={{ marginBottom: 4 }}
              >{`Total Cost: `}</StyledText>
              <StyledText variant="heading3" isBold={true}>
                {formatCurrencyToString(totalProjectPrice)}
              </StyledText>
            </View>
          </View>
        </View>
        {this.initialSelection
          ? this.renderSelectionScreen("Permits")
          : this.renderSelectionScreen("Inspection")}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  headerTop: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 50,
  },
  row: {
    flexDirection: "row",
  },
  headerRight: {
    justifyContent: "flex-end",
  },
  permitButtons: {
    flexDirection: "row",
    maxWidth: 400
  },
});

export const InjectedBudgetOptionsView = withInjectedFactory(
  BudgetOptionsViewFactory
);
