import React, { ReactElement } from "react";
import { Image, StyleSheet, TouchableOpacity, View } from "react-native";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";
import { Palette } from "./styles";
import { Inject } from "@not-the-droids/exco-ts-inject";
import { withInjectedFactory } from "../InjectorContext";
import { ColorVariant, Icon, StyledButton, StyledText, StyledTextInput, StyledTouchableOpacity } from "./controls";
import { computed, makeObservable, observable, runInAction } from "mobx";
import { Budget, BudgetMilestone, BudgetMilestoneTask, BudgetModel, BudgetPhase, Comment, ChangeRequestType, Project, ChangeRequest, PunchListItem, MilestoneCompletionType } from "../../../data-model";
import { Notification } from "../NotificationInjectable";
import { formatCurrencyToString } from "../utils/Numbers";
import { UserViewModel } from "../viewModels/UserViewModel";
import { GroupedComments, ProjectWidgetManager } from "../managers/ProjectWidgetManager";
import { ProjectWidgetComment } from "./ProjectWidgetComment";
import { formatChangeRequestTag, formatCompletionTagProps } from "../utils/Strings";
import { Tag } from "./controls/Tag";
import { ProjectDetailsFlow } from "../flows/ProjectDetailsFlow";
import { History, HistoryInjectable } from "../HistoryInjectable";
import { MilestoneManagementProps, MyContext } from "./ProjectMilestonesViewControl";
import { OrderedCommentComponent } from "./ProjectWidget";
import { noop } from "../utils/Hooks";
import FileUpload from "./FileUpload";
import { uploadFromBlobAsync } from "../utils/Storage";
import { isBudgetMilestone } from "../utils/Budget";
import { LoadingIndicator } from "./LoadingIndicator";

const menuItems = ["Details", "Activity", "Comments", "Files"] as const;
export type SidebarMenuItem = typeof menuItems[number];

const milestoneOptions = ["Edit", "Upload Files"/**, "Mark Completed", "Delete"*/] as const;
type MilestoneOptions = typeof milestoneOptions[number];

interface MilestoneOptionData {
  method: () => void;
  disabled: () => boolean;
  color: ColorVariant;
}

interface Props {
  budgetModel: BudgetModel;
  history: History;
  notification: Notification;
  projectDetailsFlow: ProjectDetailsFlow;
  projectWidgetManager: ProjectWidgetManager;
  userViewModel: UserViewModel;
}

interface CreateProps {
  budget: Budget;
  onEditPress: (changeRequestType: ChangeRequestType, selectedPhase: BudgetPhase, selectedMilestone?: BudgetMilestone) => void;
  project: Project;
}

export class ManagementSidebarViewFactory {
  static inject: Inject<ManagementSidebarViewFactory> = (injector) => {
    return () =>
      new ManagementSidebarViewFactory({
        budgetModel: injector.get(BudgetModel)(),
        history: injector.get(HistoryInjectable)(),
        notification: injector.get(Notification)(),
        projectDetailsFlow: injector.get(ProjectDetailsFlow)(),
        projectWidgetManager: injector.get(ProjectWidgetManager)(),
        userViewModel: injector.get(UserViewModel)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return (
      <MyContext.Consumer>
        {consumer => (
          <ManagementSidebarView {...this.props} {...props} {...consumer} />
        )}
      </MyContext.Consumer>
    );
  }
}

@observer
class ManagementSidebarView extends React.Component<Props & CreateProps & MilestoneManagementProps> {
  constructor(props: Props & CreateProps & MilestoneManagementProps) {
    super(props);
    makeObservable(this);
  };

  @observable private commentInput: string = "";
  @observable private isFileLoading: boolean = false;
  @observable private isOptionOpen: boolean = false;
  @observable private isItemInputVisible: boolean = false;
  @observable private punchListItemText: string = '';
  private inputFile = React.createRef<HTMLInputElement>();
  private isManagingFocus: boolean = false;
  private _timeoutId: any;

  @computed private get allTasksCompleted() {
    const { projectDetailsFlow: { selectedMilestone } } = this.props;
    const currentMilestone = selectedMilestone as BudgetMilestone;
    return currentMilestone?.tasks.every((task) => task.completed);
  }

  @computed private get isPunchListVisible() {
    const { projectDetailsFlow: { selectedMilestone }, userViewModel } = this.props;
    const currentMilestone = selectedMilestone as BudgetMilestone;
    return (currentMilestone?.completion === "pending" || currentMilestone?.completion === "completed") &&
    (userViewModel.isContractor ? (currentMilestone?.punchListItems && currentMilestone?.punchListItems?.length > 0) : true)
  }

  @computed private get optionDropdownMenu() {
    return (
      <View style={styles.optionDropdown}>
        {
          milestoneOptions.map((milestoneOption, index) => {
            const onPress = this.milestoneOptionsNav[milestoneOption].method;
            const disabled = this.milestoneOptionsNav[milestoneOption].disabled();
            const optionStyle = index < milestoneOptions.length - 1 && styles.optionLineDropdown;
            const colorMode = disabled ? "gray" : this.milestoneOptionsNav[milestoneOption].color
            return (
              <TouchableOpacity 
                key={"optionButton" + milestoneOption} 
                disabled={disabled}
                style={optionStyle} 
                onPress={onPress}
                onPressIn={() => this.onFocus()}
                onPressOut={() => this.onBlur(() => {this.isOptionOpen = false})}
              >
                <View style={{marginHorizontal: 16}}>
                  <StyledText variant={"body"} colorMode={colorMode}>{milestoneOption}</StyledText>
                </View>
              </TouchableOpacity>
            );
          })
        }
      </View>
    );
  };

  @computed public get totalMilestonePrice(): number {
    const { projectDetailsFlow: { selectedMilestone } } = this.props; 
    let totalCost: number = 0;
    selectedMilestone?.tasks?.forEach((task: BudgetMilestoneTask) => {
      totalCost += Number(task.budget);
    });
    return Math.round((totalCost + Number.EPSILON) * 100) / 100;
  };

  @computed public get alertMessage() {
    const { 
      isManagementFocus, 
      onEditPress, 
      projectDetailsFlow: { 
        changeRequests, 
        selectedMilestone, 
        selectedPhase 
      }, 
      userViewModel 
    } = this.props;
    const currentMilestone = selectedMilestone as BudgetMilestone;

    const changeRequest = changeRequests.find((changeRequest) => {
      return (
        changeRequest.milestoneId === currentMilestone?.id
      )
    });

    const requestMessageRecord: Record<ChangeRequestType, string> = {
      'change': 'Awaiting Change Order Approval',
      'complete': 'Awaiting Milestone Completion and Payment Release',
      'final': 'Awaiting Project Completion',
    }

    return (
      isManagementFocus && (
        (changeRequest && changeRequest.currentStatus === "submitted" && changeRequest.changeRequestType) ? (
          <View style={styles.alertWarning}>
            <Icon name={"alert-circle"} type={"secondary"} size={20}/>
            <StyledText colorMode="secondary" variant="body">{requestMessageRecord[changeRequest.changeRequestType]}</StyledText>
            <StyledTouchableOpacity onPress={() => this.handleViewDetails(changeRequest)} style={{flexDirection: "row", alignItems: "center"}}>
              <StyledText colorMode="secondary" variant="body" isBold>{"View Details"}</StyledText>
              <Icon name={"chevron-right"} type={"secondary"} size={14} style={{marginTop: "auto", marginLeft: 5}}/>
            </StyledTouchableOpacity>
          </View>
        ) : (
          (currentMilestone!.completion !== "completed" && this.allTasksCompleted && userViewModel.isContractor) && (
            (isBudgetMilestone(selectedMilestone) && selectedMilestone.files.length > 0) ? (
              <View style={styles.alertCompletion}>
                <StyledText colorMode="accent" variant="body">{"You’ve checked off all of your tasks, are you ready to complete the milestone for Property Owner approval?"}</StyledText>
                <StyledTouchableOpacity onPress={() => onEditPress("complete", selectedPhase!, currentMilestone)} style={{flexDirection: "row", alignItems: "center"}}>
                  <StyledText colorMode="accent" variant="body" isBold>{"Complete Milestone"}</StyledText>
                  <Icon name={"chevron-right"} type={"accent"} size={14} style={{marginTop: "auto", marginLeft: 5}}/>
                </StyledTouchableOpacity>
              </View>
            ) : (
              <View style={styles.alertCompletion}>
                <StyledText colorMode="accent" variant="body">{"A file upload is required for proof of completion."}</StyledText>
                <StyledTouchableOpacity onPress={() => this.props.projectWidgetManager.selectedChangeRequestMenuItem = 'Files'} style={{flexDirection: "row", alignItems: "center"}}>
                  <StyledText colorMode="accent" variant="body" isBold>{"Go to Files"}</StyledText>
                  <Icon name={"chevron-right"} type={"accent"} size={14} style={{marginTop: "auto", marginLeft: 5}}/>
                </StyledTouchableOpacity>
              </View>
            )
          )
        )
      )
    )
  }

  @computed public get commentsTab() {
    const { projectDetailsFlow: { changeRequests, selectedChangeRequest, selectedMilestone }, projectWidgetManager } = this.props;

    const renderCommentTime = (date: Date) => {
      const commentDate = new Date(date);
      return (
        <View style={{flexDirection: "column"}}>
          <StyledText variant="body">
            {
              (new Date(commentDate)).toLocaleDateString(undefined, {
                timeZone: "UTC",
                month: "long",
                day: "numeric",
              })
            }
          </StyledText>
          <StyledText variant="caption" colorMode={"gray"}>
            {
              (new Date(commentDate)).toLocaleString([], {
                hour: '2-digit',
                minute: '2-digit'
            })
            }
          </StyledText>
        </View>
      );
    }

    const renderTag = () => {
      const { projectWidgetManager } = this.props;
      return (
        (projectWidgetManager.activeChangeRequestCommentIndex > -2) ? (
          <View style={commentStyles.tagContainer}>
            <StyledText
              variant="caption"
              colorMode="accent"
            >
              {"Replying in: "}
            </StyledText>
            <View style={commentStyles.tag}>
              <StyledText
                style={{
                  justifyContent: "center",
                  alignContent: "center"
                }}
                variant="caption"
                isBold
              >
                {projectWidgetManager.selectedChangeRequestDescription}
              </StyledText>
            </View>
          </View> 
        ) : (
          undefined
        )
      )
    }

    const getAllWidgetComments = () => {
      const tag = this.props.projectDetailsFlow.selectedChangeRequest?.changeRequestType === "final" ? "Project Completion" : selectedMilestone!.name;
      const allComments = projectWidgetManager.retrieveSortedMilestoneComments(tag, selectedChangeRequest?.id, changeRequests);
      return (
        allComments.map((comment) => {
          // @ts-ignore
          if (comment['id']) {
            const budgetComment = (comment as Comment);
            return (
              <ProjectWidgetComment
                key={`sidebar-comment-${budgetComment.id}`}
                comment={budgetComment}
                alternateTime={renderCommentTime(comment.createdAt)}       
              />
            );
          } else {
            const changeRequestComments = (comment as GroupedComments);
            const changeRequest = changeRequests.find(cr => cr.id === changeRequestComments.mostRecentComment!.parentId)
            const category = changeRequest ? formatChangeRequestTag(changeRequest) : undefined
            const numReplies = changeRequestComments.taggedComments.reduce((prevVal, currVal) => currVal.comments.length + prevVal, 0);
            return (
              <ProjectWidgetComment
                key={`change-request-comment-${changeRequestComments.mostRecentComment?.id}`}
                category={category}
                comment={changeRequestComments.mostRecentComment!}
                alternateTime={renderCommentTime(comment.createdAt)}
                alternateBody={
                  <StyledButton
                    style={{marginRight: "auto", marginLeft: 10}}
                    textStyle={{color: Palette.Primary100Pct, borderBottomColor: Palette.Primary100Pct, fontSize: 13}}
                    variant="link"
                    iconLeft={{name: "corner-down-right", size: 16}}
                    text={`${numReplies} ${numReplies < 2 ? "reply" : "replies"}`}
                    onPress={() => {
                      projectWidgetManager.activeChangeRequestId = changeRequest?.id;
                      projectWidgetManager.activeCommentTag = undefined;
                    }}
                  />
                }     
              />
            );
          }
        }).reverse()
      );
    }

    const getChangeRequestComments = (changeRequestId: string) => {
      const {projectWidgetManager: { activeCommentTag, changeRequestCommentsByTag } } = this.props;
      const commentByTag = changeRequestCommentsByTag.get(changeRequestId);
      const commentsElements: OrderedCommentComponent[] = [];
      if (!commentByTag) return;
      if (!!activeCommentTag) {
        const comments = commentByTag[activeCommentTag]?.comments || [];
        commentsElements.push(...comments?.map(comment => {
          return ({
            date: comment.createdAt,
            commentComponents: [(
              <ProjectWidgetComment
                key={`widget-comment-${comment.id}`}
                comment={comment} 
                category={comment.tag} 
                isNested={false}
              />
            )],
          })
        }))
      } else {
        const commentIdentifiers = Object.keys(commentByTag);
        for (let i = 0; i < commentIdentifiers.length; i++) {
          const commentIdentifier = commentIdentifiers[i];
          const comments = commentByTag[commentIdentifier]?.comments || [];
          const groupedElements: ReactElement[] = [];
          if (comments.length > 0) {
            const createdDate = comments[0].createdAt
            comments.forEach((comment, index) => {
              const isFirst = index === 0;
              const commentTag = commentIdentifier.split('-')[0];
              groupedElements.push(
                <View
                  key={commentIdentifier + comment.id}
                  style={!isFirst && commentStyles.nestedComment}
                >
                  <ProjectWidgetComment
                    key={commentIdentifier + comment.id} 
                    comment={comment}
                    category={commentTag}
                    isNested={!isFirst}
                  />
                </View>
              )
            });
            commentsElements.push({
              date: createdDate,
              commentComponents: groupedElements,
            });
          }
        }
      }

      return commentsElements.sort(
        (comA, comB) => {
          return new Date(comA.date).getTime() - new Date(comB.date).getTime()
        }
      ).map((comment) => comment.commentComponents);
    }

    const isGeneralChangeRequestMenuActive = projectWidgetManager.activeChangeRequestId && !projectWidgetManager.activeCommentTag;

    return (
      <View style={{flexDirection: "column", justifyContent: "flex-end"}}>
        <View style={styles.commentBlock}>
          {
            isGeneralChangeRequestMenuActive && (
              <View style={{marginRight: "auto", marginBottom: 10}}>
                <StyledButton
                  textStyle={{color: Palette.Primary100Pct, borderBottomColor: Palette.Primary100Pct}}
                  variant="link"
                  iconLeft={{name: "arrow-left", size: 24}}
                  text={"Back to Comments"}
                  onPress={() => projectWidgetManager.activeChangeRequestId = undefined}
                />
              </View>
            )
          }
          {projectWidgetManager.activeChangeRequestId ? (
            getChangeRequestComments(projectWidgetManager.activeChangeRequestId)
          ) : (
            getAllWidgetComments()
          )}
          {!isGeneralChangeRequestMenuActive && (
            <StyledTextInput
              iconRight={{ name: "send", type: "accent", size: 14, onClick: this.submitMessage }}
              onChangeText={(value) => runInAction(() => this.commentInput = value)}
              placeholder="Write your message here"
              topElement={renderTag()}
              value={this.commentInput}
            />
          )}
        </View>
      </View>
    );
  }

  @computed public get detailsTab() {
    const { projectDetailsFlow: { selectedMilestone }, userViewModel } = this.props;
    const currentMilestone = selectedMilestone as BudgetMilestone;
    return (
      <>
        <View style={styles.textBlock}>
          <StyledText variant={"body"} isBold={true}>{"SCOPE OF WORK"}</StyledText>
          <StyledText variant={"body"}>{currentMilestone?.scopeOfWork}</StyledText>
        </View>
        <View style={styles.taskBlock}>
          {
            currentMilestone.tasks.length > 0 && (
              <StyledText variant={"body"} isBold={true}>{"TASKS"}</StyledText>
            )
          }
          {currentMilestone?.tasks.map((task) => {
            const taskColor = task.completed ? "affirm" : "dark";
            const taskStyle = task.completed && styles.textLineThrough;
            return (
              <View key={"details" + task.orderIndex} style={styles.task}>
                <TouchableOpacity disabled={this.isDisabled || !this.isEditable} activeOpacity={1} onPress={() => this.handleTaskClick(task)}>
                  {
                    task.completed ? (
                      <Icon name={"check-circle-filled"} type={"affirm"}/>
                    ) : (
                      <Icon name={"check-circle"} type={"gray"}/>
                    )
                  }
                </TouchableOpacity>
                <StyledText variant={"body"} colorMode={taskColor} style={taskStyle}>{task.description}</StyledText>
                {
                  userViewModel.isContractor && (
                    <StyledText variant={"body"} style={{marginLeft: "auto"}}>{formatCurrencyToString(task.budget)}</StyledText>
                  )
                }
              </View>
            );
          })}
        </View>
        {this.isPunchListVisible && (
          <View style={styles.taskBlock}>
            <StyledText variant={"body"} isBold={true}>{"PUNCH LIST"}</StyledText>
            {currentMilestone?.punchListItems?.map((item) => {
              const taskColor = item.completed ? "affirm" : "dark";
              const taskStyle = item.completed && styles.textLineThrough;
              return (
                <View key={"punch-list-items-" + item.id} style={styles.task}>
                  <TouchableOpacity disabled={this.isDisabled || !this.isPending} activeOpacity={1} onPress={() => this.handleItemClick(item)}>
                    {
                      item.completed ? (
                        <Icon name={"check-circle-filled"} type={"affirm"}/>
                      ) : (
                        <Icon name={"check-circle"} type={"gray"}/>
                      )
                    }
                  </TouchableOpacity>
                  <StyledText variant={"body"} colorMode={taskColor} style={taskStyle}>{item.description}</StyledText>
                  {
                    (userViewModel.isOwner && !item.completed) && (
                      <StyledButton
                        disabled={item.completed}
                        variant="link"
                        iconLeft={{name: "trash", type: "warning"}}
                        style={{ marginLeft: "auto" }}
                        text={""}
                        onPress={() => this.handleDeleteItem(item.id!)}
                      />
                    )
                  }
                </View>
              );
            })}
            {
              (userViewModel.isOwner && this.isPending) && (
                !this.isItemInputVisible ? (
                  <StyledButton 
                    onPress={() => this.isItemInputVisible = !this.isItemInputVisible}
                    variant="secondary"
                    style={{maxWidth: 225}}
                    text={"Add a Punch List Item"}
                  />
                ) : (
                  <StyledTextInput
                    placeholder="Enter punch list item here"
                    onChangeText={(value) => this.punchListItemText = value}
                    iconRight={{name: "send", type: "accent", onClick: this.handleCreateItem, size: 14}}
                  />
                )
              )
            }
          </View>
        )}
      </>
    );
  };

  @computed public get filesTab() {
    const { budgetModel, projectDetailsFlow: { selectedMilestone }, userViewModel } = this.props;
    if (!isBudgetMilestone(selectedMilestone)) return null;
    const files = selectedMilestone.files;

    const uploadFile = async (files: FileList) => {
      this.isFileLoading = true;
      try {
        const file = files[0];
        const url = await uploadFromBlobAsync({
          blobUrl: URL.createObjectURL(file),
          name: `/bid-files/${file.name}_${Date.now()}`,
        });

        const newFile = {
          fileId: uuidv4(),
          fileName: file.name,
          fileType: "file",
          fileUrl: url,
        };

        if (selectedMilestone?.id) {
          const uploadedFile = await budgetModel.addMilestoneFile(selectedMilestone!.id!, newFile);
          this.isFileLoading = false;
          selectedMilestone.files.push(uploadedFile);
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.isFileLoading = false;
      }
    };

    return (
      <View style={{flexDirection: "column", justifyContent: "space-between"}}>
        <View style={styles.imageContainer}>
          {files.map(file => {
            return (
              <TouchableOpacity 
                key={`touchable-file-sidebar-${file.fileId}`}
                style={styles.image}
                onPress={() => window.open(file.fileUrl)}
              >
                <Image
                  source={{uri: file.fileUrl}}
                  style={{width: "100%", height: 200, borderRadius: 4}}
                />
                <View style={styles.bodyContainer}>
                  <StyledText variant="body2" isBold>
                    {file.fileName}
                  </StyledText>
                  <StyledText variant="caption" colorMode="gray">
                    {new Date(file.createdAt).toDateString()}
                  </StyledText>
                </View>
              </TouchableOpacity>
            )
          })}
          {this.isFileLoading && (
            <View style={[styles.image, {height: 270}]}>
              <LoadingIndicator/>
            </View>
          )}
        </View>
        {userViewModel.isContractor && (
          <>
            <StyledButton
              iconLeft={{name: "upload", type: "accent", size: 24}} 
              onPress={() => {this.inputFile.current?.click()}}
              style={{marginVertical: 24, backgroundColor: Palette.White}}
              variant={"secondary"}
              text={"Upload Files"}
            />
            <FileUpload inputRef={this.inputFile} onFileSelect={uploadFile}/>
          </>
        )}
      </View>
    );
  }

  @computed public get isDisabled() {
    const { isManagementFocus, userViewModel } = this.props
    return userViewModel.isOwner || !isManagementFocus;
  }

  @computed public get isEditable() {
    const { projectDetailsFlow: { selectedMilestone } } = this.props;
    const currentMilestone = selectedMilestone as BudgetMilestone;
    return currentMilestone?.completion === "not-started" ||
    currentMilestone?.completion === "in-progress";
  }

  @computed public get isPending() {
    const { projectDetailsFlow: { selectedMilestone } } = this.props;
    const currentMilestone = selectedMilestone as BudgetMilestone;
    return currentMilestone?.completion === "pending";
  }

  readonly onBlur = (func?: Function) => {
    this._timeoutId = setTimeout(() => {
      if (this.isManagingFocus) {
        func && func();
        this.isManagingFocus = false;
      }
    }, 100);
  }

  readonly onFocus = (func?: Function) => {
    clearTimeout(this._timeoutId);
    this.isManagingFocus = true;
    func && func();
  }

  readonly handleCreateItem = async () => {
    const { budgetModel, projectDetailsFlow: { selectedMilestone } } = this.props;
    const currentMilestone = selectedMilestone as BudgetMilestone;
    if (this.punchListItemText.length < 1) return;
    try {
      const newPunchListItem = await budgetModel.createPunchListItem(selectedMilestone!.id!, this.punchListItemText);
      currentMilestone?.punchListItems?.push(newPunchListItem);
      this.punchListItemText = '';
      this.isItemInputVisible = false;
    } catch (error) {
      console.log(error);
    }
  }

  readonly handleDeleteItem = async (punchListItemId: string) => {
    const { budgetModel, projectDetailsFlow: { selectedMilestone } } = this.props;
    const currentMilestone = selectedMilestone as BudgetMilestone;
    try {
      await budgetModel.deletePunchListItem(selectedMilestone!.id!, punchListItemId);
      const deletedIndex = currentMilestone!.punchListItems?.findIndex(pli => pli.id === punchListItemId);
      deletedIndex && currentMilestone!.punchListItems?.splice(deletedIndex, 1);
    } catch (error) {
      console.log(error);
    }
  }

  readonly handleItemClick = async (punchListItem: PunchListItem) => {
    const { budgetModel, projectDetailsFlow: { selectedMilestone } } = this.props;
    const currentMilestone = selectedMilestone as BudgetMilestone;
    try {
      await budgetModel.updatePunchListItemStatus(selectedMilestone!.id!, punchListItem.id!, !punchListItem.completed);
      const updatedItem = currentMilestone!.punchListItems?.find(pli => pli.id === punchListItem.id);
      updatedItem && (updatedItem.completed = !updatedItem?.completed);
    } catch (error) {
      console.log(error);
    }
  }

  readonly handleTaskClick = async (task: BudgetMilestoneTask) => {
    const { budgetModel, projectDetailsFlow: { budget, selectedMilestone } } = this.props;
    const currentMilestone = selectedMilestone as BudgetMilestone;

    if (currentMilestone!.completion === "completed" || currentMilestone!.completion === "pending") return;
    
    let completionStatus = currentMilestone?.completion
    const allTasksIncompleted = currentMilestone?.tasks.every((nTask) => (nTask.id === task.id) === nTask.completed);
    if (allTasksIncompleted) {
      completionStatus = "not-started";
    } else {
      completionStatus = "in-progress";
    }

    try {
      await budgetModel.updateTaskStatus(selectedMilestone!.id!, task.id!, !task.completed);
      task.completed = !task.completed;
      currentMilestone!.completion = completionStatus;
      const currBudgetMilestone = budget?.milestones.find(m => m.id === selectedMilestone?.id);
      if (currBudgetMilestone) currBudgetMilestone.completion = completionStatus;
    } catch (error) {
      console.log(error);
    }
  }

  readonly handleViewDetails = (changeRequest: ChangeRequest) => {
    const { changeView, projectDetailsFlow } = this.props;
    projectDetailsFlow.selectedChangeRequest = changeRequest;
    changeView();
  }

  readonly menuItemNav: Record<SidebarMenuItem, () => JSX.Element | null> = {
    Details: () => {return this.detailsTab;},
    Activity: () => {return this.detailsTab;},
    Comments: () => {return this.commentsTab;},
    Files: () => {return this.filesTab;},
  };

  readonly milestoneOptionsNav: Record<MilestoneOptions, MilestoneOptionData> = {
    "Upload Files": {
      method: () => this.props.projectWidgetManager.selectedChangeRequestMenuItem = "Files",
      disabled: () => false,
      color: "dark",
    },
    "Edit": {
      method: () => 
        this.props.onEditPress(
          "change",
          this.props.projectDetailsFlow.selectedPhase!,
          (this.props.projectDetailsFlow.selectedMilestone as BudgetMilestone)
        ),
      disabled: () => !this.isEditable,
      color: "dark",
    },
    // "Mark Completed": {
    //   method: () => {},
    //   disabled: () => (this.props.projectDetailsFlow.selectedMilestone as BudgetMilestone)!.completion !== "completed",
    //   color: "affirm",
    // },
    // "Delete": {
    //   method: () => {},
    //   disabled: () => false,
    //   color: "warning"
    // },
  }

  readonly renderMenuItem = (item: SidebarMenuItem) => {
    const { budget, projectWidgetManager, projectDetailsFlow: { selectedMilestone } } = this.props;
    const isSelected = this.props.projectWidgetManager.selectedChangeRequestMenuItem === item;
    const selectedStyle = isSelected && styles.selectedMenuItem;
    return (
      <StyledTouchableOpacity
        key={"sidebarMenuItem" + item}
        style={[styles.menuItem, selectedStyle]}
        onPress={() => {
          this.commentInput = '';
          projectWidgetManager.activeChangeRequestId = undefined;
          projectWidgetManager.activeChangeRequestCommentIndex = -2;
          if (item === "Comments") {
            projectWidgetManager.setActiveCommentInfo(budget.id, selectedMilestone!.name)
          }
          this.props.projectWidgetManager.selectedChangeRequestMenuItem = item;
        }}
      >
        <StyledText
          isBold={true}
          variant={"body2"}
          colorMode={isSelected ? "accent" : "gray"}
        >
          {item}
        </StyledText>
      </StyledTouchableOpacity>
    );
  };

  readonly submitMessage = () => {
    const { projectWidgetManager } = this.props;
    const commentInput = this.commentInput;
    this.commentInput = '';
    projectWidgetManager.submitComment(commentInput);
  }

  readonly renderMainSidebar = () => {
    const { projectDetailsFlow: { selectedMilestone } } = this.props;
    const currentMilestone = selectedMilestone as BudgetMilestone;
    const completionStatus = currentMilestone?.completion;
    return (
      <>
        {this.alertMessage}
        <View style={styles.container}>
          {/* Header */}
          <View style={styles.header}>
            <View style={styles.headerBar}>
              <View style={styles.tagSection}>
                <Tag {...formatCompletionTagProps(completionStatus!)}/>
                {/* <StyledButton textStyle={{fontSize: 13, lineHeight: 16}} variant="secondary" text={"Test"}/> */}
              </View>
              <View style={styles.milestonePrice}>
                <StyledText variant="body2" isBold={true}>{"BUDGET"}</StyledText>
                <StyledText variant="body2">{formatCurrencyToString(this.totalMilestonePrice)}</StyledText>
              </View>
            </View>
            <View style={styles.headerBar}>
              <StyledText variant="heading3" isBold={true}>{currentMilestone?.name}</StyledText>
              {
                !this.isDisabled && (
                  <TouchableOpacity 
                    onPress={() => this.onFocus(() => this.isOptionOpen = !this.isOptionOpen)}
                    onBlur={() => this.onBlur(() => {this.isOptionOpen = false})}
                  >
                    <Icon name={"ellipsis"} type={"accent"} size={24}/>
                  </TouchableOpacity>
                )
              }
            </View>
            <View style={styles.menuBar}>
              {menuItems.map(this.renderMenuItem)}
            </View>
          </View>

          {/* Body */}
          <View style={styles.body}>
            {this.menuItemNav[this.props.projectWidgetManager.selectedChangeRequestMenuItem]()}
          </View>
          {this.isOptionOpen && this.optionDropdownMenu}
        </View>
      </>
    );
  };

  readonly renderCommentOnlySidebar = () => {
    const { projectDetailsFlow: { selectedChangeRequest, selectedMilestone } } = this.props;
    const completionStatus: MilestoneCompletionType = selectedChangeRequest?.currentStatus === "approved" ? "in-progress" : "needs-review";
    return (
      <>
        {this.alertMessage}
        <View style={styles.container}>
          {/* Header */}
          <View style={styles.header}>
            <View style={styles.headerBar}>
              <View style={styles.tagSection}>
                <Tag {...formatCompletionTagProps(completionStatus)}/>
              </View>
              <View style={styles.milestonePrice}>
                <StyledText variant="body2" isBold={true}>{"BUDGET"}</StyledText>
                <StyledText variant="body2">{formatCurrencyToString(this.totalMilestonePrice)}</StyledText>
              </View>
            </View>
            <View style={styles.headerBar}>
              <StyledText variant="heading3" isBold={true}>{selectedMilestone?.name}</StyledText>
            </View>
            <View style={styles.menuBar}>
              <StyledTouchableOpacity
                style={[styles.menuItem, styles.selectedMenuItem]}
                onPress={noop}
              >
                <StyledText
                  isBold={true}
                  variant={"body2"}
                  colorMode={"accent"}
                >
                  {"Comments"}
                </StyledText>
              </StyledTouchableOpacity>
            </View>
          </View>

          {/* Body */}
          <View style={styles.body}>
            {this.menuItemNav["Comments"]()}
          </View>
        </View>
      </>
    );
  }

  readonly renderCompletionSidebar = () => {
    const { project, projectDetailsFlow: { selectedChangeRequest } } = this.props;
    const completionStatus: MilestoneCompletionType = selectedChangeRequest?.currentStatus === "approved" ? "in-progress" : "needs-review";

    const calculateProjectCost = (project.budget as Budget).phases.reduce(
      (preVal, curVal) => preVal + curVal.cost, 0
    )

    return (
      <>
        {this.alertMessage}
        <View style={styles.container}>
          {/* Header */}
          <View style={styles.header}>
            <View style={styles.headerBar}>
              <View style={styles.tagSection}>
                <Tag {...formatCompletionTagProps(completionStatus)}/>
              </View>
              <View style={styles.milestonePrice}>
                <StyledText variant="body2" isBold={true}>{"PROJECT COST"}</StyledText>
                <StyledText variant="body2">{formatCurrencyToString(calculateProjectCost)}</StyledText>
              </View>
            </View>
            <View style={styles.headerBar}>
              <StyledText variant="heading3" isBold={true}>{`Project: ${project.name}`}</StyledText>
            </View>
            <View style={styles.menuBar}>
              <StyledTouchableOpacity
                style={[styles.menuItem, styles.selectedMenuItem]}
                onPress={noop}
              >
                <StyledText
                  isBold={true}
                  variant={"body2"}
                  colorMode={"accent"}
                >
                  {"Comments"}
                </StyledText>
              </StyledTouchableOpacity>
            </View>
          </View>

          {/* Body */}
          <View style={styles.body}>
            {this.menuItemNav["Comments"]()}
            <View style={{left: 0, right: 0, backgroundColor: Palette.Affirm25Pct, borderColor: Palette.Accent, width: 500, height: 500}}>
              <input id="myInput"
                type="file"
                ref={this.inputFile}
                // style={{display: 'none'}}
                onChange={this.onChangeFile}
              />
            </View>
          </View>
        </View>
      </>
    );
  }

  readonly onChangeFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    console.log(file);
  }

  render() {
    const { projectDetailsFlow: { selectedChangeRequest, selectedMilestone } } = this.props;
    return (
      selectedChangeRequest?.changeRequestType === "final" ? (
        this.renderCompletionSidebar()
      ) : (
        selectedMilestone?.type === "milestone" ? (
          this.renderMainSidebar()
        ) : (
          this.renderCommentOnlySidebar()
        )
      )
    );
  };
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    flex: 1,
  },
  header: {
    borderBottomWidth: 1,
    borderBottomColor: Palette.Primary10Pct,
    flexDirection: "column",
    gap: 10,
    paddingHorizontal: 24,
    paddingTop: 24,
  },
  headerBar: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  tagSection: {
    flexDirection: "row", 
    gap: 16,
  },
  milestonePrice: {
    flexDirection: "column",
    alignItems: "flex-end",
  },
  menuBar: {
    flexDirection: "row",
    gap: 16,
    marginTop: 15,
  },
  menuItem: {
    paddingBottom: 12,
    justifyContent: "center",
  },
  selectedMenuItem: {
    borderBottomWidth: 3,
    borderBottomColor: Palette.Accent,
  },
  completionDropdown: {
    position: "absolute",
    border: "1px solid" + Palette.Primary10Pct,
    boxShadow: "0px 0px 30px 5px rgba(0, 0, 0, 0.05)",
    borderRadius: 8,
    flexDirection: "column",
    padding: 8,
    gap: 8,
    backgroundColor: Palette.White,
    top: 56,
    left: 16,
  },
  optionDropdown: {
    position: "absolute",
    border: "1px solid" + Palette.Primary10Pct,
    boxShadow: "0px 0px 30px 5px rgba(0, 0, 0, 0.05)",
    borderRadius: 8,
    flexDirection: "column",
    paddingVertical: 12,
    gap: 12,
    backgroundColor: Palette.White,
    marginRight: "auto",
    top: 105,
    right: 16,
  },
  optionLineDropdown: {
    borderBottomWidth: 1,
    borderBottomColor: Palette.Primary10Pct,
    paddingBottom: 12,
  },
  body: {
    flexDirection: "column",
    gap: 30,
    padding: 24,
  },
  textBlock: {
    flexDirection: "column",
    gap: 14,
  },
  taskBlock: {
    flexDirection: "column",
    gap: 10,
  },
  task: {
    flexDirection: "row",
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 10,
    borderBottomWidth: 1,
    borderBottomColor: Palette.Primary10Pct,
    paddingBottom: 10,
  },
  textLineThrough: {
    textDecorationLine: "line-through",
  },
  commentBlock: {
    flexDirection: "column",
    gap: 14,
  },
  bodyContainer: {
    flexDirection: "column",
    backgroundColor: "transparent",
    margin: 16,
    gap: 4,
  },
  imageContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 10,
  },
  image: {
    width: "calc(50% - 5px)", 
    justifyContent: "space-between", 
    borderRadius: 4,
    backgroundColor: Palette.Primary5Pct,
    border: "1px solid " + Palette.Primary10Pct,
  },
  alertWarning: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
    padding: 8,
    backgroundColor: Palette.Secondary25Pct,
  },
  alertCompletion: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: 10,
    padding: 16,
    backgroundColor: Palette.Accent25Pct,
  },
});

const commentStyles = StyleSheet.create({
  tagContainer: {
    flexDirection: "row",
    gap: 4,
    alignItems: "center",
    bottom: 2,
  },
  tag: {
    backgroundColor: Palette.Primary5Pct,
    border: "1px solid " + Palette.Primary25Pct,
    borderRadius: 4,
    paddingHorizontal: 4,
    paddingVertical: 2,
  },
  nestedComment: {
    marginTop: -14,
  }
})

export const InjectedManagementSidebarView = withInjectedFactory(
  ManagementSidebarViewFactory
);
