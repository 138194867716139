import { Inject } from "@not-the-droids/exco-ts-inject";
import { computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React, { ReactElement } from "react";
import {
  Image,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import {
  Icon,
  StyledText,
  StyledTextInput,
  StyledTouchableOpacity,
} from "../components/controls";
import { ProjectWidgetComment } from "./ProjectWidgetComment";
import { ProjectWidgetManager } from "../managers/ProjectWidgetManager";
import { Palette } from "./styles";
import { ContractorModel, Owner, OwnerModel, Project, ProjectModel, User, UserModel } from "../../../data-model";
import { UserInfo } from "./UserInfo";
import { SectionBlock } from "./SectionBlock";
import { capitalizeFirstLetter, formatPhoneNumber, structureAddress } from "../utils/Strings";
import { UserViewModel } from "../viewModels/UserViewModel";
import { withInjectedFactory } from "../InjectorContext";

export const menuItems = ["details", "chat"] as const;
interface MenuItemNav {
  title: string;
  path: MenuItem | "";
}

export type MenuItem = typeof menuItems[number];

const menuItemNav: Record<MenuItem, MenuItemNav> = {
  details: {
    path: "",
    title: "Project Details",
  },
  chat: {
    path: "chat",
    title: "Chat",
  },
};

export interface OrderedCommentComponent {
  date: Date;
  commentComponents: ReactElement[];
}

interface Props {
  contractorModel: ContractorModel;
  ownerModel: OwnerModel;
  projectModel: ProjectModel;
  projectWidgetManager: ProjectWidgetManager;
  userModel: UserModel;
  userViewModel: UserViewModel;
}

interface CreateProps {
  currentProject: Project;
}

class ProjectWidgetFactory {
  static inject: Inject<ProjectWidgetFactory> = (injector) => {
    return () =>
      new ProjectWidgetFactory({
        contractorModel: injector.get(ContractorModel)(),
        ownerModel: injector.get(OwnerModel)(),
        projectModel: injector.get(ProjectModel)(),
        projectWidgetManager: injector.get(ProjectWidgetManager)(),
        userModel: injector.get(UserModel)(),
        userViewModel: injector.get(UserViewModel)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ProjectWidget {...this.props} {...props} />;
  }
}

@observer
class ProjectWidget extends React.Component<Props & CreateProps> {
  constructor(props: Props & CreateProps) {
    super(props);
    makeObservable(this);
  }
  
  @observable private commentInput: string = "";
  @observable private owner?: Owner;
  @observable private userContractor?: User;
  @observable private userOwner?: User;
  @observable private workAreas?: Array<string>;

  @computed get comments() {
    const { projectWidgetManager: { activeCommentTag, commentsByTag, generalComments } } = this.props;
    if (!!activeCommentTag) {
      const comments = commentsByTag[activeCommentTag]?.comments || [];
      return comments?.map(comment => {
        return ({
          date: comment.createdAt,
          commentComponents: [(
            <ProjectWidgetComment
              key={`widget-comment-${comment.id}`}
              comment={comment} 
              category={comment.tag} 
              isNested={false}
            />
          )],
        })
      }).reverse();
    } else {
      // Comments by Category
      const commentsElements: OrderedCommentComponent[] = [];
      const commentTags = Object.keys(commentsByTag);
      for (let i = 0; i < commentTags.length; i++) {
        const commentIdentifier = commentTags[i];
        const comments = commentsByTag[commentIdentifier]?.comments || [];
        const groupedElements: ReactElement[] = [];
        if (comments.length > 0) {
          const createdDate = comments[0].createdAt
          comments.forEach((comment, index) => {
            const isFirst = index === 0;
            const commentTag = commentIdentifier.split('-')[0];
            groupedElements.push(
              <ProjectWidgetComment 
                key={commentIdentifier + comment.id} 
                comment={comment}
                category={commentTag}
                isNested={!isFirst}
              />
            )
          });
          commentsElements.push({
            date: createdDate,
            commentComponents: groupedElements,
          });
        }
      }
      // General Comments
      commentsElements.push(...generalComments.map((comment) => {
        return ({
          date: comment.createdAt,
          commentComponents: [(
            <ProjectWidgetComment 
              key={'general' + comment.id} 
              comment={comment} 
              isNested={false} 
            />
          )]
        })
      }));

      return commentsElements.sort(
        (comA, comB) => {
          return new Date(comB.date).getTime() - new Date(comA.date).getTime()
        }
      );
    }
  }

  readonly componentDidMount = async () => {
    const { projectModel, currentProject, userModel } = this.props;
    const contractor = await projectModel.getContractorByProjectId(currentProject.id)
    if (!!contractor) {
      this.userContractor = await userModel.getUserById(contractor.userId);
    }
    const owner = await projectModel.getOwnerByProjectId(currentProject.id)
    if (!!owner) {
      this.owner = owner;
      this.userOwner = await userModel.getUserById(owner.userId)
    }
    this.sortWorkAreas();
  }

  readonly componentWillUnmount = () => {
    this.commentInput = "";
  }

  readonly handleMenuClick = (item: MenuItem) => {
    const { projectWidgetManager } = this.props;
    projectWidgetManager.selectedMenuItem = item;
  }

  readonly getCurrentUser = (creatorId: string) => {
    return this.userContractor?.id === creatorId ? this.userContractor! : this.userOwner!;
  }

  readonly onSubmitMessage = () => {
    const { projectWidgetManager } = this.props;
    const commentInput = this.commentInput;
    this.commentInput = '';
    projectWidgetManager.submitComment(commentInput);
    this.commentInput = '';
  }

  readonly renderMenuItem = (item: MenuItem, index: number) => {
    const { projectWidgetManager } = this.props;
    const isSelected = projectWidgetManager.selectedMenuItem === item;
    const menuItem = menuItemNav[item];
    const selectedStyle = isSelected && styles.selectedMenuItem;
    return (
      <StyledTouchableOpacity
        key={"menuItem" + index}
        style={[styles.menuItem, selectedStyle]}
        onPress={() => this.handleMenuClick(item)}
      >
        <StyledText isBold={true} colorMode={isSelected ? "accent" : "gray"}>
          {menuItem.title}
        </StyledText>
      </StyledTouchableOpacity>
    );
  };

  readonly sortWorkAreas = () => {
    const workAreasSet = new Set<string>();
    this.props.currentProject.workCategories?.forEach((workCat) => {
      workCat.workAreas.forEach(workArea => {
        workAreasSet.add(workArea);
      })
    })
    this.workAreas = Array.from(workAreasSet).sort();
  }

  render() {
    const { currentProject, projectWidgetManager } = this.props;

    return (
      <ScrollView
        style={styles.container}
        stickyHeaderIndices={[0]}
      >
        <View style={styles.header}>
          <View style={styles.menuItems}>
            {menuItems.map(this.renderMenuItem)}
          </View>
          <Icon
            name="x"
            size={14}
            style={styles.closeIcon}
            onClick={projectWidgetManager.closeWidget}
          />
        </View>

        <View style={styles.body}>
          {
            projectWidgetManager.selectedMenuItem === "chat" ? (
              <>
                {
                  !!projectWidgetManager.activeCommentTag && (
                    <View style={[styles.pill, styles.section]}>
                      <StyledText variant="body2" colorMode="light">{projectWidgetManager.activeCommentTag}</StyledText>
                      <Icon
                        name="x"
                        type="white"
                        size={14}
                        style={{ marginLeft: 8 }}
                        onClick={() => projectWidgetManager.setActiveCommentInfo()}
                      />
                    </View>
                )}
                {
                  !projectWidgetManager.hasComments ? (
                    <StyledText variant="body2">You can chat with the property owner about their project. Their response will appear here.</StyledText>
                  ) : (
                    <View>
                      {this.comments.map((comment) => comment.commentComponents)}
                    </View>
                  )
                }
                <StyledTextInput
                  iconRight={{ name: "send", type: "accent", size: 14, onClick: this.onSubmitMessage }}
                  onChangeText={(value) => this.commentInput = value}
                  placeholder="Write your message here"
                  value={this.commentInput}
                />
              </>
            ) : (
              <View style={styles.detailsContainer}>
                {
                  currentProject?.media && currentProject?.media?.length > 0 && (
                    <Image
                      source={{uri: currentProject.media[0].url}}
                      style={styles.image}
                    />
                  )
                }
                {
                  !!this.userContractor && (
                    <UserInfo variant="name-email" user={this.userContractor} />
                  )
                }
                <SectionBlock title="Property Owner">
                  <StyledText style={styles.sectionText}>
                    {!!this.owner ? this.owner.name  : "Not Submitted"}
                  </StyledText>
                  <StyledText style={styles.sectionText}>
                    {!!this.owner && !!this.owner.phone && formatPhoneNumber(this.owner.phone)}
                  </StyledText>
                </SectionBlock>
                <SectionBlock title="Property Address">
                  <StyledText style={styles.sectionText}>
                    {!!currentProject.address ? structureAddress(currentProject.address) : "Not Submitted"}
                  </StyledText>
                </SectionBlock>
                <SectionBlock title="Location Type">
                  <StyledText style={styles.sectionText}>
                    {!!currentProject.locationType && capitalizeFirstLetter(currentProject.locationType)}
                  </StyledText>
                </SectionBlock>
                <View style={styles.detailsRow}>
                  <SectionBlock title="Work Type">
                    <StyledText style={styles.sectionText}>
                      {!!currentProject.workType && capitalizeFirstLetter(currentProject.workType)}
                    </StyledText>
                  </SectionBlock>
                  <SectionBlock title="Work Location">
                    <StyledText style={styles.sectionText}>
                      {!!currentProject.workLocation &&
                        capitalizeFirstLetter(currentProject.workLocation)}
                    </StyledText>
                  </SectionBlock>
                </View>
                <SectionBlock title="Work Areas">
                  <StyledText style={styles.sectionText}>
                    {!!this.workAreas && this.workAreas.join(", ")}
                  </StyledText>
                </SectionBlock>
                <SectionBlock title="Work Categories">
                  <StyledText style={styles.sectionText}>
                    {currentProject.workCategories?.map((workCat) => workCat.workCategory).join(", ")}
                  </StyledText>
                </SectionBlock>
                <SectionBlock title="Structure Description">
                  <StyledText style={styles.sectionText}>
                    {!!currentProject.description && capitalizeFirstLetter(currentProject.description)}
                  </StyledText>
                </SectionBlock>
              </View>
            )
          }
        </View>
      </ScrollView>
    );
  }
}

interface IProjectWidgetController {
  onPress: () => void;
}
export const ProjectWidgetController: React.FunctionComponent<IProjectWidgetController> = ({ onPress }) => {
  return (
    // TODO: styling is incomplete. See "Decorator.ts" for reference on how to style rounded edges - EY
    <StyledTouchableOpacity onPress={onPress} style={styles.controllerContainer}>
      <Icon name="chat-pull" size={72} height={120} />
    </StyledTouchableOpacity>
  )
};

const styles = StyleSheet.create({
  container: {
    width: 312,
    height: 600,
    borderWidth: 1,
    borderColor: Palette.Primary10Pct,
    backgroundColor: Palette.Primary5Pct,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderBottomColor: Palette.Primary10Pct,
    backgroundColor: Palette.Primary5Pct,
  },
  section: {
    marginBottom: 24,
  },
  body: {
    margin: 24,
  },
  row: {
    flexDirection: "row",
  },
  menuItems: {
    flexDirection: "row",
    marginTop: 24,
    marginLeft: 24,
  },
  menuItem: {
    marginRight: 16,
    paddingBottom: 12,
  },
  selectedMenuItem: {
    borderBottomWidth: 3,
    borderBottomColor: Palette.Accent,
  },
  closeIcon: {
    padding: 4,
    marginTop: 16,
    marginRight: 16,
  },
  commentHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  commentHeaderText: {
    marginLeft: 8,
    justifyContent: "center",
  },
  commentBody: {
    marginTop: 8,
  },
  pill: {
    flexDirection: "row",
    alignSelf: "baseline",
    alignItems: "center",
    borderRadius: 25,
    paddingVertical: 4,
    paddingHorizontal: 24,
    backgroundColor: Palette.Primary100Pct,
  },
  controllerContainer: {
    justifyContent: "center",
    alignItems: "center",
    right: -84,
  },
  detailsContainer: {
    flexDirection: "column",
    gap: 24,
  },
  detailsRow: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  image: {
    height: 148,
    width: "100%",
    borderRadius: 8,
  },
  label: {
    fontWeight: "800",
  },
  sectionText: {
    lineHeight: 24,
  },
});

export const InjectedProjectWidgetFactory = withInjectedFactory(ProjectWidgetFactory);
