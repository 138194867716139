import { Identifiable } from "@not-the-droids/exco-data-model";
import { Inject } from "@not-the-droids/exco-ts-inject";
import { Contractor } from "Contractor";
import { Owner } from "Owner";
import { Media, Project, WorkArea, WorkCategoryAreas } from "./Project";
import { FirebaseFile } from "./File";

export interface UpdateProjectParams
  extends Identifiable,
    Partial<Omit<Project, "id" | "status">> {}

export interface CreateProjectParams
  extends Partial<
    Omit<Project, "workCategories" | "workAreas" | "inviteEmails">
  > {}

export interface UpdateWorkAreasParams {
  readonly projectId: string;
  readonly workAreas: ReadonlyArray<WorkArea>;
}

export interface UpsertPhotoParams {
  readonly fileName: string;
  readonly url: string;
}

export interface DeletePhotoParams {
  readonly deleteId: string;
}

export type UpdatePhotoParams = UpsertPhotoParams & Partial<DeletePhotoParams>;

export interface UpdateWorkCategoriesParams {
  readonly projectId: string;
  readonly workCategoryAreas: Array<WorkCategoryAreas>;
}

export interface InviteContractorParams {
  readonly projectId: string;
  readonly contractorId: string;
}

export interface InviteOwnerParams {
  readonly projectId: string;
  readonly ownerId: string;
}

export interface DeleteInviteParams {
  readonly projectId: string;
  readonly userId: string;
}

export interface RejectInviteParams {
  readonly projectId: string;
}

export interface InvitedUser {
  readonly email: string;
  readonly name: string;
  readonly userId: string;
  readonly photoUrl: string;
  readonly role: "contractor" | "owner";
}

export abstract class ProjectModel {
  static inject?: Inject<ProjectModel> = undefined;

  public abstract getProjects(): Promise<Project[]>;
  public abstract getNotCompletedCreation(): Promise<Project | undefined>;
  public abstract getProjectsAsRole(): Promise<Project[]>;
  public abstract getProjectById(id: string): Promise<Project>;
  public abstract getInvitesByProjectId(id: string): Promise<InvitedUser[]>;
  public abstract getMembersByProjectId(id: string): Promise<InvitedUser[]>;
  public abstract getContractorByProjectId(
    projectId: string
  ): Promise<Contractor | undefined>;
  public abstract getOwnerByProjectId(
    projectId: string
  ): Promise<Owner | undefined>;
  public abstract createProject(
    params: CreateProjectParams
  ): Promise<CreateProjectParams>;
  public abstract updateProject(params: UpdateProjectParams): Promise<void>;
  public abstract updateProjectName(
    params: UpdateProjectParams
  ): Promise<Project>;
  public abstract updateProjectPhoto(
    id: string,
    params: UpdatePhotoParams
  ): Promise<Media>;
  public abstract completeCreation(id: string): Promise<void>;
  public abstract getWorkCategoriesByProjectId(
    id: string
  ): Promise<WorkCategoryAreas[]>;
  public abstract updateWorkCategories(
    params: UpdateWorkCategoriesParams
  ): Promise<void>;
  public abstract publishProject(id: string): Promise<void>;
  public abstract inviteContractor(
    params: InviteContractorParams
  ): Promise<void>;
  public abstract inviteOwner(params: InviteOwnerParams): Promise<void>;
  public abstract acceptInvitation(projectId: string): Promise<void>;
  public abstract deleteInvitation(params: DeleteInviteParams): Promise<any>;
  public abstract rejectInvitation(params: RejectInviteParams): Promise<any>;
  public abstract archiveProject(id: string): Promise<any>;
  public abstract getRolesByProjectId(id: string): Promise<InvitedUser[]>;
  public abstract getFilesByProjectId(id: string): Promise<FirebaseFile[]>;
}
