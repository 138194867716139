import { Inject } from "@not-the-droids/exco-ts-inject";
import { Contractor, ContractorAddFile, ContractorFile } from "./Contractor";

export interface CreateContractorParams extends Partial<Contractor> {}

export abstract class ContractorModel {
  static inject?: Inject<ContractorModel> = undefined;

  public abstract createContractor(
    params: CreateContractorParams
  ): Promise<Contractor>;
  public abstract getContractorByEmail(
    contractorEmail: string
  ): Promise<Contractor>;
  public abstract getContractors(): Promise<Contractor[]>;
  public abstract addFile(file:ContractorAddFile): Promise<void>;
  public abstract getFiles(): Promise<ContractorFile[]>;
  public abstract deleteFile(fileId:string): Promise<void>;
}
