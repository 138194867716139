import { FC, ReactNode } from "react";
import { StyleSheet, View } from "react-native";
import { StyledText } from "../controls";

interface Props {
  title: string;
  children: ReactNode;
}

export const ProjectDetailQuestion: FC<Props> = ({ title, children }) => {
  return (
    <View style={styles.section}>
      <StyledText variant="heading" isBold={true} style={styles.sectionTitle}>
        {title}
      </StyledText>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  section: {
    marginBottom: 48,
  },
  sectionTitle: {
    marginBottom: 32,
  },
});
