import { Inject } from "@not-the-droids/exco-ts-inject";
import { computed, makeObservable, observable, toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { StyleSheet, View } from "react-native";
import { v4 as uuidv4 } from "uuid";
import {
  BuildingType,
  buildingTypes,
  ProjectModel,
  ProjectStatus,
  WorkLocation,
  workLocations,
  WorkType,
  workTypes
} from "../../../../data-model";
import {
  ProjectCrudFlow,
  ProjectDetailsStep
} from "../../flows/ProjectCrudFlow";
import { withInjectedFactory } from "../../InjectorContext";
import { Notification } from "../../NotificationInjectable";
import { uploadFromBlobAsync } from "../../utils/Storage";
import { structureAddress } from "../../utils/Strings";
import { UserViewModel } from "../../viewModels/UserViewModel";
import { StyledButton, StyledTextInput } from "../controls";
import { ImageDropzone } from "../controls/ImageDropzone";
import { AddressParts, GooglePlacesInput } from "../GooglePlacesInput";
import { OptionSelector } from "../OptionsSelector";
import { IconName } from "../styles";
import { ProjectDetailQuestion } from "./ProjectDetailQuestion";

interface OptionDisplay {
  name: string;
  icon?: IconName;
}

const buildingTypeOptions: Record<BuildingType, OptionDisplay> = {
  apartment: { name: "Apartment", icon: "apartment-building" },
  condo: { name: "Condo", icon: "condo-building" },
  single: { name: "Single Family", icon: "single-family-building" },
  townhome: { name: "Townhome", icon: "townhome-building" },
};

const workTypeOptions: Record<WorkType, OptionDisplay> = {
  addition: { name: "Addition" },
  new: { name: "New Construction" },
  remodel: { name: "Remodel" },
};

const situatedAtOptions: Record<WorkLocation, OptionDisplay> = {
  both: { name: "Interior and Exterior" },
  interior: { name: "Interior" },
  exterior: { name: "Exterior" },
};

interface Props {
  projectCrudFlow: ProjectCrudFlow;
  projectModel: ProjectModel;
  notification: Notification;
  userViewModel: UserViewModel;
}

interface CreateProps {
  projectId?: string;
}

class ProjectFlowDetailsViewFactory {
  static inject: Inject<ProjectFlowDetailsViewFactory> = (injector) => {
    return () =>
      new ProjectFlowDetailsViewFactory({
        projectCrudFlow: injector.get(ProjectCrudFlow)(),
        projectModel: injector.get(ProjectModel)(),
        notification: injector.get(Notification)(),
        userViewModel: injector.get(UserViewModel)(),
      });
  };

  constructor(private props: Props) {}

  public create(props: CreateProps) {
    return <ProjectFlowDetailsView {...this.props} {...props} />;
  }
}

@observer
class ProjectFlowDetailsView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  @observable _submitting = false;

  @computed get currentQuestion() {
    return this.props.projectCrudFlow.projectDetailsQuestion;
  }
  @computed get name() {
    return this.props.projectCrudFlow.name;
  }
  @computed get projectLocationInput() {
    return this.props.projectCrudFlow.projectLocationInput;
  }
  @computed get projectLocation() {
    return this.props.projectCrudFlow.projectLocation;
  }
  @computed get ownerName() {
    return this.props.projectCrudFlow.ownerName;
  }
  @computed get buildingType() {
    return this.props.projectCrudFlow.buildingType;
  }
  @computed get workType() {
    return this.props.projectCrudFlow.workType;
  }
  @computed get situatedAt() {
    return this.props.projectCrudFlow.situatedAt;
  }
  @computed get structureDescription() {
    return this.props.projectCrudFlow.structureDescription;
  }
  @computed get media() {
    return this.props.projectCrudFlow.media;
  }
  @computed get isContractor() {
    return this.props.userViewModel.isContractor;
  }

  private onChangeAddressInput = (address: AddressParts) => {
    const { projectCrudFlow } = this.props;
    projectCrudFlow.projectLocation = address;
    projectCrudFlow.projectLocationInput = structureAddress(address);
    const step = this.isContractor 
      ? ProjectDetailsStep.location : ProjectDetailsStep.owner;
    this.incrementQuestion(step);
  };

  private onChangeAddressLineOne = (address: string) => {
    this.props.projectCrudFlow.projectLocationInput = address;
  };

  private onChangeOwnerName = (name: string) => {
    this.props.projectCrudFlow.ownerName = name;
    this.incrementQuestion(ProjectDetailsStep.owner);
  }

  private onSelectBuildingType = (buildingType: BuildingType) => {
    this.props.projectCrudFlow.buildingType = buildingType;
    this.incrementQuestion(ProjectDetailsStep.buildingType);
  };

  private onSelectWorkType = (workType: WorkType) => {
    this.props.projectCrudFlow.workType = workType;
    this.incrementQuestion(ProjectDetailsStep.workType);
  };

  private onSelectSituatedAt = (situatedAt: WorkLocation) => {
    this.props.projectCrudFlow.situatedAt = situatedAt;
    this.incrementQuestion(ProjectDetailsStep.situatedAt);
  };

  private onChangeDescription = (description: string) => {
    this.props.projectCrudFlow.structureDescription = description;
    this.incrementQuestion(ProjectDetailsStep.description);
  };

  private onAddPhoto = async (
    photo: File,
    onUploadFinishedCallback: () => void
  ) => {
    try {
      const url = await uploadFromBlobAsync({
        blobUrl: URL.createObjectURL(photo),
        name: `/bid-files/${photo.name}_${Date.now()}`,
      });

      const newFile = {
        id: uuidv4(),
        fileName: photo.name,
        type: "image",
        url: url,
      } as const;

      this.props.projectCrudFlow.media = [newFile];
    } catch (e) {
      console.log(e);
    }
    onUploadFinishedCallback();
    this.incrementQuestion(0);
  };

  readonly incrementQuestion = (index: number) => {
    if (this.currentQuestion <= index) {
      this.props.projectCrudFlow.projectDetailsQuestion = index + 1;
      setTimeout(() => {
        window.scrollTo({ behavior: "smooth", top: window.screen.height });
      }, 100);
    }
  };

  readonly getValidationError = () => {
    if (!this.projectLocation) return "Location Required";
    if (!this.projectLocation.line1) return "Location Required";
    if (!this.buildingType) return "Building type required";
    if (!this.workType) return "Work Type required";
    if (!this.situatedAt) return "Situated at required";
    if (!this.structureDescription) return "Description required";
    return false;
  };

  readonly validateAndSubmit = async () => {
    const { notification } = this.props;
    const validationError = this.getValidationError();
    if (validationError) {
      return notification.setNotification("error", validationError);
    }

    try {
      const updateProjectParams = {
        name: this.projectLocationInput,
        address: {
          line1: this.projectLocation!.line1,
          line2: this.projectLocation!.line2 || "",
          city: this.projectLocation!.city || "",
          state: this.projectLocation!.state || "",
          country: this.projectLocation!.country || "",
          zip: this.projectLocation!.zip || "",
          placeId: this.projectLocation!.placeId,
        },
        ownerPlaceholderName: this.ownerName,
        locationType: this.buildingType,
        workType: this.workType,
        workLocation: this.situatedAt,
        status: "draft" as ProjectStatus,
        description: this.structureDescription,
        media: toJS(this.media),
      };

      if (this.props.projectCrudFlow.projectId) {
        await this.props.projectModel.updateProject({
          ...updateProjectParams,
          id: this.props.projectCrudFlow.projectId,
        });
      } else {
        const result = await this.props.projectModel.createProject(
          updateProjectParams
        );
        this.props.projectCrudFlow.projectId = result.id!;
      }

      this.props.projectCrudFlow.incrementCurrentStep();
    } catch (e) {
      console.log(e);
      this.props.notification.setNotification(
        "error",
        "Failed to save project"
      );
    }
  };

  render() {
    return (
      <View>
        <ProjectDetailQuestion title={"Where is your project located?"}>
          <GooglePlacesInput
            handleChange={this.onChangeAddressInput}
            onChange={this.onChangeAddressLineOne}
            location="project"
            defaultValue={this.projectLocation?.line1}
          />
        </ProjectDetailQuestion>

        {this.currentQuestion > ProjectDetailsStep.location 
          && this.isContractor && (
          <ProjectDetailQuestion
            title={"Who is the owner of this project?"}
          >
            <StyledTextInput
              value={this.ownerName}
              variant="primary"
              onChangeText={this.onChangeOwnerName}
            />
          </ProjectDetailQuestion>
        )}

        {this.currentQuestion > ProjectDetailsStep.owner && (
          <ProjectDetailQuestion
            title={"Great! What type of building is this?"}
          >
            <OptionSelector
              onSelect={(v) => this.onSelectBuildingType(v as BuildingType)}
              options={buildingTypes.map((t) => ({
                value: t,
                ...buildingTypeOptions[t],
              }))}
              selectedOption={this.buildingType}
            />
          </ProjectDetailQuestion>
        )}

        {this.currentQuestion > ProjectDetailsStep.buildingType && (
          <ProjectDetailQuestion title={"What work do you need done?"}>
            <OptionSelector
              onSelect={(v) => this.onSelectWorkType(v as WorkType)}
              options={workTypes.map((t) => ({
                value: t,
                ...workTypeOptions[t],
              }))}
              selectedOption={this.workType}
            />
          </ProjectDetailQuestion>
        )}

        {this.currentQuestion > ProjectDetailsStep.workType && (
          <ProjectDetailQuestion title={"And where will it take place?"}>
            <OptionSelector
              onSelect={(v) => this.onSelectSituatedAt(v as WorkLocation)}
              options={workLocations.map((t) => ({
                value: t,
                ...situatedAtOptions[t],
              }))}
              selectedOption={this.situatedAt}
            />
          </ProjectDetailQuestion>
        )}

        {this.currentQuestion > ProjectDetailsStep.situatedAt && (
          <ProjectDetailQuestion title={"Can you describe the structure?"}>
            <StyledTextInput
              multiline={true}
              textStyle={styles.textArea}
              value={this.structureDescription}
              variant="primary"
              onChangeText={this.onChangeDescription}
            />
          </ProjectDetailQuestion>
        )}
        {this.currentQuestion > ProjectDetailsStep.description && (
          <ProjectDetailQuestion title={"Please upload a project site photo"}>
            <ImageDropzone
              file={
                this.media.map((m) => ({
                  fileId: m.fileName,
                  fileUrl: m.url,
                  fileName: m.fileName,
                  fileType: "image",
                  createdAt: new Date(),
                }))[0]
              }
              onDrop={this.onAddPhoto}
            />
          </ProjectDetailQuestion>
        )}

        <View style={styles.buttonContainer}>
          <StyledButton
            disabled={!!this.getValidationError() || this._submitting}
            loading={this._submitting}
            onPress={this.validateAndSubmit}
            text="Save and Continue"
            style={styles.button}
          />
        </View>
      </View>
    );
  }
};

export const InjectedProjectFlowDetailsView = withInjectedFactory(
  ProjectFlowDetailsViewFactory
);

const styles = StyleSheet.create({
  textArea: {
    height: 120,
    width: "100%",
  },
  buttonContainer: {
    marginTop: 24,
    alignContent: "flex-start",
  },
  button: {
    width: "fit-content",
  },
});
