import React from "react";
import { computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { StyledTextInput, StyledTextInputProps } from "./StyledTextInput";
import { IconType } from "../styles";

export interface StyledPasswordTextInputProps extends StyledTextInputProps {
  revealType?: IconType;
}

@observer
export class StyledPasswordTextInput extends React.Component<StyledPasswordTextInputProps> {
  @observable private revealPassword: boolean = false;
  
  constructor(props: StyledPasswordTextInputProps) {
    super(props);
    makeObservable(this);
  };

  @computed get passwordIcon() {
    if (this.revealPassword) {
      return "eye-off";
    } else {
      return "eye";
    }
  };

  togglePassword = async () => {
    this.revealPassword = !this.revealPassword;
  };

  render() {
    const revealType = this.props.revealType ?? "white";

    return (
      <StyledTextInput 
        iconRight={{
          name: this.passwordIcon,
          type: revealType,
          size: 16,
          onClick: this.togglePassword,
        }}
        secureTextEntry={!this.revealPassword}
        {...this.props}
      />
    )
  }
}
