import { Inject, singletonInject } from "@not-the-droids/exco-ts-inject";
import { TrustshareSDK, PaymentsData, FundPhasesParams, CheckoutResult, PaymentIntent } from "../Payment";
import { PaymentModel } from "../PaymentModel";

import { RestClient } from "./RestClient";
import { RestPaymentConfiguration, RestPaymentConfigurationInjectable } from "./RestPaymentConfiguration";


export class RestPaymentModel extends PaymentModel {
    static inject: Inject<RestPaymentModel> = singletonInject((injector) => {
        return () => new RestPaymentModel({restClient: injector.get(RestClient)(), trustshare: injector.get(RestPaymentConfigurationInjectable)()});
      });

  private readonly rest: RestClient;
  private readonly trustshare: TrustshareSDK;

  constructor(private readonly props:{restClient: RestClient, trustshare: RestPaymentConfiguration}) {
    super();
    this.rest = props.restClient;
    this.trustshare = props.trustshare.trustshare
  }

  public getPaymentByProjectId(
    id: string
  ): Promise<PaymentsData> {
    return this.rest.get(`/payment/${id}`); 
  }

  public fundPhasesInProject(params: FundPhasesParams): Promise<PaymentIntent> {
    return this.rest.put(`/payment/fund-phases`, params);
  }

  public confirmPaymentIntent(clientSecret : string): Promise<CheckoutResult> {
    return this.trustshare.confirmPaymentIntent(clientSecret);
  }

}
