import React from "react";
import {
  StyleSheet,
  View,
} from "react-native";
import { computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { Inject } from "@not-the-droids/exco-ts-inject";
import {
  BudgetMilestone,
  BudgetPhase, 
  ChangeRequestType,
  Project,
} from "../../../data-model/";
import { withInjectedFactory } from "../InjectorContext";
import { formatCurrencyToString } from "../utils/Numbers";
import { StyledButton, StyledText } from "./controls";
import { InjectedManagementPhaseItem } from "./ManagementPhaseItem";
import { InjectedManagementSidebarView } from "./ManagementSidebarView";
import { ProjectDetailsFlow } from "../flows/ProjectDetailsFlow";
import { Palette } from "./styles";
import { InjectedMilestoneManagementModal } from "./MilestoneManagementModal";
import { UserViewModel } from "../viewModels/UserViewModel";
import { InjectedCompletionManagementModal } from "./CompletionManagementModal";
import { MilestoneManagementProps, MyContext } from "./ProjectMilestonesViewControl";
import { ProjectWidgetManager } from "../managers/ProjectWidgetManager";

interface MilestoneModalInfo {
  changeRequestType: ChangeRequestType;
  milestone?: BudgetMilestone;
  phase: BudgetPhase;
}

interface Props {
  projectDetailsFlow: ProjectDetailsFlow;
  projectWidgetManager: ProjectWidgetManager;
  userViewModel: UserViewModel;
}

interface CreateProps {
  project: Project;
}

class ProjectMilestonesViewFactory {
  static inject: Inject<ProjectMilestonesViewFactory> = (injector) => {
    return () =>
      new ProjectMilestonesViewFactory({
        projectDetailsFlow: injector.get(ProjectDetailsFlow)(),
        projectWidgetManager: injector.get(ProjectWidgetManager)(),
        userViewModel: injector.get(UserViewModel)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return (
      <MyContext.Consumer>
          {consumer => (
            <ProjectMilestonesView {...this.props} {...props} {...consumer} />
          )}
        </MyContext.Consumer>
    );
  }
}

@observer
class ProjectMilestonesView extends React.Component<Props & CreateProps & MilestoneManagementProps> {
  constructor(props: Props & CreateProps & MilestoneManagementProps) {
    super(props);
    makeObservable(this);
  }

  @observable private showCompletionModal: boolean = false;
  @observable private showMilestoneModal: boolean = false;
  @observable private milestoneModalInfo?: MilestoneModalInfo;

  @computed public get milestoneHeader() {
    return (
      <View style={styles.milestoneHeader}>
        <StyledText variant="heading3" colorMode="dark" isBold={true} style={styles.headerLeft}>
          {"Project Milestones and Management"}
        </StyledText>

        <View style={styles.headerRight}>
          <View style={styles.row}>
            <StyledText
              variant="heading3"
              colorMode="dark"
              style={{ marginBottom: 4 }}
            >{`Total Time: `}</StyledText>
            <StyledText variant="heading3" colorMode="dark" isBold={true}>
              {this.totalProjectTime
                ? `${this.totalProjectTime} Days`
                : "--"}
            </StyledText>
          </View>
          <View style={[styles.row, { marginLeft: 24 }]}>
            <StyledText
              variant="heading3"
              style={{ marginBottom: 4 }}
            >{`Total Cost: `}</StyledText>
            <StyledText variant="heading3" isBold={true}>
              {formatCurrencyToString(this.props.projectDetailsFlow.phasesTotalCost)}
            </StyledText>
          </View>
        </View>
      </View>
    );
  };

  @computed public get phaseItems() {
    const { projectDetailsFlow: { budget } } = this.props;
    return (
      budget?.phases.map((phase) => {
        const milestoneRows: Array<BudgetMilestone> = [];
        const milestoneIndices = phase.milestoneIndices;
        if (budget?.milestones && budget.milestones.length > 0) {
          for (let i = 0; i < milestoneIndices.length; i++) {
            let milestone = budget.milestones.find(milestone =>
              milestone.orderIndex === milestoneIndices[i]
            )
            if (milestone) milestoneRows.push(milestone);
          }
          return (
            <InjectedManagementPhaseItem
              key={'managementPhaseItem' + phase.orderIndex}
              milestones={milestoneRows}
              onAddMilestonePress={this.handleShowMilestoneModal}
              phase={phase}
            />
          )
        }
        return undefined;
      })
    );
  };

  @computed public get projectCompletionButton() {
    const { project, projectDetailsFlow, userViewModel } = this.props;
    const isReadyForCompletion = (
      projectDetailsFlow.budget?.milestones.every(m => m.completion === "completed") &&
      projectDetailsFlow.changeRequests.every(cr => cr.currentStatus !== "submitted")
    )

    switch (project.status) {
      case 'draft':
        if (!isReadyForCompletion || userViewModel.isOwner) return null;
        return (
          <StyledButton
            onPress={this.handleShowCompletionModal}
            variant="secondary"
            text={"Submit Project for Completion"}
            textStyle={{fontSize: 16, lineHeight: 24, color: Palette.Affirm100Pct}}
            style={{marginLeft: "auto", marginRight: "auto", borderRadius: 4, borderColor: Palette.Affirm100Pct}}
          />
        )
      case 'pendingArchival':
        return (
          <StyledButton
            onPress={this.handleChangeView}
            variant="secondary"
            text={"Project Submitted For Completion"}
            iconRight={{name: "chevron-right", type: "secondary", size: 16}}
            textStyle={{fontSize: 16, lineHeight: 24, color: Palette.Secondary100Pct}}
            style={{marginLeft: "auto", marginRight: "auto", borderRadius: 4, borderColor: Palette.Secondary100Pct}}
          />
        )
      case 'archived':
        return (
          <StyledButton
            disabled={true}
            variant="secondary"
            text={"Project Completed"}
            textStyle={{fontSize: 16, lineHeight: 24, color: Palette.Affirm100Pct}}
            style={{marginLeft: "auto", marginRight: "auto", borderRadius: 4, borderColor: Palette.Affirm100Pct}}
          />
        )
      default:
        return null;
    }
  }

  @computed public get totalProjectTime(): number {
    let totalDays: number = 0;
    this.props.projectDetailsFlow.budget?.phases.forEach((phase: BudgetPhase) => {
      totalDays += Number(phase.numDays);
    });
    return totalDays;
  }

  readonly handleChangeView = () => {
    const { changeView, projectDetailsFlow, projectWidgetManager } = this.props;
    const changeRequest = projectDetailsFlow.changeRequests.find(cr => cr.changeRequestType === "final");
    if (changeRequest) {
      projectDetailsFlow.selectedChangeRequest = changeRequest;
      projectDetailsFlow.selectedMilestone = undefined;
    }
    projectWidgetManager.setActiveCommentInfo(projectDetailsFlow.budget?.id, "Project Completion");
    changeView();
  }

  readonly handleCloseCompletionModal = () => {
    this.showCompletionModal = false;
  }

  readonly handleCloseMilestoneModal = () => {
    this.showMilestoneModal = false;
  }

  readonly handleShowCompletionModal = () => {
    this.showCompletionModal = true;
  }

  readonly handleShowMilestoneModal = (changeRequestType: ChangeRequestType, selectedPhase: BudgetPhase, selectedMilestone?: BudgetMilestone) => {
    this.milestoneModalInfo = {
      changeRequestType: changeRequestType,
      milestone: selectedMilestone,
      phase: selectedPhase,
    }
    this.showMilestoneModal = true;
  }

  render() {
    const { project, projectDetailsFlow: { budget, selectedMilestone, selectedPhase } } = this.props;
    return (
      <>
        {/* Milestone Section */}
        <View style={styles.milestonesSection}>
          {this.projectCompletionButton}
          <View>
            <StyledText variant="body" isBold>
              Project Milestones:
            </StyledText>
            <StyledText variant="body">
              Manage your project in each milestone
            </StyledText>
          </View>
          <View style={styles.milestonesContainer}>
            {this.milestoneHeader}
            {this.phaseItems}
          </View>
        </View>

        {/* Sidebar Section */}
        <View style={styles.sidebarSection}>
          {
            selectedMilestone && selectedPhase && (
              <View style={styles.sidebarInner}>
                <InjectedManagementSidebarView
                  budget={budget!}
                  onEditPress={this.handleShowMilestoneModal}
                  project={project}
                />
              </View>
            )
          }
        </View>

        {/* Modal Section */}
        {
          this.showMilestoneModal && (
            <InjectedMilestoneManagementModal
              budget={budget!}
              changeRequestType={this.milestoneModalInfo?.changeRequestType!}
              isOpen={this.showMilestoneModal}
              milestone={this.milestoneModalInfo?.milestone}
              onCancelPress={this.handleCloseMilestoneModal}
              phase={this.milestoneModalInfo?.phase!}
              project={project}
            />
          )
        }
        {
          this.showCompletionModal && (
            <InjectedCompletionManagementModal
              isOpen={this.showCompletionModal}
              onCancelPress={this.handleCloseCompletionModal}
              project={project}
            />
          )
        }
      </>
    );
  }
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    marginTop: -65,
    marginRight: -84,
    marginBottom: -98,
  },
  milestonesSection: {
    flex: 2,
    flexDirection: "column",
    gap: 16,
    marginTop: 36,
    marginRight: 24,
    marginBottom: 98,
  },
  milestonesContainer: {
    flexDirection: "column",
    gap: 8,
    padding: 8,
    backgroundColor: Palette.Primary5Pct,
    border: "1px solid" + Palette.Primary10Pct,
    borderRadius: 8,
  },
  milestoneHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 10,
    marginHorizontal: 16,
    marginVertical: 8,
  },
  sidebarSection: {
    flex: 1,
  },
  sidebarInner: {
    flex: 1,
    borderLeftWidth: 1,
    borderLeftColor: Palette.Primary10Pct,
  },
  row: {
    flexDirection: "row",
  },
  headerLeft: {
    flex: 1,
    flexWrap: "wrap",
    maxWidth: "90%",
  },
  headerRight: {
    flex: 1,
    justifyContent: "flex-end",
    flexWrap: "wrap",
    flexDirection: "row",
    maxWidth: "90%",
  },
  pendingArchivalContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 4,
    border: "1px solid" + Palette.Secondary100Pct,
  },
  pendingArchivalText: {
    fontSize: 16,
    lineHeight: 24,
    color: Palette.Secondary100Pct,
  },
});

export const InjectedProjectMilestonesView = withInjectedFactory(
  ProjectMilestonesViewFactory
);
