import React, { FC } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import { useHover } from "react-native-web-hooks";
import { Icon, StyledText, StyledTouchableOpacity } from "./controls";
import { Counter, CounterProps } from "./Counter";
import { IconName, Palette } from "./styles";

interface Option {
  value: string;
  name?: string;
  icon?: IconName;
  counter?: CounterProps;
}

interface OptionSelectorProps {
  options: Option[];
  selectedOption?: string;
  onSelect: (name: string) => void;
}

export const OptionSelector: FC<OptionSelectorProps> = ({
  options,
  selectedOption,
  onSelect,
}) => {
  return (
    <View style={styles.projectOptions}>
      {options.map((option, index) => {
        return (
          <OptionItem
            key={index}
            name={option.name ?? option.value}
            icon={option.icon}
            isSelected={option.value === selectedOption}
            onPress={() => onSelect(option.value)}
          />
        );
      })}
    </View>
  );
};

interface OptionProps extends Omit<Option, "value"> {
  isSelected: boolean;
  onPress?: () => void;
}

const OptionItem: React.FunctionComponent<OptionProps> = (props) => {
  const { counter, icon, isSelected, name, onPress } = props;
  const ref = React.useRef(null);

  const hasIcon = !!icon;
  const containerStyle = hasIcon
    ? styles.containerWithIcon
    : !!counter
    ? styles.containerWithCounter
    : styles.containerWithText;
  const selectedStyle = isSelected ? styles.selectedContainer : undefined;
  const selectedStyledWithCounter =
    isSelected && !!counter ? styles.selectedContainerWithCounter : undefined;
  const contentWrapperStyle = hasIcon
    ? styles.contentWrapperWithIcon
    : styles.contentWrapperWithText;
  const selectedIconContainerStyle = hasIcon
    ? styles.absoluteIcon
    : styles.inlineIcon;
  const hoverStyle = useHover(ref) ? styles.hover : undefined;
  const WrapperComponent = onPress ? StyledTouchableOpacity : View;

  return (
    <View ref={ref}>
      <WrapperComponent
        style={[
          styles.container,
          containerStyle,
          selectedStyle,
          selectedStyledWithCounter,
          hoverStyle,
        ]}
        disabled={isSelected && !!counter}
        onPress={onPress}
      >
        <View style={contentWrapperStyle}>
          {isSelected && (
            <View
              style={[styles.selectedIconContainer, selectedIconContainerStyle]}
            >
              {<Icon name="check" type="white" size={14} />}
            </View>
          )}
          {icon && (
            <Icon
              name={icon}
              type={isSelected ? "white" : "primary"}
              size={72}
              style={styles.largeIcon}
            />
          )}
          <StyledText
            variant="body"
            colorMode={isSelected ? "light" : "dark"}
            style={styles.text}
          >
            {name}
          </StyledText>
        </View>
        {counter && counter.number > 0 && <Counter {...counter} />}
      </WrapperComponent>
    </View>
  );
};

const sharedRowCenterStyle: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
};

const styles = StyleSheet.create({
  projectOptions: {
    flexWrap: "wrap",
    flexDirection: "row",
  },

  container: {
    minHeight: 56,
    paddingHorizontal: 24,
    marginRight: 24,
    marginBottom: 24,
    borderWidth: 1,
    borderRadius: 10,
    overflow: "hidden",
    borderColor: Palette.Primary25Pct,
  },
  hover: {
    borderColor: Palette.Primary75Pct,
  },
  text: {
    paddingVertical: 16,
  },
  containerWithIcon: {
    width: 144,
  },
  containerWithText: {
    ...sharedRowCenterStyle,
    width: 288,
  },
  containerWithCounter: {
    ...sharedRowCenterStyle,
    paddingHorizontal: 16,
    paddingVertical: 8,
    minWidth: 228,
    width: "auto",
  },
  selectedContainer: {
    borderColor: Palette.Primary75Pct,
    backgroundColor: Palette.Primary75Pct,
  },
  selectedContainerWithCounter: {
    justifyContent: "space-between",
  },
  selectedIconContainer: {
    width: 22,
    height: 22,
    borderRadius: 16,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Palette.Affirm100Pct,
  },
  contentWrapperWithIcon: {
    alignItems: "center",
    flexDirection: "column",
  },
  contentWrapperWithText: {
    ...sharedRowCenterStyle,
    flexWrap: "wrap",
  },
  absoluteIcon: {
    position: "absolute",
    top: 12,
    left: -12,
  },
  inlineIcon: {
    marginRight: 10,
  },
  largeIcon: {
    marginTop: 24,
  },
});
