import { Inject } from "@not-the-droids/exco-ts-inject";
import { Comment, CommentType, CreateCommentParams, GetCommentParams, UpdateCommentParams } from "./Comment";

export abstract class CommentModel {
  static inject?: Inject<CommentModel> = undefined;

  public abstract createComment(
    params: CreateCommentParams
  ): Promise<Comment>;

  public abstract getCommentsByType(
    params: GetCommentParams
  ): Promise<Comment[]>;

  public abstract updateComments(
    params: UpdateCommentParams
  ): Promise<void>;
}
