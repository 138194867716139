export type MilestoneDefinition = {
  orderIndex: number;
  milestoneName: string;
  scope: string;
  csiCode: string;
  csiDescriptionUnused: string;
  tasks: string[];
};

export const milestoneDefinitions: MilestoneDefinition[] = [
  {
    orderIndex: 1,
    milestoneName: "Project Drawings",
    scope:
      "Create drawings to gain city approval - clear project scope/ layout",
    csiCode: "1-00-600",
    csiDescriptionUnused: "Product Requirements (Scope of Work)",

    tasks: [
      "Design",
      "Rendering",
      "Site Drawings",
      "Engineering",
      "Architect",
      "Energy Calculations",
    ],
  },
  {
    orderIndex: 2,
    milestoneName: "Permits",
    scope: "Obtain building permit from local government for the SoW",
    csiCode: "1-00-700",
    csiDescriptionUnused: "Execution Requirements",

    tasks: [
      "Building Permit",
      "Notice of Commencement",
      "Certificant of Completion",
      "Historical Review Process",
      "FEMA Approval Process",
    ],
  },
  {
    orderIndex: 3,
    milestoneName: "Pre Orders",
    scope:
      "Order long-lagtime delivery items immediately, make sure zoning department has approved exterior design prior to ordering windows and doors",
    csiCode: "",
    csiDescriptionUnused:
      "The items placed in to pre orders will have their cost code attached to the line item/ cost code",
    tasks: [],
  },
  {
    orderIndex: 4,
    milestoneName: "Pre Construction Utilites assessment/movement",
    scope:
      "Locate all underground utilities and make sure utilities are on for construction",
    csiCode: "1-00-510",
    csiDescriptionUnused: "Temporary Utilities",

    tasks: [
      "Locate Underground Utilities",
      "Site Electric",
      "Site Plumbing",
      "Sonar/ Site Scan",
    ],
  },
  {
    orderIndex: 5,
    milestoneName: "Tree Work",
    scope: "Remove trees and limbs as needed",
    csiCode: "2-00-300",
    csiDescriptionUnused: "Earthwork",

    tasks: ["Tree Removal", "Trim Tree", "Stump Removal"],
  },
  {
    orderIndex: 6,
    milestoneName: "Site Work",
    scope: "Clear site in preperation for the addition or new construction",
    csiCode: "2-00-200",
    csiDescriptionUnused: "Site Preparation",

    tasks: ["Clear Debris", "Grass/ Shrub removal", "Bobcat"],
  },
  {
    orderIndex: 7,
    milestoneName: "Mobilization",
    scope:
      "For remodeling, taping/plasticing off work areas, protect floors as needed",
    csiCode: "2-00-200",
    csiDescriptionUnused: "Site Preparation",

    tasks: ["Site Prep", "Temporary Walls"],
  },
  {
    orderIndex: 8,
    milestoneName: "Hazardous Remediation - Asbestos",
    scope: "Remove and dispose of asbestos materials",
    csiCode: "13-00-280",
    csiDescriptionUnused: "Hazardous Material Remediation",

    tasks: ["Remediation"],
  },
  {
    orderIndex: 9,
    milestoneName: "Hazardous Remediation - Lead Base Paint",
    scope: "Remove and dispose of lead materials",
    csiCode: "13-00-280",
    csiDescriptionUnused: "Hazardous Material Remediation",

    tasks: ["Remediation"],
  },
  {
    orderIndex: 10,
    milestoneName: "Demo",
    scope: "Perform demolition as per site plans",
    csiCode: "2-00-220",
    csiDescriptionUnused: "Site Demolition",

    tasks: ["Demo", "Full Structure Demo"],
  },
  {
    orderIndex: 11,
    milestoneName: "Pest Control",
    scope: "Tent/spray house for insects",
    csiCode: "10-00-290",
    csiDescriptionUnused: "Pest Control",

    tasks: ["Termite Treatment", "Pest Control Site Treatment"],
  },
  {
    orderIndex: 12,
    milestoneName: "Seawall",
    scope: "Install/Repair Seawall per site plans",
    csiCode: "2-00-830",
    csiDescriptionUnused: "Retaining Walls",

    tasks: ["Seawall Repair"],
  },
  {
    orderIndex: 13,
    milestoneName: "House leveling",
    scope: "Level house as per site plans",
    csiCode: "2-00-250",
    csiDescriptionUnused: "Shoring and Underpinning",

    tasks: ["Level Structure", "Move Structure"],
  },
  {
    orderIndex: 14,
    milestoneName: "Underpinning",
    scope: "Underpin house as per site plans",
    csiCode: "2-00-250",
    csiDescriptionUnused: "Shoring and Underpinning",

    tasks: ["Underpin Structure"],
  },
  {
    orderIndex: 15,
    milestoneName: "Foundation Repairs",
    scope: "Repair foundation as per site plans",
    csiCode: "2-00-250",
    csiDescriptionUnused: "Shoring and Underpinning",

    tasks: ["Footer", "Stem Wall", "Back Fill Dirt", "Drainage System"],
  },
  {
    orderIndex: 16,
    milestoneName: "Concrete Foundation",
    scope: "Install concrete footer as per site plans",
    csiCode: "3-00-300",
    csiDescriptionUnused: "Footings",

    tasks: [
      "Surveyor to mark corners of the house",
      "Surveyor to confirm corners of the house",
      "Foundation",
      "Footer",
      "Stem Wall",
      "Dirt Fill",
      "Slab",
    ],
  },
  {
    orderIndex: 17,
    milestoneName: "Under Floor Plumbing Rough-in",
    scope: "Install under floor plumbing rough-in as per site plans",
    csiCode: "15-00-100",
    csiDescriptionUnused: "Plumbing",

    tasks: [
      "Scope waste line for location",
      "New Waste Line",
      "New Water Supply Line",
    ],
  },
  {
    orderIndex: 18,
    milestoneName: "Under Floor Electric Rough-in",
    scope: "Install under floor electrical rough-in as per site plans",
    csiCode: "16-00-100",
    csiDescriptionUnused: "Electrical",

    tasks: ["Underground Wire"],
  },
  {
    orderIndex: 19,
    milestoneName: "Under Floor Gas Rough-in",
    scope: "Install under floor gas rough-in as per site plans",
    csiCode: "15-00-200",
    csiDescriptionUnused: "Process Piping",

    tasks: ["Gas pipe"],
  },
  {
    orderIndex: 20,
    milestoneName: "Under Floor Mechanical Rough-in",
    scope: "Install under floor mechnical rough-in as per site plans",
    csiCode: "15-00-700",
    csiDescriptionUnused: "Heating, Venting and Air Conditioning",

    tasks: ["New Supply Line", "Move Return Air", "Upgrade Duct Work"],
  },
  {
    orderIndex: 21,
    milestoneName: "Concrete Slab",
    scope: "Install concrete slab as per site plans",
    csiCode: "3-00-320",
    csiDescriptionUnused: "Slab Foundations",

    tasks: ["Slab"],
  },
  {
    orderIndex: 22,
    milestoneName: "Masonry Walls",
    scope: "Install masonry walls as per site plans",
    csiCode: "3-00-400",
    csiDescriptionUnused: "Precast Concrete",

    tasks: [
      "First Story Masonry Wall",
      "Second Story Masonry Wall",
      "Stone",
      "Window Sills",
      "Lintels",
      "New window opening",
      "Block in a pre existing opening",
    ],
  },
  {
    orderIndex: 23,
    milestoneName: "Metal Work",
    scope: "Install metal work as per site plans",
    csiCode: "5-00-100",
    csiDescriptionUnused: "Structural Metals",

    tasks: [
      "Specialized Tools/ Rental Equipment",
      "Steel Beam",
      "Steel Column",
      "Steel Plate",
      "Welding",
      "Delivery",
      "Metal Feature",
    ],
  },
  {
    orderIndex: 24,
    milestoneName: "Framing",
    scope: "Install framing as per site plans",
    csiCode: "6-00-100",
    csiDescriptionUnused: "Rough Carpentry",

    tasks: [
      "First Floor Wall Framing",
      "Second Floor Wall Framing",
      "Third Floor Wall Framing",
      "First Floor Floor-framing",
      "Second Floor Floor-trusses",
      "Third Floor Floor-trusses",
      "Micro Laminated Beams",
      "Trusses",
    ],
  },
  {
    orderIndex: 25,
    milestoneName: "Framing Floor",
    scope: "Install framing floor as per site plans",
    csiCode: "6-00-100",
    csiDescriptionUnused: "Rough Carpentry",

    tasks: [
      "First Floor Floor-framing",
      "Third Floor Floor-trusses",
      "Rough Stairs",
    ],
  },
  {
    orderIndex: 26,
    milestoneName: "Framing Walls",
    scope: "Install framing walls as per site plans",
    csiCode: "6-00-100",
    csiDescriptionUnused: "Rough Carpentry",

    tasks: [
      "First Floor Wall Framing",
      "Second Floor Wall Framing",
      "Third Floor Wall Framing",
      "Metal Stud Framing",
      "Remove a wall",
    ],
  },
  {
    orderIndex: 27,
    milestoneName: "Framing Second Story Floor",
    scope: "Install framing second story floor as per site plans",
    csiCode: "6-00-100",
    csiDescriptionUnused: "Rough Carpentry",

    tasks: ["Second Floor Floor-trusses"],
  },
  {
    orderIndex: 28,
    milestoneName: "Framing Second Story Walls",
    scope: "Install framing second story walls as per site plans",
    csiCode: "6-00-100",
    csiDescriptionUnused: "Rough Carpentry",

    tasks: ["Second Floor Wall Framing"],
  },
  {
    orderIndex: 29,
    milestoneName: "Frame Roof",
    scope: "Install frame roof as per site plans",
    csiCode: "6-00-100",
    csiDescriptionUnused: "Rough Carpentry",

    tasks: ["Trusses"],
  },
  {
    orderIndex: 30,
    milestoneName: "Roof Dry-in",
    scope: "Dry-in roof once sheeting inspection has been approved",
    csiCode: "7-00-400",
    csiDescriptionUnused: "Roofing and Siding Panels",

    tasks: ["Asphalt Roof", "Aluminum Roof", "Tile Roof"],
  },
  {
    orderIndex: 31,
    milestoneName: "Windows",
    scope: "Install windows as per site plans",
    csiCode: "8-00-500",
    csiDescriptionUnused: "Windows",

    tasks: [
      "WIndow Order",
      "Hurricane Shutters",
      "Specialized Tools/ Rental Equipment",
    ],
  },
  {
    orderIndex: 32,
    milestoneName: "Exterior Doors",
    scope: "Install exterior doors as per site plans",
    csiCode: "8-00-100",
    csiDescriptionUnused: "Doors",

    tasks: [
      "Front Door",
      "Sidelight",
      "Sliding Glass Door",
      "French Door",
      "Secondary Entry Door",
      "Front Door Handle",
      "Entry Door Lock",
      "Deadbolt",
    ],
  },
  {
    orderIndex: 33,
    milestoneName: "Rough in Plumbing",
    scope: "Intsall rough in plumbing as per site plans",
    csiCode: "15-00-100",
    csiDescriptionUnused: "Plumbing",

    tasks: [
      "Scope waste line for location",
      "New Waste Line",
      "New Water Supply Line",
    ],
  },
  {
    orderIndex: 34,
    milestoneName: "Rough in Mechanical",
    scope: "Install rough in mechanical as per site plans",
    csiCode: "15-00-050",
    csiDescriptionUnused: "Basic Mechanical Materials and Methods",

    tasks: ["New Supply Line", "Move Return Air", "Upgrade Duct Work"],
  },
  {
    orderIndex: 35,
    milestoneName: "Rough in Gas",
    scope: "Install rough in gas as per site plans",
    csiCode: "15-00-200",
    csiDescriptionUnused: "Process Piping",

    tasks: ["Water Heater", "New or move Gas Meter", "Gas pipe"],
  },
  {
    orderIndex: 36,
    milestoneName: "Rough in Electric",
    scope: "Install rough in electrical as per site plans",
    csiCode: "16-00-100",
    csiDescriptionUnused: "Electrical",

    tasks: [
      "Underground Wire",
      "New or Moving Switches",
      "Dimmer Switches",
      "New or Moving Outlets",
      "New or Move 220 Outlets",
      "New or Switch out old Fixtures",
      "New Floor Outlet",
      "New Can Lights",
      "Update breakers to Arc-Fault",
      "Temp Pole Install",
      "Move Meter",
    ],
  },
  {
    orderIndex: 37,
    milestoneName: "Rough in Fire/Sprinklers",
    scope: "Install rough in fire/sprinklers as per site plans",
    csiCode: "10-00-300",
    csiDescriptionUnused: "Fireplaces and Stoves",

    tasks: ["Fire Piping", "Fire Sprinkler"],
  },
  {
    orderIndex: 38,
    milestoneName: "Rough-in Home Technology",
    scope: "Install Home Technology wiring prior to drywall",
    csiCode: "16-00-800",
    csiDescriptionUnused: "Sound and Video",

    tasks: ["Sound System"],
  },
  {
    orderIndex: 39,
    milestoneName: "Rough-In Fireplace",
    scope: "Install rough in fireplace as per site plans",
    csiCode: "10-00-300",
    csiDescriptionUnused: "Fireplaces and Stoves",

    tasks: ["Fireplace", "Fireplace Mantel", "Chimney Sweep"],
  },
  {
    orderIndex: 40,
    milestoneName: "Elevator",
    scope: "Install elevator as per site plans",
    csiCode: "14-00-200",
    csiDescriptionUnused: "Elevators",

    tasks: ["Elevator Shaft Preparation", "Elevator Car"],
  },
  {
    orderIndex: 41,
    milestoneName: "Pest Control Pipe System",
    scope: "Install Pest Control Pipe System prior to drywall",
    csiCode: "10-00-290",
    csiDescriptionUnused: "Pest Control",

    tasks: ["In Wall Pest System"],
  },
  {
    orderIndex: 42,
    milestoneName: "Rough in Solar",
    scope: "Install rough in soloar as per site plans",
    csiCode: "13-00-600",
    csiDescriptionUnused: "Solar and Wind Energy Equipment",

    tasks: ["Solar"],
  },
  {
    orderIndex: 43,
    milestoneName: "Roof Finalize",
    scope: "Install final roof material",
    csiCode: "7-00-400",
    csiDescriptionUnused: "Roofing and Siding Panels",
    tasks: [],
  },
  {
    orderIndex: 44,
    milestoneName: "Pool",
    scope: "Start pool install as per site plans",
    csiCode: "13-00-150",
    csiDescriptionUnused: "Swimming Pools",

    tasks: ["Pool", "Pool Pump", "Pool/Marcite Repair"],
  },
  {
    orderIndex: 45,
    milestoneName: "Finishes Pre Order",
    scope: "Order all finish materials",
    csiCode: "",
    csiDescriptionUnused:
      "The items placed in to pre orders will have their cost code attached to the line item/ cost code",

    tasks: ["Mirrors", "Mailbox", "House Numbers", "Wallpaper"],
  },
  {
    orderIndex: 46,
    milestoneName: "Pre Drywall Insulation",
    scope: "Install pre drywall insulation as per site plans",
    csiCode: "7-00-200",
    csiDescriptionUnused: "Thermal Protection - Insulation",

    tasks: [
      "Blown In for Attic",
      "Batts Insulation",
      "Foam Insulation",
      "Concrete wall insulation - Foil",
      "Concrete wall insulation - Rigid Foam",
      "Sound Insulation",
    ],
  },
  {
    orderIndex: 47,
    milestoneName: "Hang Drywall",
    scope: "Hang drywall",
    csiCode: "9-00-250",
    csiDescriptionUnused: "Gypsum Wallboard",

    tasks: ["Drywall Install"],
  },
  {
    orderIndex: 48,
    milestoneName: "Finish Drywall",
    scope: "Finish drywall",
    csiCode: "9-00-250",
    csiDescriptionUnused: "Gypsum Wallboard",

    tasks: ["Drywall Texture"],
  },
  {
    orderIndex: 49,
    milestoneName: "Interior First Coat of Paint",
    scope: "Paint primer and first coat on ceilings",
    csiCode: "9-00-900",
    csiDescriptionUnused: "Paints and Coatings",

    tasks: ["Interior"],
  },
  {
    orderIndex: 50,
    milestoneName: "Siding",
    scope: "Install siding as per site plans",
    csiCode: "7-00-400",
    csiDescriptionUnused: "Roofing and Siding Panels",

    tasks: [
      "Stucco",
      "Lap Siding",
      "Hardie Siding",
      "Wood Siding",
      "Vinyl Siding",
      "Window Trim",
      "Corbels",
      "Window Caulking",
      "Tyvek",
      "Window Wraps",
      "Wood Accent Siding",
      "Porch Column Wraps",
      "Vinyl Soffits",
      "Aluminum Fascia",
      "Specialized Tools/ Rental Equipment",
    ],
  },
  {
    orderIndex: 51,
    milestoneName: "Mechanical Trim Out",
    scope: "Trim out mechnical system, turn on systems, pass final inspection",
    csiCode: "15-00-700",
    csiDescriptionUnused: "Heating, Venting and Air Conditioning",

    tasks: ["Mini Split System", "Exhaust Fan", "Range Hood Exhaust Vent"],
  },
  {
    orderIndex: 52,
    milestoneName: "Tile",
    scope: "Install tile as per site renderings",
    csiCode: "9-00-300",
    csiDescriptionUnused: "Tile",

    tasks: [
      "Grout/Tile Sealer",
      "Self-Leveling",
      "Tile Backsplashes",
      "Master Bathroom Tile",
      "Shower Wall",
      "Shower Ceiling",
      "Shower Floor",
      "Bathroom Floor",
      "Schulter Stricts",
      "Bullnose lip",
      "Bench",
      "Shelf",
      "Shower pan pre-slope (large)",
      "Shower pan pre-slope (small)",
      "Self-Leveling",
      "Accent Tile",
      "Decorative walls",
      "Vanity Backsplash",
      "Pre-manufactured Niche",
    ],
  },
  {
    orderIndex: 53,
    milestoneName: "Flooring",
    scope: "Install flooring",
    csiCode: "9-00-600",
    csiDescriptionUnused: "Flooring",

    tasks: [
      "Total Quote from Sub Contractor",
      "Carpet",
      "Tile over concrete",
      "Tile over Wood",
      "Regrout Tile",
      "Laminate",
      "Vinyl",
      "Engineered Hardwood",
      "Transition Pieces",
      "Self Leveling Floor",
      "Sound Proofing",
      "Prefinished Hardwood",
      "Hardwood Repair and Raw Harwood",
      "Refinish Hardwood",
    ],
  },
  {
    orderIndex: 54,
    milestoneName: "Soffits",
    scope: "Install soffits",
    csiCode: "7-00-050",
    csiDescriptionUnused:
      "Basic Thermal and Moisture Protection Materials and Methods",

    tasks: ["Vinyl Soffits"],
  },
  {
    orderIndex: 55,
    milestoneName: "Driveways/Sidewalks",
    scope: "Install driveways and sidewalks as per site plans",
    csiCode: "2-00-750",
    csiDescriptionUnused: "Concrete Pads and Walks",

    tasks: ["Driveways/Sidewalks"],
  },
  {
    orderIndex: 56,
    milestoneName: "Exterior Details",
    scope: "Install exterior doors as per site plans",
    csiCode: "10-00-340",
    csiDescriptionUnused: "Manufactured Exterior Specialties",
    tasks: [],
  },
  {
    orderIndex: 57,
    milestoneName: "Garage Door",
    scope: "Install garage doors as per site plans",
    csiCode: "8-00-300",
    csiDescriptionUnused: "Specialty Doors",

    tasks: ["Garage Door"],
  },
  {
    orderIndex: 58,
    milestoneName: "Cabinetry",
    scope: "Install cabinets",
    csiCode: "6-00-200",
    csiDescriptionUnused: "Finish Carpentry",

    tasks: [
      "Total Quote from Sub Contractor",
      "Kitchen Cabinets - Standard Cab Cabinet",
      "Kitchen Cabinets - Pantry",
      "Kitchen Cabinets - Drawer Base",
      "Kitchen Cabinets - Specialty Cabinet",
      "Kitchen -Special Interior Components",
      "Kitchen Cabinets - Crown",
      "Kitchen Cabinets - Light Rail",
      "Kitchen Cabinets - Fillers",
      "Screws, Shims, Touch up kit",
      "Custom Cabinetry #1",
      "Custom Cabinetry #2",
      "Custom Cabinetry #3",
      "Custom Cabinetry #4",
      "Laundry Cabinets",
      "Custom Bench",
      "Cabinet Delivery",
      "Master Bathroom Vanity",
      "Common Bath Vanity",
      "Common Bath Vanity",
      "Common Bath Vanity",
      "Common Bath Vanity",
      "Half Bath Vanity",
      "Half Bath Vanity",
      "Cabinetry Hardware (knobs and pulls)",
    ],
  },
  {
    orderIndex: 59,
    milestoneName: "Interior Doors",
    scope: "Install interior doors",
    csiCode: "8-00-100",
    csiDescriptionUnused: "Doors",

    tasks: [
      "Prehung Door (Hollow Core) - 6'8",
      "Prehung Door (Solid Core) - 6'8",
      "Prehung Door (Hollow Core) - 8'0",
      "Prehung Door (Solid Core) - 8'0",
      "Door Slab (Hollow Core) - 6'8",
      "Door Slab (Solid Core) - 6'8",
      "French Door",
      "French Door with glass",
      "Bifold Door",
      "Pocket Door",
      "Double Pocket Door",
      "Barn Door",
      "Custom Door",
      "Bypass Door",
      "Privacy Door Handle",
      "Hall Closet Door Handle",
      "Dummy (one side) Door Handle",
      "Ball catches must buy with dummy",
      "Pocket door hardware",
      "Pocket Door Frame Hardware",
      "Barn Door Handle",
      "Barn Door Slide Hardware",
      "Bi Fold Pull",
      "Door Hinges",
      "Door Stops",
    ],
  },
  {
    orderIndex: 60,
    milestoneName: "Trim",
    scope: "Install trim",
    csiCode: "6-00-200",
    csiDescriptionUnused: "Finish Carpentry",

    tasks: [
      "1x2 Batten",
      "Baseboard",
      "Casement",
      "Chair Rail",
      "Window Sill",
      "Crown",
      "Quarter Round",
      "Trim Nails",
      "Wood Range Hood Cover",
      "Wood Accent #1",
      "Wood Accent #2",
    ],
  },
  {
    orderIndex: 61,
    milestoneName: "Closet System",
    scope: "Install Closet System",
    csiCode: "10-00-900",
    csiDescriptionUnused: "Wardrobe and Closet Specialties",

    tasks: [
      "Master Closet",
      "Master Closet Shoe Rack",
      "Master Closet Drawers",
      "Laundry Shelf and Rod",
      'Linen Closet Wood Shelves 16" deep',
      "Pantry Closet",
      "Standard Closet",
      "Small Closet Shelf System",
    ],
  },
  {
    orderIndex: 62,
    milestoneName: "Handrails",
    scope: "Install handrails",
    csiCode: "6-00-050",
    csiDescriptionUnused: "Basic Wood and Plastic Materials and Methods",

    tasks: ["Handrails"],
  },
  {
    orderIndex: 63,
    milestoneName: "Stairs",
    scope: "Install stairs",
    csiCode: "6-00-050",
    csiDescriptionUnused: "Basic Wood and Plastic Materials and Methods",

    tasks: [
      "Interior Stairwell",
      "Wall Mounted Handrail",
      "Open Handrail with Spindles",
      "Grand Staircase",
      "Wood Treads and Risers",
      "Other",
      "Exterior Stairs",
    ],
  },
  {
    orderIndex: 64,
    milestoneName: "Post Drywall Insulation",
    scope: "Complete post drywall insulation install",
    csiCode: "7-00-050",
    csiDescriptionUnused:
      "Basic Thermal and Moisture Protection Materials and Methods",
    tasks: [],
  },
  {
    orderIndex: 65,
    milestoneName: "Exterior Paint",
    scope: "Paint exterior",
    csiCode: "9-00-900",
    csiDescriptionUnused: "Paints and Coatings",

    tasks: [
      "Exterior",
      "Staining and Sealing",
      "Pressure Washing",
      "Specialized Tools/ Rental Equipment",
    ],
  },
  {
    orderIndex: 66,
    milestoneName: "Countertops",
    scope: "Install countertops",
    csiCode: "9-00-050",
    csiDescriptionUnused: "Basic Finish Materials and Methods",

    tasks: [
      "Butcher Block",
      "Formica",
      "Cost for the slab",
      "Seal the countertop",
      "Cost to fabricate and install",
      "Waterfall edge",
      "Extra Overhang Upgrade",
      "Extra Labor for Moving",
      "Small scrap",
    ],
  },
  {
    orderIndex: 67,
    milestoneName: "Final Tile",
    scope: "Install final tile materials",
    csiCode: "9-00-300",
    csiDescriptionUnused: "Tile",
    tasks: [],
  },
  {
    orderIndex: 68,
    milestoneName: "Glass Enclosures",
    scope: "Install glass enclosures",
    csiCode: "8-00-500",
    csiDescriptionUnused: "Windows",

    tasks: ["Glass Enclosures"],
  },
  {
    orderIndex: 69,
    milestoneName: "Fence",
    scope: "Install fences",
    csiCode: "2-00-820",
    csiDescriptionUnused: "Fences and Gates",

    tasks: ["Fence"],
  },
  {
    orderIndex: 70,
    milestoneName: "Lawn Sprinklers",
    scope: "Install lawn sprinklers",
    csiCode: "2-00-813",
    csiDescriptionUnused: "Lawn Sprinkling and Irrigation",

    tasks: [
      "Install New Sprinkler System",
      "Repair Sprinklers heads",
      "Repair Well Pump",
      "New Well",
      "New Well Pump",
    ],
  },
  {
    orderIndex: 71,
    milestoneName: "Decks",
    scope: "Install decks",
    csiCode: "6-00-050",
    csiDescriptionUnused: "Basic Wood and Plastic Materials and Methods",

    tasks: [
      "Total Quote from Sub Contractor",
      "Labor",
      "1x6x16 PT Deck Board",
      "2x6x12 PT",
      "2x4x12 PT",
      "2x2x3 PT",
      "4x4x8 PT",
      "4x4x12 PT",
      "Precut 3' Stringer",
      "Precut 8' Stringer",
      "2x6 Joist Hanger double",
      "2x6 Joist Hanger single",
      "Paint Joist Hanger",
      "4x4 post bucket",
      "Bucket of Joist Nails",
      "Z Flashing",
      "Concrete Foundation",
      "Carriage Bolts",
      "RedHead Lag Bolts 25pk",
      "Tar for top of boards",
      "PT Handrail",
      "PT Handrail with Composite top",
      "Deck Composite Board",
      "Deck Composite Board Nosing Board",
      "Composite Board Screws 500pk",
      "Staining the deck",
      "Specialized Tools/ Rental Equipment",
    ],
  },
  {
    orderIndex: 72,
    milestoneName: "Docks",
    scope: "Install docks",
    csiCode: "2-00-850",
    csiDescriptionUnused: "Bridges/Footbridges",

    tasks: [
      "4x4x16 Marine Grade",
      "4x4x12 Marine Grade",
      "4x4x8 Marine Grade",
      "2x6x12 Marine Grade",
      "Dock Pilings",
      "New Dock via Sub Contractor",
    ],
  },
  {
    orderIndex: 73,
    milestoneName: "Pavers",
    scope: "Install pavers",
    csiCode: "2-00-782",
    csiDescriptionUnused: "Brick Pavers",

    tasks: ["Pavers"],
  },
  {
    orderIndex: 74,
    milestoneName: "Landscaping",
    scope: "Perform landscaping as per site plans",
    csiCode: "2-00-900",
    csiDescriptionUnused: "Landscaping",

    tasks: ["Pavers", "Gravel", "Shell", "Fire Pit"],
  },
  {
    orderIndex: 75,
    milestoneName: "Appliances",
    scope: "Install appliances",
    csiCode: "15-00-050",
    csiDescriptionUnused: "Basic Mechanical Materials and Methods",

    tasks: [
      "Range",
      "Cook top",
      "Range Hood",
      "Refrigerator",
      "Wall Oven",
      "Dishwasher",
      "Microwave",
      "Miscellaneous Parts",
      "Delivery",
      "Appliance Install",
    ],
  },
  {
    orderIndex: 76,
    milestoneName: "Finalize Fireplace",
    scope: "Install fireplace trim kits and pass final inspection",
    csiCode: "10-00-300",
    csiDescriptionUnused: "Fireplaces and Stoves",

    tasks: ["Fireplace", "Fireplace Mantel"],
  },
  {
    orderIndex: 77,
    milestoneName: "Plumbing Trim out",
    scope: "Install plumbing fixtures trim out and pass final inspection",
    csiCode: "15-00-100",
    csiDescriptionUnused: "Plumbing",

    tasks: [
      "Total Quote from Supplier",
      "Toilet",
      "Shower Faucet",
      "Shower Diverter",
      "Shower Wand",
      "Body Srayers",
      "Shower Drain",
      "Bathtub",
      "Tub  Drain",
      "Stand alone Tub",
      "Tub Faucet",
      "Bathroom Sink",
      "Sink Faucet",
      "Sink Drain",
      "Laundry Sink",
      "Sink Faucet",
      "Sink Drain",
      "Water Heater",
      "Hose Bib",
      "Garage Utility Sink",
      "Water Softener",
      "Reverse Osmosis",
      "Magnetized Water System",
      "Kitchen Faucet",
      "Kitchen Sink",
      "Pot Filler",
      "Water Line to Refrigerator",
      "Air Switch for Disposal",
      "Garbage Disposal",
    ],
  },
  {
    orderIndex: 78,
    milestoneName: "Gas Trim out",
    scope: "Install fireplace trim kits and pass final inspection",
    csiCode: "15-00-200",
    csiDescriptionUnused: "Process Piping",

    tasks: ["Water Heater"],
  },
  {
    orderIndex: 79,
    milestoneName: "Electric Trim out",
    scope: "Install fireplace trim kits and pass final inspection",
    csiCode: "16-00-100",
    csiDescriptionUnused: "Electrical",

    tasks: [
      "Master Fan",
      "Common Bed Fan",
      "Exhaust Fan",
      "Moisture Detection Exhaust Fan",
      "Living room Fan",
      "Exterior Fan",
      "Light Bulbs",
      "Bathroom Sconce",
      "Wall Sconce",
      "Backlit Mirror",
      "Laundry Lighting",
      "Closet Lighting",
      "Can Light",
      "Under Cabinet Lighting",
      "Vanity Light",
      "Pendant Light 1",
      "Pendant Light 2",
      "Entry Chandelier",
      "Dining Room Chandelier",
      "Outdoor Spot Lights",
      "Outdoor Chandelier",
      "Outdoor Wall Sconce",
      "Outdoor Ceiling Light",
      "Garage Light",
      "Landscape Lighting",
      "Pool Lighting",
      "Smoke Detectors",
      "Carbon monoxide detector",
    ],
  },
  {
    orderIndex: 80,
    milestoneName: "Fire/Sprinklers Trim out",
    scope: "Install fireplace trim kits and pass final inspection",
    csiCode: "10-00-300",
    csiDescriptionUnused: "Fireplaces and Stoves",

    tasks: ["Fire Sprinkler", "Exit Signs", "Fire Extinguisher"],
  },
  {
    orderIndex: 81,
    milestoneName: "Home Technology Trim out",
    scope: "Install fireplace trim kits and pass final inspection",
    csiCode: "16-00-800",
    csiDescriptionUnused: "Sound and Video",

    tasks: ["Security Cameras", "Sound System", "Smart Home Controls"],
  },
  {
    orderIndex: 82,
    milestoneName: "Hardware",
    scope: "Install hardware",
    csiCode: "8-00-700",
    csiDescriptionUnused: "Hardware",

    tasks: [
      "Towel Racks",
      "Hand Towel Rack",
      "Towel Hook",
      "Toilet Paper Holder",
      "Handicap Grab Bar",
    ],
  },
  {
    orderIndex: 83,
    milestoneName: "Finishes Installs",
    scope: "Install finish materials",
    csiCode: "9-00-050",
    csiDescriptionUnused: "Basic Finish Materials and Methods",

    tasks: ["Mirrors", "Mailbox", "House Numbers", "Wallpaper"],
  },
  {
    orderIndex: 84,
    milestoneName: "Rodent Protection",
    scope: "Install pest control systems",
    csiCode: "10-00-290",
    csiDescriptionUnused: "Pest Control",

    tasks: [
      "Spray for Footers",
      "Termite Spot Treatment",
      "Tent for Termites",
      "In Wall Pest System",
      "Termidor Spray the Framing",
    ],
  },
  {
    orderIndex: 85,
    milestoneName: "Gutters",
    scope: "Install gutters",
    csiCode: "7-00-700",
    csiDescriptionUnused: "Roof Specialties and Accessories",

    tasks: ["Gutters"],
  },
  {
    orderIndex: 86,
    milestoneName: "Blinds/ Shutters",
    scope: "Install blinds and shutters",
    csiCode: "9-00-050",
    csiDescriptionUnused: "Basic Finish Materials and Methods",

    tasks: ["Blinds/ Shutters"],
  },
  {
    orderIndex: 87,
    milestoneName: "Shelves",
    scope: "Install shelves",
    csiCode: "10-00-670",
    csiDescriptionUnused: "Storage Shelving",

    tasks: [
      "Floating Shelves",
      "Shelves",
      "Built-in Shelves",
      "Library Shelves",
    ],
  },
  {
    orderIndex: 88,
    milestoneName: "Final Interior Paint",
    scope: "Finish interior paint and final punch list of paint",
    csiCode: "9-00-900",
    csiDescriptionUnused: "Paints and Coatings",

    tasks: ["Interior Paint"],
  },
  {
    orderIndex: 89,
    milestoneName: "Final Cleaning",
    scope: "Final cleaning",
    csiCode: "1-00-740",
    csiDescriptionUnused: "Cleaning",

    tasks: ["Final Cleaning"],
  },
  {
    orderIndex: 90,
    milestoneName: "Other",
    scope: "Other",
    csiCode: "2-00-000",
    csiDescriptionUnused: "General",

    tasks: ["Other"],
  },
  {
    orderIndex: 91,
    milestoneName: "Solar Finalize Install",
    scope: "Finish solar install, pass inspection, and power on",
    csiCode: "13-00-600",
    csiDescriptionUnused: "Solar and Wind Energy Equipment",

    tasks: ["Solar"],
  },
  {
    orderIndex: 92,
    milestoneName: "Job Administration",
    scope: "",
    csiCode: "",
    csiDescriptionUnused: "",

    tasks: ["Dumpsters", "Project Management", "Insurance"],
  },
];

export const universalTasks = [
  "Pass all necessary inspections",
  "Upload Photos",
  "Upload Receipts",
];
