import { Inject } from "@not-the-droids/exco-ts-inject";

export interface AuthPasscodeParams {
  readonly passcode: string;
}

export abstract class AuthPasscodeModel {
  static inject?: Inject<AuthPasscodeModel> = undefined;

  public abstract confirmPasscode(params: AuthPasscodeParams): Promise<boolean>;
}
