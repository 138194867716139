import { Inject } from "@not-the-droids/exco-ts-inject";
import { observer } from "mobx-react";
import React, { ReactElement } from "react";
import { StyleSheet, View } from "react-native";
import { ProjectModel } from "../../../data-model";
import { InjectedSideBar, Page, Screen, StyledText } from "../components/controls";
import { HeaderFactory } from "../components/Header";
import { ProjectCrudFlow, ProjectStep } from "../flows/ProjectCrudFlow";
import { SideBarMain } from "../components/SideBarMain";
import { Authenticator } from "../exco-lib/exco-auth";
import { History, HistoryInjectable } from "../HistoryInjectable";
import { InjectedFactoryComponent } from "../InjectorContext";
import { computed, makeObservable, observable } from "mobx";
import { ProgressSteps } from "../components/ProgressSteps";
import { InjectedProjectFlowDetailsView } from "../components/ProjectCrud/ProjectFlowDetailsView";
import { LoadingIndicator } from "../components/LoadingIndicator";
import { InjectedProjectFlowWorkAreasViewFactory } from "../components/ProjectCrud/ProjectFlowWorkAreasView";
import { InjectedProjectFlowConfirmProjectViewFactory } from "../components/ProjectCrud/ProjectFlowConfirmProjectView";
import { InjectedYoutubeTooltip } from "../components/YoutubeTooltip/YoutubeTooltip";
import { UserViewModel } from "../viewModels/UserViewModel";

interface Props {
  authenticator: Authenticator;
  history: History;
  projectCrudFlow: ProjectCrudFlow;
  projectModel: ProjectModel;
  userViewModel: UserViewModel;
}

interface CreateProps {
  projectId?: string;
}

export class ProjectCrudScreenFactory {
  static inject: Inject<ProjectCrudScreenFactory> = (injector) => {
    return () =>
      new ProjectCrudScreenFactory({
        authenticator: injector.get(Authenticator)(),
        projectCrudFlow: injector.get(ProjectCrudFlow)(),
        projectModel: injector.get(ProjectModel)(),
        history: injector.get(HistoryInjectable)(),
        userViewModel: injector.get(UserViewModel)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ProjectCrudScreen {...this.props} {...props} />;
  }
}

@observer
class ProjectCrudScreen extends React.Component<Props & CreateProps> {
  constructor(props: Props & CreateProps) {
    super(props);
    makeObservable(this);
  }

  @observable private _initialized = false;

  async componentDidMount() {
    await this.props.projectCrudFlow.fetchNotCompletedProject();
    this._initialized = true;
  }

  componentWillUnmount = () => {
    this.props.projectCrudFlow.clearProject();
  };

  @computed public get progressSteps() {
    return this.props.projectCrudFlow.progressSteps;
  }

  renderCurrentStepElement = (): ReactElement => {
    if (!this._initialized) return <LoadingIndicator />;

    switch (this.props.projectCrudFlow.currentStep) {
      case ProjectStep.WorkAreas:
        return <InjectedProjectFlowWorkAreasViewFactory />;
      case ProjectStep.Finish:
        return <InjectedProjectFlowConfirmProjectViewFactory />;
      default:
        return <InjectedProjectFlowDetailsView />;
    }
  };

  render() {
    return (
      <Screen>
        <InjectedSideBar size="default">
          <SideBarMain />
        </InjectedSideBar>
        <Page variant="main">
          <InjectedFactoryComponent factory={HeaderFactory} props={{}} />
          <StyledText variant="heading2" style={styles.header}>
            Create New Project
          </StyledText>
          <View style={styles.progressStepsContainer}>
            <ProgressSteps steps={this.progressSteps} />
            <InjectedYoutubeTooltip
              videoId={
                this.props.userViewModel.isContractor 
                  ? "qoA1Jilf3-o" : "lX58sV0G7Rc"
              }
              text={"Create New Project Tutorial Video"}
            />
          </View>

          {this.renderCurrentStepElement()}
        </Page>
      </Screen>
    );
  }
}

const styles = StyleSheet.create({
  header: {
    marginBottom: 24,
  },
  progressStepsContainer: {
    marginBottom: 72,
    gap: 16,
  },
});
