import { Inject, singletonInject } from "@not-the-droids/exco-ts-inject";
import { ContractorModel, CreateContractorParams } from "../ContractorModel";
import { Contractor, ContractorAddFile, ContractorFile } from "../Contractor";
import { RestClient } from "./RestClient";

export class RestContractorModel extends ContractorModel {
  static inject: Inject<RestContractorModel> = singletonInject((injector) => {
    return () => new RestContractorModel(injector.get(RestClient)());
  });

  constructor(private readonly rest: RestClient) {
    super();
  }

  public getContractors(): Promise<Contractor[]> {
    return this.rest.get("/contractor");
  }

  public createContractor(params: CreateContractorParams): Promise<Contractor> {
    return this.rest.post("/contractor", params);
  }

  public getContractorByEmail(contractorEmail: string): Promise<Contractor> {
    return this.rest.get(
      `/contractor/search?email=${encodeURIComponent(contractorEmail)}`
    );
  }

  public getFiles(): Promise<ContractorFile[]> {
    return this.rest.get(`/contractor/files`);
  }

  public addFile(file: ContractorAddFile): Promise<void> {
    return this.rest.post(`/contractor/file/${file.id}`, file);
  }

  public deleteFile(fileId: string): Promise<void> {
    return this.rest.delete(`/contractor/file/${fileId}`);
  }
}
