import { Identifiable } from "@not-the-droids/exco-data-model";
import { Bid } from "Bid";
import { Budget } from "Budget";
import { Contractor } from "Contractor";
import { Owner } from "Owner";
import { InvitedUser } from "ProjectModel";
import { User } from "User";

export type ProjectStatus = "draft" | "published" | "pendingArchival" | "archived" | "inviteRejected" | "bidRejected";

export const buildingTypes = [
  "apartment",
  "condo",
  "single",
  "townhome",
] as const;

export const workTypes = ["addition", "new", "remodel"] as const;

export const workLocations = ["interior", "exterior", "both"] as const

export type BuildingType = typeof buildingTypes[number];

export type WorkType = typeof workTypes[number];

export type WorkLocation =  typeof workLocations[number];

export type MediaType = "image";

export interface Address {
  readonly line1: string;
  readonly line2?: string;
  readonly city: string;
  readonly state: string;
  readonly country: string;
  readonly zip: string;
  readonly placeId?: string;
}

export interface Media {
  readonly id: string,
  readonly type?: MediaType;
  readonly fileName: string;
  readonly url: string;
}

export interface WorkCategoryAreas {
  workCategory: string;
  workAreas: Array<string>;
}

export interface WorkArea {
  name: string;
  workCategories?: ReadonlyArray<string>;
}

export interface ProjectInvite {
  projectName: string;
  projectDescription: string;
  projectAddress: Address;
  createdAt: string;
  expiresAt: string;
  createdBy?: string;
  workType?: string;
  name?: string;
  email?: string;
  projectId?: string;
  userId?: string;
  userType?: string;
  photoUrl?: string;
}

export interface Project extends Identifiable {
  readonly status: ProjectStatus;
  readonly name: string;
  readonly createdAt: Date;
  readonly description: string;
  readonly media: Media[];
  readonly address: Address;
  readonly ownerPlaceholderName: string;
  readonly locationType: BuildingType;
  readonly workType: WorkType;
  readonly workLocation: WorkLocation;
  readonly workAreas: ReadonlyArray<WorkArea>;
  readonly workCategories: ReadonlyArray<WorkCategoryAreas>;
  readonly inviteEmails: ReadonlyArray<InvitedUser>;
  readonly creator?: User;
  readonly bid?: Bid;
  readonly budget?: Budget;
  readonly owner?: Owner;
  readonly contractor?: Contractor;
}
