import { v4 as uuidv4 } from "uuid";
import { milestoneDefinitions, universalTasks } from "../constants/MilestoneDefinitions";
import { categoryDefinitions } from "../constants/CategoryDefinitions";
import {
  BidLine,
  Budget,
  BudgetInput,
  BudgetMilestone,
  BudgetMilestoneInput,
  BudgetMilestoneTask,
  BudgetMilestoneTaskInput,
  BudgetPhase,
  BudgetPhaseInput,
  BudgetPhaseWithMilestones,
  BudgetPhaseWithPayment,
  WorkCategoryAreas,
} from "../../../data-model";
import { parsePriceInput, parseSavedPriceToInput } from "./Numbers";
import {
  addDays,
  differenceInCalendarDays,
  formatDistanceToNowStrict,
  isPast,
} from "date-fns";
import { PaymentsData, ProjectPayment } from "../../../data-model/Payment";
import { TagType } from "../components/controls/Tag";
import { Palette } from "../components/styles";

export const getTomorrowDate = (): Date => {
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

export const sortOrderIndex = (a: any, b: any) => {
  if (a.renderIndex < b.renderIndex) {
    return -1;
  }
  if (a.renderIndex > b.renderIndex) {
    return 1;
  }
  return 0;
};

export const parseBudgetPhasesToInput = (
  phases: BudgetPhase[],
  budgetId: string
): BudgetPhaseInput[] => {
  return phases.sort(sortOrderIndex).map((item, index) => ({
    ...item,
    type: "phase",
    phaseIndex: index + 1,
    renderIndex: item.orderIndex,
    budgetId,
  }));
};

export const parseBudgetMilestonesToInput = (
  milestones: BudgetMilestone[],
  budgetId: string
): BudgetMilestoneInput[] => {
  return milestones.sort(sortOrderIndex).map((item, index) => ({
    ...item,
    type: "milestone",
    milestoneIndex: index + 1,
    renderIndex: item.orderIndex,
    budgetId: budgetId,
    tasks: item.tasks.map((i) => ({
      ...i,
      budget: parseSavedPriceToInput(i.budget),
    })),
  }));
};

export const reindexBudgetInput = (budget: BudgetInput[]): BudgetInput[] => {
  const newBudget: any = budget;
  let lastPhaseIndex: number = 0;
  let milestoneIndices: number[] = [];
  for (
    let i: number = 0, x: number = 0, y: number = 0;
    i < budget.length;
    i++
  ) {
    newBudget[i].renderIndex = i + 1;
    newBudget[i].orderIndex = i + 1;
    if (newBudget[i].type === "phase") {
      if (milestoneIndices.length > 0) {
        newBudget[lastPhaseIndex].milestoneIndices = milestoneIndices;
        milestoneIndices = [];
        lastPhaseIndex = i;
      }

      x++;
      newBudget[i].phaseIndex = x;
    } else if (newBudget[i].type === "milestone") {
      milestoneIndices.push(i + 1);
      y++;
      newBudget[i].milestoneIndex = y;
      if (i + 1 === newBudget.length) {
        newBudget[lastPhaseIndex].milestoneIndices = milestoneIndices;
      }
    }
  }
  return newBudget;
};

export const createBudgetInput = (
  budget: Budget,
  bidLines: BidLine[] | undefined,
  workCategories: WorkCategoryAreas[]
): BudgetInput[] => {
  const phases = parseBudgetPhasesToInput(budget.phases, budget.id);
  const milestones = parseBudgetMilestonesToInput(budget.milestones, budget.id);
  const sortedBudget = [...phases, ...milestones].sort(sortOrderIndex);

  if (sortedBudget.length > 0) {
    return reindexBudgetInput(sortedBudget);
  } else {
    const budgetInput = createNewBudgetInput(bidLines, budget.id);
    mapWorkAreasInBudgetInput(budgetInput, workCategories);
    return budgetInput;
  }
};

export const createTasksFromNames = (tasks: string[]): BudgetMilestoneTaskInput[] => {
  let newTasks: any[] = [];
  for (let i = 0; i < tasks.length; i++) {
    newTasks.push({
      id: uuidv4(),
      description: tasks[i],
      budget: 0,
    });
  }
  return newTasks;
};

export const createNewBudgetInput = (
  bidLines: BidLine[] | undefined,
  budgetId: string
): BudgetInput[] => {
  const phases: BudgetInput[] = [];
  const milestones: BudgetMilestoneInput[] = [];

  phases.push({
    id: uuidv4(),
    type: "phase",
    phaseIndex: 1,
    renderIndex: 1,
    orderIndex: 0,
    name: "Phase 1",
    milestoneIndices: [],
    budgetId: budgetId,
    cost: 0,
    numDays: 0,
  });
  if (bidLines && bidLines.length > 0) {
    const getMilestoneDefinitionIndex = (category: string) => {
      return milestoneDefinitions.findIndex(
        (x: any) => x.milestoneName.toUpperCase() === category.toUpperCase()
      );
    };

    const getCategoryDefinitionIndex = (category: string) => {
      return categoryDefinitions.findIndex(
        (x: any) => x.category.toUpperCase() === category.toUpperCase()
      );
    };

    let index: number = 0;
    for (let i = 0; i < bidLines.length; i++) {
      const categoryDefinition =
        categoryDefinitions[getCategoryDefinitionIndex(bidLines[i].category)];
      if (categoryDefinition) {
        for (let x = 0; x < categoryDefinition.milestones.length; x++) {
          const currentMilestone = categoryDefinition.milestones[x];
          const milestoneDefinition =
            milestoneDefinitions[getMilestoneDefinitionIndex(currentMilestone)];

          if (
            milestones.findIndex((item) => item.name === currentMilestone) ===
            -1
          ) {
            milestones.push({
              id: uuidv4(),
              type: "milestone",
              milestoneIndex: index + 1,
              renderIndex: milestoneDefinition.orderIndex + 1,
              orderIndex: milestoneDefinition.orderIndex + 1,
              tasks: [
                ...createTasksFromNames(milestoneDefinition.tasks),
                ...createTasksFromNames(universalTasks),
              ],
              files: [],
              name: currentMilestone,
              scopeOfWork: milestoneDefinition.scope,
              csiCode: milestoneDefinition.csiCode,
              budgetId: budgetId,
            });
            (phases[0] as BudgetPhaseInput).milestoneIndices.push(index);
            index++;
          }
        }
      } else {
        milestones.push({
          id: uuidv4(),
          type: "milestone",
          milestoneIndex: index + 1,
          renderIndex: index + 99,
          orderIndex: index + 99,
          tasks: [...createTasksFromNames(universalTasks)],
          files: [],
          name: bidLines[i].category,
          scopeOfWork: bidLines[i].scopeOfWork,
          budgetId: budgetId,
          csiCode: "",
        });
      }
    }
  }

  return reindexBudgetInput([...phases, ...milestones].sort(sortOrderIndex));
};

export const mapWorkAreasInBudgetInput = (
  budget: BudgetInput[],
  workCategories: WorkCategoryAreas[]
) => {
  const milestoneWorkAreasMap: Map<string, string[]> = new Map();

  workCategories.forEach((workCat) => {
    const foundCategory = categoryDefinitions.find(
      (catDef) => catDef.category === workCat.workCategory
    );
    if (foundCategory) {
      foundCategory.milestones.forEach((milestone: string) => {
        milestoneWorkAreasMap.set(milestone, workCat.workAreas);
      });
    }
  });

  for (let i = 0; i < budget.length; i++) {
    if (budget[i].type === "milestone") {
      const workAreas = milestoneWorkAreasMap.get(budget[i].name);
      (budget[i] as BudgetMilestoneInput).workAreas = workAreas;
      const scopeOfWork = (budget[i] as BudgetMilestoneInput).scopeOfWork;
      if (workAreas) {
        (budget[i] as BudgetMilestoneInput).scopeOfWork = scopeOfWork
          ? scopeOfWork + ` (${workAreas?.join(", ")})`
          : workAreas.join(", ");
      }
    }
  }
};

export const calculatePhaseStartDates = (
  start: Date | string,
  phases: BudgetPhase[]
): Date[] => {
  let prev: Date = new Date(start);
  return phases.map((_, index) => {
    if (index === 0) {
      return prev;
    } else {
      const current = addDays(prev, phases[index - 1].numDays);
      prev = current;
      return current;
    }
  });
};

export const calculateProjectBalance = (payments: PaymentsData) => {
  const remaining = payments.phases.reduce(
    (acc, phase) => acc + Number(phase.balanceWithFees),
    0
  );
  return {
    remaining: remaining > 0 ? remaining : 0,
    current: payments.balance,
  };
};

export const mapMilestonesToPhases = (
  phases: BudgetPhase[],
  milestones: BudgetMilestone[]
): BudgetPhaseWithMilestones[] => {
  return phases.map((phase, idx) => {
    const nextOrderIndex = phases[idx + 1]?.orderIndex ?? 1000000;
    const phaseMilestones = milestones.filter(
      (m) => m.orderIndex > phase.orderIndex && m.orderIndex < nextOrderIndex
    );
    return {
      ...phase,
      milestones: phaseMilestones,
    };
  });
};

export const mapProjectPayments = (payments: PaymentsData): ProjectPayment => {
  // First phase that has not been payed and has a cost
  const nextPayment = payments.phases.find(
    (p) => p.balanceWithFees > 0 && p.cost > 0
  );

  // The first phase that either has not been payed or
  // has not start yet should be in the middle
  let phasesPreview = [];
  let addPhase = false;
  for (let i = -1; i < payments.phases.length; i++) {
    const phase = payments.phases[i];
    const nextPhase = payments.phases[i + 1];
    if (
      nextPhase &&
      (new Date(nextPhase.startDate) > new Date() ||
        nextPhase.balanceWithFees > 0)
    ) {
      addPhase = true;
    }
    if (addPhase && phase) phasesPreview.push(phase);
  }

  phasesPreview = phasesPreview.slice(0, 3);

  const transactionsHistory = [
    ...payments.settlements,
    ...payments.outbounds,
  ].sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf());

  const remainingBalance = payments.phases.reduce(
    (acc, phase) => acc + Number(phase.balanceWithFees),
    0
  );

  return {
    ...payments,
    nextPayment,
    phasesPreview,
    transactionsHistory,
    remainingBalance: remainingBalance > 0 ? remainingBalance : 0,
  };
};

type PaymentPhaseDateTextAndTag = {
  dateTag: {
    type: TagType;
    text: string;
  };
  fundingTag: {
    type: TagType;
    text: string;
  };
  startsText: string;
  startsTextColor: string;
};

export const formatPaymentPhaseDateTextAndTag = (
  phase: BudgetPhaseWithPayment
): PaymentPhaseDateTextAndTag => {
  const phaseStartDate = new Date(phase.startDate);
  const isInPast = isPast(phaseStartDate);

  const startDistance = differenceInCalendarDays(phaseStartDate, new Date());
  let startDistanceText = formatDistanceToNowStrict(phaseStartDate, {
    unit: "day",
  });

  if (isInPast) {
    if (startDistanceText === "0 days") startDistanceText = "Started Today";
    else startDistanceText = `Started ${startDistanceText} ago`;
  } else if (startDistanceText === "0 days") {
    startDistanceText = "Starts Today";
  } else if (startDistanceText === "1 day") {
    startDistanceText = "Starts Tomorrow";
  } else {
    startDistanceText = `Starts in ${startDistanceText}`;
  }

  let tagType: TagType = "light";
  let tagText = "Not Funded";

  if (phase.lastPayment && phase.balanceWithFees > 0) {
    tagType = "secondary";
    tagText = "Change";
  } else if (phase.lastPayment?.status === "settled") {
    tagType = "affirm";
    tagText = "Funded";
  } else if (phase.lastPayment) {
    tagType = "dark";
    tagText = "Pending";
  }

  let dateTagType: TagType = "light";
  let dateTagText = "Not Started";

  if (phase.milestones.every((m) => m.completion === "completed")) {
    dateTagType = "affirm";
    dateTagText = "Completed";
  } else if (
    phase.milestones.some(
      (m) =>
        m.completion === "in-progress" ||
        m.completion === "pending" ||
        m.completion === "needs-review"
    )
  ) {
    dateTagType = "dark";
    dateTagText = "In Progress";
  }

  return {
    dateTag: {
      type: dateTagType,
      text: dateTagText,
    },
    fundingTag: {
      type: tagType,
      text: tagText,
    },
    startsText: startDistanceText,
    startsTextColor:
      isInPast &&
      (phase.balanceWithFees > 0 || phase.lastPayment?.status !== "settled")
        ? Palette.Warning100Pct
        : !isInPast && startDistance <= 10
        ? Palette.Secondary100Pct
        : Palette.Primary100Pct,
  };
};

export const convertTaskInputToTask = (
  task: BudgetMilestoneTaskInput
) => {
  return {
    ...task,
    budget: parsePriceInput(task.budget),
  } as BudgetMilestoneTask;
};

export function isBudgetMilestone(item: any): item is BudgetMilestone {
  return item?.type === "milestone";
};
