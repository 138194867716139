import { Inject } from "@not-the-droids/exco-ts-inject";
import { computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { StyleSheet, View } from "react-native";
import { UserModel } from "../../../data-model";
import { Icon, StyledButton, StyledText } from "../components/controls";
import { Authenticator } from "../exco-lib/exco-auth";
import { History, HistoryInjectable } from "../HistoryInjectable";
import { getRoute } from "../routes";
import { UserViewModel } from "../viewModels/UserViewModel";
import { Palette } from "./styles";

interface Props {
  authenticator: Authenticator;
  userModel: UserModel;
  userViewModel: UserViewModel;
  history: History;
}

interface CreateProps {}

export class VerifyEmailViewFactory {
  static inject: Inject<VerifyEmailViewFactory> = (injector) => {
    return () =>
      new VerifyEmailViewFactory({
        authenticator: injector.get(Authenticator)(),
        userModel: injector.get(UserModel)(),
        userViewModel: injector.get(UserViewModel)(),
        history: injector.get(HistoryInjectable)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(createProps: CreateProps) {
    return <VerifyEmailView {...this.props} {...createProps} />;
  }
}

@observer
class VerifyEmailView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  @observable private resendStatus: "pending" | "sent" | "none" = "none";
  @observable private verificationStatus: "pending" | "unverified" | "unknown" =
    "unknown";

  @computed get authenticator() {
    return this.props.authenticator;
  }

  @computed get currentUser() {
    return this.props.userViewModel.currentUser;
  }

  verifyEmail = async () => {
    this.verificationStatus = "pending";
    await this.props.userViewModel.refreshCurrentUser(true);

    if (this.currentUser?.isVerified) {
      this.props.history.push(getRoute("signUp").path);
    }
    this.verificationStatus = "unverified";
  };

  resendEmailVerification = async () => {
    this.resendStatus = "pending";
    await this.authenticator.email!.sendEmailVerification();
    this.resendStatus = "sent";
  };

  signOut = async () => {
    this.props.userViewModel.signOut();
    this.props.history.push("/");
  };

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.checkCircle}>
          <Icon name="check" type="white" size={56} />
        </View>
        <StyledText
          variant="heading3"
          colorMode={"light"}
          style={styles.heading}
        >
          Please verify your email address
        </StyledText>

        <StyledText
          variant="body2"
          colorMode={"light"}
          style={styles.emailSentToText}
        >
          We've sent an email to:
        </StyledText>
        <View style={styles.emailContainer}>
          <StyledText
            variant="body"
            colorMode={"light"}
            style={styles.emailAddress}
          >
            {this.currentUser!.email}
          </StyledText>
        </View>

        <StyledText
          variant="body2"
          colorMode={"light"}
          style={styles.checkEmailText}
        >
          Please check your email and click the verification link to continue.
        </StyledText>
        <StyledButton
          onPress={this.verifyEmail}
          text={
            this.verificationStatus === "unknown"
              ? "Already verified?"
              : this.verificationStatus === "pending"
              ? "Verifying..."
              : "Email not verified"
          }
          variant="primary"
          style={styles.buttons}
          disabled={this.verificationStatus === "pending"}
          textNumberOfLines={1}
        />
        <StyledButton
          text={
            this.resendStatus === "none"
              ? "Resend Verification Email"
              : this.resendStatus === "pending"
              ? "Sending..."
              : "Sent!"
          }
          variant="primary"
          onPress={this.resendEmailVerification}
          style={[styles.buttons, styles.resendButton]}
          textNumberOfLines={1}
          disabled={
            this.resendStatus === "pending" || this.resendStatus === "sent"
          }
        />
        <StyledText
          variant="body2"
          colorMode={"light"}
          style={styles.checkEmailText}
        >
          {`Not you? `}
          {
            <StyledButton
              text={"Sign Out"}
              variant="textOnly"
              onPress={this.signOut}
              disabled={this.resendStatus === "pending"}
            />
          }
        </StyledText>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  checkCircle: {
    backgroundColor: Palette.Affirm100Pct,
    width: 120,
    height: 120,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 60,
    marginBottom: 30,
  },
  heading: {
    fontWeight: "bold",
    marginBottom: 40,
  },
  emailSentToText: {
    marginBottom: 10,
  },
  emailContainer: {
    paddingHorizontal: 30,
    paddingVertical: 15,
    backgroundColor: Palette.Primary90Pct,
    borderRadius: 5,
    marginBottom: 40,
  },
  emailAddress: {
    fontWeight: "bold",
  },
  checkEmailText: {
    marginBottom: 50,
    width: 280,
    textAlign: "center",
  },
  buttons: {
    width: 230,
    marginBottom: 15,
  },
  resendButton: {
    backgroundColor: Palette.Primary50Pct,
  },
});
