import { StyleSheet, View } from "react-native";
import CheckBox, { CheckBoxProps } from "../CheckBox";
import { StyledText } from "../controls";
import { Palette } from "../styles";

type Props = {
  address: string
  checkboxStateAddress: CheckBoxProps
  checkboxStateTerms: CheckBoxProps
};

export const ConfirmAgreementView: React.FunctionComponent<Props> = ({
  address,
  checkboxStateAddress,
  checkboxStateTerms,
}) => (
  <View style={styles.container}>
    <StyledText variant="heading3" isBold>
      Confirm Agreement
    </StyledText>
    <View style={styles.confirmCheckbox}>
      <CheckBox
        checked={checkboxStateAddress.checked}
        onChange={checkboxStateAddress.onChange}
      />
      <StyledText variant="body">
        By checking this box, you are confirming that 
        <StyledText variant="body">
          {` ${address} `}
        </StyledText>
        is the address where the work defined in this contract will take place.
      </StyledText>
    </View>
    <View style={styles.confirmCheckbox}>
      <CheckBox
        checked={checkboxStateTerms.checked}
        onChange={checkboxStateTerms.onChange}
      />
      <StyledText variant="body">
        By checking this box, you are confirming that you have read, understand, and agree to the terms of the Contract.
      </StyledText>
    </View>
  </View>
);

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    border: "1px solid " + Palette.Accent25Pct,
    backgroundColor: Palette.Accent10Pct,
    display: "flex",
    padding: 32,
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 24,
  },
  confirmCheckbox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: 8,
  },
});
