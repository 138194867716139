import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { StyleSheet, View } from "react-native";
import { CreateOwnerParams } from "../../../data-model";
import { StyledTextInput, StyledText, StyledButton } from "./controls";
import { GooglePlacesInput, AddressParts } from "./GooglePlacesInput";

interface Props {
  onUpdatePersonalDetails: (params: CreateOwnerParams) => Promise<void>;
}

@observer
export default class PersonalDetails extends React.Component<Props> {
  @observable private phone: string = "";
  //TODO remove type assertion
  @observable private addressParts: AddressParts = {} as any;
  @observable private addressString: string = "";

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  onChangeAddressInput = (address: AddressParts) => {
    this.addressParts = address;
    this.addressString = address.line1
  };

  updatePhone = (update: string) => {
    this.phone = update.replace(/[^0-9]/, "");
  };

  renderPhoneInput() {
    return (
      <View style={[styles.inputOC]}>
      <StyledText variant="body2" isBold={true} style={styles.label}>Phone Number</StyledText>
        <StyledTextInput
          placeholder="Enter your phone number"
          maxLength={10}
          value={this.phone}
          variant="tertiary"
          onChangeText={(text) => this.updatePhone(text)}
        />
      </View>
    );
  }

  renderAddressInput() {
    return (
      <View style={styles.inputOC}>
      <StyledText variant="body2" isBold={true} style={styles.label}>Address</StyledText>
        <GooglePlacesInput handleChange={this.onChangeAddressInput} />
      </View>
    );
  }

  renderButton() {
    // TODO: Add proper validation in the future?
    const isDisabled =
      !this.phone ||
      !this.addressParts ||
      !this.addressString ||
      this.phone.length < 10;
    return (
      <StyledButton
        onPress={() =>
          this.props.onUpdatePersonalDetails({
            phone: this.phone,
            address: {
              line1: this.addressParts.line1,
              line2: this.addressParts.line2 || "",
              city: this.addressParts.city || "",
              state: this.addressParts.state || "",
              country: this.addressParts.country || "",
              zip: this.addressParts.zip || "",
              placeId: this.addressParts.placeId || "",
            },
          })
        }
        disabled={isDisabled}
        style={styles.buttonStyle}
        text={"Finish Profile"}
      />
    );
  }

  render() {
    return (
      <View style={styles.container}>
       <StyledText variant="heading" isBold={true} style={styles.sectionTitle} isUpperCase={true} >
          Personal Details
        </StyledText>
        {this.renderPhoneInput()}
        {this.renderAddressInput()}
        {this.renderButton()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    marginLeft:'auto',
    marginRight:'auto',
    marginTop: '10vh',
    display: "flex",
    flexDirection: "column",
    width: 360,
    alignItems:"center"
  },
  sectionTitle: {
    marginBottom: 32,
    textAlign:"center"
  },
  inputOC: {
    marginTop: 30,
    marginBottom: 30,
    width:"100%"
  },
  label: {
    fontSize: 12,
    marginBottom: 8,
  },
  buttonStyle: {
    width:"50%",
  }
});
