import { Inject } from "@not-the-droids/exco-ts-inject";
import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React, { createRef } from "react";
import { Dimensions, Modal, StyleSheet, View, ViewProps } from "react-native";
import { UserType } from "../../../../data-model";
import { withInjectedFactory } from "../../InjectorContext";
import { UserViewModel } from "../../viewModels/UserViewModel";
import { Icon, IconComponentProps, StyledText, StyledTextProps, StyledTouchableOpacity } from "../controls";

interface Props {
  userViewModel: UserViewModel;
}

interface CreateProps extends ViewProps {
  iconStyle?: IconComponentProps;
  textStyle?: StyledTextProps;
  text: string;
  videoId: string;
  userType?: UserType;
}

const ASPECT_RATIO = 16 / 9;

export class YoutubeTooltipFactory {
  static inject: Inject<YoutubeTooltipFactory> = (injector) => {
    return () =>
      new YoutubeTooltipFactory({
        userViewModel: injector.get(UserViewModel)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <YoutubeTooltip {...this.props} {...props} />;
  }
}

@observer
class YoutubeTooltip extends React.Component<Props & CreateProps> {
  @observable private showModal = false;
  private playerRef = createRef<HTMLIFrameElement>();

  constructor(props: Props & CreateProps) {
    super(props);
    makeObservable(this);
  }

  static defaultProps = {
    iconStyle: {
      name: "info",
      type: "accent",
      size: 16
    },
    textStyle: {
      variant: "body2",
      colorMode: "accent",
    },
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  readonly closeModal = () => {
    this.setShowModal(false);
    if (this.playerRef.current) {
      this.playerRef.current.contentWindow?.postMessage(
        '{"event":"command","func":"pauseVideo","args":""}',
        "*"
      );
    }
  };

  readonly handleClickOutside = (event: MouseEvent) => {
    if (
      this.playerRef.current &&
      !this.playerRef.current.contains(event.target as Node)
    ) {
      this.closeModal();
    }
  };

  readonly setShowModal = (show: boolean) => this.showModal = show;

  render() {
    const {
      iconStyle,
      text,
      textStyle,
      userType,
      userViewModel,
      videoId,
      style,
      ...rest
    } = this.props;
    const { width } = Dimensions.get("window");
    const videoWidth = ( width - 32 ) / 2;
    const videoHeight = videoWidth / ASPECT_RATIO;

    if (userType && userViewModel.currentUser?.userType !== userType) return null;

    return (
      <View {...rest} style={[{ alignSelf: 'flex-start' }, style]}>
        <StyledTouchableOpacity
          onPress={() => this.setShowModal(true)}
        >
          <View style={styles.touchable}>
            <StyledText {...textStyle}>
              {text}
            </StyledText>
            {iconStyle && <Icon {...iconStyle} size={16}/>}
          </View>
        </StyledTouchableOpacity>
        <Modal animationType="fade" transparent visible={this.showModal}>
          <View style={styles.modalContainer}>
            <View style={[styles.videoContainer, { width: videoWidth }]}>
              <View
                style={[styles.videoWrapper, { height: videoHeight, width: videoWidth }]}
              >
                <View style={styles.videoIframe}>
                  <iframe
                    title="youtube-video-player"
                    width={videoWidth}
                    height={videoHeight}
                    src={`https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0&showinfo=0`}
                    allowFullScreen
                    ref={this.playerRef}
                  />
                </View>
              </View>
            </View>
          </View>
        </Modal>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  touchable: {
    flexDirection: "row",
    justifyContent: "center",
    gap: 5,
  },
  modalContainer: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    alignItems: "center",
    justifyContent: "center",
  },
  videoContainer: {
    borderRadius: 5,
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  videoWrapper: {
    position: "relative",
    paddingBottom: "56.25%",
  },
  videoIframe: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
});

export const InjectedYoutubeTooltip = withInjectedFactory(
  YoutubeTooltipFactory
);
