import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { View, ScrollView, StyleSheet } from "react-native";
import { useHover } from "react-native-web-hooks";
import { Palette } from "./styles";
import { Icon, StyledText, StyledTouchableOpacity } from "./controls";
import CheckBox from "./CheckBox";

export type StyledVariant =
  | "original"
  | "pill";

type ListItem = {
  name: string;
  value: string;
};

type Props = {
  placeholder?: string;
  list: string[] | ListItem[];
  variant?: StyledVariant;
} & (
  | {
      type: "single";
      onChange: (value: string) => void;
      value: string;
    }
  | {
      type: "multi";
      onChange: (value: string[]) => void;
      value: string[];
    }
);

@observer
export class DropdownMenu extends React.Component<Props> {
  @observable private isDropdownMenuVisible: boolean = false;

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  readonly getName = (item: string | ListItem) => {
    if (typeof item === "string") {
      return item;
    } else {
      return item.name;
    }
  };

  readonly toggleDropdownMenu = () => {
    this.isDropdownMenuVisible = !this.isDropdownMenuVisible;
  };

  readonly handleMenuItemPress = (item: string | ListItem) => {
    const { onChange, type } = this.props;

    // Ignore the following ts-ignores, types are correct
    // It compiles correctly but if the files is changed
    // the compiler thinks items is string & string[]
    if (type === "single") {
      // @ts-ignore
      onChange(this.getName(item));
      this.toggleDropdownMenu();
    } else {
      const name = this.getName(item);
      const currIdx = this.props.value.findIndex((v) => v === name);
      if (currIdx === -1) {
        // @ts-ignore
        onChange([...this.props.value, name]);
      } else {
        const newValue = [...this.props.value];
        newValue.splice(currIdx, 1);
        // @ts-ignore
        onChange(newValue);
      }
    }
  };
  // Note: unable to find proper Typescript for this; marking as "any" for now - EY
  //--its NativeSyntheticEvent<TargetedEvent>, not sure what contains/related target are though - RM
  readonly handleBlur = (e: any) => {
    if (e.currentTarget.contains(e.relatedTarget)) {
      return;
    }
    this.toggleDropdownMenu();
  };

  readonly formatText = (item: string | string[]) => {
    if (typeof item === "string") {
      return this.getName(item);
    } else {
      return item.map(this.getName).join(", ");
    }
  };

  render() {
    const { list, placeholder, value, variant } = this.props;
    const containerStyle = containerVariants[variant || "original"];
    const highlightedStyle =
      this.isDropdownMenuVisible && containerFocused[variant || "original"];
    const listStyle = listVariant[variant || "original"];
    const inputStyle = inputVariant[variant || "original"]
    return (
      <View style={[containerStyle, highlightedStyle]}>
        <StyledTouchableOpacity
          onPress={this.toggleDropdownMenu}
          onBlur={this.handleBlur}
        >
          <View style={[styles.inputField, inputStyle]}>
            <StyledText colorMode={!!value ? "dark" : "gray"}>
              {value ? this.formatText(value) : placeholder}
            </StyledText>
            <Icon name="chevron-down" size={14} />
          </View>
          {
            // remove type assertion
            this.isDropdownMenuVisible && (
              <ScrollView style={[styles.dropdownListItemsContainer, listStyle]}>
                {list?.map((listItem, index) => {
                  return (
                    <DropdownMenuItem
                      key={index}
                      name={this.getName(listItem)}
                      onPress={this.handleMenuItemPress}
                      included={value?.includes(this.getName(listItem))}
                      type={this.props.type}
                    />
                  );
                })}
              </ScrollView>
            )
          }
        </StyledTouchableOpacity>
      </View>
    );
  }
}

interface DropdownMenuItemProps {
  name: string;
  onPress: (value: string) => void;
  type: "single" | "multi";
  included: boolean;
}
const DropdownMenuItem: React.FunctionComponent<DropdownMenuItemProps> = (
  props
) => {
  const { name, onPress } = props;
  const ref = React.useRef(null);
  const isHover = useHover(ref);
  const hoverStyle = isHover && styles.hover;

  return (
    <View ref={ref}>
      <StyledTouchableOpacity
        style={[styles.dropDownListItem, hoverStyle]}
        onPress={() => onPress(name)}
      >
        {props.type === "multi" && (
          <CheckBox checked={props.included} value={""} readonly />
        )}
        <StyledText style={{lineHeight: 26, textAlignVertical: "center"}}>{name}</StyledText>
      </StyledTouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  inputField: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dropdownListItemsContainer: {
    maxHeight: 200, // TODO: number is arbitrary - EY
    width: "100%",
    position: "absolute",
    top: "100%",
    overflow: "hidden",
    borderWidth: 1,
    borderRadius: 8,
    backgroundColor: Palette.Primary5Pct,
    borderColor: Palette.Primary10Pct,
  },
  dropDownListItem: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    flexDirection: "row",
  },
  hover: {
    backgroundColor: Palette.White,
  },
});

const containerVariants = StyleSheet.create({
  original: {
    flex: 1,
    paddingBottom: 12,
    borderBottomWidth: 1,
    borderBottomColor: Palette.Primary100Pct,
    marginBottom: 2,
  },
  pill: {
    border: "1px solid " + Palette.Primary100Pct,
    borderRadius: 25,
  },
});

const containerFocused = StyleSheet.create({
  original: {
    borderBottomWidth: 3,
    borderBottomColor: Palette.Secondary100Pct,
    marginBottom: 0,
  },
  pill: {},
});

const inputVariant = StyleSheet.create({
  original: {},
  pill: {
    paddingHorizontal: 12,
    paddingVertical: 2,
  },
});

const listVariant = StyleSheet.create({
  original: {
    marginTop: 20,
  },
  pill: {
    marginTop: 1,
  },
});

