import { FunctionComponent } from "react";
import {
  Image,
  View,
  Modal,
  TouchableWithoutFeedback,
  StyleSheet,
  StyleProp,
  ViewStyle,
} from "react-native";
import { StyledButton, StyledText, Icon } from "./controls";
import modalTop from "../assets/modal-top.svg";
import { noop } from "../utils/Hooks";

interface ModalProps {
  visible: boolean;
  title: string;
  text?: string;
  children?: React.ReactNode;
  closeButtonText?: string;
  confirmButtonText?: string;
  onClose: () => void;
  onConfirm?: () => void;
  modalStyle?: StyleProp<ViewStyle>;
  hideClose?: boolean;
  disableClickOutside?: boolean;
}

export const StyledModal: FunctionComponent<ModalProps> = ({
  visible,
  title,
  text,
  children,
  onClose,
  onConfirm,
  confirmButtonText = "Confirm",
  closeButtonText = "Close",
  modalStyle,
  hideClose,
  disableClickOutside,
}) => {
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      onRequestClose={onClose}
    >
      <View style={styles.centeredView}>
        {/* Background */}
        <TouchableWithoutFeedback onPress={disableClickOutside ? noop : onClose}>
          <View style={styles.background} />
        </TouchableWithoutFeedback>
        <Image style={styles.logo} source={{ uri: modalTop }} />
        {/* Modal */}
        <View style={[styles.modalView, modalStyle]}>
          <View style={styles.iconContainer}>
            <Icon
              name="alert-circle"
              size={24}
            />
          </View>

          <View style={[styles.section, { width: "100%" }]}>
            <StyledText style={styles.title} variant="heading2">
             {title}
            </StyledText>
            <StyledText style={styles.sectionText} variant="body" isMedium>
              {text}
            </StyledText>
          </View>

          {children}

          <View style={styles.row}>
            {onConfirm && (
              <StyledButton
                onPress={onConfirm}
                text={confirmButtonText}
                style={styles.inviteButton}
              />
            )}
            {!hideClose && (
              <StyledButton
                onPress={onClose}
                text={closeButtonText}
                style={styles.inviteButton}
                variant={onConfirm ? "secondary" : "primary"}
              />
            )}
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  section: {
    marginBottom: 24,
  },title:{
    width: "100%",
    textAlign: "center",
    marginBottom: 12,
  },
  sectionText: {
    lineHeight: 24,
  },
  logo: {
    width: 180,
    height: 90,
    top: 76,
    resizeMode: "contain",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  background: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    width: "30vh",
    maxWidth: 500,
    borderRadius: 8,
    padding: 35,
    alignItems: "center",
    elevation: 5,
    minWidth: 400,
  },
  iconContainer: {
    padding: 20,
    borderRadius: 30,
    position: "absolute",
    top: -35,
  },
  inviteButton: {
    marginTop: 30,
    width: 150,
    minWidth: 150,
    marginHorizontal: 10
  },
  selectedInviteContainer: {
    width: "100%",
    marginTop: 20,
  },
  cancelIcon: {
    position: "absolute",
    right: 0,
    marginTop: 5,
  },
  selectedInviteStatusContainer: {
    marginTop: 5,
    flexDirection: "row",
  },
  selectedInviteStatus: {
    marginRight: 15,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
});
