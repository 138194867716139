import { Inject } from "@not-the-droids/exco-ts-inject";
import { computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { BudgetModel, Project } from "../../../data-model";

import { StyledText } from "./controls";
import { ProjectDetailsFlow } from "../flows/ProjectDetailsFlow";

import { withInjectedFactory } from "../InjectorContext";
import { LoadingIndicator } from "./LoadingIndicator";
import { UserViewModel } from "../viewModels/UserViewModel";
import { Palette } from "./styles";
import { PaymentPhaseTabHeader } from "./Payments/PaymentPhaseTabHeader";
import { PaymentNextPhasePreview } from "./Payments/PaymentNextPhasePreview";
import { formatCurrencyToString } from "../utils/Numbers";
import { PaymentPhasesPreview } from "./Payments/PaymentPhasesPreview";
import { PaymentTransactionPreview } from "./Payments/PaymentTransactionPreview";
import {
  PaymentIntentPreview,
  TransactionDetail,
} from "../../../data-model/Payment";
import { PaymentModal } from "./PaymentModal";
import { InjectedYoutubeTooltip } from "./YoutubeTooltip/YoutubeTooltip";

interface Props {
  budgetModel: BudgetModel;
  projectDetailsFlow: ProjectDetailsFlow;
  userViewModel: UserViewModel;
}

interface CreateProps {
  project: Project;
}

export class ProjectPaymentsDashboardViewFactory {
  static inject: Inject<ProjectPaymentsDashboardViewFactory> = (injector) => {
    return () =>
      new ProjectPaymentsDashboardViewFactory({
        budgetModel: injector.get(BudgetModel)(),
        projectDetailsFlow: injector.get(ProjectDetailsFlow)(),
        userViewModel: injector.get(UserViewModel)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ProjectPaymentsDashboardView {...this.props} {...props} />;
  }
}

@observer
class ProjectPaymentsDashboardView extends React.Component<
  CreateProps & Props
> {
  @observable private _initialized: boolean = false;
  @observable private paymentIntentModal: PaymentIntentPreview | undefined;

  @computed get remainingBalance() {
    return this.props.projectDetailsFlow.paymentData?.remainingBalance || 0;
  }

  @computed get fundsInEscrow() {
    return this.props.projectDetailsFlow.paymentData?.balance || 0;
  }
  
  constructor(props: Props & CreateProps) {
    super(props);
    makeObservable(this);
  }

  readonly componentDidMount = async () => {
    const { project, projectDetailsFlow } = this.props;
    try {
      await projectDetailsFlow.getPaymentData(project.id);
      this._initialized = true;
    } catch (error) {
      console.log(error);
    }
  };

  private handleFundPhaseClick = async (id: string) => {
    const { projectDetailsFlow } = this.props;
    projectDetailsFlow.selectedPaymentPhases = [id];
    projectDetailsFlow.paymentView = "make-payment";
  };

  private handleRecentTransactionClick = async (
    transation: TransactionDetail
  ) => {
    const { projectDetailsFlow } = this.props;

    this.paymentIntentModal = {
      paymentInfo: projectDetailsFlow.paymentData!.paymentInfo!,
      total: transation.amount,
      paymentIntentId: transation.id,
    };
  };

  render() {
    const { projectDetailsFlow, userViewModel } = this.props;
    if (!this._initialized) return <LoadingIndicator />;
    return (
      <>
        <ScrollView contentContainerStyle={styles.container}>
          {/* Top */}
          <StyledText variant="body">
            {"This is your payment management center. Remember, we fund \""}
            <StyledText variant="body" isBold>
              Milestones
            </StyledText>
            {"\" through funding a group of milestones in a \""}
            <StyledText variant="body" isBold>
              Phase
            </StyledText>
            {"\" and release payments to contractors in  \""} 
            <StyledText variant="body" isBold>
              Milestones
            </StyledText>
            {"\"."}
          </StyledText>

          <InjectedYoutubeTooltip
            videoId={
              userViewModel.isContractor
                ? "erkn_dGkuec"
                : "Aud6N2g3J8k"
              }
            text={
              userViewModel.isContractor 
                ? "Payments Tutorial Video"
                : "Funding Projects Tutorial Video"
            }
          />

          {/* Bottom */}
          <View style={styles.bottomContainer}>
            {/* Left Side */}
            <View style={styles.leftSideContainer}>
              <PaymentNextPhasePreview
                phase={projectDetailsFlow.paymentData!.nextPayment}
                showFundPhaseButton={this.props.userViewModel.isOwner}
                onFundPhaseClick={this.handleFundPhaseClick}
              />
              <PaymentPhasesPreview
                phases={projectDetailsFlow.paymentData!.phasesPreview}
              />
            </View>
            {/* Right side */}
            <View style={styles.rightSideContainer}>
              {/* Top */}
              <View style={styles.rightSideTopContainer}>
                <View style={styles.balanceContainer}>
                  <PaymentPhaseTabHeader
                    title={"Remaining Project Balance"}
                    onInfoClick={() => {}}
                  />
                  <StyledText style={styles.balanceText} isBold>
                    {formatCurrencyToString(this.remainingBalance)}
                  </StyledText>
                </View>
                <View style={[styles.balanceContainer, styles.escrowContainer]}>
                  <PaymentPhaseTabHeader
                    title={"Funds in Escrow"}
                    onInfoClick={() => {}}
                  />
                  <StyledText style={styles.balanceText} isBold>
                    {formatCurrencyToString(this.fundsInEscrow)}
                  </StyledText>
                </View>
              </View>
              {/* Bottom */}
              <PaymentTransactionPreview
                transactions={projectDetailsFlow.paymentData!.transactionsHistory}
                onItemInfoPress={this.handleRecentTransactionClick}
              />
            </View>
          </View>
        </ScrollView>
        {this.paymentIntentModal && (
          <PaymentModal
            onHideModal={() => (this.paymentIntentModal = undefined)}
            total={this.paymentIntentModal.total}
            paymentInfo={this.paymentIntentModal.paymentInfo}
            paymentIntentId={this.paymentIntentModal.paymentIntentId}
          />
        )}
      </>
    );
  }
}

const CONTAINER_MARGIN = 16;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: "flex",
    gap: CONTAINER_MARGIN,
  },
  bottomContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
  },
  leftSideContainer: {
    flex: 1,
    marginRight: CONTAINER_MARGIN,
  },
  rightSideContainer: {
    flex: 2,
  },
  rightSideTopContainer: {
    display: "flex",
    flexDirection: "row",
  },
  balanceContainer: {
    flex: 1,
    borderRadius: 8,
    borderWidth: 1,
    marginBottom: CONTAINER_MARGIN,
    marginRight: CONTAINER_MARGIN,
    borderColor: Palette.Secondary25Pct,
    backgroundColor: Palette.Secondary10Pct,
    height: 132,
    padding: 24,
  },
  escrowContainer: {
    marginRight: 0,
    borderColor: Palette.Accent25Pct,
    backgroundColor: Palette.Accent10Pct,
  },
  balanceText: {
    fontSize: 28,
    lineHeight: 36,
    marginTop: 18,
    position: "absolute",
    bottom: 15,
  },
});

export const InjectedProjectPaymentsDashboardView = withInjectedFactory(
  ProjectPaymentsDashboardViewFactory
);
