import { Inject } from "@not-the-droids/exco-ts-inject";
import { computed, makeObservable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { StyleSheet } from "react-native";
import { Redirect } from "react-router";
import { InjectedSideBar, Page, Screen, StyledText } from "../components/controls";
import { VerifyEmailViewFactory } from "../components/VerifyEmailView";
import { InjectedFactoryComponent } from "../InjectorContext";
import { getRoute } from "../routes";
import { UserViewModel } from "../viewModels/UserViewModel";

interface Props {
  userViewModel: UserViewModel;
}

@observer
export class VerifyEmailScreen extends React.Component<Props> {
  static inject: Inject<React.ReactElement> = (injector) => {
    return () => (
      <VerifyEmailScreen userViewModel={injector.get(UserViewModel)()} />
    );
  };

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  @computed get currentUser() {
    return this.props.userViewModel.currentUser;
  }

  @computed get isOwner(): boolean {
    return this.currentUser?.userType === 'owner' && this.currentUser.owner !== undefined;
  }

  @computed get isContractor(): boolean {
    return this.currentUser?.userType === 'contractor' && this.currentUser.contractor !== undefined;
  }

  render() {
    if (this.currentUser?.isVerified && (this.isOwner || this.isContractor)) {
      return (
        <Redirect to={getRoute("dashboard").path} />
      );
    }

    return (
      <Screen>
        <InjectedSideBar size="large" footer="copyright">
          <InjectedFactoryComponent
            factory={VerifyEmailViewFactory}
            props={{}}
          />
        </InjectedSideBar>
        <Page variant="splash" withBorder={true}>
          <StyledText variant="heading" style={styles.heading}>
            Financial, Transactional, Accountability. For All.
          </StyledText>
          <StyledText variant="body">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt.
          </StyledText>
        </Page>
      </Screen>
    );
  }
}

const styles = StyleSheet.create({
  heading: {
    // Placeholder: remove when done with placeholder text - EY
    marginBottom: 16,
  },
});
