import { FunctionComponent } from "react";
import { StyleSheet, View } from "react-native";
import { TransactionDetail } from "../../../../data-model/Payment";
import { formatCurrencyToString } from "../../utils/Numbers";
import { formatDateLongMonth, formatTagString } from "../../utils/Strings";
import { Icon, StyledText } from "../controls";
import { Tag } from "../controls/Tag";
import { Palette } from "../styles";
import { PaymentPhaseTabHeader } from "./PaymentPhaseTabHeader";

interface Props {
  transactions: TransactionDetail[];
  onItemInfoPress: (transaction: TransactionDetail) => void;
}

export const PaymentTransactionPreview: FunctionComponent<Props> = ({
  transactions,
  onItemInfoPress,
}) => {
  return (
    <View style={styles.container}>
      <PaymentPhaseTabHeader
        title={"Recent Transactions"}
        // onViewAllClick={() => {}}
        style={styles.header}
      />
      {transactions.length === 0 && (
        <View style={styles.noTranscationsContainer}>
          <StyledText variant="body" style={styles.noTransactions} isMedium>
            No transactions have been made yet.
          </StyledText>
        </View>
      )}
      {transactions.map((transaction, index) => {
        const isOutbound =
          transaction.type === "refund" || transaction.type === "release";
        const transactionAmount = isOutbound
          ? `- ${formatCurrencyToString(transaction.amount)}`
          : formatCurrencyToString(transaction.amount);
        return (
          <View
            key={index}
            style={[
              styles.transactionContainer,
              {
                backgroundColor:
                  index % 2 === 0 ? Palette.Primary10Pct : Palette.Primary5Pct,
              },
            ]}
          >
            <View style={styles.row}>
              <StyledText isBold={true} style={styles.transactionName}>
                {transaction.name}
              </StyledText>
              {transaction.type === "funding" &&
                transaction.status !== "settled" && (
                  <Icon
                    name="info"
                    size={20}
                    type={"accent"}
                    style={{ marginLeft: 8 }}
                    onClick={() => onItemInfoPress(transaction)}
                  />
                )}
            </View>

            <View style={styles.row}>
              <StyledText variant="body" style={styles.date}>
                {formatDateLongMonth(transaction.date)}
              </StyledText>
            </View>
            <View style={styles.rightSide}>
              <StyledText variant="body" isBold style={styles.rightText}>
                {transactionAmount}
              </StyledText>
              <Tag
                text={formatTagString(transaction.status)}
                type="dark"
                style={styles.tag}
                textStyle={styles.tagText}
              />
            </View>
          </View>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    borderRadius: 8,
    borderWidth: 1,
    backgroundColor: Palette.Primary5Pct,
    borderColor: Palette.Primary10Pct,
  },
  header: {
    margin: 16,
  },
  transactionContainer: {
    width: "100%",
    paddingVertical: 10,
    paddingHorizontal: 24,
    height: 60,
  },
  transactionName: {
    marginBottom: 4,
  },
  rightSide: {
    alignSelf: "flex-end",
    position: "absolute",
  },
  noTranscationsContainer: {
    backgroundColor: Palette.Primary10Pct,
    width: "100%",
    height: 150,
    justifyContent: "center",
    alignItems: "center",
  },
  noTransactions: {
    fontSize: 16,
  },
  rightText: {
    textAlign: "right",
  },
  row: {
    flexDirection: "row",
  },
  date: {
    marginTop: 2,
  },
  tag: {
    paddingHorizontal: 0,
    height: 22,
    marginTop: 2,
    alignSelf: "flex-end",
  },
  tagText: {
    fontSize: 13,
  },
});
