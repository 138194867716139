import { Inject, singletonInject } from "@not-the-droids/exco-ts-inject";
import { cloneDeep } from "lodash"
import { makeObservable, observable } from "mobx";

export class AutoSaveManager {
  static inject: Inject<AutoSaveManager> = singletonInject(() => {
    return () => new AutoSaveManager();
  });

  constructor() {
    makeObservable(this);
  };

  @observable private prevState: Object = {};
  @observable public isSaving: boolean = false;
  @observable public timeSaved: Date = new Date();

  private readonly initialize = (retrieveState: () => Object) => {
     this.isSaving = false;
     this.timeSaved = new Date();
     this.prevState = cloneDeep(retrieveState())
  };

  private readonly isEqual = (stateA: Object, stateB: Object) => {
    return JSON.stringify(stateA) === JSON.stringify(stateB);
  }

  private readonly autoSave = async (
    retrieveState: () => Object,
    onSave: Function,
  ) => {
    const state = retrieveState();
    if (!this.isEqual(this.prevState, state)) {
      this.isSaving = true;
      this.prevState = cloneDeep(state);
      try {
        await onSave();
        this.timeSaved = new Date();
      } catch (e) {
        console.error(e);
      } finally {
        this.isSaving = false;
      }
    }
  };

  public readonly setAutoSave = (
    retrieveState: () => Object,
    onSave: Function,
    ms: number,
  ): Function => {
    this.initialize(retrieveState);

    const timeoutId = setInterval(async () => {
      await this.autoSave(retrieveState, onSave);
    }, ms);

    return () => clearInterval(timeoutId);
  };

  public readonly setTimeSaved = (timeSaved: Date) => {
    this.timeSaved = timeSaved;
  }
}
