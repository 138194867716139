import { Inject } from "@not-the-droids/exco-ts-inject";
import { Contract } from "./Budget";
import { ContractValidation } from "./Contract";

export abstract class ContractModel {
  static inject?: Inject<ContractModel> = undefined;
  public abstract getBudgetContract(budgetId: string): Promise<Contract>;
  public abstract confirmBudgetContract(
    budgetId: string
  ): Promise<ContractValidation>;

  // // CHANGE REQUEST

  public abstract getChangeRequestContract(
    changeRequestId: string,
    generateNew?: boolean
  ): Promise<Contract | undefined>;
  public abstract confirmChangeRequestContract(
    changeRequestId: string
  ): Promise<ContractValidation>;
}
