import React from "react";
import { StyleSheet, View } from "react-native";
import { Inject } from "@not-the-droids/exco-ts-inject";
import {
  BudgetMilestoneTask,
  BudgetModel,
  ChangeRequest,
} from "../../../data-model";
import { withInjectedFactory } from "../InjectorContext";
import { formatCurrencyToString } from "../utils/Numbers";
import { UserViewModel } from "../viewModels/UserViewModel";
import { Icon, StyledText } from "./controls";
import { Tag } from "./controls/Tag";
import { Palette } from "./styles";
import {
  MilestoneManagementProps,
  MyContext,
} from "./ProjectMilestonesViewControl";
import { ProjectDetailsFlow } from "../flows/ProjectDetailsFlow";
import { CommentBubble } from "./CommentBubble";
import { ProjectWidgetManager } from "../managers/ProjectWidgetManager";

interface EditTask extends Omit<BudgetMilestoneTask, "budget"> {
  budget: string;
  orderIndex: number;
}

interface Props {
  budgetModel: BudgetModel;
  projectDetailsFlow: ProjectDetailsFlow;
  projectWidgetManager: ProjectWidgetManager;
  userViewModel: UserViewModel;
}

interface CreateProps {
  changeRequest?: ChangeRequest;
  commentsEnabled?: boolean;
  selectedCommentIndex?: number;
}

class ChangeRequestViewFactory {
  static inject: Inject<ChangeRequestViewFactory> = (injector) => {
    return () =>
      new ChangeRequestViewFactory({
        budgetModel: injector.get(BudgetModel)(),
        projectDetailsFlow: injector.get(ProjectDetailsFlow)(),
        projectWidgetManager: injector.get(ProjectWidgetManager)(),
        userViewModel: injector.get(UserViewModel)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return (
      <MyContext.Consumer>
        {(consumer) => (
          <ChangeRequestView {...this.props} {...props} {...consumer} />
        )}
      </MyContext.Consumer>
    );
  }
}

const ChangeRequestView: React.FunctionComponent<
  CreateProps & Props & MilestoneManagementProps
> = (props) => {
  const { changeRequest, userViewModel, selectedCommentIndex } = props;

  const changeItem = (
    itemIndex: number,
    isOld: boolean,
    text: string,
    itemDescription?: string,
    showComment?: boolean,
    budget?: number,
    itemId?: string
  ) => {
    const itemStyle = isOld && modalStyles.warningTask;
    const color = isOld ? "warning" : "affirm";
    const tagText = isOld ? "Old" : "New";

    const handleCommentBubbleClick = () => {
      const {
        projectDetailsFlow: { budget, selectedMilestone },
        projectWidgetManager: { activeChangeRequestCommentIndex },
      } = props;
      if (activeChangeRequestCommentIndex === itemIndex) {
        props.projectWidgetManager.activeChangeRequestCommentIndex = -2;
        props.projectWidgetManager.activeChangeRequestId = undefined;
        props.projectWidgetManager.setActiveCommentInfo(
          budget?.id,
          selectedMilestone?.name
        );
        props.projectWidgetManager.selectedChangeRequestDescription = "";
        props.projectWidgetManager.activeCommentType = "budget";
      } else {
        props.projectWidgetManager.selectedChangeRequestMenuItem = "Comments";
        props.projectWidgetManager.activeChangeRequestCommentIndex = itemIndex;
        props.projectWidgetManager.activeChangeRequestId = changeRequest?.id;
        props.projectWidgetManager.setActiveCommentInfo(
          changeRequest?.id,
          itemDescription
        );
        props.projectWidgetManager.selectedChangeRequestDescription =
          itemDescription!;
        props.projectWidgetManager.activeCommentType = "change-request";
      }
    };

    return (
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <View style={{ width: 60 }}>
          <Tag type={color} text={tagText}></Tag>
        </View>
        <View
          style={{
            flexDirection: "row",
            flex: 1,
            justifyContent: "space-between",
          }}
        >
          <StyledText
            variant={"body2"}
            colorMode={color}
            style={[itemStyle, { justifyContent: "center" }]}
          >
            {text}
          </StyledText>
          {budget && (
            <StyledText
              variant={"body2"}
              colorMode={color}
              style={[
                itemStyle,
                { width: 80, justifyContent: "center", textAlign: "right" },
              ]}
            >
              {formatCurrencyToString(String(budget))}
            </StyledText>
          )}
        </View>
        {!!showComment && props.commentsEnabled && (
          <CommentBubble
            // isAlert={this.props.projectWidgetManager.determineTaggedCommentsUnread(this.props.userViewModel.currentUser!.id, item.category, item.id)}
            isAlert={false}
            isFilled={selectedCommentIndex === itemIndex}
            onPress={handleCommentBubbleClick}
            style={{ marginLeft: -18, left: 34 }}
          />
        )}
      </View>
    );
  };

  const changeRequestMessage = () => {
    return userViewModel.isContractor ? (
      <View style={{ flexDirection: "row" }}>
        <StyledText variant="body2">
          {"The following changes have been sent to "}
          <StyledText variant="body2" isBold>
            {props.ownerName.split(" ", 1)}
          </StyledText>
          {" for review and approval."}
        </StyledText>
      </View>
    ) : (
      <View style={{ flexDirection: "row" }}>
        <StyledText variant="body2">
          {"Your contractor, "}
          <StyledText variant="body2" isBold>
            {props.contractorName.split(" ", 1)}
          </StyledText>
          {" has requested the following changes: "}
        </StyledText>
      </View>
    );
  };

  const completeRequestMessage = () => {
    return userViewModel.isContractor ? (
      <View style={{ flexDirection: "row" }}>
        <StyledText variant="body2">
          {"The milestone has been sent to "}
          <StyledText variant="body2" isBold>
            {props.ownerName.split(" ", 1)}
          </StyledText>
          {" for "}
          <StyledText variant="body2" colorMode="affirm" isBold>
            {"COMPLETION"}
          </StyledText>
          {"."}
        </StyledText>
      </View>
    ) : (
      <View style={{ flexDirection: "row" }}>
        <StyledText variant="body2">
          {"Your contractor, "}
          <StyledText variant="body2" isBold>
            {props.contractorName.split(" ", 1)}
          </StyledText>
          {" has requested the "}
          <StyledText variant="body2" colorMode="affirm" isBold>
            {"COMPLETION"}
          </StyledText>
          {" of this milestone: "}
        </StyledText>
      </View>
    );
  };

  const finalRequestMessage = () => {
    return userViewModel.isContractor ? (
      <View style={{ flexDirection: "row" }}>
        <StyledText variant="body2">
          {"This "}
          <StyledText variant="body2" isBold>
            {"Project"}
          </StyledText>
          {" has been sent to "}
          <StyledText variant="body2" isBold>
            {props.ownerName.split(" ", 1)}
          </StyledText>
          {" for "}
          <StyledText variant="body2" colorMode="affirm" isBold>
            {"COMPLETION"}
          </StyledText>
          {"."}
        </StyledText>
      </View>
    ) : (
      <View style={{ flexDirection: "row" }}>
        <StyledText variant="body2">
          {"Your contractor, "}
          <StyledText variant="body2" isBold>
            {props.contractorName.split(" ", 1)}
          </StyledText>
          {" has requested the "}
          <StyledText variant="body2" colorMode="affirm" isBold>
            {"COMPLETION"}
          </StyledText>
          {" of this "}
          <StyledText variant="body2" isBold>
            {"Project"}
          </StyledText>
        </StyledText>
      </View>
    );
  };

  const computeMessageIndex = (
    outerIndex: number,
    innerIndex: number,
    outerIndexLength: number
  ) => {
    return (outerIndex + 1) * outerIndexLength + innerIndex + 1;
  };

  const renderBodyChange = () => {
    const originalMilestone = changeRequest?.milestones.find(
      (milestone) => milestone.changeRequestMilestoneType === "original"
    );
    const newMilestone = changeRequest?.milestones.find(
      (milestone) => milestone.changeRequestMilestoneType === "new"
    )!;

    const oldTasksMap = new Map<string, EditTask>();
    originalMilestone?.tasks.forEach((task) =>
      oldTasksMap.set(task.taskId!, {
        ...task,
        orderIndex: task.orderIndex || 0,
        budget: String(task.budget),
      })
    );

    const additions = newMilestone.tasks.filter(
      (task) => task.changeRequestTaskType === "addition"
    );
    const changes = newMilestone.tasks.filter(
      (task) => task.changeRequestTaskType === "change"
    );
    const deletions = newMilestone.tasks.filter(
      (task) => task.changeRequestTaskType === "deletion"
    );
    const changeRequestTaskTypes = [additions, deletions, changes];

    const addedBudget = additions.reduce(
      (prevVal, currVal) => prevVal + Number(currVal.budget),
      0
    );
    const changedBudget = changes.reduce((prevVal, currVal) => {
      const editTask = oldTasksMap.get(currVal.taskId!);
      return (
        prevVal +
        (editTask
          ? currVal.budget - Number(editTask.budget)
          : Number(currVal.budget))
      );
    }, 0);
    const subtractedBudget = deletions.reduce(
      (prevVal, currVal) => prevVal - Number(currVal.budget),
      0
    );
    const currentBudgetChange = addedBudget + changedBudget + subtractedBudget;
    const plus = currentBudgetChange >= 0 ? "+" : "";
    const bodyStyle = props.commentsEnabled
      ? modalStyles.bodyChange
      : modalStyles.bodyAlt;

    return (
      <View style={bodyStyle}>
        {changeRequestMessage()}
        {((!originalMilestone && newMilestone.scope !== "") ||
          (!!originalMilestone &&
            originalMilestone?.scope !== newMilestone.scope)) && (
          <View style={[modalStyles.bodyItem, { marginTop: 24 }]}>
            <StyledText variant="body2" isBold>
              {"SCOPE OF WORK"}
            </StyledText>
            <View style={modalStyles.changeContainer}>
              {originalMilestone &&
                originalMilestone.scope !== "" &&
                changeItem(-1, true, originalMilestone?.scope!)}
              {changeItem(
                -1,
                false,
                newMilestone.scope,
                newMilestone?.scope,
                true
              )}
            </View>
          </View>
        )}

        {newMilestone.tasks.length > 0 && (
          <View style={[modalStyles.bodyItem, { marginTop: 32 }]}>
            <StyledText variant="body2" isBold>
              {"TASKS"}
            </StyledText>
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <StyledText variant="body2">{"Description"}</StyledText>
              <StyledText variant="body2">{"Budget"}</StyledText>
            </View>
            {changeRequestTaskTypes.map((changeRequestTasks, typeIndex) => {
              return changeRequestTasks.map((taskChange, index) => {
                const oldTask = taskChange.taskId
                  ? oldTasksMap.get(taskChange.taskId)
                  : undefined;
                const messageIndex = computeMessageIndex(
                  typeIndex,
                  index,
                  changeRequestTaskTypes.length
                );
                if (
                  taskChange.changeRequestTaskType !== "deletion" &&
                  oldTask?.budget === String(taskChange.budget) &&
                  oldTask.description === taskChange.description
                )
                  return null;
                const notFirsSttyle = (typeIndex !== 0 || index !== 0) && {
                  marginTop: 8,
                };
                const bottomBorderStyle = [
                  modalStyles.bottomBorder,
                  { paddingBottom: 16 },
                ];
                switch (taskChange.changeRequestTaskType) {
                  case "addition":
                    const lastOfAddStyle =
                      index === changeRequestTasks.length - 1 &&
                      (changes.length > 0 || deletions.length > 0) &&
                      bottomBorderStyle;
                    return (
                      <View
                        key={`task-change-addition-${typeIndex}-${index}`}
                        style={[
                          modalStyles.changeContainer,
                          notFirsSttyle,
                          lastOfAddStyle,
                        ]}
                      >
                        {changeItem(
                          messageIndex,
                          false,
                          taskChange.description,
                          taskChange.description,
                          true,
                          taskChange.budget,
                          taskChange.taskId
                        )}
                      </View>
                    );
                  case "deletion":
                    const lastStyle =
                      index === changeRequestTasks.length - 1 &&
                      changes.length > 0 &&
                      bottomBorderStyle;
                    return (
                      <View
                        key={`task-change-deletion-${typeIndex}-${index}`}
                        style={[
                          modalStyles.changeContainer,
                          notFirsSttyle,
                          lastStyle,
                        ]}
                      >
                        {changeItem(
                          messageIndex,
                          true,
                          taskChange.description,
                          taskChange.description,
                          true,
                          taskChange.budget,
                          taskChange.taskId
                        )}
                      </View>
                    );
                  case "change":
                    const oldTask = originalMilestone!.tasks.find(
                      (task) => task.taskId === taskChange.taskId
                    )!;
                    const notLastStyle =
                      index < changeRequestTasks.length - 1 &&
                      bottomBorderStyle;
                    return (
                      <View
                        key={`task-change-change-${typeIndex}-${index}`}
                        style={[
                          modalStyles.changeContainer,
                          notFirsSttyle,
                          notLastStyle,
                        ]}
                      >
                        {changeItem(
                          messageIndex,
                          true,
                          oldTask.description,
                          taskChange.description,
                          false,
                          oldTask.budget
                        )}
                        {changeItem(
                          messageIndex,
                          false,
                          taskChange.description,
                          oldTask.description,
                          true,
                          taskChange.budget,
                          taskChange.taskId
                        )}
                      </View>
                    );
                  default:
                    return null;
                }
              });
            })}
          </View>
        )}

        <StyledText variant="body2" style={{ marginTop: 34 }} isBold>
          {"BUDGET CHANGE"}
        </StyledText>
        <StyledText
          variant="heading3"
          style={{ marginTop: 8 }}
          isBold
        >{`${plus}${formatCurrencyToString(currentBudgetChange)}`}</StyledText>
      </View>
    );
  };

  const renderBodyComplete = () => {
    const originalMilestone = props.projectDetailsFlow.budget?.milestones.find(
      (milestone) => milestone.id === changeRequest?.milestoneId
    );
    return (
      <View style={modalStyles.bodyAlt}>
        {completeRequestMessage()}
        <View style={[modalStyles.bodyItem, { marginTop: 24 }]}>
          <StyledText variant="body2" isBold>
            {"SCOPE OF WORK"}
          </StyledText>
          <View style={modalStyles.changeContainer}>
            <StyledText variant={"body2"} style={{ justifyContent: "center" }}>
              {originalMilestone?.scopeOfWork!}
            </StyledText>
          </View>
        </View>

        <View style={[modalStyles.bodyItem, { marginTop: 32 }]}>
          <StyledText variant="body2" isBold>
            {"TASKS"}
          </StyledText>
          {originalMilestone?.tasks.map((task) => {
            return (
              <View
                key={`completed-tasks-${task.orderIndex}`}
                style={{
                  flexDirection: "row",
                  flex: 1,
                  justifyContent: "space-between",
                }}
              >
                <StyledText
                  variant={"body2"}
                  style={{ justifyContent: "center" }}
                >
                  {task.description}
                </StyledText>
              </View>
            );
          })}
        </View>
      </View>
    );
  };

  const renderBodyFinish = () => {
    return <View style={modalStyles.bodyAlt}>{finalRequestMessage()}</View>;
  };

  if (!changeRequest) {
    return (
      <View style={[modalStyles.bodyAlt, modalStyles.bottomBorder]}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon name="x-circle" type="warning" size={32} />
          <StyledText
            variant="heading3"
            isBold
            style={{ marginLeft: 10, alignItems: "center" }}
          >
            {"No Change Was Made"}
          </StyledText>
        </View>
      </View>
    );
  }

  switch (changeRequest.changeRequestType) {
    case "change":
      return renderBodyChange();
    case "complete":
      return renderBodyComplete();
    case "final":
      return renderBodyFinish();
    default:
      return null;
  }
};

const modalStyles = StyleSheet.create({
  scroll: {
    flex: 1,
    overflow: "hidden",
  },
  headerRight: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  bodyAlt: {
    flexDirection: "column",
    paddingVertical: 24,
    paddingHorizontal: 32,
  },
  bodyChange: {
    flexDirection: "column",
    paddingVertical: 24,
    paddingLeft: 32,
    paddingRight: 66,
  },
  bodyItem: {
    flexDirection: "column",
    gap: 8,
    zIndex: 0,
  },
  changeContainer: {
    flexDirection: "column",
    gap: 16,
  },
  warningTask: {
    textDecorationLine: "line-through",
  },
  bottomBorder: {
    borderBottomWidth: 1,
    borderBottomColor: Palette.Primary10Pct,
  },
});

export const InjectedChangeRequestView = withInjectedFactory(
  ChangeRequestViewFactory
);
