import { Inject, singletonInject } from "@not-the-droids/exco-ts-inject";
import { Owner } from "../Owner";
import { RestClient } from "./RestClient";
import { CreateOwnerParams, OwnerModel } from "../OwnerModel";

export class RestOwnerModel extends OwnerModel {
  static inject: Inject<RestOwnerModel> = singletonInject((injector) => {
    return () => new RestOwnerModel(injector.get(RestClient)());
  });

  constructor(private readonly rest: RestClient) {
    super();
  }

  public getOwners(): Promise<Owner[]> {
    return this.rest.get("/owner");
  }

  public createOwner(params: CreateOwnerParams): Promise<Owner> {
    return this.rest.post("/owner", params);
  }
}
