import { Inject } from "@not-the-droids/exco-ts-inject";
import { computed, makeObservable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { View, StyleSheet } from "react-native";
import { Project } from "../../../data-model";

import {
  Icon,
  StyledButton,
  StyledText,
  StyledTouchableOpacity,
} from "./controls";
import { ProjectDetailsFlow } from "../flows/ProjectDetailsFlow";

import { withInjectedFactory } from "../InjectorContext";
import { Palette } from "./styles";

import { FlatList } from "react-native-gesture-handler";
import { formatDateLongMonth } from "../utils/Strings";
import { formatCurrencyToString } from "../utils/Numbers";

interface Props {
  projectDetailsFlow: ProjectDetailsFlow;
}

interface CreateProps {
  project: Project;
}

export class ProjectProjectPaymentsConfirmationView {
  static inject: Inject<ProjectProjectPaymentsConfirmationView> = (
    injector
  ) => {
    return () =>
      new ProjectProjectPaymentsConfirmationView({
        projectDetailsFlow: injector.get(ProjectDetailsFlow)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ProjectPaymentsConfirmationView {...this.props} {...props} />;
  }
}

@observer
class ProjectPaymentsConfirmationView extends React.Component<
  CreateProps & Props
> {
  @computed get paymentConfirmation() {
    return this.props.projectDetailsFlow.paymentConfirmation;
  }

  constructor(props: Props & CreateProps) {
    super(props);
    makeObservable(this);
  }

  private handleBackClick = () => {
    const { projectDetailsFlow } = this.props;
    projectDetailsFlow.paymentView = "dashboard";
  };

  private handlePrintClick = () => {
    window.print();
  };

  render() {
    const { project } = this.props;
    const confirmationData = [
      { rowName: "Confirmation #:", value: this.paymentConfirmation?.id },
      {
        rowName: "Payment Amount:",
        value: formatCurrencyToString(this.paymentConfirmation?.total),
      },
      {
        rowName: "Payment Date:",
        value: formatDateLongMonth(this.paymentConfirmation?.date),
      },
    ];
    return (
      <View style={styles.container}>
        <View style={styles.checkCircle}>
          <Icon name="check" type="white" size={28} />
        </View>
        <StyledText variant="heading2" style={styles.title}>
          Thank you!
        </StyledText>
        <StyledText variant="heading" style={styles.subTitle}>
          We are awaiting your payment.
        </StyledText>
        <StyledText style={styles.description}>
          {`Your `}
          <StyledText isMedium>{project.name}</StyledText>
          {` project account balance and available funds will update once the payment is received.`}
        </StyledText>

        <StyledTouchableOpacity
          style={styles.printContainer}
          onPress={this.handlePrintClick}
        >
          <Icon name="printer" type="accent" size={20} />
          <StyledText variant="heading3" isMedium style={styles.printText}>
            Print
          </StyledText>
        </StyledTouchableOpacity>
        <FlatList
          style={styles.list}
          data={confirmationData}
          renderItem={({ item }) => (
            <View style={styles.listItem}>
              <StyledText
                variant="body"
                isBold
                style={{ alignSelf: "flex-start" }}
              >
                {item.rowName}
              </StyledText>
              <StyledText variant="body" style={styles.listValue}>
                {item.value}
              </StyledText>
            </View>
          )}
          ItemSeparatorComponent={() => (
            <View style={styles.listItemSeparator} />
          )}
        />
        <StyledButton
          text="Return to Payments"
          style={styles.returnButton}
          onPress={this.handleBackClick}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: 420,
    alignItems: "center",
    alignSelf: "center",
  },
  checkCircle: {
    backgroundColor: Palette.Affirm100Pct,
    width: 50,
    height: 50,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 60,
    marginBottom: 20,
  },
  title: {
    fontSize: 36,
    marginBottom: 5,
  },
  subTitle: {
    fontSize: 24,
    marginBottom: 30,
  },
  description: {
    marginBottom: 20,
  },
  printContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 10,
    width: "100%",
  },
  printText: {
    marginLeft: 3,
    color: Palette.Accent,
  },
  list: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Palette.Primary10Pct,
    backgroundColor: Palette.Primary5Pct,
    width: "100%",
    marginBottom: 40,
  },
  listItem: {
    height: 60,
    alignItems: "center",
    padding: 20,
  },
  listValue: {
    position: "absolute",
    right: 20,
  },
  listItemSeparator: {
    height: 1,
    backgroundColor: Palette.Primary10Pct,
    width: "100%",
  },
  returnButton: {
    width: 220,
  },
});

export const InjectedProjectPaymentsConfirmationView = withInjectedFactory(
  ProjectProjectPaymentsConfirmationView
);
