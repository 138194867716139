import { Inject } from "@not-the-droids/exco-ts-inject";
import { Owner } from "./Owner";

export interface CreateOwnerParams extends Partial<Owner> {}

export abstract class OwnerModel {
  static inject?: Inject<OwnerModel> = undefined;
  public abstract getOwners(): Promise<Owner[]>;
  public abstract createOwner(params: CreateOwnerParams): Promise<Owner>;
}
