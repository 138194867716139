import React from "react";
import { StyleSheet, View } from "react-native";
import { Margins } from "./Margins";
import { Background } from "./Background";
import { PageVariant } from "../../constants/Variants";

interface Props {
  variant: PageVariant;
  withBorder?: boolean;
}

export const Page: React.FunctionComponent<Props> = (props) => {
  const { variant, withBorder } = props;
  const borderStyles = withBorder && styles.border;
  return (
    <>
      <Background variant="dark">
        <View style={[styles.page, borderStyles]}>
          <Background variant="light" style={styles.background}>
            <Margins variant={variant}>{props.children}</Margins>
          </Background>
        </View>
      </Background>
    </>
  );
};

const styles = StyleSheet.create({
  page: {
    flex: 1,
    overflow: "visible",
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
  },
  background: {
    minHeight: "100vh",
    paddingBottom: 50,
  },
  border: {
    marginRight: 4,
    marginVertical: 4,
  },
});
