import { StyleSheet, View } from "react-native";
import React, { ChangeEvent } from "react";
import { StyledText } from "./controls";

export interface CheckBoxProps {
  checked: boolean;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  readonly?: boolean;
}

export default class CheckBox extends React.Component<CheckBoxProps> {
  render() {
    const value = this.props.value ? <span>{this.props.value}</span> : null;
    const checkboxStyle = {
      width: 20,
      height: 20,
      borderRadius: 4,
    };
    return (
      <View style={styles.container}>
        {/* TODO: convert input component to StyledTouchable for styling access & include styledText as touchable interface - EY */}
        <input
          readOnly={this.props.readonly}
          type="checkbox"
          checked={this.props.checked}
          onChange={this.props.onChange}
          id="category"
          name="category"
          value="Appliances"
          style={checkboxStyle}
          disabled={this.props.disabled}
        />
        {value && (
          <StyledText isBold={true} style={styles.text}>
            {value}
          </StyledText>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    marginLeft: 8,
  },
});
