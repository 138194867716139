import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { Inject } from "@not-the-droids/exco-ts-inject";
import React from "react";
import { StyleSheet, View } from "react-native";
import {
  ContractorModel,
  Project,
  ProjectModel,
  UserModel,
  WorkCategoryAreas,
} from "../../../../data-model";
import { Icon, StyledButton, StyledText, StyledTextInput } from "../controls";
import { History, HistoryInjectable } from "../../HistoryInjectable";
import { ProjectCrudFlow } from "../../flows/ProjectCrudFlow";
import { capitalizeFirstLetter, structureAddress } from "../../utils/Strings";
import { Palette } from "../styles";
import { InviteModal } from "../InviteModal";
import { withInjectedFactory } from "../../InjectorContext";
import { ImageDropzone } from "../controls/ImageDropzone";

interface Props {
  contractorModel: ContractorModel;
  history: History;
  projectCrudFlow: ProjectCrudFlow;
  projectModel: ProjectModel;
  userModel: UserModel;
}

interface CreateProps {}

class ProjectFlowConfirmProjectViewFactory {
  static inject: Inject<ProjectFlowConfirmProjectViewFactory> = (injector) => {
    return () =>
      new ProjectFlowConfirmProjectViewFactory({
        contractorModel: injector.get(ContractorModel)(),
        history: injector.get(HistoryInjectable)(),
        projectCrudFlow: injector.get(ProjectCrudFlow)(),
        projectModel: injector.get(ProjectModel)(),
        userModel: injector.get(UserModel)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ProjectFlowConfirmProjectView {...this.props} {...props} />;
  }
}

@observer
export class ProjectFlowConfirmProjectView extends React.Component<
  CreateProps & Props
> {
  @observable private currentProject: Project | undefined;
  @observable private editName: boolean = false;
  @observable private currentName: string = this.props.projectCrudFlow.name;
  @observable private currentUser: any = {};

  constructor(props: CreateProps & Props) {
    super(props);
    makeObservable(this);
  }

  async componentDidMount() {
    const { projectModel, userModel } = this.props;
    const project = await projectModel.getNotCompletedCreation();
    this.currentProject = project;
    this.props.projectCrudFlow.name = project?.name ?? "";
    this.currentName = project?.name ?? "";
    this.currentUser = await userModel.getUser();
  }

  handleUpdateProjectName = async (name: any) => {
    if (!this.currentProject) return;
    try {
      await this.props.projectModel.updateProjectName({
        id: this.currentProject.id,
        name,
      });
    } catch (e) {
      console.log(e);
    }
  };

  onNameChangeHandler = (input: string) => {
    const cleanInput = input.replace(/[^a-z0-9"  *"]/gi, "");
    this.currentName = cleanInput;
  };

  handleSubmit = async () => {
    if (!this.currentProject) return;
    if (this.props.projectCrudFlow.name !== this.currentName) {
      await this.handleUpdateProjectName(this.currentName);
    }
    await this.props.projectModel.completeCreation(this.currentProject.id);

    this.props.history.push(`/project/${this.currentProject.id}/details`);
  };

  handleCreateEstimate = async () => {
    if (!this.currentProject) return;
    if (this.props.projectCrudFlow.name !== this.currentName) {
      await this.handleUpdateProjectName(this.currentName);
    }
    await this.props.projectModel.completeCreation(this.currentProject.id);

    this.props.history.push(`/project/${this.currentProject.id}/bid`);
  };

  render() {
    const { contractorModel, projectCrudFlow, projectModel } = this.props;
    const continueButton =
      this.currentUser.userType === "contractor" ? (
        <StyledButton
          onPress={this.handleCreateEstimate}
          disabled={this.editName}
          text="Create estimate"
        />
      ) : (
        <InviteModal
          contractorModel={contractorModel}
          // onSubmitInvite={handleSendInvitation}
          projectId={projectCrudFlow.projectId!}
          projectModel={projectModel}
        />
      );

    return (
      <View style={styles.container}>
        <StyledText variant="heading" isBold={true} style={styles.sectionTitle}>
          Review
        </StyledText>
        <StyledText style={styles.bodyText}>
          Please take a moment to confirm the details of your project.
        </StyledText>
        <View style={styles.iconHeadingContainer}>
          <StyledText variant="heading2" isBold={true} style={styles.header2}>
            Project Details
          </StyledText>
          <Icon
            name="edit"
            type="accent"
            size={24}
            style={styles.selectedIconStyle}
            onClick={() => this.props.projectCrudFlow.goToStep(1)}
          />
        </View>
        <StyledText
          variant="body2"
          isBold={true}
          style={styles.label}
          isUpperCase={true}
        >
          Project Name
        </StyledText>
        <StyledTextInput
          variant="tertiary"
          maxLength={100}
          placeholder="Project Name"
          placeholderTextColor={"black"}
          value={this.currentName}
          style={styles.textInput}
          onChangeText={(text) => {
            this.onNameChangeHandler(text);
          }}
        />
        {this.currentProject?.media && this.currentProject?.media?.length > 0 && (
          <ImageDropzone 
            editable={false}
            file={{
              fileUrl: this.currentProject?.media[0].url,
              fileName: "",
              fileType: "", 
              createdAt: new Date(),
              fileId: "",
            }}
            onDrop={async () => {}}
          />
        )}

        <StyledText
          variant="body2"
          isBold={true}
          style={styles.label}
          isUpperCase={true}
        >
          Project Address
        </StyledText>
        <StyledText style={styles.bodyText}>
          {/*TODO: remove type assertion */}
          {this.currentProject?.address &&
            structureAddress(this.currentProject.address)}
        </StyledText>
        <StyledText
          variant="body2"
          isBold={true}
          style={styles.label}
          isUpperCase={true}
        >
          Location Type
        </StyledText>
        <StyledText style={styles.bodyText}>
          {this.currentProject?.locationType &&
            capitalizeFirstLetter(this.currentProject?.locationType)}
        </StyledText>
        <StyledText
          variant="body2"
          isBold={true}
          style={styles.label}
          isUpperCase={true}
        >
          Work Type
        </StyledText>
        <StyledText style={styles.bodyText}>
          {this.currentProject?.workType &&
            capitalizeFirstLetter(this.currentProject?.workType)}
        </StyledText>
        <StyledText
          variant="body2"
          isBold={true}
          style={styles.label}
          isUpperCase={true}
        >
          Work Location
        </StyledText>
        <StyledText style={styles.bodyText}>
          {this.currentProject?.workLocation &&
            capitalizeFirstLetter(this.currentProject?.workLocation)}
        </StyledText>
        <StyledText
          variant="body2"
          isBold={true}
          style={styles.label}
          isUpperCase={true}
        >
          Structure Description
        </StyledText>
        <StyledText style={styles.bodyText}>
          {this.currentProject?.description}
        </StyledText>

        <View style={styles.iconHeadingContainer}>
          <StyledText variant="heading2" isBold={true} style={styles.header2}>
            Work Categories
          </StyledText>
          <Icon
            name="edit"
            type="accent"
            size={24}
            style={styles.selectedIconStyle}
            onClick={() => this.props.projectCrudFlow.goToStep(2)}
          />
        </View>
        <StyledText style={styles.bodyText}>
          <View style={styles.workCategoriesContainer}>
            {this.currentProject?.workCategories?.map(
              (item: WorkCategoryAreas, index: number) => {
                return (
                  <StyledText key={index} style={styles.workCategoryText}>
                    {item.workCategory}
                  </StyledText>
                );
              }
            )}
          </View>
        </StyledText>
        <View style={styles.buttonsContainer}>
          <View style={styles.buttonsInnerContainer}>
            <StyledButton
              onPress={this.handleSubmit}
              disabled={this.editName}
              style={styles.saveButton}
              text="Save & Exit"
            />
            {continueButton}
          </View>
          <StyledButton
            text="Previous"
            variant="textOnly"
            iconLeft={{ name: "chevron-left", type: "warning" }}
            onPress={() => this.props.projectCrudFlow.decrementCurrentStep()}
            style={styles.previousButtonContainer}
            textStyle={styles.previousButton}
          />
        </View>
      </View>
    );
  }
}

export const InjectedProjectFlowConfirmProjectViewFactory = withInjectedFactory(
  ProjectFlowConfirmProjectViewFactory
);

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 648,
  },
  workCategoriesContainer: {
    display: "flex",
    flexDirection: "column",
  },
  image: {
    height: 432,
    width: "100%",
    borderRadius: 8,
    marginBottom: 24,
    // resizeMode: "contain",
    // aspectRatio: 1,
    // height:200,
    // minWidth:100,
    // marginBottom:8,
    // alignSelf:"flex-start"
  },
  sectionTitle: {
    marginBottom: 8,
  },
  header: {
    fontSize: 25,
  },
  header2: {
    marginTop: 12,
    marginBottom: 24,
  },
  projectDetailsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  buttonArrow: {
    color: "white",
  },
  buttonsContainer: {
    display: "flex",
    width: "80%",
    marginTop: 24,
    alignItems: "flex-start",
  },
  buttonsInnerContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  bodyText: {
    marginBottom: 24,
  },
  workCategoryText: {
    marginBottom: 8,
  },
  disabledButton: {
    backgroundColor: "#a9aaa9",
  },
  iconHeadingContainer: {
    flexDirection: "row",
  },
  textInput: {
    marginBottom: 24,
    width: "60%",
  },
  label: {
    fontSize: 12,
    marginBottom: 8,
  },
  previousButtonContainer: {
    marginTop: 32,
  },
  previousButton: {
    fontSize: 13,
    color: Palette.Primary100Pct,
  },
  saveButton: {
    marginRight: 20,
    maxHeight: 48,
    marginVertical: "auto",
  },
  selectedIconStyle: {
    marginLeft: 8,
    top: 15,
  },
});
