import { Inject } from "@not-the-droids/exco-ts-inject";
import { computed, makeObservable, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";
import { contractorSpecialties } from "../constants/Profile";
import { Icon, StyledButton, StyledText, StyledTextInput } from "./controls";
import { DropdownMenu } from "./DropdownMenu";
import { History, HistoryInjectable } from "../HistoryInjectable";
import { getRoute } from "../routes";
import { Palette } from "./styles";
import { UserModel } from "../../../data-model";
import { Notification } from "../NotificationInjectable";
import Dropzone from "react-dropzone";
import { uploadFromBlobAsync } from "../utils/Storage";
import { UserThumbnailPhoto } from "./UserThumbnailPhoto";
import { UserViewModel } from "../viewModels/UserViewModel";
import { CurrentUser } from "../../../data-model/CurrentUser";
import { AddressParts, GooglePlacesInput } from "./GooglePlacesInput";
import { structureAddress } from "../utils/Strings";

interface Props {
  history: History;
  userModel: UserModel;
  userViewModel: UserViewModel;
  notification: Notification;
}

interface CreateProps {}

export class ProfileViewFactory {
  static inject: Inject<ProfileViewFactory> = (injector) => {
    return () =>
      new ProfileViewFactory({
        history: injector.get(HistoryInjectable)(),
        userModel: injector.get(UserModel)(),
        userViewModel: injector.get(UserViewModel)(),
        notification: injector.get(Notification)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ProfileView {...this.props} {...props} />;
  }
}

@observer
export class ProfileView extends React.Component<Props> {
  //TODO: remove type assertion
  @observable private userType: string = "";
  @observable private name: string = "";
  @observable private email: string = "";
  @observable private phone: string = "";
  @observable private businessName: string = "";
  @observable private businessWebsite: string = "";
  @observable private address: AddressParts = {} as any;
  @observable private selectedContractorSpecialties: string[] = [];
  @observable private taxId: string = "";
  @observable private updateImage: boolean = false;
  @observable private isFileUploading: boolean = false;
  @observable private error: string = "";
  @observable private _submitting = false;

  @computed get currentUser(): CurrentUser {
    return this.props.userViewModel.currentUser!;
  }

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  readonly componentDidMount = async () => {
    this.name = this.currentUser.name;
    this.email = this.currentUser.email;
    this.phone = this.currentUser.owner?.phone ?? "";
    this.userType =
      this.currentUser.userType ?? !!this.currentUser.contractor
        ? "contractor"
        : "owner";
    if (this.currentUser.contractor) {
      this.businessName = this.currentUser.contractor.businessName;
      this.businessWebsite = this.currentUser.contractor.businessUrl;
      this.selectedContractorSpecialties =
        this.currentUser.contractor.specialties;
      this.address = this.currentUser.contractor.address;
    } else {
      this.address = this.currentUser.owner!.address;
    }
  };

  readonly handlePasswordChangePress = () => {
    const { history } = this.props;
    history.push(getRoute("changePassword").path);
  };

  readonly onSavePress = () => {
    const { notification } = this.props;
    if (this.userType === "owner" && this.phone.length !== 10) {
      notification.setNotification("error", "Incorrect phone number length.");
    } else {
      this.updateUser();
    }
  };

  onNameChangeHandler = (input: string) => {
    const cleanInput = input.replace(/[^a-z0-9"  *"]/gi, "");
    this.businessName = cleanInput;
  };

  onWebsiteChangeHandler = (input: string) => {
    var cleanInput = input.replace(/[^a-z0-9:/.]/gi, "");
    this.businessWebsite = cleanInput;
  };

  updateUser = async () => {
    const { notification } = this.props;
    if (
      this.userType === "contractor" &&
      (!this.address.state || this.address.state === "")
    ) {
      return notification.setNotification(
        "error",
        "Please select your address."
      );
    }
    this._submitting = true;
    const updateObject = {
      userId: this.currentUser.id,
      name: this.name,
      email: this.email,
      phone: this.phone,
      businessName: this.businessName,
      businessUrl: this.businessWebsite,
      specialties: this.selectedContractorSpecialties,
      userType: this.userType,
      address: this.address,
    };
    try {
      await this.props.userModel.updateUser(updateObject);
      await this.props.userViewModel.refreshCurrentUser();
    } catch (e) {
      if (e) {
        notification.setNotification("error", "Error");
      }
    } finally {
      notification.setNotification("success", "Information Updated");
    }
    this._submitting = false;
  };

  updatePhone = (update: string) => {
    this.phone = update.replace(/[^0-9]/, "");
  };

  returnPersonalDetails = () => (
    <View style={styles.section}>
      <StyledText variant="heading3" isBold={true} style={styles.sectionHeader}>
        Personal Details
      </StyledText>
      <View style={styles.sectionSmall}>
        <StyledText variant="body2" isBold={true} style={styles.label}>
          Name
        </StyledText>
        <StyledTextInput
          variant="tertiary"
          placeholder="Enter name"
          value={this.name}
          onChangeText={(value) => (this.name = value)}
        />
      </View>
      {/*          <View style={styles.sectionSmall}>
            <StyledText variant="body2" isBold={true} style={styles.label}>
              Email
            </StyledText>
            <StyledTextInput
              variant="tertiary"
              placeholder="Enter email"
              value={this.email}
              onChangeText={(value) => (this.email = value)}
            />
          </View>*/}
      <View style={styles.sectionSmall}>
        <StyledText variant="body2" isBold={true} style={styles.label}>
          Phone
        </StyledText>
        <StyledTextInput
          variant="tertiary"
          placeholder="Enter phone"
          value={this.phone}
          maxLength={10}
          onChangeText={(text) => this.updatePhone(text)}
        />
      </View>
      {/* <StyledButton
            text="Change Password"
            variant="link"
            onPress={this.handlePasswordChangePress}
          />*/}
    </View>
  );

  returnBusinessDetails = () => (
    <View style={[styles.section, { zIndex: 1 }]}>
      <StyledText variant="heading3" isBold={true} style={styles.sectionHeader}>
        Business Details
      </StyledText>

      <View style={[styles.verificationContainer, styles.sectionSmall]}>
        <StyledText variant="body2" isBold={true}>
          Verification Status
        </StyledText>
        <View style={styles.verificationStatus}>
          <Icon
            name="clock"
            type="secondary"
            size={14}
            style={styles.verificationIcon}
          />
          <StyledText colorMode="secondary" isBold={true}>
            Pending
          </StyledText>
        </View>
      </View>

      <View style={styles.sectionSmall}>
        <StyledText variant="body2" isBold={true} style={styles.label}>
          Business Name
        </StyledText>
        <StyledTextInput
          maxLength={40}
          variant="tertiary"
          placeholder="Enter business name"
          value={this.businessName}
          onChangeText={(text) => {
            this.onNameChangeHandler(text);
          }}
        />
      </View>
      <View style={styles.sectionSmall}>
        <StyledText variant="body2" isBold={true} style={styles.label}>
          Business Website
        </StyledText>
        <StyledTextInput
          maxLength={100}
          variant="tertiary"
          placeholder="Enter business website"
          value={this.businessWebsite}
          onChangeText={(text) => {
            this.onWebsiteChangeHandler(text);
          }}
        />
      </View>
      <View style={[styles.sectionSmall, { zIndex: 2 }]}>
        <StyledText variant="body2" isBold={true} style={styles.label}>
          Business Address
        </StyledText>
        <GooglePlacesInput handleChange={(a) => (this.address = a)} defaultValue={structureAddress(this.address)} />
      </View>
      <View style={[styles.sectionSmall, { zIndex: 1 }]}>
        <StyledText variant="body2" isBold={true} style={styles.label}>
          Contractor Specialty
        </StyledText>
        <DropdownMenu
          list={contractorSpecialties}
          type="multi"
          onChange={(value) => (this.selectedContractorSpecialties = value)}
          placeholder="Select contractor specialty"
          value={this.selectedContractorSpecialties}
        />
      </View>
      {/* <View style={styles.sectionSmall}>
        <StyledText variant="body2" isBold={true} style={styles.label}>
          Tax ID
        </StyledText>
        <StyledTextInput
          variant="tertiary"
          placeholder="Enter your Tax ID"
          value={this.taxId}
          onChangeText={(value) => (this.taxId = value)}
        />
      </View>*/}
    </View>
  );

  returnDocuments = () => (
    <View style={[styles.section, styles.documentLinks]}>
      <StyledText variant="heading3" isBold={true} style={styles.sectionHeader}>
        Documents
      </StyledText>
      <StyledButton
        text="Contractor Documentation"
        variant="link"
        iconRight={{ name: "external-link", type: "accent" }}
        style={styles.documentLink}
      />
      <StyledButton
        text="Insurance "
        variant="link"
        iconRight={{ name: "external-link", type: "accent" }}
        style={styles.documentLink}
      />
      <StyledButton
        text="Worker's Comp"
        variant="link"
        iconRight={{ name: "external-link", type: "accent" }}
        style={styles.documentLink}
      />
      <StyledButton
        text="Contractor License Bond"
        variant="link"
        iconRight={{ name: "external-link", type: "accent" }}
        style={styles.documentLink}
      />
      <StyledButton
        text="City Business License"
        variant="link"
        iconRight={{ name: "external-link", type: "accent" }}
        style={styles.documentLink}
      />
    </View>
  );

  onDrop = async (acceptedFiles: any) => {
    const { userModel } = this.props;
    const file = acceptedFiles?.[0];
    if (!file) {
      return;
    }
    this.isFileUploading = true;

    this.error = "";
    let url: string;
    try {
      url = await uploadFromBlobAsync({
        blobUrl: URL.createObjectURL(file),
        name: `/bid-files/${file.name}_${Date.now()}`,
      });
    } catch (e) {
      runInAction(() => {
        this.isFileUploading = false;
      });
      this.error = e as string;
      return;
    }

    runInAction(() => {
      userModel
        .updateImage({ userId: this.currentUser.id, url })
        .then(async () => {
          this.props.userViewModel.refreshCurrentUser();
          // this.disclosureFiles.push(newFile);
          this.isFileUploading = false;
          this.updateImage = false;
        });
    });
  };
  renderDropZone = () => {
    return (
      <View style={styles.dropzone}>
        <Dropzone onDrop={(acceptedFiles) => this.onDrop(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <input {...getInputProps()} />
              <View style={styles.dropIC}>
                <View style={styles.iconContainer}>
                  <Icon style={styles.uploadIcon} name={"upload"} size={24} />
                </View>
                <StyledText style={styles.dropzoneText}>
                  Drop files here or select to upload
                </StyledText>
              </View>
            </div>
          )}
        </Dropzone>
      </View>
    );
  };

  renderDropZoneOC() {
    return (
      <View>
        {!this.isFileUploading && this.renderDropZone()}

        {this.isFileUploading && (
          <ActivityIndicator
            animating={true}
            color={Palette.Secondary100Pct}
            style={styles.indicator}
          />
        )}
      </View>
    );
  }

  render() {
    return (
      <View style={styles.container}>
        {/*TODO: Implement after image upload*/}
        <View style={[styles.header, styles.sectionSmall]}>
          <UserThumbnailPhoto
            name={this.currentUser.name}
            photoURL={this.currentUser.photoUrl}
          />
          <StyledButton
            style={styles.userImageUpdate}
            text="Update"
            variant="secondary"
            onPress={() => (this.updateImage = !this.updateImage)}
          />
        </View>
        {this.updateImage && this.renderDropZoneOC()}
        {!!this.currentUser?.owner && this.returnPersonalDetails()}
        {!!this.currentUser?.contractor && this.returnBusinessDetails()}
        {!!this.currentUser?.contractor && this.returnDocuments()}
        <StyledButton
          text="Save Changes"
          alignSelf={true}
          onPress={this.onSavePress}
          loading={this._submitting}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: 312,
    marginLeft: 82,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
  },
  userImageUpdate: {
    marginLeft: 24,
  },
  section: {
    marginBottom: 48,
  },
  sectionSmall: {
    marginBottom: 32,
  },
  sectionHeader: {
    marginBottom: 16,
  },
  label: {
    textTransform: "uppercase",
    marginBottom: 10,
  },
  verificationContainer: {
    borderWidth: 1,
    borderRadius: 8,
    borderColor: Palette.Primary10Pct,
    backgroundColor: Palette.Primary5Pct,
    padding: 16,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  verificationStatus: {
    flexDirection: "row",
    alignItems: "center",
  },
  verificationIcon: {
    marginRight: 6,
  },
  documentLinks: {
    alignItems: "flex-start",
  },
  documentLink: {
    marginBottom: 24,
  },
  dropIC: {
    flexDirection: "column",
    margin: 40,
  },
  uploadIcon: {
    alignSelf: "center",
    top: 13,
  },
  dropzone: {
    display: "flex",
    borderColor: "#a9aaa9",
    borderStyle: "dashed",
    borderRadius: 3,
    borderWidth: 2,
    fontSize: 18,
    minHeight: 100,
    height: "auto",
    padding: 10,
    marginBottom: 20,
  },
  dropzoneOC: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  dropzoneText: {},
  indicator: {
    margin: 50,
  },
  fileContainer: {
    margin: 10,
    flexDirection: "row",
  },
  iconContainer: {
    backgroundColor: Palette.Primary10Pct,
    width: 50,
    height: 50,
    borderRadius: 25,
    marginLeft: "41%",
    marginBottom: 15,
  },
});
