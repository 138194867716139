import React from "react";
import { Modal, StyleProp, StyleSheet, TouchableOpacity, ViewStyle } from "react-native";

interface Props {
  onClick: () => void;
  isOpen: boolean;
  isVerticallyCentered?: boolean;
  isHorizontallyCentered?: boolean;
  style?: StyleProp<ViewStyle>;
  activeOpacity?: number;
}

export class RenderModal extends React.Component<Props> {
  render() {
    return (
      <Modal
        animationType="fade"
        transparent={true}
        visible={this.props.isOpen}
      >
        <TouchableOpacity
          style={[
            style.modalContainer,
            this.props.isHorizontallyCentered && style.horizontallyCenter,
            this.props.isVerticallyCentered && style.verticallyCenter,
          ]}
          activeOpacity={this.props.activeOpacity}
          onPress={this.props.onClick}
        >
          <TouchableOpacity style={[style.modalView, this.props.style]} activeOpacity={1}>
            {this.props.children}
          </TouchableOpacity>
        </TouchableOpacity>
      </Modal>
    );
  }
}

const style = StyleSheet.create({
  modalContainer: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    backgroundColor: "rgba(0,0,0, 0.32)",
  },
  modalView: {
    display: "flex",
    position: "relative",
    backgroundColor: "white",
    padding: 35,
    shadowColor: "#000",
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "50%",
    borderRadius: 10,
  },
  verticallyCenter: {
    justifyContent: "center",
  },
  horizontallyCenter: {
    alignItems: "center",
  },
});
