import React, { ReactElement } from "react";
import {
  Keyboard,
  NativeSyntheticEvent,
  StyleProp,
  StyleSheet,
  TextInput,
  TextInputFocusEventData,
  TextInputKeyPressEventData,
  TextInputProps,
  TextStyle,
  View,
  ViewStyle,
} from "react-native";
import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { Icon, IconSize } from "./Icon";
import { StyledText } from "./StyledText";
import { IconName, IconType, Palette } from "../styles";

type Variant = "custom" | "primary" | "secondary" | "tertiary";

export interface TextInputIcon {
  name: IconName;
  type?: IconType;
  size?: IconSize;
  onClick?: () => void;
}

export interface StyledTextInputProps extends TextInputProps {
  blurOnReturn?: boolean;
  errorMessage?: string;
  maxLength?: number;
  iconLeft?: TextInputIcon;
  iconRight?: TextInputIcon;
  multiline?: boolean;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  topElement?: ReactElement;
  variant?: Variant;
  onSubmitEditing?: () => void;
}

type Variants = { [V in Variant]: StyleProp<TextStyle> };

@observer
export class StyledTextInput extends React.Component<StyledTextInputProps> {
  @observable private inputFieldTouched: boolean = false;
  @observable private isFocused: boolean = false;

  private enterKeyPressed: boolean = false;
  private textInputRef = React.createRef<TextInput>();

  constructor(props: StyledTextInputProps) {
    super(props);
    makeObservable(this);
  }

  readonly onBlur = (
    event: NativeSyntheticEvent<TextInputFocusEventData>
  ): void => {
    const { value, onBlur } = this.props;
    this.inputFieldTouched = !!value;
    this.isFocused = false;

    if (onBlur) {
      onBlur(event);
    }
  };

  readonly onFocus = (
    event: NativeSyntheticEvent<TextInputFocusEventData>
  ): void => {
    this.isFocused = true;
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  };

  readonly onKeyPress = (
    e: NativeSyntheticEvent<TextInputKeyPressEventData>
  ) => {
    const { blurOnReturn } = this.props;
    const key = e.nativeEvent.key;
    if (blurOnReturn && key === "Enter") {
      this.enterKeyPressed = true;
      Keyboard.dismiss();
    }
  };

  readonly onChangeText = (input: string) => {
    const { onChangeText, returnKeyType } = this.props;

    if (returnKeyType === "done" && this.enterKeyPressed) {
      this.enterKeyPressed = false;
      return;
    }

    if (!input) {
      this.inputFieldTouched = false;
    }

    if (onChangeText) {
      onChangeText(input);
    }
  };

  public focus = () => {
    this.textInputRef.current?.focus();
  };

  render() {
    const {
      errorMessage,
      iconLeft,
      iconRight,
      style,
      textStyle,
      containerStyle,
      value,
      variant,
      maxLength,
      topElement,
      ...textInputProps
    } = this.props;
    const variantStyle = variants[variant || "primary"];
    const textInputStyle = textInputStyles[variant || "primary"];
    const variantFocusedStyle =
      this.isFocused && variantsFocused[variant || "primary"];
    const textInputFocusedStyle =
      this.isFocused && textInputStylesFocused[variant || "primary"];
    const errorStyle =
      !!errorMessage && this.inputFieldTouched ? undefined : undefined;
    const multilineStyle = this.props.multiline && styles.multilineTextInput;
    const marginBottom = { marginBottom: this.isFocused ? 0 : 2 };

    return (
      <View style={style}>
        <View
          style={[
            styles.container,
            variantStyle,
            variantFocusedStyle,
            multilineStyle,
            errorStyle,
            marginBottom,
            containerStyle,
          ]}
        >
          {topElement}
          <View style={styles.innerContainer}>
            {iconLeft && (
              <Icon
                name={iconLeft.name}
                type={iconLeft.type ?? "white"}
                size={iconLeft.size || 14}
                style={styles.iconLeft}
                onClick={iconLeft.onClick}
              />
            )}
            <TextInput
              onBlur={this.onBlur}
              onFocus={this.onFocus}
              onKeyPress={this.onKeyPress}
              onChangeText={this.onChangeText}
              maxLength={maxLength && maxLength}
              placeholderTextColor={Palette.Primary50Pct}
              ref={this.textInputRef}
              style={[
                styles.textInput,
                textInputStyle,
                textInputFocusedStyle,
                textStyle,
              ]}
              value={value}
              {...textInputProps}
            />
            {iconRight && (
              <Icon
                name={iconRight.name}
                type={iconRight.type ?? "white"}
                size={iconRight.size || 24}
                style={styles.iconRight}
                onClick={iconRight.onClick}
              />
            )}
          </View>
        </View>
        {/* Double Negate errorMessage to determine truthy - TS */}
        {!!errorMessage && this.inputFieldTouched && (
          <View style={styles.errorContainer}>
            <StyledText
              variant="body"
              colorMode="warning"
              style={styles.errorIcon}
            >
              !
            </StyledText>
            <StyledText
              variant="body"
              colorMode="warning"
              style={styles.errorMessage}
            >
              {errorMessage}
            </StyledText>
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
  },
  innerContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  textInput: {
    fontSize: 15,
    lineHeight: 24,
    width: "100%",
    height: "100%",
    alignItems: "center",
    outlineStyle: "none",
  },
  multilineTextInput: {
    justifyContent: "flex-start"
  },
  errorContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 14,
  },
  errorIcon: {
    width: 20,
    height: 20,
    fontSize: 12,
    marginRight: 10,
    borderRadius: 24,
    display: "flex",
    fontWeight: "900",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Palette.Warning25Pct,
  },
  errorMessage: {
    fontSize: 12,
  },
  iconLeft: {
    marginRight: 14,
  },
  iconRight: {
    marginLeft: 14,
  },
});

const variants: Variants = {
  custom: {},
  primary: {
    height: "100%",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 6,
    paddingHorizontal: 12,
    borderColor: Palette.Primary25Pct,
    backgroundColor: Palette.White,
  },
  secondary: {
    borderBottomWidth: 1,
    paddingVertical: 12,
    borderBottomColor: Palette.Primary5Pct,
  },
  tertiary: {
    borderBottomWidth: 1,
    borderBottomColor: Palette.Primary100Pct,
  },
};

const variantsFocused: Variants = {
  custom: {},
  primary: {},
  secondary: {
    paddingVertical: 12,
    borderBottomWidth: 3,
    borderBottomColor: Palette.Secondary100Pct,
  },
  tertiary: {
    borderBottomWidth: 3,
    borderBottomColor: Palette.Secondary100Pct,
  },
};

const textInputStyles: Variants = {
  custom: {},
  primary: {},
  secondary: {
    color: Palette.Primary5Pct,
  },
  tertiary: {
    paddingBottom: 12,
  },
};

const textInputStylesFocused: Variants = {
  custom: {},
  primary: {},
  secondary: {},
  tertiary: {},
};
