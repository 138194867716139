export type Screen =
  | "root"
  | "changePassword"
  | "dashboard"
  | "editProject"
  | "invitations"
  | "login"
  | "newProject"
  | "payments"
  | "pricingSettings"
  | "profile"
  | "project"
  | "projectBid"
  | "projectBudget"
  | "projectChangeRequests"
  | "projectDetails"
  | "projectDocuments"
  | "projectMilestones"
  | "projectPayments"
  | "projects"
  | "settings"
  | "signUp"
  | "verifyEmail"
  | "verifyContractors"
  | "privacy"
  | "terms";

type ProjectRouteParams = {
  projectId: string;
};

type RouteBuilder<T extends Screen> = {
  [x: string]: any;
  path: string;
} & (T extends
  | "editProject"
  | "milestones"
  | "project"
  | "projectBid"
  | "projectBudget"
  | "projectDetails"
  | "projectDocuments"
  | "projectPayments"
  ? {
      build: (params: ProjectRouteParams) => string;
    }
  : {});

export function getRoute<T extends Screen>(screen: T): RouteBuilder<T> {
  const path = simpleRouteMap[screen];
  let build: (params: ProjectRouteParams) => string = () => path;

  switch (screen) {
    case "editProject":
      build = (params) =>
        `/projects/edit/${encodeURIComponent(params.projectId)}`;
      break;
    case "project":
      build = (params) => `/project/${encodeURIComponent(params.projectId)}`;
      break;
    case "projectBid":
      build = (params) =>
        `/project/${encodeURIComponent(params.projectId)}/bid`;
      break;
    case "projectBudget":
      build = (params) =>
        `/project/${encodeURIComponent(params.projectId)}/budget`;
      break;
    case "projectChangeRequests":
      build = (params) =>
        `/project/${encodeURIComponent(params.projectId)}/change-requests`;
      break;
    case "projectDetails":
      build = (params) =>
        `/project/${encodeURIComponent(params.projectId)}/details`;
      break;
    case "projectDocuments":
      build = (params) =>
        `/project/${encodeURIComponent(params.projectId)}/documents`;
      break;
    case "projectMilestones":
      build = (params) =>
        `/project/${encodeURIComponent(params.projectId)}/milestones`;
      break;
    case "projectPayments":
      build = (params) =>
        `/project/${encodeURIComponent(params.projectId)}/payments`;
      break;
  }

  return {
    path,
    build,
  };
}

const simpleRouteMap: Record<Screen, string> = {
  root: "/",
  changePassword: "/profile/changePassword",
  dashboard: "/dashboard",
  editProject: "/projects/edit/:id",
  invitations: "/invitations",
  login: "/login",
  newProject: "/projects/new",
  payments: "/payments",
  pricingSettings: "/profile/pricingSettings",
  profile: "/profile",
  project: "/project/:id",
  projectBid: "/project/:id/bid",
  projectBudget: "/project/:id/budget",
  projectChangeRequests: "/project/:id/change-requests",
  projectDetails: "/project/:id/details",
  projectDocuments: "/project/:id/documents",
  projectMilestones: "/project/:id/milestones",
  projectPayments: "/project/:id/payments",
  projects: "/projects",
  settings: "/settings",
  signUp: "/signup",
  verifyEmail: "/verify-email",
  verifyContractors: "/verify-contractors",
  privacy: "/privacy",
  terms: "/terms",
};
