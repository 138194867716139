import { Inject, singletonInject } from "@not-the-droids/exco-ts-inject";
import { computed, makeObservable, observable, when } from "mobx";
import { UserModel, UserType } from "../../../data-model";
import { CurrentUser } from "../../../data-model/CurrentUser";
import { Authenticator } from "../exco-lib/exco-auth";

export interface SignUpParams {
  email: string;
  password: string;
  name?: string;
  userType: UserType;
}

export class UserViewModel {
  static inject: Inject<UserViewModel> = singletonInject((injector) => {
    return () => new UserViewModel(
      injector.get(Authenticator)(),
      injector.get(UserModel)(),
    );
  });

  @observable public currentUser: CurrentUser | undefined;

  @observable private _initialized: boolean = false;
  @observable private _initializing: boolean = false;

  @computed get initialized(): boolean {
    return this._initialized;
  }

  @computed get isOwner(): boolean {
    return !!this.currentUser?.owner;
  }
  @computed get isContractor(): boolean {
    return !!this.currentUser?.contractor;
  }

  constructor(
    private readonly authenticator: Authenticator,
    private readonly userModel: UserModel
  ) {
    makeObservable(this);
  }

  public async initialize(): Promise<void> {
    if (this.initialized || this._initializing) {
      return;
    }

    this._initializing = true;
    await this.trySignIn();
    this._initialized = true;
    this._initializing = false;
  }

  public async refreshCurrentUser(forceRefreshToken?: boolean): Promise<void> {
    await this.trySignIn(forceRefreshToken);
  }

  public async signUp(params: SignUpParams): Promise<CurrentUser | undefined> {
    const { email, password, ...rest } = params;
    await this.authenticator.email!.signUp(email, password);
    await this.userModel.createUser(rest);
    return await this.trySignIn();
  }

  public async signIn(email: string, password: string): Promise<CurrentUser | undefined> {
    await this.authenticator.email!.signIn(email, password);
    return await this.trySignIn();
  }

  public async signOut(): Promise<void> {
    await this.authenticator.signOut();
    this.currentUser = undefined;
  }

  private async trySignIn(forceRefreshToken?: boolean): Promise<CurrentUser | undefined> {
    await when(() => this.authenticator.initialized);
    try {
      this.currentUser = await this.userModel.getUser(forceRefreshToken);
    } catch (e) {
      console.error(e);
      this.currentUser = undefined;
    }
    return this.currentUser;
  }
}
