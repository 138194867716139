import { FunctionComponent } from "react";
import { StyleSheet, View } from "react-native";
import { BudgetPhaseWithPayment } from "../../../../data-model";
import { formatPaymentPhaseDateTextAndTag } from "../../utils/Budget";
import { formatCurrencyToString } from "../../utils/Numbers";
import { StyledText } from "../controls";
import { Tag } from "../controls/Tag";
import { Palette } from "../styles";
import { PaymentPhaseTabHeader } from "./PaymentPhaseTabHeader";

interface Props {
  phases: BudgetPhaseWithPayment[];
}

export const PaymentPhasesPreview: FunctionComponent<Props> = ({ phases }) => {
  return (
    <View style={styles.container}>
      <PaymentPhaseTabHeader
        title={"Project Phases"}
        maxTitleWidth={"100%"}
        // onViewAllClick={() => {}}
      />
      {phases.map((phase) => {
        const { dateTag, fundingTag, startsText, startsTextColor } =
          formatPaymentPhaseDateTextAndTag(phase);
        return (
          <View style={styles.phaseContainer} key={phase.id}>
            <View style={styles.upperPhase}>
              <StyledText isBold style={styles.phaseTitle}>
                {phase.name}
              </StyledText>
              <StyledText variant="body">
                {formatCurrencyToString(phase.balanceWithFees)}
              </StyledText>
            </View>
            <StyledText
              variant="body"
              style={[
                styles.startsIn,
                {
                  color: startsTextColor,
                },
              ]}
            >
              {startsText}
            </StyledText>
            <View style={styles.tagContainer}>
              <Tag
                type={fundingTag.type}
                text={fundingTag.text}
                style={styles.tag}
              />
              <Tag type={dateTag.type} text={dateTag.text} />
            </View>
          </View>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Palette.Primary5Pct,
    borderColor: Palette.Primary10Pct,
    borderWidth: 1,
    width: "100%",
    borderRadius: 8,
    padding: 16,
  },
  phaseContainer: {
    marginTop: 16,
    width: "100%",
    height: 108,
    backgroundColor: Palette.White,
    borderRadius: 8,
    padding: 16,
    overflow: "hidden",
  },
  upperPhase: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 10,
  },
  phaseTitle: {
    fontSize: 18,
    marginBottom: 4,
  },
  startsIn: {
    marginBottom: 12,
  },
  tagContainer: {
    flexDirection: "row",
  },
  tag: {
    marginRight: 6,
  },
});
