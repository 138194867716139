import { Inject } from "@not-the-droids/exco-ts-inject";
import { FirebaseFile } from "File";
import {
  Budget,
  ChangeRequest,
  CreateBudgetParams,
  CreateChangeRequestParams,
  DeleteBudgetMilestoneParams,
  DeleteBudgetPhaseParams,
  FileUploadParams,
  MilestoneCompletionType,
  PunchListItem,
  UpdateBudgetMilestoneParams,
  UpdateBudgetParams,
  UpdateBudgetPhasesParams,
} from "./Budget";

export abstract class BudgetModel {
  static inject?: Inject<BudgetModel> = undefined;

  public abstract getBudgetById(id: string): Promise<Budget>;
  public abstract getBudgetByProjectId(id: string): Promise<Budget>;
  public abstract createBudget(params: CreateBudgetParams): Promise<Budget>;
  public abstract updateMilestone(
    params: UpdateBudgetMilestoneParams
  ): Promise<void>;
  public abstract deleteMilestone(
    params: DeleteBudgetMilestoneParams
  ): Promise<void>;
  public abstract updatePhases(params: UpdateBudgetPhasesParams): Promise<void>;
  public abstract updateBudget(params: UpdateBudgetParams): Promise<void>;
  public abstract updateDisclosure(params: any): Promise<void>;
  public abstract completeContract(id: string): Promise<void>;
  public abstract submitBudget(id: string): Promise<void>;
  public abstract addFile(budgetId: string, params: any): Promise<void>;
  public abstract getFilesById(budgetId: string): Promise<FirebaseFile[]>;
  public abstract deleteFile(budgetId: string, id: string): Promise<void>;
  public abstract addMilestoneFile(
    milestoneId: string,
    fileParams: FileUploadParams
  ): Promise<FirebaseFile>;
  public abstract getMilestoneFilesById(milestoneId: string): Promise<FirebaseFile[]>;
  public abstract deleteMilestoneFile(
    milestoneId: string,
    id: string
  ): Promise<void>;
  public abstract deletePhase(params: DeleteBudgetPhaseParams): Promise<void>;
  public abstract createChangeRequest(
    params: CreateChangeRequestParams
  ): Promise<ChangeRequest>;
  public abstract getChangeRequestsByBudgetId(
    budgetId: string
  ): Promise<ChangeRequest[]>;
  public abstract deleteChangeRequest(
    budgetId: string,
    id: string
  ): Promise<void>;
  public abstract startMilestone(milestoneId: string): Promise<void>;
  public abstract updateTaskStatus(
    milestoneId: string,
    taskId: string,
    isCompleted: boolean
  ): Promise<void>;
  public abstract approveMilestoneCompletion(
    budgetId: string,
    milestoneId: string,
    changeRequestId: string
  ): Promise<void>;
  public abstract cancelChangeRequest(
    budgetId: string,
    milestoneId: string,
    changeRequestId: string,
    prevMilestoneStatus: MilestoneCompletionType
  ): Promise<void>;
  public abstract rejectChangeRequest(
    budgetId: string,
    milestoneId: string,
    changeRequestId: string,
    prevMilestoneStatus: MilestoneCompletionType
  ): Promise<void>;
  public abstract createProjectCompletionRequest(
    projectId: string,
    budgetId: string,
  ): Promise<ChangeRequest>;
  public abstract createPunchListItem(
    milestoneId: string,
    description: string
  ): Promise<PunchListItem>;
  public abstract updatePunchListItemStatus(
    milestoneId: string,
    punchListId: string,
    isCompleted: boolean
  ): Promise<void>;
  public abstract deletePunchListItem(
    milestoneId: string,
    punchListId: string
  ): Promise<void>;
}
