import { Inject } from "@not-the-droids/exco-ts-inject";
import { toJS } from "mobx";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { 
  Budget,
  BudgetMilestone,
  BudgetMilestoneTask,
  BudgetModel,
  BudgetPhase,
  ChangeRequest,
  ChangeRequestMilestone,
  ChangeRequestTask,
  ChangeRequestTaskType,
  ChangeRequestType,
  CreateChangeRequestParams,
  MilestoneCompletionType,
  Project
} from "../../../data-model";
import { MilestoneDefinition, milestoneDefinitions, universalTasks } from "../constants/MilestoneDefinitions";
import { withInjectedFactory } from "../InjectorContext";
import { formatCurrencyToString, parsePriceInput, sanitizePriceInput } from "../utils/Numbers";
import { InjectedChangeRequestView } from "./ChangeRequestView";
import { Icon, StyledButton, StyledText, StyledTextArea, StyledTextInput, StyledTouchableOpacity } from "./controls";
import { FuzzySearchModular } from "./FuzzySearchModular";
import { ProjectDetailsFlow } from "../flows/ProjectDetailsFlow";
import { RenderModal } from "./RenderModal";
import { Palette } from "./styles";

interface EditTask extends Omit<BudgetMilestoneTask, "budget"> {
  budget: string;
  orderIndex: number;
}

interface Props {
  budgetModel: BudgetModel;
  projectDetailsFlow: ProjectDetailsFlow;
}

interface CreateProps {
  budget: Budget;
  changeRequestType: ChangeRequestType;
  isOpen: boolean;
  milestone?: BudgetMilestone;
  onCancelPress: () => void;
  phase: BudgetPhase;
  project: Project;
}

class MilestoneManagementModalFactory {
  static inject: Inject<MilestoneManagementModalFactory> = (injector) => {
    return () =>
      new MilestoneManagementModalFactory({
        budgetModel: injector.get(BudgetModel)(),
        projectDetailsFlow: injector.get(ProjectDetailsFlow)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <MilestoneManagementModal {...this.props} {...props} />;
  }
}

const MilestoneManagementModal: React.FunctionComponent<CreateProps & Props> = (props) => {
  const { budget, budgetModel, changeRequestType, isOpen, onCancelPress, milestone, phase, project, projectDetailsFlow } = props;
  const [milestoneCsiCode, setMilestoneCsiCode] = useState<string | undefined>(props.milestone?.csiCode);
  const [milestoneName, setMilestoneName] = useState<string>(milestone?.name || '');
  const [milestoneScope, setMilestoneScope] = useState<string>(props.milestone?.scopeOfWork || '');
  const [changeRequest, setChangeRequest] = useState<ChangeRequest | undefined>();
  const [taskList, setTaskList] = useState<Array<EditTask>>(initializeTaskList(milestone?.tasks));
  const [isNotSubmitted, setIsNotSubmitted] = useState<boolean>(true);

  const determineChanges = () => {
    const updatedTasks: Array<ChangeRequestTask> = []; 
    const originalTasks: Array<ChangeRequestTask> = [];
    const oldTasks = milestone?.tasks || [];
    const updatedTaskSet = new Set(taskList.map(task => task.id));
    const oldTasksMap = new Map<string, EditTask>();
    let isChanged: boolean = false;

    oldTasks.forEach(task => 
      oldTasksMap.set(task.id!, {
        ...task,
        orderIndex: task.orderIndex || 0,
        budget: String(task.budget),
      })
    );

    const pushChange = (tasks: ChangeRequestTask[], task: EditTask, type: ChangeRequestTaskType) => {
      tasks.push({
        taskId: task.id,
        orderIndex: task.orderIndex,
        description: task.description,
        budget: parsePriceInput(task.budget),
        completed: task.completed,
        changeRequestTaskType: type,
      })
    }

    const pushDeletion = (tasks: ChangeRequestTask[], task: BudgetMilestoneTask) => {
      tasks.push({
        taskId: task.id,
        orderIndex: task.orderIndex || 0,
        description: task.description,
        budget: parsePriceInput(task.budget),
        completed: task.completed,
        changeRequestTaskType: "deletion",
      })
    }

    // Tasks
    taskList.forEach((task) => {
      if (task.description === '') return;

      const oldTask = oldTasksMap.get(task.id!);
      const type = task.id ? "change" : "addition" 
      if (
        oldTask?.budget !== task.budget || 
        oldTask.description !== task.description ||
        oldTask.orderIndex !== task.orderIndex
      ) {
        // Change or Addition
        isChanged = true;
        pushChange(updatedTasks, task, type);
        oldTask ? pushChange(originalTasks, oldTask, type) : pushChange(originalTasks, task, type);
      }
    });

    oldTasks.forEach((task) => {
      if (!(updatedTaskSet.has(task.id!))) {
        // Deletion
        isChanged = true;
        pushDeletion(updatedTasks, task);
        pushDeletion(originalTasks, task);
      }
    })

    // Scope
    const updatedMilestone: ChangeRequestMilestone = {
      csiCode: milestoneCsiCode || "",
      name: milestoneName,
      scope: milestoneScope,
      orderIndex: milestone ? milestone.orderIndex : phase.milestoneIndices[phase.milestoneIndices.length - 1] + 1,
      tasks: updatedTasks,
      changeRequestMilestoneType: "new",
    }

    if (
      (updatedTasks.length === 0 && ((!milestone && milestoneScope === '') || (milestone && milestone.scopeOfWork === milestoneScope))) || 
      !milestoneName ||
      !isChanged
    ) {
      return;
    }

    const changeRequest: CreateChangeRequestParams = {
      budgetId: budget.id!,
      milestoneId: milestone?.id,
      milestones: [updatedMilestone],
      changeRequestType: changeRequestType,
    }

    if (milestone) {
      const originalMilestone: ChangeRequestMilestone = {
        csiCode: milestone.csiCode,
        name: milestone.name,
        scope: milestone.scopeOfWork,
        orderIndex: milestone.orderIndex,
        tasks: originalTasks,
        changeRequestMilestoneType: "original",
      }

      changeRequest.milestones.push(originalMilestone);
    }

    return changeRequest;
  }

  const getItemStyle = (_isDragging: boolean, draggableStyle: any) => ({
    background: "transparent",
    ...draggableStyle,
  });

  const handleAddTaskPress = () => {
    const newTask = {
      budget: '',
      description: '',
      orderIndex: 0,
      completed: false,
    };
    setTaskList([...taskList, newTask])
  }

  const handleDeleteTaskPress = (taskIndex: number) => {
    setTaskList([...taskList.slice(0, taskIndex), ...taskList.slice(taskIndex + 1)])
  }

  const handleChangeSubmit = async () => {
    const changeRequest = determineChanges();
    if (changeRequest) {
      try {
        const changeRequestResult = await budgetModel.createChangeRequest(changeRequest);
        props.projectDetailsFlow.changeRequests.splice(0, 0, changeRequestResult);
        setCurrentMilestoneCompletion("needs-review")
        setChangeRequest(changeRequestResult);
      } catch (e) {
        console.log(e)
      }
    }
    setIsNotSubmitted(false);
  }

  const handleCompletionSubmit = async () => {
    try {
      const changeRequestResult = await budgetModel.createChangeRequest({
        budgetId: budget.id,
        milestoneId: milestone?.id!,
        milestones: [{
          ...milestone!,
          scope: milestone!.scopeOfWork,
          changeRequestMilestoneType: "original",
          tasks: [],
        }],
        changeRequestType: "complete"
      });
      projectDetailsFlow.changeRequests.splice(0, 0, changeRequestResult);
      setCurrentMilestoneCompletion("pending")
      setChangeRequest(changeRequestResult);
    } catch (e) {
      console.log(e)
    }
    setIsNotSubmitted(false);
  }

  const setCurrentMilestoneCompletion = (status:MilestoneCompletionType )=> {
    if((projectDetailsFlow.selectedMilestone as BudgetMilestone)?.completion)
    (projectDetailsFlow.selectedMilestone as BudgetMilestone)!.completion = status;
    if(budget.milestones.find(m => m.id === milestone?.id)?.completion)
      budget.milestones.find(m => m.id === milestone?.id)!.completion = status;
  }

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const tasks = Array.from(toJS(taskList));
    const [removed] = tasks.splice(result.source.index, 1);
    tasks.splice(result.destination.index, 0, removed);
    tasks.forEach((task, index) => task.orderIndex = index);
    setTaskList(tasks);
  };

  const totalMilestonePrice = (tasks: BudgetMilestoneTask[] = []): number => {
    let totalCost: number = 0;
    tasks.forEach((task: BudgetMilestoneTask) => {
      totalCost += Number(task.budget);
    });
    return Math.round((totalCost + Number.EPSILON) * 100) / 100;
  };

  const updateBudget = (taskIndex: number, updatedBudget: string) => {
    const updatedTask: EditTask = {...taskList[taskIndex]};
    updatedTask.budget = sanitizePriceInput(updatedBudget);
    setTaskList([...taskList.slice(0, taskIndex), updatedTask, ...taskList.slice(taskIndex + 1)]);
  }

  const updateDescription = (taskIndex: number, updatedDescription: string) => {
    const updatedTask: EditTask = {...taskList[taskIndex]};
    updatedTask.description = updatedDescription;
    setTaskList([...taskList.slice(0, taskIndex), updatedTask, ...taskList.slice(taskIndex + 1)]);
  }

  const renderItems = () => {
    return (
      <View style={modalStyles.bodyItem}>
        {taskList.map((task, index) => {
          return (
            <Draggable
              key={`drag-task-item-${task.id}-${index}`}
              draggableId={index.toString()}
              index={index}
              isDragDisabled={taskList.length < 2}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                  )}
                >
                  <View style={modalStyles.inputContainer}>
                    <Icon name={"move-vertical"} size={16} type="accent" style={{justifyContent: "center", left: 4}}/>
                    <StyledTextInput
                      onChangeText={(text: string) => updateDescription(index, text)}
                      placeholder="Enter description of task"
                      style={{width: 460}}
                      value={taskList[index].description}
                      defaultValue={task.description}
                    />
                    <StyledTextInput
                      onChangeText={(text: string) => updateBudget(index, text)}
                      placeholder="$--"
                      style={{flex: 2}}
                      value={String(taskList[index].budget)}
                      defaultValue={String(task.budget)}
                    />
                    <TouchableOpacity onPress={() => handleDeleteTaskPress(index)} style={{justifyContent: "center"}}>
                      <Icon name={"trash"} type={"warning"} size={16}/>
                    </TouchableOpacity>
                  </View>
                </div>
              )}
            </Draggable>
          );
        })}
      </View>
    );
  }

  const renderBodyComplete = () => {
    return (
      <View style={[modalStyles.body, modalStyles.bottomBorder]}>
        <StyledText variant="body2">{"Submit the current milestone for completion?"}</StyledText>
        <View style={[modalStyles.bodyItem, {marginTop: 24}]}>
          <StyledText variant={"body2"} isBold={true}>{"SCOPE OF WORK"}</StyledText>
          <StyledText variant={"body2"}>{milestone?.scopeOfWork}</StyledText>
        </View>
      
        <View style={[modalStyles.bodyItem, {marginTop: 12}]}>
          <StyledText variant="body2" isBold>{"TASKS"}</StyledText>
          {milestone?.tasks.map((task) => {
            return (
              <View key={"details" + task.orderIndex} style={modalStyles.task}>
                <Icon name={"check-circle-filled"} type={"affirm"}/>
                <StyledText variant={"body2"} colorMode={"affirm"} style={{textDecorationLine: "line-through"}}>{task.description}</StyledText>
                <StyledText variant={"body2"} style={{marginLeft: "auto"}}>{formatCurrencyToString(task.budget)}</StyledText>
              </View>
            );
          })}
        </View>
      </View>
    );
  }

  const renderBodyEdit = () => {
    const onMilestoneSelect = (topic: MilestoneDefinition) => {
      const newTasks = [...topic.tasks, ...universalTasks];
      const newTaskList: Array<EditTask> = newTasks.map((task, index) => {
        return {
          description: task,
          budget: "",
          completed: false,
          orderIndex: index,
        };
      })
      setMilestoneCsiCode(topic.csiCode);
      setMilestoneName(topic.milestoneName);
      setMilestoneScope(topic.scope);
      setTaskList(newTaskList);
    }

    return (
      <View style={[modalStyles.body, modalStyles.bottomBorder]}>
        {
          !milestone && (
            <View style={[modalStyles.bodyItem, {zIndex: 2}]}>
              <StyledText variant="body2" isBold>{"MILESTONE NAME"}</StyledText>
              <FuzzySearchModular
                onChangeText={setMilestoneName}
                onSelect={onMilestoneSelect}    
                iconRight={{ name: "search", type: "primary", size: 16 }}
                placedholder={"Type or search for new category"}         
                flatListStyle={modalStyles.flatList}
                maxListSize={3}
                searchList={milestoneDefinitions} 
                searchKeys={["milestoneName"]}
                value={milestoneName}
              />
            </View>
          )
        }
        <View style={modalStyles.bodyItem}>
          <StyledText variant="body2" isBold>{"SCOPE OF WORK"}</StyledText>
          <StyledTextArea
            minHeight={88}
            onChangeText={(text: string) => setMilestoneScope(text)}
            placeholder="Enter a description of the scope of work for this milestone"
            value={milestoneScope}
            style={{borderColor: Palette.Secondary10Pct}}
          />
        </View>
        <View style={modalStyles.bodyItem}>
          <StyledText variant="body2" isBold>{"TASKS"}</StyledText>
          <View style={modalStyles.bodyTextContainer}>
            <StyledText variant="caption" style={{width: 460}}>{"Description"}</StyledText>
            <StyledText variant="caption" style={{flex: 1}}>{"Budget"}</StyledText>
          </View>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <View
                  {...provided.droppableProps}
                  ref={provided.innerRef as any}
                >
                  {renderItems()}
                  {provided.placeholder}
                </View>
              )}
            </Droppable>
          </DragDropContext>
          <StyledTouchableOpacity onPress={handleAddTaskPress} style={modalStyles.addTaskTouchable}>
            <Icon name="plus" type="accent" size={14}/>
            <StyledText variant="body2" isBold style={{color: Palette.Accent}}>{"Add Task"}</StyledText>
          </StyledTouchableOpacity>
        </View>
      </View>
    );
  }

  const renderBodyFinal = () => {
    return <InjectedChangeRequestView changeRequest={changeRequest}/>
  }

  const title = !!milestone ? changeRequestType === "change" ? "EDIT MILESTONE" : "COMPLETE MILESTONE" : "ADD MILESTONE";
  const name = milestone?.name || (isNotSubmitted ? project.name : milestoneName);

  return (
    <RenderModal
      onClick={() => {}}
      isOpen={isOpen}
      isHorizontallyCentered={true}
      isVerticallyCentered={true}
      activeOpacity={1}
      style={{padding: 0, width: 650}}
    >
      <View style={modalStyles.container}>
        <ScrollView style={modalStyles.scroll}>
          {/* Header */}
          <View style={[modalStyles.header, modalStyles.bottomBorder]}>
            <View style={modalStyles.headerLeft}>
              <StyledText variant="body2" isBold>{title}</StyledText>
              <StyledText variant="heading3" isBold>{name}</StyledText>
              {
                !!milestone && (
                  <StyledText variant="caption">{phase.name}</StyledText>
                )
              }
            </View>
            {
              isNotSubmitted && (
                <View style={modalStyles.headerRight}>
                  <StyledText variant="body" isBold={true}>{"BUDGET"}</StyledText>
                  <StyledText>{formatCurrencyToString(totalMilestonePrice(milestone?.tasks))}</StyledText>
                </View>
              )
            }
          </View>

          {/* Body */}
          {isNotSubmitted ? changeRequestType === "change" ? renderBodyEdit() : renderBodyComplete() : renderBodyFinal()}

          {/* Footer */}
          <View style={modalStyles.footer}>
            {
              isNotSubmitted ? (
                changeRequestType === "change" ? (
                  <StyledButton
                    disabled={!milestoneName}
                    onPress={handleChangeSubmit}
                    text={"Submit Change Order Request"}
                    iconRight={{name: "chevron-right", type: milestoneName ? "white" : "gray", size: 24}}
                  />
                ) : (
                  <StyledButton
                    disabled={!milestoneName}
                    onPress={handleCompletionSubmit}
                    text={"Submit Completion"}
                    iconRight={{name: "chevron-right", type: milestoneName ? "white" : "gray", size: 24}}
                  />
                )
              ) : (
                <StyledButton
                  disabled={false}
                  onPress={onCancelPress}
                  text={"Return to Milestone"}
                />
              )
            }
            <StyledTouchableOpacity onPress={onCancelPress}>
              <StyledText variant="body2" isBold style={{color: Palette.Accent, textDecorationLine: "underline"}}>{"Cancel"}</StyledText>
            </StyledTouchableOpacity>
          </View>
        </ScrollView>
      </View>
    </RenderModal>
  );
};

const initializeTaskList = (tasks?: Array<BudgetMilestoneTask>): Array<EditTask> => {
  const initTaskList: Array<EditTask> = [];
  if (tasks && tasks.length > 0) {
    initTaskList.push(...tasks.map((task) => {
      return (
        {
          ...task,
          orderIndex: task.orderIndex || 0,
          budget: String(task.budget),
        }
      );
    }))
  } else {
    initTaskList.push(...universalTasks.map((task, index) => {
      return (
        {
          description: task,
          orderIndex: index,
          budget: '',
          completed: false,
        }
      );
    }))
  }

  return initTaskList;
}

const modalStyles = StyleSheet.create({
  container: {
    flexDirection: "column",
    justifyContent: "flex-end",
    overflow: "hidden",
    maxHeight: "80vh",
  },
  scroll: {
    flex: 1,
    overflow: "hidden",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 32,
    paddingHorizontal: 32,
    paddingBottom: 15,
  },
  headerLeft: {
    flexDirection: "column",
    gap: 4,
  },
  headerRight: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  body: {
    flexDirection: "column",
    gap: 24,
    paddingVertical: 24,
    paddingHorizontal: 32,
  },
  bodyAlt: {
    flexDirection: "column",
    paddingVertical: 24,
    paddingHorizontal: 32,

  },
  bodyItem: {
    flexDirection: "column",
    gap: 8,
    zIndex: 0,
  },
  inputContainer: {
    flexDirection: "row",
    gap: 8,
    marginLeft: -24,
  },
  changeContainer: {
    flexDirection: "column",
    gap: 16,
  },
  warningTask: {
    textDecorationLine: "line-through",
  },
  addTaskTouchable: {
    flexDirection: "row", 
    alignItems: "center",
    gap: 4,
  },
  bodyTextContainer: {
    flexDirection: "row",
    gap: 8,
    marginBottom: -4,
  },
  footer: {
    flexDirection: "column",
    gap: 24,
    paddingVertical: 24,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 0,
  },
  bottomBorder: {
    borderBottomWidth: 1,
    borderBottomColor: Palette.Primary10Pct,
  },
  flatList: {
    borderRadius: 5,
    width: "100%",
    position: "absolute",
    top: 48,
    zIndex: 1,
  },
  task: {
    flexDirection: "row",
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 10,
  },
});

export const InjectedMilestoneManagementModal = withInjectedFactory(
  MilestoneManagementModalFactory
);
