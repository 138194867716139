import React from "react";
import { Inject } from "@not-the-droids/exco-ts-inject";
import { Invitation } from "./InvitationCard";
import { Notification } from "../NotificationInjectable";
import { observer } from "mobx-react";
import { Palette } from "./styles";
import { ProjectModel, UserModel } from "../../../data-model";
import { StyledButton, StyledText } from "./controls";
import { StyleSheet, View } from "react-native";
import { UserInfo } from "./UserInfo";
import { formatArrayToString } from "../utils/Strings";

interface CreateProps {
  invitation?: any;
  key?: any;
  removeInvitation?: (invitation: Invitation) => void;
  isLastChild?: boolean;
}

interface Props {
  projectModel: ProjectModel;
  userModel: UserModel;
  notification: Notification;
}

export class InvitationListItemFactory {
  static inject: Inject<InvitationListItemFactory> = (injector) => {
    return () =>
      new InvitationListItemFactory({
        projectModel: injector.get(ProjectModel)(),
        userModel: injector.get(UserModel)(),
        notification: injector.get(Notification)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <InvitationListItem {...this.props} {...props} />;
  }
}

@observer
class InvitationListItem extends React.Component<Props & CreateProps> {
  // constructor(props: Props) {
  //   super(props);
  //   makeObservable(this);
  // }

  handleAcceptPress = async () => {
    const {
      projectModel,
      invitation,
      notification,
      removeInvitation,
    } = this.props;

    try {
      await projectModel.acceptInvitation(invitation.projectId);
      removeInvitation?.({
        projectId: invitation.projectId,
        userId: invitation.userId,
      });
      notification.setNotification("success", "Invitation accepted!");
    } catch (err: any) {
      notification.setNotification("error", err?.message);
    }
  };

  handleRejectPress = async () => {
    //TODO: future -> create removed table to prevent readding invite to a project.
    const { projectModel, invitation, notification, removeInvitation } =
      this.props;
    try {
      await projectModel.rejectInvitation({
        projectId: invitation.projectId,
      });
    } catch (err: any) {
      notification.setNotification("error", err?.message);
    } finally {
      notification.setNotification("success", "Invitation removed!");
      removeInvitation?.({
        projectId: invitation.projectId,
        userId: invitation.userId,
      });
    }
  };

  render() {
    const { invitation, isLastChild } = this.props;
    const address = formatArrayToString(
      [invitation.projectAddress.city, invitation.projectAddress.state],
      ", "
    );
    return (
      <>
        <View style={styles.container}>
          <View style={[styles.row, styles.topRow]}>
            <UserInfo variant="name" user={invitation} />
            <View style={styles.row}>
              <StyledButton
                variant="affirm"
                text="Accept"
                style={[styles.button, styles.firstButton]}
                onPress={this.handleAcceptPress}
              />
              <StyledButton
                variant="warning"
                text="Reject"
                style={styles.button}
                onPress={this.handleRejectPress}
              />
            </View>
          </View>
          <View style={styles.row}>
            <View>
              <StyledText variant="body2" isBold={true}>
                {invitation.projectName}
              </StyledText>
              <StyledText variant="body2">{address}</StyledText>
            </View>
          </View>
        </View>
        {!isLastChild && <View style={styles.divider} />}
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingBottom: 16,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  column: {
    flexDirection: "column",
    justifyContent: "space-between",
  },
  topRow: {
    marginBottom: 16,
  },
  button: {
    width: 80,
  },
  firstButton: {
    marginRight: 8,
  },
  timeFrame: {
    justifyContent: "flex-end",
  },
  divider: {
    marginBottom: 24,
    borderBottomWidth: 1,
    marginHorizontal: -16,
    borderColor: Palette.Primary25Pct,
  },
});
