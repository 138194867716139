import { useEffect, useState } from "react";
import { useFuzzy } from "react-use-fuzzy";
import {
  Icon,
  StyledText,
  StyledTextInput,
  StyledTouchableOpacity,
  TextInputIcon,
} from "./controls";
import { 
  FlatList,
  NativeSyntheticEvent,
  StyleProp,
  StyleSheet,
  TargetedEvent,
  View,
  ViewStyle
} from "react-native";
import { Hoverable } from "react-native-web-hooks";
import { IconName, Palette } from "./styles";

type SelectableTextInputIcon = TextInputIcon & {
  nameClosed?: IconName;
}

export interface FuzzySearchModularProps<T> {
  flatListStyle?: StyleProp<ViewStyle>;
  inputStyle?: StyleProp<ViewStyle>;
  modifyResult?: (result: Array<T>, keyword: string) => Array<T>;
  onChangeText?: (keyword: string) => void;
  onSelect: (topic: T) => void;
  placedholder?: string;
  hideNotFoundHeader?: boolean;
  searchKeys: Array<keyof T>;
  searchList: Array<T>;
  value?: string;
  iconLeft?: SelectableTextInputIcon;
  iconRight?: SelectableTextInputIcon;
  maxListSize?: number;
}

export function FuzzySearchModular<T>(props: FuzzySearchModularProps<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const [forcedResult, setForcedResult] = useState<Array<T> | undefined>();
  const [isResultNotFound, setIsResultNotFound] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  let { modifiedResult, keyword, search } = useModifyResult(
    props.searchKeys,
    props.searchList,
    (notFound: boolean) => setIsResultNotFound(notFound),
    props.modifyResult
  );

  useEffect(() => {
    if (isOpen && props.value === '') {
      setForcedResult(props.searchList)
    } else if (!isOpen) {
      setForcedResult(undefined);
      setIsFocused(false);
    }
  }, [isOpen, props.searchList, props.value])

  const result = (forcedResult && props.value === '') ? forcedResult : modifiedResult;
  const iconRight = props.iconRight?.nameClosed ? {
    ...props.iconRight,
    name: isOpen ? props.iconRight?.name : props.iconRight?.nameClosed,
    onClick: () => {
      if (isOpen) handleChangeText('');
      setIsOpen(!isOpen);
    },
  } : props.iconRight as TextInputIcon;

  const assembleDisplay = (result: T) => {
    const resultArr = props.searchKeys.map((key) => result[key])
    return resultArr.join("\n");
  };

  const handleSelect = (item: T) => {
    search("");
    props.onSelect(item);
  };

  const handleChangeText = (keyword: string) => {
    const { onChangeText } = props;
    onChangeText && onChangeText(keyword);
    setIsOpen(keyword !== '');
    search(keyword);
  };
  
  const handleBlur = (e: NativeSyntheticEvent<TargetedEvent>) => {
    //@ts-ignore
    if (!e.currentTarget.contains(e.nativeEvent.relatedTarget)) {
      setIsFocused(false);
      setForcedResult(undefined);
      search("");
    }
  };

  const renderHeader = () => (
    !props.hideNotFoundHeader && isResultNotFound ? (
      <View style={[styles.headerContainer, styles.itemContainer]}>
        <StyledText variant="caption" colorMode="gray">
          Not Found
        </StyledText>
      </View>
    ) : (
      null
    )
  );

  const renderItem = (item: T) => {  
    return (
      <Hoverable>
        {(isHovered) => (
          <StyledTouchableOpacity
            style={[
              styles.suggestionContainer,
              isHovered && styles.suggestionHover,
            ]}
            onPress={() => handleSelect(item)}
          >
            <View style={styles.itemContainer} nativeID={'searchItem'}>
              {isResultNotFound && (
                <View style={styles.plusIcon}>
                  <Icon name={"plus"} type="white" size={16}/>
                </View>
              )}
              <StyledText variant="body">
                {assembleDisplay(item)}
              </StyledText>
            </View>
          </StyledTouchableOpacity>
        )}
      </Hoverable>
    );
  };

  const focusStyle: StyleProp<ViewStyle> = {
    borderColor: isFocused ? Palette.Accent : Palette.Primary25Pct,
  };

  const listSizeStyle: StyleProp<ViewStyle> = {
    maxHeight: (props.maxListSize ?? 5) * 61
  };

  return (
    <StyledTouchableOpacity
      onBlur={handleBlur}
      activeOpacity={1}
      style={{flexDirection: "column"}}
    >
      <StyledTextInput
        onFocus={() => setIsFocused(true)}
        iconLeft={props.iconLeft}
        iconRight={iconRight}
        placeholder={props.placedholder || ""}
        variant="primary"
        value={props.value || keyword}
        style={[styles.input, props.inputStyle]}
        containerStyle={focusStyle}
        onChangeText={handleChangeText}
      />
      {(keyword || forcedResult) && (
        <FlatList
          ListHeaderComponent={renderHeader}
          data={result}
          style={[styles.flatList, props.flatListStyle, listSizeStyle]}
          renderItem={({ item }) => renderItem(item)}
          keyExtractor={(item) => `flatlist-item-${item[props.searchKeys[0]]}`}
        />
      )}
    </StyledTouchableOpacity>
  );
};

function useModifyResult<T>(
  searchKeys: Array<keyof T>,
  searchList: Array<T>,
  onResultNotFound: (notFound: boolean) => void,
  modifyResult?: (result: Array<T>, keyword: string) => Array<T>,
) {
  const nonEmptySearchList = searchList.length > 0 ? searchList : [{}] as Array<T>;
  const { result, keyword, search } = useFuzzy<T>(nonEmptySearchList, {
    keys: searchKeys,
  });

  useEffect(() => {
    onResultNotFound(result.length === 0)
  }, [onResultNotFound, result]);

  const modifiedResult = !!modifyResult ? modifyResult(result, keyword) : result;
  return { modifiedResult, keyword, search };
};

const styles = StyleSheet.create({
  item: {
    color: "red",
  },
  headerContainer: {
    paddingVertical: 16,
    paddingHorizontal: 16,
    backgroundColor: Palette.Primary5Pct,
    borderColor: Palette.Primary10Pct,
    borderWidth: 1,
  },
  flatList: {
    position: "absolute",
    borderRadius: 8,
    width: "100%",
    top: 48,
  },
  input: {
    width: "100%",
    height: 38,
  },
  plusIcon: {
    height: 20, 
    width: 20, 
    backgroundColor: Palette.Accent, 
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 8,
  },
  itemContainer: {
    flexDirection: "row",
    width: "100%",
  },
  inviteIcon: {
    marginTop: 6,
    marginRight: 10,
  },
  suggestionContainer: {
    paddingVertical: 20,
    paddingHorizontal: 25,
    backgroundColor: Palette.Primary5Pct,
    borderColor: Palette.Primary10Pct,
    borderWidth: 1,
  },
  suggestionHover: {
    backgroundColor: Palette.White,
  },
});
