import { WorkCategories } from "./WorkCategories";

export type CategoryLocation = "Interior" | "Exterior" | "Both";

export interface CategoryDefinition {
	category: WorkCategories,
	milestones: Array<string>,
	location: CategoryLocation,
};

export const categoryDefinitions: CategoryDefinition[] = [
	{ category: "Administration", milestones: ["Job Administration"], location: "Both", },
	{ category: "Appliances", milestones: ["Finishes Pre Order", "Appliances"], location: "Both" },
	{ category: "Cabinetry", milestones: ["Finishes Pre Order", "Cabinetry"], location: "Both" },
	{ category: "Closet System", milestones: ["Closet System"], location: "Interior" },
	{ category: "Custom Woodwork", milestones: ["Other"], location: "Interior" },
	{
		category: "Concrete",
		milestones: ["Concrete Foundation", "Concrete Slab"], 
		location: "Interior",
	},
	{ category: "Masonry", milestones: ["Masonry Walls"], location: "Exterior" },
	{
		category: "Countertops",
		milestones: ["Finishes Pre Order", "Countertops"], 
		location: "Interior",
	},
	{ category: "Demo", milestones: ["Demo"], location: "Interior" },
	{ category: "Decks", milestones: ["Decks"], location: "Interior" },
	{ category: "Docks", milestones: ["Docks"], location: "Exterior" },
	{ category: "Driveway/ Sidewalks", milestones: ["Driveways/Sidewalks"], location: "Exterior" },
	{ category: "Drywall", milestones: ["Hang Drywall", "Finish Drywall"], location: "Interior" },
	{
		category: "Electrical",
		milestones: [
			"Under Floor Electric Rough-in",
			"Rough in Electric",
			"Finishes Pre Order",
			"Electric Trim out",
		], 
		location: "Both",
	},
	{ category: "Elevator", milestones: ["Pre Orders", "Elevator"], location: "Interior" },
	{
		category: "Exterior Doors",
		milestones: ["Pre Orders", "Windows", "Exterior Doors"], 
		location: "Exterior",
	},
	{ category: "Exterior Details", milestones: ["Other"], location: "Interior" },
	{ category: "Fence", milestones: ["Fence"], location: "Exterior" },
	{
		category: "Finishes",
		milestones: ["Finishes Pre Order", "Finishes Installs"], 
		location: "Interior",
	},
	{
		category: "Fire Sprinklers",
		milestones: ["Rough in Fire/Sprinklers", "Rough in Fire/Sprinklers"], 
		location: "Interior",
	},
	{
		category: "Fireplace",
		milestones: ["Rough-In Fireplace", "Finalize Fireplace"], 
		location: "Both",
	},
	{ category: "Flooring", milestones: ["Finishes Pre Order", "Flooring"], location: "Interior" },
	{ category: "Foundation Repairs", milestones: ["Foundation Repairs"], location: "Exterior" },
	{
		category: "Framing",
		milestones: [
			"Pre Orders",
			"Framing Floor",
			"Framing Walls",
			"Frame Roof",
			"Framing Second Story Floor",
			"Framing Second Story Walls",
		], 
		location: "Both",
	},
	{ category: "Garage Door", milestones: ["Garage Door"], location: "Exterior" },
	{
		category: "Gas",
		milestones: ["Under Floor Gas Rough-in", "Rough in Gas", "Gas Trim out"], 
		location: "Both",
	},
	{ category: "Glass Enclosure", milestones: ["Glass Enclosures"], location: "Interior" },
	{ category: "Gutters", milestones: ["Gutters"], location: "Exterior" },
	{ category: "Handrails", milestones: ["Handrails"], location: "Both" },
	{ category: "Pavers", milestones: ["Finishes Pre Order", "Pavers"], location: "Both" },
	{ category: "Hardware", milestones: ["Hardware"], location: "Interior" },
	{
		category: "Hazardous Material Remediation - Asbestos",
		milestones: ["Hazardous Remediation - Asbestos"], 
		location: "Both",
	},
	{
		category: "Hazardous Material Remediation - Lead Based Paint",
		milestones: ["Hazardous Remediation - Lead Base Paint"], 
		location: "Both",
	},
	{
		category: "Home Technology",
		milestones: ["Rough-in Home Technology", "Home Technology Trim out"], 
		location: "Both",
	},
	{ category: "House leveling", milestones: ["House leveling"], location: "Exterior" },
	{ category: "House Pest Control Tenting", milestones: ["Pest Control"], location: "Both" },
	{
		category: "Mechanical",
		milestones: [
			"Under Floor Mechanical Rough-in",
			"Rough in Mechanical",
			"Mechanical Trim Out",
		], 
		location: "Exterior",
	},
	{
		category: "Insulation",
		milestones: [
			"Pre Drywall Insulation",
			"Post Drywall Insulation",
		], 
		location: "Both",
	},
	{
		category: "Interior Doors",
		milestones: ["Finishes Pre Order", "Interior Doors"], 
		location: "Interior",
	},
	{ category: "Landscaping (allowance)", milestones: ["Landscaping"], location: "Exterior" },
	{ category: "Metal", milestones: ["Pre Orders", "Metal Work"], location: "Both" },
	{ category: "Mobilization", milestones: ["Site Work"], location: "Both" },
	{
		category: "Paint",
		milestones: [
			"Interior First Coat of Paint",
			"Exterior Paint",
			"Final Interior Paint",
		], 
		location: "Both",
	},
	{ category: "Permit", milestones: ["Permits"], location: "Both" },
	{ category: "Pest Control", milestones: ["Pest Control"], location: "Both" },
	{ category: "Project Drawings", milestones: ["Project Drawings"], location: "Exterior" },
	{
		category: "Plumbing",
		milestones: [
			"Under Floor Plumbing Rough-in",
			"Finishes Pre Order",
			"Rough in Plumbing",
			"Plumbing Trim out",
		], 
		location: "Both",
	},
	{ category: "Pool/ Water Features", milestones: ["Pool"], location: "Exterior" },
	{ category: "Property Cleaning", milestones: ["Final Cleaning"], location: "Both" },
	{ category: "Rodent Protection", milestones: ["Pest Control Pipe System"], location: "Interior" },
	{ category: "Roof", milestones: ["Roof Dry-in", "Roof Finalize"], location: "Interior" },
	{ category: "Seawalls", milestones: ["Seawall", "Site Work"], location: "Interior" },
	{ category: "Shelves", milestones: ["Shelves"], location: "Interior" },
	{ category: "Blinds/ Shutters", milestones: ["Blinds/ Shutters"], location: "Both" },
	{ category: "Siding", milestones: ["Siding"], location: "Exterior" },
	{ category: "Site Work", milestones: ["Site Work"], location: "Exterior" },
	{
		category: "Solar",
		milestones: ["Pre Orders", "Rough in Solar", "Solar Finalize Install"], 
		location: "Exterior",
	},
	{ category: "Soffits", milestones: ["Finishes Pre Order", "Soffits"], location: "Exterior" },
	{ category: "Lawn Sprinklers", milestones: ["Lawn Sprinklers"], location: "Exterior" },
	{ category: "Stairs", milestones: ["Stairs"], location: "Both" },
	{ category: "Tile", milestones: ["Tile", "Final Tile"], location: "Both" },
	{ category: "Tree Removal", milestones: ["Tree Work"], location: "Exterior" },
	{ category: "Trim & Millwork", milestones: ["Trim"], location: "Interior" },
	{
		category: "Pre Construction Utilites assessment/ turn on/ movement",
		milestones: ["Pre Construction Utilites assessment/movement"], 
		location: "Exterior",
	},
	{ category: "Underpinning", milestones: ["Underpinning"], location: "Exterior" },
	{ category: "Warranty", milestones: ["Other"], location: "Both" },
	{ category: "Windows", milestones: ["Pre Orders", "Windows"], location: "Exterior" },
];

export interface WorkCategoryParams {
	category: string;
	isCustom: boolean;
	isSelected: boolean;
	order: number;
	wasExcluded: boolean;
  }
export interface WorkAreaParams {
	areaName: string;
	duplicate?: boolean;
	exclusiveCategories: Array<WorkCategoryParams>;
	inclusiveCategories: Array<WorkCategoryParams>;
	isChecked: boolean;
	isOpen?: boolean;
	location: CategoryLocation;
	numDuplicated: Array<number>;
	order: number;
  }


export const generateCategoryObjects = (workAreaCategories: string[]) => {
    const includedCategories: Array<WorkCategoryParams> = [];
    const excludedCategories: Array<WorkCategoryParams> = [];
    categoryDefinitions.forEach((categoryDef: CategoryDefinition, index: number) => {
      if (workAreaCategories.includes(categoryDef.category)) {
        includedCategories.push({
          category: categoryDef.category,
          isCustom: false,
          isSelected: false,
          order: index,
          wasExcluded: false,
        });
      } else {
        excludedCategories.push({
          category: categoryDef.category,
          isCustom: false,
          isSelected: false,
          order: index,
          wasExcluded: true,
        });
      }
    });
    return [excludedCategories, includedCategories];
  }