import React, { useEffect, useState } from "react";
import { Inject } from "@not-the-droids/exco-ts-inject";
import {
  Image,
  StyleSheet,
  View,
  TouchableOpacity,
} from "react-native";
import { StyledText } from "./controls";
import { Palette } from "./styles";
import { Project } from "../../../data-model/Project";
import { FirebaseFile } from "../../../data-model/File";
import { ContractorModel, ProjectModel, UserModel } from "../../../data-model/";
import { withInjectedFactory } from "../InjectorContext";
import { DropdownMenu } from "./DropdownMenu";

interface Props {
  contractorModel: ContractorModel;
  projectModel: ProjectModel;
  userModel: UserModel;
}

interface CreateProps {
  project: Project;
  currentUser: any;
}

export class ProjectDocumentsViewFactory {
  static inject: Inject<ProjectDocumentsViewFactory> = (injector) => {
    return () =>
      new ProjectDocumentsViewFactory({
        contractorModel: injector.get(ContractorModel)(),
        projectModel: injector.get(ProjectModel)(),
        userModel: injector.get(UserModel)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ProjectDocumentsView {...this.props} {...props} />;
  }
}

const ProjectDocumentsView: React.FunctionComponent<Props & CreateProps> = (
  props
) => {
  const { project, currentUser, projectModel } = props;
  const [documents, setDocuments] = useState<FirebaseFile[]>([]); // eslint-disable-next-line
  const [documentsFetched, setDocumentsFetched] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<string>("Newest");

  const order = [
    { name: "Newest", value: "new" },
    { name: "Oldest", value: "old" },
  ];

  useEffect(() => {
    fetchDocuments(); // eslint-disable-next-line
  }, [currentUser, project]);

  const fetchDocuments = async () => {
    if (project.id) {
      let response = await projectModel.getFilesByProjectId(project.id);
      setDocuments(response);
      setDocumentsFetched(true);
    }
  };

  const orderDocuments = (newSortOrder: string) => {
    switch (newSortOrder) {
      case "Newest": 
        documents.sort((docA, docB) => new Date(docA.createdAt).getTime() - new Date(docB.createdAt).getTime());
        break;
      case "Oldest": 
        documents.sort((docA, docB) => new Date(docB.createdAt).getTime() - new Date(docA.createdAt).getTime());
        break;
    }
    setSortOrder(newSortOrder);
  }

  return (
    <View style={[styles.container, styles.row]}>
      <View style={styles.column}>
        <View style={[styles.row, {justifyContent: "space-between", marginBottom: 33, zIndex: 2}]}>
          <StyledText variant="heading3" isBold={true}>
            Documents
          </StyledText>
          <View style={{width: 100}}>
            <DropdownMenu
              variant="pill"
              list={order}
              type="single"
              onChange={(itemValue: string) => (orderDocuments(itemValue))}
              value={sortOrder}
            />
          </View>
        </View>
        <View style={styles.fileListContainer}>
          {documents.length > 0 ? (
            documents.map((item: FirebaseFile) => {
              return (
                <TouchableOpacity
                  onPress={() => window.open(item.fileUrl)}
                  style={styles.imageContainer}
                  key={`document-file-${item.fileName}`}
                >
                  <View style={styles.image}>
                    <Image 
                      source={{uri: item.fileUrl}}
                      style={{height: "15vw", borderRadius: 4}}
                    />
                    <View style={styles.bodyContainer}>
                      <StyledText variant="body2" isBold>
                        {item.fileName}
                      </StyledText>
                      <StyledText variant="caption" colorMode="gray">
                        {new Date(item.createdAt).toLocaleDateString()}
                      </StyledText>
                    </View>
                  </View>
                </TouchableOpacity>
              );
            }
          )) : (
            <View style={styles.center}>
              <StyledText variant="heading" isBold>
                Empty
              </StyledText>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: Palette.Primary25Pct,
    padding: 10,
    marginTop: 20,
  },
  fileListContainer: {
    flex: 1,
    zIndex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 24,
  },
  container: {
    flex: 1,
  },
  row: {
    flexDirection: "row",
  },
  column: {
    flex: 1,
    marginRight: 48,
  },
  lastColumn: {
    marginRight: 0,
  },
  section: {
    marginBottom: 24,
  },
  sectionSecondaryTitle: {
    justifyContent: "space-between",
  },
  editButtonContainer: {
    width: 48,
    height: 48,
    borderRadius: 25,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Palette.Accent,
  },
  bodyContainer: {
    flexDirection: "column",
    backgroundColor: "transparent",
    margin: 16,
    gap: 4,
  },
  center: {
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto"
  },
  imageContainer: {
    width: "calc(33% - 16px)",
  },
  image: {
    justifyContent: "space-between", 
    borderRadius: 4,
    backgroundColor: Palette.Primary5Pct,
    border: "1px solid " + Palette.Primary10Pct,
  },
  calendarIcon: {
    marginRight: 10,
  },
  sectionText: {
    lineHeight: 24,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    width: "30vh",
    maxWidth: 500,
    borderRadius: 8,
    padding: 35,
    alignItems: "center",
    elevation: 5,
    minWidth: 400,
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalIcon: {},
  iconContainer: {
    padding: 20,
    borderRadius: 30,
    position: "absolute",
    top: -27,
    backgroundColor: Palette.White,
  },
  inviteSearch: {
    width: "100%",
  },
  inviteButton: {
    marginTop: 30,
  },
  selectedInviteContainer: {
    flexDirection: "row",
    width: "100%",
    marginTop: 20,
  },
  inviteIcon: {
    marginTop: 6,
    marginRight: 10,
  },
  cancelIcon: {
    position: "absolute",
    right: 0,
    marginTop: 5,
  },
  invitedIcon: {
    marginRight: 10,
  },
});

export const InjectedProjectDocumentsView = withInjectedFactory(ProjectDocumentsViewFactory);
