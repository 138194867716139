import { Inject, singletonInject } from "@not-the-droids/exco-ts-inject";
import { computed, observable, makeObservable } from "mobx";
import { AddressParts } from "../components/GooglePlacesInput";
import React from "react";
import {
  BuildingType,
  Media,
  ProjectModel,
  WorkLocation,
  WorkType,
} from "../../../data-model";
import { structureAddress } from "../utils/Strings";
import { WorkAreaParams } from "../constants/CategoryDefinitions";

export enum ProjectStep {
  Details,
  WorkAreas,
  Finish,
}

const PROJECT_STEPS = ["Project Details", "Work Areas", "Finish"];

export enum ProjectDetailsStep {
  location,
  owner,
  buildingType,
  workType,
  situatedAt,
  description,
  media,
}

interface Props {
  projectModel: ProjectModel;
}

export class ProjectCrudFlow extends React.Component<Props> {
  static inject: Inject<ProjectCrudFlow> = singletonInject((injector) => {
    return () =>
      new ProjectCrudFlow({
        projectModel: injector.get(ProjectModel)(),
      });
  });

  constructor(props?: any) {
    super(props);
    makeObservable(this);
  }

  // Flow Tracker
  @observable public projectId: string | undefined = undefined;
  @observable public currentStep: number = ProjectStep.Details;

  @computed public get progressSteps() {
    return PROJECT_STEPS.map((step, index) => {
      return {
        name: step,
        step: index + 1,
        isActive: index === this.currentStep,
        isCompleted: index < this.currentStep,
      };
    });
  }

  // Step: Project Details
  @observable public projectDetailsQuestion = ProjectDetailsStep.location;

  @observable public name: string = "";
  @observable public ownerName: string = "";
  @observable public projectLocationInput: string = "";
  @observable public projectLocation?: AddressParts;
  @observable public buildingType?: BuildingType;
  @observable public workType?: WorkType;
  @observable public situatedAt?: WorkLocation;
  @observable public structureDescription: string = "";
  @observable public media: Media[] = [];

  // Step: Work Areas
  @observable public inclusiveWorkAreas: Array<WorkAreaParams> = [];
  @observable public exclusiveWorkAreas: Array<WorkAreaParams> = [];
  @observable public categoryCount: Array<number> = [];
  @observable public numSelectedWorkAreas: number = 0;

  // Step: Work Categories
  @observable public selectedCategoryIds: Set<string> = new Set<string>();

  readonly goToStep = (step: number) => {
    if (step) {
      this.currentStep = step;
      window.scrollTo(0, 0);
    }
  };

  readonly incrementCurrentStep = () => {
    if (this.currentStep < ProjectStep.Finish) {
      this.currentStep++;
      window.scrollTo(0, 0);
    }
  };

  readonly decrementCurrentStep = () => {
    if (this.currentStep >= ProjectStep.Details) {
      this.currentStep--;
    }
  };

  readonly fetchNotCompletedProject = async () => {
    const project = await this.props.projectModel.getNotCompletedCreation();
    if (!project) return;
    this.projectId = project.id;
    this.name = project.name;
    this.projectLocation = project.address;
    this.projectLocationInput = structureAddress(project.address || {});
    this.buildingType = project.locationType;
    this.workType = project.workType;
    this.situatedAt = project.workLocation;
    this.structureDescription = project.description || "";
    this.media = project.media || [];
    this.projectDetailsQuestion = ProjectDetailsStep.media;
  };

  readonly clearProject = () => {
    this.projectId = undefined;
    this.name = "";
    this.projectDetailsQuestion = ProjectDetailsStep.location;
    this.currentStep = ProjectStep.Details;
    this.projectLocationInput = "";
    this.projectLocation = undefined;
    this.ownerName = "";
    this.buildingType = undefined;
    this.workType = undefined;
    this.situatedAt = undefined;
    this.structureDescription = "";
    this.media = [];
    this.inclusiveWorkAreas = [];
    this.exclusiveWorkAreas = [];
    this.categoryCount = [];
    this.numSelectedWorkAreas = 0;
  };
}
