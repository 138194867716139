import { Inject } from "@not-the-droids/exco-ts-inject";
import React from "react";
import {
  Image,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import { StyledText } from "../../components/controls";
import { SidebarVariant } from "../../constants/Variants";
import { History, HistoryInjectable } from "../../HistoryInjectable";
import { withInjectedFactory } from "../../InjectorContext";
import { getRoute, Screen } from "../../routes";
import { InvitationWidget } from "../InvitationWidget";
import { Background } from "./Background";
import { Margins } from "./Margins";
import { StyledTouchableOpacity } from "./StyledTouchableOpacity";

export type Size = "default" | "large";

type SidebarSizes = Record<Size, SidebarVariant>;
const sidebarSizes: SidebarSizes = {
  default: "sidebar",
  large: "sidebarLarge",
};

type FooterType = "copyright" | "invitation";
interface Props {
  history: History;
}

interface CreateProps {
  footer?: FooterType;
  isLogoHidden?: boolean;
  size?: Size;
}

type Sizes = Record<Size, StyleProp<ViewStyle>>;

export class SideBarFactory {
  static inject: Inject<SideBarFactory> = (injector) => {
    return () =>
      new SideBarFactory({
        history: injector.get(HistoryInjectable)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <SideBar {...this.props} {...props} />;
  }
}

class SideBar extends React.Component<Props & CreateProps> {
  static inject: Inject<React.ReactElement> = (injector) => {
    return () => <SideBar history={injector.get(HistoryInjectable)()} />;
  };

  readonly handleLinkClick = (screen: Screen) => {
    const { history } = this.props;
    const route = getRoute(screen);
    const path = "build" in route ? route.build() : route.path;
    history.push(path);
  };

  render() {
    const { footer, isLogoHidden, size } = this.props;
    const containerStyle = containerStyles[size || "default"];
    const logoStyle = logoStyles[size || "default"];
    const marginVariant: SidebarSizes[Size] = sidebarSizes[size || "default"];

    return (
      <View style={containerStyle}>
        <Background variant="dark">
          <Margins variant={marginVariant}>
            <View style={styles.sidebar}>
              {!isLogoHidden && (
                // TODO: route logo press to default home screen - EY
                <StyledTouchableOpacity style={logoStyle} onPress={() => {}}>
                  {/* <Image style={styles.logo} source={{ uri: proofLogo }} /> */}
                </StyledTouchableOpacity>
              )}
              {this.props.children}
            </View>

            <TouchableOpacity onPress={() => this.handleLinkClick("privacy")}>
              <StyledText variant="caption" colorMode="gray">
                Privacy Policy
              </StyledText>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => this.handleLinkClick("terms")}>
              <StyledText variant="caption" colorMode="gray">
                Terms
              </StyledText>
            </TouchableOpacity>
            <StyledText variant="caption" colorMode="gray">
              Version 1.0.0
            </StyledText>
            {renderFooter(footer)}
          </Margins>
        </Background>
      </View>
    );
  }
}

const CopyrightText: React.FunctionComponent = (props) => {
  return (
    <View style={styles.copyrightContainer}>
      <StyledText variant="caption" colorMode="gray">
        Proof Payments © 2022
      </StyledText>
    </View>
  );
};

const renderFooter = (footer?: FooterType) => {
  if (!footer) {
    return;
  } else if (footer === "copyright") {
    return <CopyrightText />;
  } else if (footer === "invitation") {
    return <InvitationWidget />;
  }
};

const styles = StyleSheet.create({
  sidebar: {
    flex: 1,
  },
  logo: {
    width: 88,
    height: 40,
    resizeMode: "contain",
  },
  copyrightContainer: {
    flexDirection: "row",
  },
});

const containerStyles: Sizes = {
  default: {
    width: 288,
  },
  large: {
    width: 540,
  },
};

const logoStyles: Sizes = {
  default: {
    marginBottom: 48,
  },
  large: {
    marginBottom: 96,
  },
};

export const InjectedSideBar = withInjectedFactory(SideBarFactory);
