
import { ChangeRequest, ChangeRequestType, MilestoneCompletionType } from "../../../data-model/Budget";
import { Address } from "../../../data-model/Project";
import { TagType } from "../components/controls/Tag";

export const formatArrayToString = (
  array: ReadonlyArray<string | undefined>,
  separator: string
): string => {
  return array.filter((a) => a).join(separator);
};

//TODO: eval if state/zip can include nbsp
export const formatFullAddressToString = (address: Address): string => {
  if (address) {
    const addressArray = [
      address.line1,
      address.line2,
      address.city,
      address.state,
      address.zip,
    ];

    return formatArrayToString(addressArray, ", ");
  } else {
    return "";
  }
};

export const structureAddress = (address: Partial<Address>): string => {
  if (address) {
    const addressArr = [
      address.line1?.length && address.line1 !== "undefined undefined"
        ? `${capitalizeFirstLetter(address.line1)},`
        : "",
      address.line2 ? ` ${capitalizeFirstLetter(address.line2)},` : "",
      address.city ? ` ${capitalizeFirstLetter(address.city)},` : "",
      address.state ? ` ${capitalizeFirstLetter(address.state)}` : "",
      address.zip ? ` ${capitalizeFirstLetter(address.zip)}` : "",
    ];
    return addressArr.join("").trim();
  } else {
    return "";
  }
};

export const capitalizeFirstLetter = (string: string): string => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return "";
  }
};

export const isEmptyString = (string: string | null | undefined): boolean => {
  return !string || string === "";
};

type PhoneFormats = "hyphenDash" | "period";

export const formatPhoneNumber = (
  phoneNumberString: string,
  format: PhoneFormats = "period"
) => {
  var cleaned = phoneNumberString.replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    switch (format) {
      case "hyphenDash":
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      case "period":
        return match[1] + "." + match[2] + "." + match[3];
      default:
        return match[1] + match[2] + match[3];
    }
  }
  return phoneNumberString;
};

export const formatDateLongMonth = (date: Date): string => {
  date = new Date(date);
  return date.toLocaleDateString(undefined, {
    timeZone: "UTC",
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

const statusDetails: Record<MilestoneCompletionType, TagType> = {
  "not-started": "light",
  "in-progress": "dark",
  "needs-review": "secondary",
  "pending": "secondary",
  "completed": "affirm",
};

export const formatTagString = (text: string) =>{
  return text.split(/[_-]/g).map(capitalizeFirstLetter).join(" ");
}

export const formatCompletionTagProps = (completionStatus: MilestoneCompletionType) => {
  const formatCompletionStatus = (completionStatus: MilestoneCompletionType) => {
    const status = completionStatus === "pending" ? "needs-review" : completionStatus;
    return formatTagString(String(status))
  }

  return {
    type: statusDetails[completionStatus],
    text: formatCompletionStatus(completionStatus)
  };
}

export const formatChangeRequestTag = (changeRequest: ChangeRequest) => {
  const requestTagRecord: Record<ChangeRequestType, Array<string>> = {
    'change': [
      'Change Order Request',
      `#${changeRequest.id.slice(0, 7).toUpperCase()}`
    ],
    'complete': [
      'Milestone Completion:', 
      changeRequest.milestones.find(m => m.changeRequestMilestoneType === "original")?.name ?? ''
    ],
    'final': [
      'Project Completion',
      ''
    ],
  }

  const requestTag = requestTagRecord[changeRequest.changeRequestType]
  return `${requestTag[0]} ${requestTag[1]}`
}
