import React from "react";
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from "react-native";
import { Palette } from "../styles";
import { StyledText } from "./StyledText";

const types = {
  dark: {
    backgroundColor: Palette.Primary25Pct,
    color: Palette.Primary100Pct,
  },
  light: {
    backgroundColor: Palette.Primary10Pct,
    color: Palette.Primary50Pct,
  },
  secondary: {
    backgroundColor: Palette.Secondary25Pct,
    color: Palette.Secondary100Pct,
  },
  affirm: {
    backgroundColor: Palette.Affirm25Pct,
    color: Palette.Affirm100Pct,
  },
  warning: {
    backgroundColor: Palette.Warning25Pct,
    color: Palette.Warning100Pct,
  },
};

export type TagType = keyof typeof types;

interface Props {
  type: TagType;
  text: string;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
}

export const Tag: React.FunctionComponent<Props> = ({ type, text, style, textStyle }) => {
  const { backgroundColor, color } = types[type];
  return (
    <View style={[styles.tag, { backgroundColor }, style]}>
      <StyledText isMedium style={[styles.text, {backgroundColor, color}, textStyle]}>{text}</StyledText>
    </View>
  );
};

const styles = StyleSheet.create({
  tag: {
    paddingHorizontal: 2,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 6,
    alignSelf: "flex-start",
    height: 26,
  },
  text: {
    paddingHorizontal: 8,
    borderRadius: 6,
    fontSize: 14,
    marginTop: -2,
  },
});
