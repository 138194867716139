import { Inject, singletonInject } from "@not-the-droids/exco-ts-inject";
import { RestClient } from "./RestClient";
import { Contract } from "../Budget";
import { ContractValidation } from "../Contract";
import { ContractModel } from "../ContractModel";

export class RestContractModel extends ContractModel {
  static inject: Inject<any> = singletonInject((injector) => {
    return () => new RestContractModel(injector.get(RestClient)());
  });

  constructor(private readonly rest: RestClient) {
    super();
  }

  public getBudgetContract(budgetId: string): Promise<Contract> {
    return this.rest.post(`/contract/${budgetId}/generate-contract`);
  }

  public confirmBudgetContract(budgetId: string): Promise<ContractValidation> {
    return this.rest.post(`/contract/${budgetId}/confirm-contract`);
  }

  //   // CHANGE REQUEST

  public getChangeRequestContract(
    changeRequestId: string,
    generateNew: boolean = true
  ): Promise<Contract | undefined> {
    return this.rest.post(
      `/contract/change-request/${changeRequestId}/generate-contract`,
      { generateNew }
    );
  }

  public confirmChangeRequestContract(
    changeRequestId: string
  ): Promise<ContractValidation> {
    return this.rest.post(
      `/contract/change-request/${changeRequestId}/confirm-contract`
    );
  }
}
