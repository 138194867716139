import { Inject, singletonInject } from "@not-the-droids/exco-ts-inject";
import { DemoModel } from "../DemoModel";
import { RestClient } from "./RestClient";

export class RestDemoModel extends DemoModel {
  static inject: Inject<RestDemoModel> = singletonInject((injector) => {
    return () => new RestDemoModel(injector.get(RestClient)());
  });

  constructor(private readonly rest: RestClient) {
    super();
  }

  public restore(): Promise<void> {
    return this.rest.get("/demo/restore");
  }

  public backup(): Promise<void> {
    return this.rest.get(`/demo/backup`);
  }
}
