import "firebase/storage";
import { makeObservable, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Inject } from "@not-the-droids/exco-ts-inject";
import { Link } from "react-router-dom";
import { ActivityIndicator, StyleSheet, Text, View } from "react-native";
import { Icon, StyledButton, StyledText } from "./controls";
import { Palette } from "./styles";
import { uploadFromBlobAsync } from "../utils/Storage";
import { ContractorModel, UserModel } from "../../../data-model";
import Dropzone from "react-dropzone";
import { v4 as uuidv4 } from "uuid";

interface CreateProps {
  currentUserId: string | undefined;
  onPreviousStep: () => void;
  onUploadVerification: (params: any) => void;
}

interface Props {
  contractorModel: ContractorModel;
  userModel: UserModel;
}

const checkBoxInputStyle = {
  marginRight: "10px",
};

export class VerificationFactory {
  static inject: Inject<VerificationFactory> = (injector) => {
    return () =>
      new VerificationFactory({
        contractorModel: injector.get(ContractorModel)(),
        userModel: injector.get(UserModel)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <Verification {...this.props} {...props} />;
  }
}

@observer
class Verification extends React.Component<Props & CreateProps> {
  @observable private uploading: boolean = false;
  @observable private contractorLicense: File[] = [];
  @observable private hasNoContractorLicense: boolean = false;
  @observable private hasNoContractorLicenseBond: boolean = false;
  @observable private cityBusinessLicense: File[] = [];
  @observable private error: string = "";
  @observable private contractorFiles: any[] = [];
  @observable private isFileUploading: string = "";
  @observable private currentUser: any = {};

  constructor(props: Props & CreateProps) {
    super(props);
    makeObservable(this);
  }

  componentDidMount = async () => {
    const { userModel } = this.props;
    this.currentUser = await userModel.getUser();
  };

  // onSubmitVerification = async () => {
  //   await this._uploadVerification();

  //   let params: UploadVerificationParams = {
  //     contractorLicenseWaived: this.hasNoContractorLicense,
  //     contractorLicenseUrls: this._buildFilePaths(this.contractorLicense),
  //     insuranceDocumentationUrls: this._buildFilePaths(
  //       this.insuranceDocumentation
  //     ),
  //     workersCompUrls: this._buildFilePaths(this.workersComp),
  //     cityBusinessLicenseUrls: this._buildFilePaths(this.cityBusinessLicense),
  //     contractorLicenseBondWaived: this.hasNoContractorLicenseBond,
  //     contractorLicenseBondUrls: this._buildFilePaths(
  //       this.contractorLicenseBond
  //     ),
  //   };

  //   this.props.onUploadVerification(params);
  // };

  buildUploadPlaceholder(files: File[]) {
    return files.length > 0 ? files[0].name : "Select a file...";
  }

  renderBackButton() {
    return (
      <Text style={styles.backButton} onPress={this.props.onPreviousStep}>
        ← Back to Business Details
      </Text>
    );
  }

  fileExists(fileType: string) {
    return this.contractorFiles.some((e) => e.fileType === fileType);
  }

  onDrop = async (acceptedFiles: any, fileType: string) => {
    const { contractorModel } = this.props;
    const file = acceptedFiles?.[0];
    if (!file) {
      return;
    }
    this.isFileUploading = fileType;

    this.error = "";
    let url: string;
    try {
      url = await uploadFromBlobAsync({
        blobUrl: URL.createObjectURL(file),
        name: `/contractor-files/${file.name}_${Date.now()}`,
      });
    } catch (e) {
      runInAction(() => {
        this.isFileUploading = "";
      });
      this.error = e as string;
      return;
    }
    let newFile = {
      id: uuidv4(),
      fileName: acceptedFiles[0].name,
      fileType: fileType,
      url: url,
    };
    runInAction(() => {
      contractorModel.addFile(newFile).then(() => {
        this.contractorFiles.push(newFile);
      });

      this.isFileUploading = "";
    });
  };

  renderDropZone = (fileType: string) => {
    return (
      <View style={styles.dropzone}>
        <Dropzone
          onDrop={(acceptedFiles) => this.onDrop(acceptedFiles, fileType)}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <input {...getInputProps()} />
              <View style={styles.dropIC}>
                <View style={styles.iconContainer}>
                  <Icon
                    style={styles.uploadIcon}
                    name={"upload"}
                    size={24}
                  />
                </View>
                <StyledText style={styles.dropzoneText}>
                  Drop files here or select to upload
                </StyledText>
              </View>
            </div>
          )}
        </Dropzone>
      </View>
    );
  };
  renderDropZoneOC(fileType: string) {
    return (
      <View>
        {this.isFileUploading !== fileType && this.renderDropZone(fileType)}

        {this.isFileUploading === fileType && (
          <ActivityIndicator
            animating={true}
            color={Palette.Secondary100Pct}
            style={styles.indicator}
          />
        )}
      </View>
    );
  }
  removeFile = (index: number) => {
    const { contractorModel } = this.props;
    let id = this.contractorFiles[index].id;
    contractorModel.deleteFile(id).then(() => {
      this.contractorFiles.splice(index, 1);
    });
  };

  renderFiles = (fileType: string) => {
    const fileIndex = this.contractorFiles.findIndex(
      (x) => x.fileType === fileType
    );
    const file = this.contractorFiles[fileIndex];
    return (
      <View style={styles.fileContainer}>
        <Icon
          name="file"
          size={24}
          onClick={() => window.open(file.url)}
        />
        <StyledText
          style={styles.fileText}
          onPress={() => window.open(file.url)}
        >
          {file.fileName}
        </StyledText>
        <Icon
          name="x"
          size={14}
          style={styles.removeIcon}
          onClick={() => this.removeFile(fileIndex)}
        />
      </View>
    );
  };

  renderContractorLicense() {
    return (
      <View>
        <StyledText variant="heading2" style={styles.typeHeader}>
          Contractor License
        </StyledText>
        <StyledText style={styles.text}>
          Proof Payments requires all contractors on the platform to be licensed
          in the states they work in. Please upload your contractors license.
        </StyledText>
        {!this.fileExists("contractor-state-license") &&
          this.renderDropZoneOC("contractor-state-license")}
        {this.fileExists("contractor-state-license") &&
          this.renderFiles("contractor-state-license")}
        {/*        <UploadFileSection
          header="Contractor License"
          label="Proof Payments requires all contractors on the platform to be licensed in the states they work in. Please upload your contractors license."
          placeholder={this.buildUploadPlaceholder(this.contractorLicense)}
          file={this.contractorLicense}
          disabled={this.hasNoContractorLicense}
          onUploadAccepted={(acceptedFiles) =>
            (this.contractorLicense = acceptedFiles)
          }
        />*/}
        <Text style={styles.checkBoxText}>
          <input
            type="checkbox"
            style={checkBoxInputStyle}
            checked={this.hasNoContractorLicense}
            onChange={() =>
              (this.hasNoContractorLicense = !this.hasNoContractorLicense)
            }
          />
          I do not have a contractor license
        </Text>
      </View>
    );
  }

  renderInsuranceDocumentation() {
    return (
      <View>
        <StyledText variant="heading2" style={styles.typeHeader}>
          Insurance Documentation
        </StyledText>
        <StyledText style={styles.text}>
          Proof Payments requires all contractors on the platform to carry
          contractors insurance. Please upload here.
        </StyledText>
        {!this.fileExists("contractor-insurance") &&
          this.renderDropZoneOC("contractor-insurance")}
        {this.fileExists("contractor-insurance") &&
          this.renderFiles("contractor-insurance")}
      </View>
    );
    // return (
    //   <UploadFileSection
    //     header="Insurance Documentation"
    //     label="Proof Payments requires all contractors on the platform to carry contractors insurance. Please upload here."
    //     placeholder={this.buildUploadPlaceholder(this.insuranceDocumentation)}
    //     file={this.insuranceDocumentation}
    //     disabled={false}
    //     onUploadAccepted={(acceptedFiles) =>
    //       (this.insuranceDocumentation = acceptedFiles)
    //     }
    //   />
    // );
  }

  renderWorkersComp() {
    return (
      <View>
        <StyledText variant="heading2" style={styles.typeHeader}>
          Workers Comp
        </StyledText>
        <StyledText style={styles.text}>
          Proof Payments requires all contractors on the platform to carry
          worker’s compensation insurance. Please upload here.
        </StyledText>
        {!this.fileExists("workers-compensation-insurance") &&
          this.renderDropZoneOC("workers-compensation-insurance")}
        {this.fileExists("workers-compensation-insurance") && this.renderFiles("workers-compensation-insurance")}
      </View>
    );
    // return (
    //   <UploadFileSection
    //     header="Workers Comp"
    //     label="Proof Payments requires all contractors on the platform to carry
    //     worker’s compensation insurance. Please upload here."
    //     placeholder={this.buildUploadPlaceholder(this.workersComp)}
    //     file={this.workersComp}
    //     disabled={false}
    //     onUploadAccepted={(acceptedFiles) => (this.workersComp = acceptedFiles)}
    //   />
    // );
  }

  renderContractorLicenseBond() {
    return (
      <View>
        <StyledText variant="heading2" style={styles.typeHeader}>
          Contractor License Bond
        </StyledText>
        <StyledText style={styles.text}>
          If you have a contractor license bond, please upload documentation
          regarding the bond here.
        </StyledText>
        {!this.fileExists("contractor-license-bond") &&
          this.renderDropZoneOC("contractor-license-bond")}
        {this.fileExists("contractor-license-bond") &&
          this.renderFiles("contractor-license-bond")}
        {/*<UploadFileSection
          header="Contractor License Bond"
          label="If you have a contractor license bond, please upload documentation
          regarding the bond here."
          placeholder={this.buildUploadPlaceholder(this.contractorLicenseBond)}
          file={this.contractorLicenseBond}
          disabled={this.hasNoContractorLicenseBond}
          onUploadAccepted={(acceptedFiles) =>
            (this.contractorLicenseBond = acceptedFiles)
          }
        />*/}
        <Text style={styles.checkBoxText}>
          <input
            type="checkbox"
            style={checkBoxInputStyle}
            checked={this.hasNoContractorLicenseBond}
            onChange={() =>
              (this.hasNoContractorLicenseBond =
                !this.hasNoContractorLicenseBond)
            }
          />
          I do not have a contractor license bond
        </Text>
      </View>
    );
  }

  renderCityBusinessLicense() {
    return (
      <View>
        <StyledText variant="heading2" style={styles.typeHeader}>
          City Business License
        </StyledText>
        <StyledText style={styles.text}>
          Please upload your business license from the city your business is
          registered in here.
        </StyledText>
        {!this.fileExists("city-business-license") &&
          this.renderDropZoneOC("city-business-license")}
        {this.fileExists("city-business-license") &&
          this.renderFiles("city-business-license")}
      </View>
    );
    // return (
    //   <UploadFileSection
    //     header="City Business License"
    //     label="Please upload your business license from the city your business is
    //     registered in here."
    //     placeholder={this.buildUploadPlaceholder(this.cityBusinessLicense)}
    //     file={this.cityBusinessLicense}
    //     disabled={false}
    //     onUploadAccepted={(acceptedFiles) =>
    //       (this.cityBusinessLicense = acceptedFiles)
    //     }
    //   />
    // );
  }

  renderSubmitButton() {
    // let disabled = false;
    // let disabled =
    //   (!this.hasNoContractorLicense && !this.contractorLicense.length) ||
    //   !this.insuranceDocumentation.length ||
    //   !this.workersComp.length ||
    //   (!this.hasNoContractorLicenseBond &&
    //     !this.contractorLicenseBond.length) ||
    //   !this.cityBusinessLicense.length;
    return (
      //TODO: re-enable when uploading functional/validated
      // <StyledButton
      //   onPress={this.onSubmitVerification}
      //   disabled={disabled || this.uploading}
      //   style={styles.submitButton}
      //   text={"Submit for verification"}
      // />
      <StyledButton
        onPress={() => this.props.onUploadVerification({})}
        style={styles.submitButton}
        text={"Submit for verification"}
      />
    );
  }

  renderAgreementText() {
    return (
      <View style={styles.agreement}>
        <Text>
          By submitting these files for verification, you agree to the Proof
          Payments <Link to="/login">Privacy Policy </Link> and
          <Link to="/login">Terms of Service</Link>
        </Text>
      </View>
    );
  }

  render() {
    return (
      <View style={styles.container}>
        {this.renderBackButton()}
        {this.renderContractorLicense()}
        {this.renderInsuranceDocumentation()}
        {this.renderWorkersComp()}
        {this.renderContractorLicenseBond()}
        {this.renderCityBusinessLicense()}
        {this.renderSubmitButton()}
        {this.renderAgreementText()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: "#074ee7",
    padding: 16,
    marginTop: 16,
    borderRadius: 4,
  },
  disabledButton: {
    backgroundColor: "#a9aaa9",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: 540,
  },
  backButton: {
    fontSize: 18,
    color: "blue",
    marginBottom: 16,
  },
  header: {
    fontSize: 24,
    marginTop: 18,
  },
  input: {
    borderColor: "#a9aaa9",
    borderRadius: 3,
    borderWidth: 2,
    fontSize: 18,
    padding: 12,
    marginBottom: 16,
  },
  inputText: {
    fontSize: 18,
  },
  dropZoneBase: {
    display: "flex",
    borderColor: "#a9aaa9",
    borderStyle: "dashed",
    borderRadius: 3,
    borderWidth: 2,
    fontSize: 18,
    height: 100,
  },
  dropZoneAccepted: {
    backgroundColor: "#e0e0e0",
    borderStyle: "solid",
  },
  submitButton: {
    marginTop: 40,
  },
  dropZoneDisabled: {
    opacity: 0.2,
  },
  label: {
    color: "#757675",
    fontSize: 12,
    marginTop: 16,
    marginBottom: 8,
  },
  checkBoxText: {
    marginTop: 10,
    fontSize: 16,
  },
  agreement: {
    paddingTop: 40,
    width: "60%",
    margin: "auto",
    textAlign: "center",
    paddingBottom: 50,
  },
  dropIC: {
    flexDirection: "column",
    margin: 40,
  },
  uploadIcon: {
    alignSelf: "center",
    top: 13,
  },
  dropzone: {
    display: "flex",
    borderColor: "#a9aaa9",
    borderStyle: "dashed",
    borderRadius: 3,
    borderWidth: 2,
    fontSize: 18,
    minHeight: 100,
    height: "auto",
    padding: 10,
  },
  dropzoneOC: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  dropzoneText: {},
  indicator: {
    margin: 50,
  },
  fileContainer: {
    margin: 10,
    flexDirection: "row",
  },
  iconContainer: {
    backgroundColor: Palette.Primary10Pct,
    width: 50,
    height: 50,
    borderRadius: 25,
    marginLeft: "41%",
    marginBottom: 15,
  },
  disclosureText: {
    marginBottom: 10,
  },
  selectedInviteContainer: {
    flexDirection: "row",
    width: "100%",
    marginTop: 20,
  },
  cancelIcon: {
    position: "absolute",
    right: 0,
    marginTop: 5,
  },
  inviteIcon: {
    marginTop: 6,
    marginRight: 10,
  },
  searchInputOC: {
    marginTop: 20,
  },
  invitedIcon: {
    marginRight: 10,
  },
  fileText: {
    marginTop: 3,
    marginLeft: 5,
  },
  removeIcon: {
    margin: 5,
  },
  text: {
    marginBottom: 8,
  },
  typeHeader: {
    marginTop: 24,
    marginBottom: 8,
  },
});
