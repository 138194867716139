import { Identifiable } from "@not-the-droids/exco-data-model";
import { Inject } from "@not-the-droids/exco-ts-inject";
import { FirebaseFile } from "File";
import { Bid, BidLine } from "./Bid";

export interface UpdateBidParams
  extends Identifiable,
    Partial<Omit<Bid, "id">> {}

export interface bidLinesParams {
  bidLines: BidLine[];
}

export interface deleteBidLinesParams {
  bidLines: BidLine[];
}

export interface AddContractorToBidParams {
  readonly bidId: string;
  readonly contractorId: string;
}

export interface CreateBidParams {
  readonly projectId: string;
}

export abstract class BidModel {
  static inject?: Inject<BidModel> = undefined;

  // public abstract getBids(): List<Bid>; //will we have more than one bid eventually per project?
  public abstract getBidById(id: string): Promise<Bid>;
  public abstract getBidLines(id: string): Promise<BidLine[]>;
  public abstract deleteBidLines(params: deleteBidLinesParams): Promise<void>;
  public abstract getBidByProjectId(projectId: string): Promise<Bid>;
  public abstract updateBidLines(params: bidLinesParams): Promise<void>;
  public abstract createBid(params: CreateBidParams): Promise<Bid>;
  public abstract updateBid(params: UpdateBidParams): Promise<void>;
  public abstract submitBid(id: string): Promise<void>;
  public abstract approveBid(id: string): Promise<void>;
  public abstract rejectBid(id: string): Promise<void>;
  public abstract addContractorToBid(
    params: AddContractorToBidParams
  ): Promise<void>;
  public abstract addFile(bidId:string, params:any): Promise<void>;
  public abstract getFilesById(bidId: string): Promise<FirebaseFile[]>;
  public abstract deleteFile(bidId:string, id:string): Promise<void>;
}
