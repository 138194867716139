import React from "react";
import { StyleSheet, View } from "react-native";
import { useHover } from "react-native-web-hooks";
import {
  Icon,
  StyledButton,
  StyledText,
  StyledTouchableOpacity,
} from "./controls";
import { IconName, IconType, Palette } from "./styles";
import { Project, ProjectStatus } from "../../../data-model";
import { formatArrayToString } from "../utils/Strings";
interface Props {
  projects: ReadonlyArray<Project>;
  onProjectClick: (projectId: string) => void;
  onCreateNewProject: () => void;
}

export class ProjectsCardView extends React.Component<Props> {
  render() {
    if (this.props.projects.length === 0) {
      return (
        <View style={styles.noProjectsContainer}>
          <StyledText variant="body" isBold={true} style={styles.noProjectsText}>
            No projects yet
          </StyledText>
          <StyledButton
            variant="secondary"
            text={"New Project"}
            onPress={this.props.onCreateNewProject}
            iconRight={{ name: "plus", type: "accent"}}
            style={styles.newProjectButton}
          />
        </View>
      );
    }

    return (
      <>
        {/* Table Header */}
        <View style={[styles.row, styles.headerRow]}>
          <View style={styles.column}>
            <StyledText variant="body2" isBold={true}>
              Name
            </StyledText>
          </View>
          <View style={styles.column}>
            <StyledText variant="body2" isBold={true}>
              Location
            </StyledText>
          </View>
          <View style={styles.column}>
            <StyledText variant="body2" isBold={true}>
              Timeframe
            </StyledText>
          </View>
          <View style={styles.statusColumn} />
        </View>

        {/* Table Body */}
        {this.props.projects?.map((project) => {
          return (
            <ProjectListItem
              project={project}
              onProjectClick={() => this.props.onProjectClick(project.id)}
              key={project.id}
            />
          );
        })}
      </>
    );
  }
}

interface ProjectStatusDisplay {
  iconName: IconName;
  text: string;
  type: IconType;
}

const projectStatus: Record<ProjectStatus, ProjectStatusDisplay> = {
  draft: {
    iconName: "horizontal-dots-circle",
    text: "In Progress",
    type: "primary",
  },
  published: {
    iconName: "check-circle",
    text: "Approved",
    type: "affirm"
  },
  pendingArchival: {
    iconName: "alert-circle",
    text:"Pending Archival",
    type: "secondary"
  },
  archived: {
    iconName: "check-circle",
    text: "Archived",
    type: "affirm"
  },
  inviteRejected: {
    iconName: "alert-circle",
    text: "Invitation Rejected",
    type: "secondary"
  },
  bidRejected: {
    iconName: "alert-circle",
    text: "Bid Rejected",
    type: "secondary"
  }
};

interface ProjectListItemProp {
  project: Project;
  onProjectClick: () => void;
}

export const ProjectListItem: React.FunctionComponent<ProjectListItemProp> = (
  props
) => {
  const { project, onProjectClick } = props;

  // Container
  const ref = React.useRef(null);
  const isHover = useHover(ref);
  const hoverStyle = isHover && styles.hover;

  // Status Icon
  const statusRef = React.useRef(null);
  const isStatusHover = useHover(statusRef);
  const address = formatArrayToString(
    [project.address.city, project.address.state],
    ", "
  );

  return (
    <View ref={ref}>
      <StyledTouchableOpacity
        style={[styles.rowContainer, hoverStyle]}
        onPress={onProjectClick}
      >
        <View style={[styles.row]}>
          <View style={styles.column}>
            <StyledText variant="body2">{project.name || "-"}</StyledText>
          </View>
          <View style={styles.column}>
            <StyledText variant="body2">{address || "-"}</StyledText>
          </View>
          <View style={styles.column}>
            {/* TODO: This is a placeholder for timeframe, use real timeframe - EY */}
            {/* <StyledText variant="body2">April 2022 - Jun 2022</StyledText> */}
          </View>
          <View style={[styles.statusColumn]}>
            <View ref={statusRef}>
              {isStatusHover ? (
                <Tooltip text={projectStatus[project.status].text} />
              ) : null}
              <Icon
                name={projectStatus[project.status].iconName}
                type={projectStatus[project.status].type}
                size={20}
              />
            </View>
          </View>
        </View>
      </StyledTouchableOpacity>
    </View>
  );
};

interface ToolTipProps {
  text: string;
}

const Tooltip: React.FunctionComponent<ToolTipProps> = (props) => {
  const { text } = props;
  return (
    <View style={styles.tooltip}>
      <View style={styles.arrow} />
      <StyledText variant="body2" colorMode="light" numberOfLines={1}>
        {text}
      </StyledText>
    </View>
  );
};

const styles = StyleSheet.create({
  rowContainer: {
    borderRadius: 5,
    marginHorizontal: -16,
    paddingHorizontal: 16,
  },
  row: {
    flexDirection: "row",
    paddingVertical: 12,
  },
  column: {
    flex: 1,
  },
  headerRow: {
    paddingTop: 0,
  },
  statusColumn: {
    width: 14,
  },
  lastChild: {
    paddingBottom: 0,
  },
  hover: {
    backgroundColor: Palette.Primary10Pct,
  },
  tooltip: {
    position: "absolute",
    top: -12,
    right: 24,
    alignItems: "center",
    justifyContent: "center",
    minWidth: 100,
    padding: 12,
    borderRadius: 4,
    backgroundColor: Palette.Primary100Pct,
  },
  arrow: {
    width: 0,
    height: 0,
    borderWidth: 5,
    borderStyle: "solid",
    borderColor: "transparent",
    position: "absolute",
    right: -10,
    borderLeftColor: Palette.Primary100Pct,
  },
  noProjectsContainer:{
    height: 200,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noProjectsText:{
    color: Palette.Primary75Pct,
    marginBottom: 10
  },
  newProjectButton: {
    width: 170,
  },
});
