import { Inject } from "@not-the-droids/exco-ts-inject";
import { computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { StyleSheet, View } from "react-native";
import {
  StyledButton,
  StyledText,
  StyledTextInput,
  StyledTouchableOpacity,
} from "../components/controls";
import { Authenticator } from "../exco-lib/exco-auth";
import { History, HistoryInjectable } from "../HistoryInjectable";
import { getRoute } from "../routes";
import { UserViewModel } from "../viewModels/UserViewModel";
import LockAuthModal from "./LockAuthModal";
import { Palette } from "./styles";

interface Props {
  authenticator: Authenticator;
  userViewModel: UserViewModel;
  history: History;
}

interface CreateProps {}

type AuthenticationType = "login" | "signup" | "forgot";

export class AuthenticationViewFactory {
  static inject: Inject<AuthenticationViewFactory> = (injector) => {
    return () =>
      new AuthenticationViewFactory({
        authenticator: injector.get(Authenticator)(),
        userViewModel: injector.get(UserViewModel)(),
        history: injector.get(HistoryInjectable)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(createProps: CreateProps) {
    return <AuthenticationView {...this.props} {...createProps} />;
  }
}

@observer
class AuthenticationView extends React.Component<Props> {
  @observable private authenticationType: AuthenticationType = "login";
  @observable private loading: boolean = false;

  // text input form:
  @observable private email: string = "";
  @observable private name: string = "";
  @observable private password: string = "";
  @observable private forgotEmail: string = "";
  @observable private errorMessage: string = "";
  @observable private revealPassword: boolean = false;
  @observable private forgotEmailSent: boolean = false;

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  @computed get passwordIcon() {
    if (this.revealPassword) {
      return "eye-off";
    } else {
      return "eye";
    }
  }

  readonly isAuthType = (authType: AuthenticationType) => {
    return authType === this.authenticationType;
  };

  login = async () => {
    this.loading = true;
    try {
      const user = await this.props.userViewModel.signIn(
        this.email,
        this.password
      );

      if (!user) {
        this.loading = false;
        this.errorMessage =
          "This user is no longer supported, create a new user or contact support.";
        return;
      }

      if (!user.isVerified) {
        this.props.history.push(getRoute("verifyEmail").path);
        return;
      }

      const userType = user.userType;
      if (
        (userType === "contractor" && !user.contractor) ||
        (userType === "owner" && !user.owner)
      ) {
        this.props.history.push(getRoute("signUp").path, userType);
        return;
      }

      this.props.history.push(getRoute("dashboard").path);
    } catch (error: any) {
      // TODO: Add error type ^
      console.error(error);
      // TODO: Test auth errors
      // this.errorMessage = "Invalid email or password";
      this.errorMessage = error.message;
      this.loading = false;
    }
  };

  signup = async (userType: "contractor" | "owner") => {
    if (!this.name || this.name.length <= 0)
      return (this.errorMessage = "Name is required for sign-up");

    this.loading = true;
    try {
      // TODO: Do we want to save the user's full name in Firebase?
      const cleanedName = this.name.trim();
      const cleanedEmail = this.email.trim();
      await this.props.userViewModel.signUp({
        email: cleanedEmail,
        password: this.password,
        name: cleanedName,
        userType,
      });

      this.props.history.push(getRoute("verifyEmail").path, userType);
    } catch (error: any) {
      // TODO: Add error type ^
      console.error(error);
      // TODO: Test auth errors
      // this.errorMessage = "Invalid email or password";
      this.errorMessage = error.message;
      this.loading = false;
    }
  };

  resetPassword = async () => {
    this.loading = true;
    try {
      await this.props.authenticator.email!.resetPassword(this.forgotEmail);
      this.forgotEmailSent = true;
    } catch (e) {
      console.error(e);
      alert("Failed to send reset email!");
    } finally {
      this.loading = false;
    }
  };

  togglePassword = async () => {
    this.revealPassword = !this.revealPassword;
  };

  readonly onToggleAuthenticationType = (
    authenticationType: AuthenticationType
  ) => {
    this.resetInput();
    this.errorMessage = "";
    this.authenticationType = authenticationType;
  };

  readonly resetInput = () => {
    this.email = "";
    this.name = "";
    this.password = "";
  };

  readonly renderAuthentication = () => {
    switch (this.authenticationType) {
      case "login":
        return (
          <>
            <StyledText variant="inputLabel" colorMode="light">
              Email
            </StyledText>
            <StyledTextInput
              variant="secondary"
              onChangeText={(input) => (this.email = input)}
              placeholder="Enter your email"
              style={styles.textInput}
              value={this.email}
              onSubmitEditing={this.login}
              iconLeft={{ name: "mail" }}
            />
            <StyledText variant="inputLabel" colorMode="light">
              Password
            </StyledText>
            <StyledTextInput
              variant="secondary"
              onChangeText={(input) => (this.password = input)}
              placeholder="Enter your password"
              style={styles.textInput}
              value={this.password}
              onSubmitEditing={this.login}
              iconLeft={{ name: "lock" }}
              iconRight={{
                name: this.passwordIcon,
                type: "white",
                size: 16,
                onClick: this.togglePassword,
              }}
              secureTextEntry={!this.revealPassword}
              errorMessage={this.errorMessage}
            />
            <View style={[styles.row, styles.buttonsContainer]}>
              <StyledButton
                text="Forgot password?"
                variant="link"
                onPress={() => this.onToggleAuthenticationType("forgot")}
              />
              <StyledButton
                loading={this.loading}
                onPress={this.login}
                text="Login"
                variant="primary"
              />
            </View>
          </>
        );
      case "signup":
        return (
          <>
            <StyledText variant="inputLabel" colorMode="light">
              Name
            </StyledText>
            <StyledTextInput
              variant="secondary"
              iconLeft={{ name: "user" }}
              onChangeText={(input) => (this.name = input)}
              placeholder="Enter your name"
              style={styles.textInput}
              value={this.name}
            />
            <StyledText variant="inputLabel" colorMode="light">
              Email
            </StyledText>
            <StyledTextInput
              variant="secondary"
              iconLeft={{ name: "mail" }}
              onChangeText={(input) => (this.email = input)}
              placeholder="Enter your email"
              style={styles.textInput}
              value={this.email}
            />
            <StyledText variant="inputLabel" colorMode="light">
              Password
            </StyledText>
            <StyledTextInput
              variant="secondary"
              iconLeft={{ name: "lock" }}
              iconRight={{
                name: this.passwordIcon,
                size: 16,
                onClick: this.togglePassword,
              }}
              onChangeText={(input) => (this.password = input)}
              placeholder="Enter your password"
              style={styles.textInput}
              value={this.password}
              secureTextEntry={!this.revealPassword}
              errorMessage={this.errorMessage}
            />
            <View style={[styles.signUpButtonsContainer]}>
              {/* TODO: Add function to below disabled button - EY */}
              <StyledButton
                loading={this.loading}
                onPress={() => this.signup("owner")}
                text="Sign Up As Property Owner"
                variant="primary"
                style={[styles.signupButton]}
              />
              <StyledButton
                loading={this.loading}
                onPress={() => this.signup("contractor")}
                text="Sign Up As Contractor"
                variant="primary"
                style={[styles.signupButton]}
              />
            </View>
            <View style={styles.agreements}>
              <StyledText variant="caption" colorMode="gray">
                {`By clicking Sign Up you agree to the `}
              </StyledText>
              <StyledButton
                variant="link"
                text="Terms of Use"
                textStyle={{ fontSize: 12 }}
              />
              <StyledText variant="caption" colorMode="gray">
                {` and `}
              </StyledText>
              <StyledButton
                variant="link"
                text="Privacy Policy"
                textStyle={{ fontSize: 12 }}
              />
            </View>
          </>
        );
      case "forgot":
        return (
          <>
            <StyledText variant="inputLabel" colorMode="light">
              Recover Password
            </StyledText>
            <StyledTextInput
              variant="secondary"
              onChangeText={(input) => (this.forgotEmail = input)}
              placeholder="Enter your email"
              style={styles.textInput}
              value={this.forgotEmail}
              onSubmitEditing={this.resetPassword}
              iconLeft={{ name: "mail" }}
              errorMessage={this.errorMessage}
            />
            <StyledButton
              loading={this.loading}
              onPress={this.resetPassword}
              iconRight={
                this.forgotEmailSent
                  ? { name: "check", type: "gray" }
                  : undefined
              }
              text={this.forgotEmailSent ? "Sent" : "Reset password"}
              disabled={this.forgotEmailSent}
            />
          </>
        );
    }
  };

  render() {
    return (
      <View>
        {/* NOTE: convert below inline selector group into reusable component should it appear the second time in app - EY */}
        <View style={[styles.row, styles.inlineSelectors]}>
          <StyledTouchableOpacity
            onPress={() => this.onToggleAuthenticationType("login")}
            style={[
              styles.inlineSelector,
              this.isAuthType("login") && styles.selectedInlineOption,
            ]}
          >
            <StyledText
              variant="heading"
              colorMode={this.isAuthType("login") ? "light" : "gray"}
              style={styles.boldText}
            >
              Login
            </StyledText>
          </StyledTouchableOpacity>
          <StyledTouchableOpacity
            onPress={() => this.onToggleAuthenticationType("signup")}
            style={[
              styles.inlineSelector,
              this.isAuthType("signup") && styles.selectedInlineOption,
            ]}
          >
            <StyledText
              variant="heading"
              colorMode={this.isAuthType("signup") ? "light" : "gray"}
              style={styles.boldText}
            >
              Sign Up
            </StyledText>
          </StyledTouchableOpacity>
        </View>

        {this.renderAuthentication()}

        <LockAuthModal />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
  },
  form: {
    alignItems: "center",
  },
  inlineSelectors: {
    marginBottom: 56,
  },
  inlineSelector: {
    marginRight: 32,
  },
  selectedInlineOption: {
    borderBottomWidth: 3,
    borderBottomColor: Palette.Secondary100Pct,
  },
  buttonsContainer: {
    marginTop: 32,
    alignItems: "center",
    justifyContent: "space-between",
  },
  signUpButtonsContainer: {
    flexDirection: "column",
  },
  textInput: {
    marginBottom: 32,
  },
  signupButton: {
    alignSelf: "center",
    minWidth: 400,
    marginBottom: 12,
  },
  agreements: {
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 74,
  },
  boldText: {
    fontWeight: "bold",
  },
  sentMessageHidden: {
    opacity: 0,
  },
});
