import { FC, ReactNode } from "react";
import { StyleSheet, View } from "react-native";
import { StyledText } from "../controls";

interface ArticleProps {
  title: string;
  subtitle: string;
}

export const Article: FC<ArticleProps> = ({ title, subtitle }) => {
  return (
    <>
      <StyledText
        selectable={false}
        isBold
        isUpperCase
        style={styles.articleTitle}
      >
        {title}
      </StyledText>

      <StyledText
        variant="inputLabel"
        selectable={false}
        isBold
        isUnderlined
        style={styles.articleSubitle}
      >
        {subtitle}
      </StyledText>
    </>
  );
};

interface ParagraphProps {
  number: string;
  title?: string;
  children?: ReactNode | ReactNode[];
}

export const Paragraph: FC<ParagraphProps> = ({ number, title, children }) => {
  return (
    <StyledText selectable={false} style={[styles.justify, styles.marginBottom]}>
      {"\t"}
      {number}
      {"\t"}
      <StyledText isBold isUnderlined>
        {title}
      </StyledText>
      {"  "}
      {children}
    </StyledText>
  );
};

export const BulletPoint: FC<ParagraphProps> = ({
  number,
  title,
  children,
}) => {
  return (
    <StyledText style={[styles.justify, styles.marginBottom]}>
      {"\t"}
      {number}{" "}
      <StyledText isBold isUnderlined>
        {title}
      </StyledText>
      {"  "}
      {children}
    </StyledText>
  );
};
export const SubBulletPoint: FC<ParagraphProps> = ({
  number,
  title,
  children,
}) => {
  return (
    <View style={[styles.marginBottom, styles.row]}>
      {/* Has to be wrapped in an additional view to prevent formatting*/}
      <View>
        <StyledText>
          {"\t"}
          {number}
          {"    "}
        </StyledText>
      </View>

      <StyledText style={styles.justify}>
        {title && (
          <>
            <StyledText isBold isUnderlined>
              {title}
            </StyledText>
            {"  "}
          </>
        )}
        {children}
      </StyledText>
    </View>
  );
};

const styles = StyleSheet.create({
  articleTitle: {
    textAlign: "center",
    marginTop: 20,
  },
  articleSubitle: {
    textAlign: "center",
    marginBottom: 20,
  },
  justify: {
    textAlign: "justify",
  },
  row: {
    flexDirection: "row",
  },
  marginBottom: {
    marginBottom: 20,
  },
});
