import { Inject } from "@not-the-droids/exco-ts-inject";
import { computed, makeObservable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { InjectedSideBar } from "../components/controls";
import { Screen } from "../components/controls/Screen";
import { FullScreenLoadingView } from "../components/FullScreenLoadingView";
import { SideBarMain } from "../components/SideBarMain";
import { CenteredLayout } from "../layouts/CenteredLayout";
import { UserViewModel } from "../viewModels/UserViewModel";

interface Props {
  userViewModel: UserViewModel;
}

@observer
export class NotFoundScreen extends React.Component<Props> {
  static inject: Inject<React.ReactElement> = (injector) => {
    return () => (
      <NotFoundScreen userViewModel={injector.get(UserViewModel)()} />
    );
  };

  constructor(props: Props) {
    super(props);
    makeObservable(this);
    this.props.userViewModel.initialize();
  }

  @computed get initialized() {
    return this.props.userViewModel.initialized;
  }

  @computed get currentUser() {
    return this.props.userViewModel.currentUser;
  }

  render() {
    if (!this.initialized) {
      return <FullScreenLoadingView />;
    }

    return (
      <Screen>
        {this.currentUser !== undefined && (
          <InjectedSideBar size="default">
            <SideBarMain />
          </InjectedSideBar>
        )}
        <CenteredLayout>
          <View style={localStyle.horizontal}>
            <Text style={[localStyle.size, localStyle.heading]}>404</Text>
            <View style={localStyle.pipe} />
            <Text style={[localStyle.size, localStyle.text]}>
              Page Not Found
            </Text>
          </View>
        </CenteredLayout>
      </Screen>
    );
  }
}

const localStyle = StyleSheet.create({
  horizontal: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  size: {
    fontSize: 42,
  },
  heading: {
    fontWeight: "400",
  },
  text: {
    fontWeight: "200",
  },
  pipe: {
    width: 1,
    backgroundColor: "#555",
    height: 64,
    marginHorizontal: 12,
  },
});
