import { Inject, singletonInject } from "@not-the-droids/exco-ts-inject";
import { AuthPasscodeModel, AuthPasscodeParams } from "../AuthPasscodeModel";
import { RestClient } from "./RestClient";

export class RestAuthPasscodeModel extends AuthPasscodeModel {
  static inject: Inject<RestAuthPasscodeModel> = singletonInject((injector) => {
    return () => new RestAuthPasscodeModel(injector.get(RestClient)());
  });

  constructor(private readonly rest: RestClient) {
    super();
  }

  public confirmPasscode(params: AuthPasscodeParams): Promise<boolean> {
    return this.rest.post("/auth-passcode", params);
  }
}
