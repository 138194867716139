import { Inject } from "@not-the-droids/exco-ts-inject";
import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { ReactElement } from "react";
import { StyleSheet, View } from "react-native";
import { AuthPasscodeModel } from "../../../data-model/AuthPasscodeModel";
import { withInjectedProps } from "../InjectorContext";
import { noop } from "../utils/Hooks";
import { StyledText } from "./controls";
import { StyledPasswordTextInput } from "./controls/StyledPasswordTextInput";
import { StyledModal } from "./Modal";
import { Palette } from "./styles";

interface Props {
  authPasscodeModel: AuthPasscodeModel
}

@observer
class LockAuthModal extends React.Component<Props> {
  static inject: Inject<ReactElement> = (injector) => {
    return () => (
      <LockAuthModal
        authPasscodeModel={injector.get(AuthPasscodeModel)()}
      />
    );
  };

  @observable private open: boolean = true;
  @observable private passcode: string = '';
  @observable private errorMessage: string | undefined;

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  readonly login = async () => {
      const confirmed = await this.props.authPasscodeModel.confirmPasscode({
        passcode: this.passcode
      });
      if (confirmed) {
        this.open = false;
      } else {
        this.errorMessage = 'Password is incorrect';
      }
  }

  render() {
    return (
      <StyledModal
        visible={this.open}
        title={"Proof Password Required"}
        onClose={noop}
        onConfirm={this.login}
        hideClose
        disableClickOutside
      >
        <StyledPasswordTextInput
          autoFocus
          onChangeText={(input) => this.passcode = input}
          placeholder="Enter password"
          value={this.passcode}
          onSubmitEditing={this.login}
          revealType="primary"
        />
        {this.errorMessage && (
          <View style={styles.errorContainer}>
            <StyledText variant="body" colorMode="warning" style={styles.errorIcon}>!</StyledText>
            <StyledText variant="body" colorMode="warning" style={styles.errorMessage}>{this.errorMessage}</StyledText>
          </View>
        )}
      </StyledModal>
    );
  }
};

const styles = StyleSheet.create({
  errorContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 14,
  },
  errorIcon: {
    width: 20,
    height: 20,
    fontSize: 12,
    marginRight: 10,
    borderRadius: 24,
    display: "flex",
    fontWeight: "900",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Palette.Warning25Pct,
  },
  errorMessage: {
    fontSize: 12,
  },
});

export default withInjectedProps(LockAuthModal);
