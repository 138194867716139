import { Authenticator } from "../exco-lib/exco-auth";
import { Inject } from "@not-the-droids/exco-ts-inject";
import { computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { StyleSheet, View } from "react-native";
import {
  BudgetMilestone,
  BudgetModel,
  Contract,
  Contractor,
  Project,
  ProjectModel,
  Budget,
  Owner,
  ContractModel,
} from "../../../data-model";
import { Clause } from "./Clause";
import { Card, StyledButton, StyledText, StyledTextArea } from "./controls";
import { History, HistoryInjectable } from "../HistoryInjectable";
import { ProjectDetailsFlow } from "../flows/ProjectDetailsFlow";
import { SummaryLayout } from "./SummaryLayout";
import { Palette } from "./styles";
import { uploadFromBlobAsync } from "../utils/Storage";
import { Notification } from "../NotificationInjectable";
import { v4 as uuidv4 } from "uuid";
import { ProjectWidgetManager } from "../managers/ProjectWidgetManager";
import { withInjectedFactory } from "../InjectorContext";
import { ContractProjectDetail } from "./Budget/ContractProjectDetail";
import { ContractPaymentSchedule } from "./Budget/ContractPaymentSchedule";
import { UserViewModel } from "../viewModels/UserViewModel";
import { SummaryBlock } from "./Budget/SummaryBlock";
import { ContractPhases } from "./Budget/ContractPhases";
import { LoadingIndicator } from "./LoadingIndicator";
import { FileDropzone } from "./controls/FileDropzone";
import { StyledModal } from "./Modal";
import * as routes from "../routes";
import { FirebaseFile } from "../../../data-model/File";
import { ContractView } from "./Contract/ContractView";
import { structureAddress } from "../utils/Strings";
import { formatCurrencyToString } from "../utils/Numbers";
import { ConfirmAgreementView } from "./Confirmation/ConfirmAgreementView";

interface Props {
  authenticator: Authenticator;
  budgetModel: BudgetModel;
  contractModel: ContractModel;
  history: History;
  projectDetailsFlow: ProjectDetailsFlow;
  projectModel: ProjectModel;
  projectWidgetManager: ProjectWidgetManager;
  userViewModel: UserViewModel;
  notification: Notification;
}

interface CreateProps {
  changeBudgetView: () => void;
  project: Project;
}

export class ProjectBudgetSummaryViewFactory {
  static inject: Inject<ProjectBudgetSummaryViewFactory> = (injector) => {
    return () =>
      new ProjectBudgetSummaryViewFactory({
        authenticator: injector.get(Authenticator)(),
        budgetModel: injector.get(BudgetModel)(),
        contractModel: injector.get(ContractModel)(),
        history: injector.get(HistoryInjectable)(),
        projectDetailsFlow: injector.get(ProjectDetailsFlow)(),
        projectModel: injector.get(ProjectModel)(),
        projectWidgetManager: injector.get(ProjectWidgetManager)(),
        userViewModel: injector.get(UserViewModel)(),
        notification: injector.get(Notification)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ProjectBudgetSummaryView {...this.props} {...props} />;
  }
}

@observer
class ProjectBudgetSummaryView extends React.Component<CreateProps & Props> {
  @observable private _initialized: boolean = false;
  @observable private _isSigning: boolean = false;
  @observable private showMissingAddressModal = false;
  @observable private contractor?: Contractor;
  @observable private owner?: Owner;
  @observable private isConfirmationAddressChecked: boolean = false;
  @observable private isConfirmationTermsChecked: boolean = false;
  @observable private error: string = "";
  @observable private disclosureInput: string = "";

  @computed get userType(): "contractor" | "owner" {
    return this.props.userViewModel.isContractor ? "contractor" : "owner";
  }

  @computed get budget(): Budget | undefined {
    return this.props.projectDetailsFlow.budget;
  }

  @computed get contract(): Contract | undefined {
    return this.props.projectDetailsFlow.budget?.contract;
  }

  @computed get isBudgetSubmitted(): boolean {
    return !!this.props.projectDetailsFlow.budget?.submitted;
  }

  @computed get isSignedByContractor(): boolean {
    return !!this.props.projectDetailsFlow.budget?.contract?.contractorSigned;
  }

  @computed get isSignedByOwner(): boolean {
    return !!this.props.projectDetailsFlow.budget?.contract?.ownerSigned;
  }

  @computed get disclosureFiles(): any[] {
    return this.props.projectDetailsFlow.budget?.files || [];
  }

  @computed get subtitle(): string {
    return this.props.userViewModel.isOwner
      ? "This is the contractor's high level estimate. We encourage both users to make sure they are on the same page before the contractor commits to building our your expansive budget and contract. Please review and comment on any questions you may have on these line items. There is NO binding payment until this point but you are committing to this contractor to work together."
      : "Please take a moment to review your contract before sending before sending to your client for review.";
  }

  constructor(props: Props & CreateProps) {
    super(props);
    makeObservable(this);
  }

  componentDidMount = async () => {
    const {
      budgetModel,
      project,
      projectModel,
      projectDetailsFlow,
      projectWidgetManager,
    } = this.props;
    try {
      this.contractor = await projectModel.getContractorByProjectId(project.id);
      this.owner = await projectModel.getOwnerByProjectId(project.id);

      projectDetailsFlow.budget = await budgetModel.getBudgetByProjectId(
        project.id
      );

      await projectWidgetManager.loadComments(
        project.id,
        "budget",
        projectDetailsFlow.budget.id
      );
      this._initialized = true;
    } catch (error) {
      console.log(error);
    }
  };

  handleDisclosureFileDrop = async (
    files: File[],
    onUploadFinishedCallback: () => void
  ) => {
    const { budgetModel, projectDetailsFlow } = this.props;
    if (!projectDetailsFlow.budget) return;

    const newFiles: any[] = [];
    const budgetId = projectDetailsFlow.budget.id;

    const uploadFile = async (file: File) => {
      try {
        const url = await uploadFromBlobAsync({
          blobUrl: URL.createObjectURL(file),
          name: `/budget-files/${file.name}_${Date.now()}`,
        });

        const newFile = {
          id: uuidv4(),
          fileName: file.name,
          type: "file",
          url: url,
        };

        await budgetModel.addFile(budgetId, newFile);
        newFiles.push(newFile);
      } catch (e) {
        console.log(e);
      }
    };

    await Promise.all(files.map((file) => uploadFile(file)));

    if (!projectDetailsFlow.budget.files)
      projectDetailsFlow.budget.files = [...newFiles];
    else projectDetailsFlow.budget.files.push(...newFiles);
    onUploadFinishedCallback();
  };

  handleRemoveDisclosureFile = (file: FirebaseFile) => {
    const { budgetModel, projectDetailsFlow } = this.props;
    const newFiles = projectDetailsFlow.budget!.files.filter(
      (f) => f.fileId !== file.fileId
    );
    projectDetailsFlow.budget!.files = newFiles;
    budgetModel.deleteFile(projectDetailsFlow.budget!.id, file.fileId);
  };

  handleSubmitBudget = async () => {
    const { budgetModel, projectDetailsFlow, notification } = this.props;
    this.handleUpdateDisclosure();

    // Double check contractor has an address
    if (this.contractor!.address.state === "") {
      this.showMissingAddressModal = true;
      return;
    }

    await budgetModel.submitBudget(projectDetailsFlow.budget!.id);
    projectDetailsFlow.budget!.submitted = true;
    notification.setNotification("success", "Budget Submitted");
  };

  handleUpdateDisclosure = () => {
    const { budgetModel, projectDetailsFlow } = this.props;
    budgetModel.updateDisclosure({
      budgetId: projectDetailsFlow.budget!.id,
      disclosure: this.disclosureInput,
    });
    projectDetailsFlow.budget!.disclosure = this.disclosureInput;
  };

  handleCommentBubblePress = (
    milestone?: BudgetMilestone,
    schedule?: boolean
  ) => {
    const { projectWidgetManager } = this.props;
    const parentId = this.budget!.id;
    let tagName = undefined;

    if (milestone) {
      tagName = milestone.name;
    } else if (schedule) {
      tagName = "Payment Schedule";
    }

    if (projectWidgetManager.activeCommentTag !== tagName) {
      projectWidgetManager.openChatWidget(parentId, tagName);
    } else {
      projectWidgetManager.closeWidget();
    }
  };

  handleConfirmDocument = async () => {
    const { contractModel, projectDetailsFlow, notification } = this.props;
    if (!this.budget) return;

    const contractInfo = await contractModel.getBudgetContract(this.budget.id);

    projectDetailsFlow.budget!.contract = contractInfo;

    if (contractInfo[`${this.userType}Signed`]) {
      notification.setNotification(
        "success",
        "The contract has already been confirmed."
      );
      return;
    }

    try {
      this._isSigning = true;
      const signatureValidation = await contractModel.confirmBudgetContract(
        this.budget.id
      );
      projectDetailsFlow.budget!.contract[`${this.userType}Signed`] =
        signatureValidation.validated;
      notification.setNotification(
        "success",
        "The contract has been confirmed!"
      );
    } catch (e) {
      notification.setNotification("error", "Contract confirmation failed!");
    } finally {
      this._isSigning = false;
    }
  };

  renderFooter = () => {
    let action = () => {};
    let text = "";
    let disabled = true;
    let showCheckBox = false;

    // Contractor
    if (this.userType === "contractor") {
      if (this.isSignedByContractor && this.isSignedByOwner) {
        text = "Contract signed by both parties";
      } else if (this.isSignedByOwner) {
        text = "Confirm Contract";
        action = this.handleConfirmDocument;
        disabled = false;
        showCheckBox = true;
      } else if (this.isBudgetSubmitted) {
        text = "Budget Submitted, awaiting owner signature";
      } else {
        text = "Submit Budget";
        action = this.handleSubmitBudget;
        disabled = false;
      }
    }
    // Owner
    else {
      if (this.isSignedByContractor && this.isSignedByOwner) {
        text = "Contract signed by both parties";
      } else if (this.isSignedByOwner) {
        text = "Contract signed, awaiting contractor signature";
      } else {
        text = "Confirm Contract";
        action = this.handleConfirmDocument;
        disabled = false;
        showCheckBox = true;
      }
    }

    return (
      <>
        {showCheckBox && (
          <ConfirmAgreementView
            address={structureAddress(this.props.project.address)}
            checkboxStateAddress={{
              checked: this.isConfirmationAddressChecked,
              onChange: () =>
                (this.isConfirmationAddressChecked =
                  !this.isConfirmationAddressChecked),
            }}
            checkboxStateTerms={{
              checked: this.isConfirmationTermsChecked,
              onChange: () =>
                (this.isConfirmationTermsChecked =
                  !this.isConfirmationTermsChecked),
            }}
          />
        )}
        <StyledButton
          text={text}
          style={styles.footerButton}
          disabled={
            disabled ||
            (showCheckBox &&
              (!this.isConfirmationAddressChecked ||
                !this.isConfirmationTermsChecked))
          }
          onPress={action}
          loading={this._isSigning}
        />
      </>
    );
  };

  render() {
    const {
      changeBudgetView,
      projectDetailsFlow,
      projectWidgetManager,
      project,
      userViewModel,
    } = this.props;
    if (!this._initialized) return <LoadingIndicator />;
    return (
      <>
        <StyledModal
          title="Missing Address"
          text="Looks like we don't have your address yet, please update it under your profile."
          onClose={() => (this.showMissingAddressModal = false)}
          onConfirm={() =>
            this.props.history.push(routes.getRoute("profile").path)
          }
          confirmButtonText="Go to Profile"
          visible={this.showMissingAddressModal}
        />

        <SummaryLayout
          title="Contract Review"
          userType={this.userType}
          subtitle={this.subtitle}
          onBackPress={() => changeBudgetView()}
          backButtonHidden={this.isSignedByOwner || this.isSignedByContractor}
          footer={this.renderFooter()}
        >
          <SummaryBlock containerStyle={styles.row}>
            <View style={styles.sectionBlockRowItem}>
              <StyledText variant="heading3" isBold={true}>
                Contractor
              </StyledText>
              <StyledText style={styles.sectionText}>
                {this.contractor ? this.contractor!.name : "Contractor Name"}
              </StyledText>
            </View>
            <View style={styles.sectionBlockRowItem}>
              <StyledText variant="heading3" isBold={true}>
                Property Owner
              </StyledText>
              <StyledText style={styles.sectionText}>
                {this.owner ? this.owner!.name : "Property Owner Name"}
              </StyledText>
            </View>
          </SummaryBlock>

          <SummaryBlock title="Project Details">
            <ContractProjectDetail project={project} />
          </SummaryBlock>

          <SummaryBlock
            title="Budget and Scope of Work"
            onEditClick={
              this.userType === "contractor" && !this.isSignedByOwner
                ? changeBudgetView
                : undefined
            }
            containerStyle={styles.zeroMargin}
          >
            <Card variant="secondary" style={styles.section}>
              {projectDetailsFlow.budget && (
                <ContractPhases
                  activeCommentId={projectWidgetManager.activeCommentTag}
                  budget={projectDetailsFlow.budget}
                  currentUserId={userViewModel.currentUser!.id}
                  determineCommentsUnread={
                    projectWidgetManager.determineTaggedCommentsUnread
                  }
                  isActive={true}
                  handleCommentBubblePress={this.handleCommentBubblePress}
                />
              )}
            </Card>
          </SummaryBlock>

          <SummaryBlock>
            {projectDetailsFlow.budget && (
              <ContractPaymentSchedule
                activeComment={
                  projectWidgetManager.activeCommentTag === "Payment Schedule"
                }
                unreadComment={this.props.projectWidgetManager.determineTaggedCommentsUnread(
                  this.props.userViewModel.currentUser!.id,
                  "Payment Schedule"
                )}
                phases={projectDetailsFlow.budget.phases}
                startDate={projectDetailsFlow.budget.startDate}
                totalCost={projectDetailsFlow.phasesTotalCost}
                totalDays={projectDetailsFlow.phasesTotalDays}
                handleCommentBubblePress={() =>
                  this.handleCommentBubblePress(undefined, true)
                }
              />
            )}
          </SummaryBlock>

          <ContractView
            effectiveDate={new Date().toLocaleString(undefined, {
              day: "numeric",
              month: "numeric",
              year: "numeric",
            })}
            ownerName={this.owner!.name}
            ownerAddress={structureAddress(this.owner!.address) || "TBD"}
            contractorName={this.contractor!.name!}
            contractorAddress={
              structureAddress(this.contractor!.address) || "TBD"
            }
            projectAddress={structureAddress(project.address)}
            totalCost={formatCurrencyToString(
              projectDetailsFlow.phasesTotalCost
            )}
            downPayment={formatCurrencyToString(
              projectDetailsFlow.budget!.phases[0].cost
            )}
          />

          {/*  Section: covid clause */}
          <View style={styles.section}>
            <StyledText
              variant="inputLabel"
              isBold={true}
              style={[styles.termsAndConditionText, styles.sectionSmall]}
            >
              COVID / Volatile Market Clause
            </StyledText>
            <StyledText style={styles.sectionText}>
              Due to manufacturing delays worldwide and high demand for
              construction, we can not guarantee pricing will not increase
              between time of contract signing to installation of construction
              install and materials purchases. The client is responsible to
              cover any overages of materials during the duration of this
              contract. Labor quote is only good for 30 days from the date of
              this contract.
            </StyledText>
          </View>

          {/* Section: Standard Terms and Conditions */}
          <View style={styles.sectionSmall}>
            <View style={styles.sectionLarge}>
              <StyledText
                variant="inputLabel"
                isBold={true}
                style={[styles.termsAndConditionText, styles.sectionSmall]}
              >
                Standard Terms and Conditions
              </StyledText>
              <StyledText style={[styles.sectionText, styles.sectionSmall]}>
                The following Standard Terms and Conditions govern and control
                the construction contract to which they are attached, which was
                entered into between CFP Home Construction, Inc. (the “Building
                Contractor”) and the property owner(s) identified therein
                (hereinafter, the “Customer”). The term “Project,” as used
                herein, shall mean the specific scope of work identified in the
                construction contract between the Building Contractor and
                Customer to which these Standard Terms and Conditions are
                attached (together that contract as well as these Standard Terms
                and Condition are collectively, the “Contract”).
              </StyledText>
              <Clause
                heading="1. Scope of work."
                body="The scope of work for the Project is specified in the Contract and any engineer sealed drawings are the absolute authority for what is to be constructed as part of the Project. All work is to be completed in accordance with the Contract and sealed engineer’s drawings/plans, and to the extent there is any conflict between the two, the engineers sealed plans/drawings shall control."
                style={styles.sectionSmall}
              />
              <Clause
                heading="2. Construction management."
                body="Building Contractor shall maintain sole discretion over the means, methods, techniques, sequences, schedules, and procedures employed for construction of the Project. Building Contractor will make every reasonable effort to negotiate for the best pricing while not affecting the quality of the Project. Building Contractor will manage all subcontractors and push for timely turnaround times as well as manage the Project schedule and quotes for work on the Project."
                style={styles.sectionSmall}
              />
              <StyledText isBold={true}>END.</StyledText>
            </View>

            {/* Section: Permits */}
            {/* <View style={styles.sectionLarge}>
            <View style={[styles.row, { marginBottom: 8 }]}>
              <StyledText variant="heading3" isBold={true}>
                Permits
              </StyledText>
              {this.userType === "contractor" && (
                <StyledTouchableOpacity
                  onPress={() => {}}
                  style={styles.editIcon}
                >
                  <Icon name="edit" mode="color" />
                </StyledTouchableOpacity>
              )}
            </View>
            <StyledText isBold={true} style={styles.sectionText}>
              Compensation Agreement: Fix Bid
            </StyledText>
          </View> */}

            {/* Section: Disclosures */}
            <SummaryBlock
              title="Disclosures"
              containerStyle={{ marginBottom: 0 }}
            >
              <>
                {(this.userType === "owner" || this.isSignedByOwner) && (
                  <StyledText
                    style={[styles.sectionText, styles.disclosureText]}
                  >
                    {projectDetailsFlow.budget?.disclosure === ""
                      ? "Empty"
                      : projectDetailsFlow.budget?.disclosure}
                  </StyledText>
                )}
                {this.userType === "contractor" && !this.isSignedByOwner && (
                  <>
                    <StyledText style={styles.sectionText}>
                      Write in disclosures here, or upload a PDF file containing
                      disclosures for this estimate.
                    </StyledText>
                    <StyledTextArea
                      style={styles.disclosuresTextBox}
                      minHeight={100}
                      value={this.disclosureInput}
                      onChangeText={(value) => (this.disclosureInput = value)}
                    />
                    {this.disclosureInput === "" ||
                    this.disclosureInput ===
                      projectDetailsFlow.budget?.disclosure ? (
                      <View style={styles.disclosureButtonFiller}></View>
                    ) : (
                      <StyledButton
                        variant="secondary"
                        text={"Update disclosure"}
                        onPress={this.handleUpdateDisclosure}
                        style={styles.disclosureButton}
                        textNumberOfLines={1}
                      />
                    )}
                  </>
                )}
                <FileDropzone
                  files={this.disclosureFiles}
                  onDrop={this.handleDisclosureFileDrop}
                  onRemove={this.handleRemoveDisclosureFile}
                  editable={
                    this.userType === "contractor" && !this.isSignedByOwner
                  }
                />
              </>
            </SummaryBlock>

            {/* Section: Signature */}
            {/* <Signature title="Owner" />
              <Signature title="Owner" />
              <Signature title="Manager of CFP Home Construction" />*/}
          </View>
        </SummaryLayout>
      </>
    );
  }
}

const styles = StyleSheet.create({
  row: {
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
  },
  section: {
    marginBottom: 32,
  },
  sectionLarge: {
    marginBottom: 48,
  },
  sectionSmall: {
    marginBottom: 24,
  },

  sectionText: {
    lineHeight: 20,
  },
  sectionBlockRowItem: {
    flex: 1,
  },
  contractSectionHeader: {
    lineHeight: 24,
    marginBottom: 4,
  },
  termsAndConditionText: {
    marginHorizontal: "auto",
  },
  zeroMargin: {
    marginBottom: 0,
  },
  footerContainer: {
    flexDirection: "column",
    gap: 32,
  },
  signatureContainer: {
    paddingTop: 2,
    marginTop: 50,
    borderTopWidth: 1,
    paddingHorizontal: 70,
    flexDirection: "row",
    justifyContent: "space-between",
    borderColor: Palette.Primary100Pct,
  },
  constructionAgreementContainer: {
    marginBottom: 4,
  },
  constructionAgreementInput: {
    width: 32,
    height: 20,
    marginRight: 4,
    marginBottom: 4,
    borderBottomWidth: 1,
    alignItems: "center",
    borderStyle: "dashed",
    borderColor: Palette.Primary100Pct,
  },
  constructionAgreementPercentContainer: {
    flexWrap: "nowrap",
    alignItems: "flex-start",
  },
  disclosuresTextBox: {
    marginTop: 10,
  },
  disclosureText: {
    marginTop: 10,
  },
  disclosureButton: {
    marginVertical: 10,
    maxWidth: 200,
  },
  disclosureButtonFiller: {
    marginVertical: 10,
    maxWidth: 180,
    height: 34,
  },
  footerButton: {
    display: "flex",
    justifyContent: "center",
    marginHorizontal: "auto",
  },
});

export const InjectedProjectBudgetSummaryView = withInjectedFactory(
  ProjectBudgetSummaryViewFactory
);
