import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { StyleSheet, View } from "react-native";
import { CreateContractorParams } from "../../../data-model/ContractorModel";
import { DropdownMenu } from "../components/DropdownMenu";
import { StyledTextInput, StyledText, StyledButton } from "./controls";
import { StatesArray } from "../constants/States";
import { AddressParts, GooglePlacesInput } from "./GooglePlacesInput";
import { contractorSpecialties } from "../constants/Profile";

interface Props {
  onUpdateBusinessDetails: (params: CreateContractorParams) => Promise<void>;
}

@observer
export default class BusinessDetails extends React.Component<Props> {
  @observable private name: string = "";
  @observable private website: string = "";
  @observable private specialities: string[] = [];
  @observable private address: AddressParts = {} as any;

  states = StatesArray;

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  renderButton() {
    const isDisabled = !this.name || !this.specialities || !this.address.line1;
    return (
      <StyledButton
        onPress={() =>
          this.props.onUpdateBusinessDetails({
            businessName: this.name,
            businessUrl: this.website,
            specialties: this.specialities,
            address: this.address,
          })
        }
        disabled={isDisabled}
        text={"Continue"}
      />
    );
  }

  onNameChangeHandler = (input: string) => {
    const cleanInput = input.replace(/[^a-z0-9"  *"]/gi, "");
    this.name = cleanInput;
  };

  onWebsiteChangeHandler = (input: string) => {
    var cleanInput = input.replace(/[^a-z0-9:/.]/gi, "");
    this.website = cleanInput;
  };

  render() {
    return (
      <View style={styles.container}>
        <StyledText variant="heading" isBold={true} style={styles.sectionTitle}>
          Business Details
        </StyledText>
        <View style={[styles.inputOC]}>
          <StyledText
            variant="body2"
            isBold={true}
            style={styles.label}
            isUpperCase={true}
          >
            Business name
          </StyledText>
          <StyledTextInput
            variant="tertiary"
            maxLength={100}
            placeholder="Enter your business name"
            placeholderTextColor={"black"}
            value={this.name}
            onChangeText={(text) => {
              this.onNameChangeHandler(text);
            }}
          />
        </View>
        <View style={[styles.inputOC]}>
          <StyledText
            variant="body2"
            isBold={true}
            style={styles.label}
            isUpperCase={true}
          >
            Business website
          </StyledText>
          <StyledTextInput
            variant="tertiary"
            placeholder="Enter your URL"
            maxLength={40}
            placeholderTextColor={"black"}
            value={this.website}
            onChangeText={(text) => {
              this.onWebsiteChangeHandler(text);
            }}
          />
        </View>
        <View style={[styles.inputOC, { zIndex: 2 }]}>
          <StyledText
            variant="body2"
            isBold={true}
            style={styles.label}
            isUpperCase={true}
          >
            Business address
          </StyledText>
          <GooglePlacesInput handleChange={(a) => (this.address = a)} />
        </View>
        <View style={[styles.inputOC, { zIndex: 1 }]}>
          <StyledText
            variant="body2"
            isBold={true}
            style={styles.label}
            isUpperCase={true}
          >
            Contractor Specialty
          </StyledText>
          <DropdownMenu
            value={this.specialities}
            placeholder={"Select your specialty"}
            list={contractorSpecialties}
            type="multi"
            onChange={(itemValue) => (this.specialities = itemValue)}
          />
        </View>
        {this.renderButton()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "10vh",
    display: "flex",
    flexDirection: "column",
    width: 360,
    alignItems: "center",
  },
  button: {
    backgroundColor: "#074ee7",
    padding: 16,
    marginTop: 16,
    borderRadius: 4,
  },
  disabledButton: {
    backgroundColor: "#a9aaa9",
  },
  sectionTitle: {
    marginBottom: 32,
    textAlign: "center",
  },
  inputOC: {
    marginTop: 30,
    marginBottom: 30,
    width: "100%",
    zIndex: 2,
  },
  label: {
    fontSize: 12,
    marginBottom: 8,
  },
});
