import { FC } from "react";
import { StyleSheet, View } from "react-native";
import { StyledText } from "../controls";
import {
  Article,
  BulletPoint,
  Paragraph,
  SubBulletPoint,
} from "./ContractComponents";

interface ContractViewProps {
  effectiveDate: string;
  ownerName: string;
  ownerAddress: string;
  contractorName: string;
  contractorAddress: string;
  projectAddress: string;
  totalCost: string;
  downPayment: string;
}

export const ContractView: FC<ContractViewProps> = ({
  effectiveDate,
  ownerName,
  ownerAddress,
  contractorName,
  contractorAddress,
  projectAddress,
  totalCost,
  downPayment,
}) => {
  return (
    <View style={styles.container}>
      <StyledText
        variant="heading2"
        selectable={false}
        isBold={true}
        style={styles.title}
      >
        Building Construction Contract
      </StyledText>

      <StyledText
        variant="inputLabel"
        selectable={false}
        isBold={true}
        style={styles.justify}
      >
        ACCORDING TO FLORIDA'S CONSTRUCTION LIEN LAW (SECTIONS 713.001-713.37,
        FLORIDA STATUTES), THOSE WHO WORK ON YOUR PROPERTY OR PROVIDE MATERIALS
        AND SERVICES AND ARE NOT PAID IN FULL HAVE A RIGHT TO ENFORCE THEIR
        CLAIM FOR PAYMENT AGAINST YOUR PROPERTY. THIS CLAIM IS KNOWN AS A
        CONSTRUCTION LIEN. IF YOUR CONTRACTOR OR A SUBCONTRACTOR FAILS TO PAY
        SUBCONTRACTORS, SUB-SUBCONTRACTORS, OR MATERIAL SUPPLIERS, THOSE PEOPLE
        WHO ARE OWED MONEY MAY LOOK TO YOUR PROPERTY FOR PAYMENT, EVEN IF YOU
        HAVE ALREADY PAID YOUR CONTRACTOR IN FULL. IF YOU FAIL TO PAY YOUR
        CONTRACTOR, YOUR CONTRACTOR MAY ALSO HAVE A LIEN ON YOUR PROPERTY. THIS
        MEANS IF A LIEN IS FILED YOUR PROPERTY COULD BE SOLD AGAINST YOUR WILL
        TO PAY FOR LABOR, MATERIALS, OR OTHER SERVICES THAT YOUR CONTRACTOR OR A
        SUBCONTRACTOR MAY HAVE FAILED TO PAY. TO PROTECT YOURSELF, YOU SHOULD
        STIPULATE IN THIS CONTRACT THAT BEFORE ANY PAYMENT IS MADE, YOUR
        CONTRACTOR IS REQUIRED TO PROVIDE YOU WITH A WRITTEN RELEASE OF LIEN
        FROM ANY PERSON OR COMPANY THAT HAS PROVIDED TO YOU A “NOTICE TO OWNER.”
        FLORIDA'S CONSTRUCTION LIEN LAW IS COMPLEX, AND IT IS RECOMMENDED THAT
        YOU CONSULT AN ATTORNEY.
      </StyledText>

      <StyledText style={styles.firstTitle} isBold isUpperCase isUnderlined>
        BUILDING CONSTRUCTION CONTRACT
      </StyledText>
      <StyledText style={styles.firstSubtitle} isBold>
        (Fixed Price - Except Change Orders)
      </StyledText>

      <StyledText style={[styles.justify, styles.marginBottom]}>
        This Building Construction Contract (hereinafter “Contract”) is made as
        of <StyledText isBold>{effectiveDate}</StyledText> (the “Effective
        Date”), between {ownerName}, hereinafter collectively referred to as
        “Owner”, whose current mailing address is{" "}
        <StyledText isBold>{ownerAddress}</StyledText> and{" "}
        <StyledText isBold>{contractorName}</StyledText> hereinafter referred to
        as “Contractor,” whose mailing address is{" "}
        <StyledText isBold>{contractorAddress}</StyledText>. The Owner and
        Contractor may hereinafter be referred to commonly as the “Parties” or
        each, as a “Party.”
      </StyledText>

      <StyledText>{"     "}The Parties agree as follows:</StyledText>

      {/* ARTICLE 1 */}
      <Article title="ARTICLE 1" subtitle="CONTRACT SUM AND PAYMENTS" />
      <Paragraph number={"1.1"} title={"The Project."}>
        Owner has retained Contractor to construct a residence on Owner's real
        property located at <StyledText isBold>{projectAddress}</StyledText>{" "}
        (the “Project”).
      </Paragraph>
      <Paragraph number={"1.2"} title={"Contract Sum."}>
        Owner shall pay Contractor for the performance of the Work (defined
        herein), subject to additions and deductions by Change Orders (defined
        herein) and the adjustments provided for in this Article 1, if any, the
        contract sum of <StyledText isBold>{totalCost}</StyledText> (the
        “Contract Sum”). The Contract Sum has been established in accordance
        with the Plans/Specifications, which are attached hereto and
        incorporated herein as{" "}
        <StyledText isBold>Composite Exhibit “A,”</StyledText> (the
        “Specifications”). Any additions or deviations from the Specifications,
        even if those additions or deviations exist within the design documents
        or drawings, will result in a Change Order and a corresponding
        modification of the Contract Sum.
      </Paragraph>
      <Paragraph number={"1.3"} title={"Deposit."}>
        At the time Owner signs this Contract, Owner shall place with Contractor
        an earnest money deposit in the amount of{" "}
        <StyledText isBold>{downPayment}</StyledText>. Said deposit shall
        hereinafter collectively be referred to collectively as the “Deposit.”
        THE BUYER OF A ONE-FAMILY OR TWO-FAMILY RESIDENTIAL DWELLING UNIT HAS
        THE RIGHT TO HAVE ALL DEPOSIT FUNDS (UP TO TEN PERCENT (10%) OF THE
        PURCHASE PRICE) DEPOSITED IN AN ESCROW ACCOUNT. THIS RIGHT MAY BE WAIVED
        IN WRITING BY OWNER. By execution of this Contract, Owner hereby waives
        their right to have their deposit placed in an escrow account. Said
        Deposit shall be held by Contractor and disbursed in accordance with the
        terms and conditions of this Contract. Unless Owner otherwise defaults
        under this Contract, the full amount of the Deposit shall be applied to
        the Contract Sum.
      </Paragraph>
      <Paragraph number={"1.4"} title={"Subsequent Draw Payments. "}>
        All payments after the initial Deposit shall be paid to, held, and
        subsequently disbursed to Contractor, sub-contractors, suppliers and
        materialmen by a designated third-party escrow service contracted
        through Proof Payments, Inc.
      </Paragraph>

      {/* ARTICLE 2 */}
      <Article title="ARTICLE 2" subtitle="CONSTRUCTION WORK" />
      <Paragraph number={"2.1"} title={"Construction."}>
        Contractor shall perform all work required on the Project in a manner
        consistent with the Contract Documents (as defined in paragraph 2.2)
        (the “Work”).
      </Paragraph>
      <Paragraph number={"2.2"} title={"Contract Documents."}>
        The Contract Documents, which constitute the entire Contract between
        Owner and Contractor, consist of this Contract, together with the
        Exhibits (including, without limitation, the Specifications), and the
        design documents. As it relates to the Contract Sum, if a conflict
        exists between the Specifications and any other portion of the Contract
        Documents, the Specifications will control.
      </Paragraph>
      <Paragraph number={"2.3"} title={"Contractor's Obligations."}>
        . It shall be the responsibility of Contractor to perform the Work
        substantially in accordance with the Contract Documents. Contractor
        shall complete the work within the time and upon the conditions set
        forth in this Contract and shall do the work and perform the
        requirements to obtain the approval of all applicable governmental
        agencies and boards, as required to complete the Project. Owner
        acknowledges that the Work may contain minor deviations from the
        Contract Documents, and that so long as such deviations comply with the
        intent of the Contract Documents, the Work will be deemed acceptable
        under this Contract.
      </Paragraph>
      <Paragraph number={"2.4"} title={"Permitting and Licensing."}>
        As part of the Contract Sum, Contractor shall secure and pay for all
        permits and licenses necessary for the proper execution and completion
        of the Work for the Project. Contractor, however, shall not be
        responsible to pay fees in the nature of assessments or real estate
        taxes associated with the Project. The foregoing fees shall be the
        responsibility of the Owner, and will either be in addition to the
        Contract Sum (by using the Change Order process) or paid by Owner
        directly out-of-pocket. Contractor shall not be responsible for any
        delay caused by the delay of Owner in paying such fees (i.e., if permit
        issuance is delayed due to Owner's failure to pay impact fees, then
        Contractor's delivery date may correspondingly be delayed at the
        discretion of Contractor).
      </Paragraph>
      <Paragraph number={"2.5"} title={"Intent of Contract Documents."}>
        The intent of the Contract Documents is to include all items necessary
        for the proper execution and completion of the Work in a manner that is
        generally acceptable in the industry. For reference as to what is
        generally accepted in the industry, Contractor will provide Owner with a
        2/10 Bonded Builder Limited Warranty which describes some acceptable
        tolerances. Work not covered in the Contract Documents will not be
        required unless it is consistent therewith and is reasonably inferable
        therefrom as being necessary to produce the intended results. Words and
        abbreviations which have well-known technical, or trade meanings are
        used in the Contract Documents in accordance with such recognized
        meanings. Contractor reserves the right to substitute like kind and
        quality of materials so long as they meet the general intent of the
        Contract Documents.
      </Paragraph>
      <Paragraph number={"2.6"} title={"Change Orders."}>
        While Owner may request any extras, options, or changes to the
        Specifications, Contractor, in its sole discretion, shall have the right
        to determine whether Contractor will accept the request and separately
        contract with Owner to provide any such extras, options or changes. If
        accepted by Contractor, the cost or credit to Owner resulting from a
        Change Order shall be mutually agreed upon between Owner and Contractor
        for the construction costs resulting from such change, but in the event
        that a Change Order is required for an upgrade or effectively causes an
        increase in the Contract Sum, the Parties understand and hereby
        acknowledge that Contactor may (a) include up to a $250 administrative
        fee for each Change Order to defray its overhead, time and cost
        associated with dealing with such change order and any related milestone
        schedule modification(s), if any and (b) charge Owner up to a twenty
        percent (20%) profit margin in addition to the cost of the materials
        (and labor, if any) associated with each such Change Order. For the
        avoidance of any doubt, if the cost of an upgrade to a media room (in
        the form of materials and labor alone) totals an additional $20,000,
        then Contractor may charge Owner up to $24,250 ($20,000 for materials
        and labor, $4,000 for profit, and $250 for admin. fee) for such Change
        Order and the Parties shall execute a written Change Order memorializing
        all fees associated with said Change Order. {"\n\n\t"}The time for
        completion of the Contract shall be equitably adjusted to reflect the
        time necessary to incorporate the Change Order work into the sequence of
        construction. Until such time as the price for the changed work is
        agreed upon and until Contractor receives a commitment for payment of
        the changes from the Owner, the Contractor shall have no obligation to
        commence the changed work. All Change Orders shall be authorized by a
        written Change Order properly executed by Owner and Contractor.
      </Paragraph>

      {/* ARTICLE 3 */}
      <Article title="ARTICLE 3" subtitle="CONTRACT SUM PAYMENTS" />
      <Paragraph number={"3.1"} title={"Payment of Contract Sum."}>
        The Owner shall furnish to Contractor reasonable evidence that Owner has
        made financial arrangements to fulfill the obligations under the
        Contract. Unless such reasonable evidence is furnished, Contractor is
        not required to commence Work on the Project.
      </Paragraph>
      <Paragraph number={"3.2"} title={"Applications for Payment."}>
        Contractor shall submit to Owner and Proof Payments applications for
        payment in accordance with the Phases attached hereto and incorporated
        herein as <StyledText isBold>Exhibit “A”</StyledText> (the
        “Plans/Specifications”). Owner shall undertake all necessary actions to
        facilitate and ensure timely payment to Contractor via payments to Proof
        Payment's designated third-party escrow service. As a condition
        precedent to payment in accordance with the Draw Schedule, Contractor
        shall furnish labor and material lien releases for those lienors who
        have served Notices to Owner. If Owner fails or otherwise refuses to
        approve all or a portion of any payment request of Contractor in
        accordance with the Draw Schedule, then Contractor shall be notified in
        writing within five (5) days following Contractor's submission of
        payment request, stating the reasons for such disapproval and the
        justification therefore, but approval by Owner shall not be unreasonably
        withheld from Contractor. Payment shall be made to Contractor within
        forty-eight (48) hours of Contractor's satisfaction of the circumstances
        giving rise to the disapproval.
      </Paragraph>
      <Paragraph number={"3.3"} title={"Final Payment."}>
        Final payment (“Final Payment”) from Owner is due to Contractor, via
        Proof Payments designated third-party escrow service, in accordance with
        this Section and the Draw Schedule. At the time of Final Payment,
        Contractor shall deliver to Owner a duly executed Waiver and Release of
        Lien Upon Final Payment for the entire Project. In addition, Contractor
        shall deliver similar Waivers and Releases of Lien Upon Final Payment
        duly executed by all subcontractors and suppliers furnishing a Notice to
        Owner that as of the date of final payment have not been satisfied of
        record. At the time of Final Payment, Contractor shall also provide
        Owner with the Final Contractor's Payment Affidavit in accordance with
        Florida's Construction Lien Statute (the “Contractor's Final
        Affidavit”). The Parties understand and agree that Owner shall not be
        allowed to move into the Property until after delivering Final Payment
        to Contractor.
      </Paragraph>
      <Paragraph number={"3.4"} title={"Payment."}>
        Payment by Owner to Proof Payments designated third-party escrow service
        of any portion of the Contract Sum, including the Final Payment, or the
        occupancy of the improvements or any part thereof shall not be construed
        as an acceptance of any work performed by Contractor to the time of such
        payment or occupancy.
      </Paragraph>
      <Paragraph number={"3.5"} title={"Pay When Paid."}>
        Owner acknowledges that Contractor has no obligation to pay its
        subcontractors for performance of the Work until such time as Owner has
        authorized payment to Contractor for the Work performed via the Proof
        Payments system, and such payments are made to Contractor. Owner
        acknowledges that the failure of timely payment to Contractor for the
        Work may result in subcontractors filing liens on the Project that are
        foreclosable.
      </Paragraph>

      {/* ARTICLE 4 */}
      <Article
        title="ARTICLE 4"
        subtitle="COMMENCEMENT AND PERFORMANCE OF WORK"
      />
      <Paragraph number={"4.1"} title={"Commencement."}>
        Contractor shall commence Work on the Project within sixty (60) calendar
        days after receipt of the last of the following: (a) a Building Permit;
        (b) a recorded Notice of Commencement and shall prosecute the work
        diligently and continuously to completion within the Contract time; and
        (c) receipt of this Agreement duly executed by the Parties.
      </Paragraph>
      <Paragraph number={"4.2"} title={"Time."}>
        The Work shall be substantially completed, as defined in paragraph 4.3
        within 24 months after the issuance of the Building Permit. The time for
        completion shall be extended by delays, change orders, if any, and
        occurrence as stated in Paragraphs 18.1. and 18.2 herein.
      </Paragraph>
      <Paragraph number={"4.3"} title={"Completion."}>
        The Work will be considered substantially complete when: (a) a
        certificate of occupancy has been issued by the appropriate governmental
        authority; and (b) Contractor has delivered a Contractor's Final
        Affidavit.
      </Paragraph>
      <Paragraph number={"4.4"} title={"Owner Interference."}>
        The Contractor is responsible for the progress of the Project, and Owner
        acknowledges that there will be times during the Project where the Work
        may be temporarily stopped for various reasons. Owner will not demand
        that Contractor deviate from the Contractor's schedule, and Owner
        acknowledges that demanding that Contractor proceed in a manner
        inconsistent with its schedule could result in an acceleration claim by
        the Contractor or create grounds for the Contractor to terminate this
        Contract for cause. Owner agrees that the direction and supervision of
        all workers on the Project, including subcontractors and suppliers,
        rests exclusively with Contractor. Owner further agrees not to contract
        with Contractor's subcontractors or to engage other builders or
        subcontractors or personally perform any work on the Project prior to
        Contractor's completion of the Work. If the Owner interferes with the
        Contractor's duties or responsibilities such that the Contractor finds
        it difficult to complete the Work, Contractor will notify the Owner in
        writing and provide the Owner an opportunity to cease the interference.
        If the Owner continues to interfere, Contractor may terminate this
        Contract pursuant to paragraph 4.6.
      </Paragraph>
      <Paragraph number={"4.5"} title={"Project Safety."}>
        Owner acknowledges that Contractor is responsible for the safety of the
        Project, and that construction worksites are dangerous to persons not
        trained and equipped to perform the construction. Owner therefore agrees
        that after construction commences, Owner will not enter the Project site
        without Contractor's prior approval and supervision. Owner further
        agrees that Owner will not occupy the Project until Contractor certifies
        that the Project is fit for occupancy. Owner agrees that a violation of
        this provision may create safety hazards to both the Owner and the
        Contractor, and could impact Contractor's ability to insure the
        construction. Owner also agrees that the violation of this provision
        could void the Warranties provided to the Owner under this Contract and
        entitle Contractor to terminate this Contract pursuant to paragraph 4.6.
      </Paragraph>
      <Paragraph number={"4.6"} title={"Termination for Cause Only."}>
        This Contract may not be terminated for convenience. The Parties agree
        as follows:
      </Paragraph>
      <BulletPoint number={"(a)"} title={"Owner's Default:"} />
      <SubBulletPoint number="(i)" title={"Before Substantial Completion."}>
        In the event the Owner defaults in the performance of any of Owner's
        obligations under this Agreement, Contractor shall provide a written
        notice of default to Owner. Owner shall have ten (10) business days from
        the date of receipt of said notice of default to cure the default. In
        the event that Owner fails to timely cure the default (time being of the
        essence of this paragraph), Contractor shall have the right to recover
        from the Owner and retain as Contractor's property: (i) the full amount
        of the Deposit, and (ii) all subsequent draws paid by the Owner to the
        Contractor through the date of termination, and (iii) a Contractor's
        termination fee of $3,000 under this Agreement, all of which shall be
        deemed to be liquidated damages for Owner's default. Upon Owner's
        failure to cure the default, Contractor shall be released from all
        further duties and obligations under this Agreement. Contractor shall
        have all remedies available under Florida law to collect all sums due
        and owing to Contractor under this Agreement, including, without
        limitation, the right to pursue enforcement of a construction lien under
        Ch. 713, Florida Statutes.
      </SubBulletPoint>
      <SubBulletPoint number="(ii)" title={"After Substantial Completion."}>
        In the event that Owner has moved into the Project (without first
        receiving Contractor's prior written approval) and without making the
        Final Payment due to Contractor, Owner shall be conclusively deemed to
        have assumed all responsibility for performing all of the items on the
        punch list, and Contractor shall be released from all of Contractor's
        obligations to complete the punch list items. Contractor shall have all
        remedies available to it under Florida law to collect all sums due and
        owing to Contractor under this Agreement, including, without limitation,
        the right to pursue enforcement of a construction lien under Ch. 713,
        Florida Statutes. Statutes.
      </SubBulletPoint>
      <SubBulletPoint number="(iii)">
        No remedy or election hereunder shall be deemed exclusive but shall,
        wherever possible, be cumulative with all other remedies at law or in
        equity.
      </SubBulletPoint>
      <BulletPoint number={"(b)"} title={"Contractor's Default:"} />
      <SubBulletPoint number="(i)" title={"Before Substantial Completion."}>
        In the event that Contractor defaults in the performance of any of
        Contractor's obligations under this Agreement, Owner shall provide a
        written notice of default to Contractor specifying the default and the
        action demanded to cure the default. Builder shall have thirty (30) days
        from the date of receipt of said notice of default to cure the Default
        or to commence the curing of the default if the default is one that
        cannot be fully cured within such 30-day period; provided, however, that
        Contractor shall not be required to cure the default if Owner has
        withheld any payments due to Contractor under this Agreement at the time
        of Contractor's default. In the event that Contractor fails to timely
        cure a default which occurs before Substantial Completion of the
        Project, or in the event Contractor fails to timely begin the curing of
        the default which occurs before Substantial Completion as described
        herein, Owner shall have the right to recover from Contractor: (a) a
        return of the Deposit if construction has not been commenced (or return
        of a pro-rata portion of the Deposit if Contractor has not performed
        enough of the construction to have fully expended the Deposit on the
        improvements then in place on the Project); or (b) if construction has
        been commenced, Owner may terminate this Agreement and pursue remedies
        available to Owner under Florida law, provided, however, that Owner
        shall not have the right to seek specific performance against
        Contractor. Owner shall have the right to pursue damages for the cost of
        completion of the construction in accordance with Florida law but shall
        not be entitled to recover incidental or consequential damages of any
        kind.
      </SubBulletPoint>
      <SubBulletPoint number="(ii)" title={"After Substantial Completion"}>
        After Substantial Completion of the Work by the Contractor, the default
        provision set forth in Section 4(b)(i) shall automatically terminate.
        After Substantial Completion, the remaining performance obligations of
        Contractor shall be governed solely by the Limited Warranty.
      </SubBulletPoint>

      {/* ARTICLE 5 */}
      <Article
        title="ARTICLE 5"
        subtitle="COMMENCEMENT AND PERFORMANCE OF WORK"
      />
      <Paragraph number={"5.1"} title={"Punch List."}>
        After the Work is substantially complete for a Milestone, Owner and
        Contractor will cooperate to prepare a single unified punch list for
        that Milestone. When the punch list is complete, both Owner and
        Contractor will sign and date the punch list. After both parties sign
        and date the punch list, the Contractor shall have sixty (60) days to
        commence work on the punch list items. However, before the Contractor
        shall have any duty to complete punch list items, it shall be a
        condition precedent that the Owner (or the Owner's lender) must have
        paid Contractor all monies owed under this Contract, including any and
        all monies due and owing for approved Change Orders.
      </Paragraph>
      <Paragraph number={"5.2"} title={"Notice of Construction Defects."}>
        ANY CLAIMS FOR CONSTRUCTION DEFECTS ARE SUBJECT TO THE NOTICE AND CURE
        PROVISIONS OF CHAPTER 558, FLORIDA STATUTES.
      </Paragraph>
      <Paragraph number={"5.3"} title={"Waiver of Consequential Damages. "}>
        The Contractor and Owner waive claims against each other for
        consequential damages arising out of or relating to this Contract. This
        mutual waiver includes:
      </Paragraph>
      <SubBulletPoint number="a.">
        damages incurred by the Owner for rental expenses, for losses of use,
        income, profit, financing, business and reputation, and for loss of
        management or employee productivity or of the services of such persons;
        and
      </SubBulletPoint>
      <SubBulletPoint number="b.">
        damages incurred by the Contractor for principal office expenses
        including the compensation of personnel stationed there, for losses of
        financing, business and reputation, and for loss of profit{" "}
        <StyledText isBold isUnderlined>
          except
        </StyledText>{" "}
        anticipated profit arising directly from the Work.
      </SubBulletPoint>
      <StyledText style={styles.justify}>
        This mutual waiver is applicable, without limitation, to all
        consequential damages due to either party's termination or default in
        accordance with this Contract. Nothing contained in this paragraph shall
        be deemed to preclude an award of liquidated damages, when applicable,
        in accordance with the requirements of the Contract Documents.
      </StyledText>
      <Paragraph number={"5.4"} title={"Mediation."}>
        Claims, disputes, or other matters in controversy arising out of or
        related to this Contract (including the performance of the Work) shall
        be subject to mediation as a condition precedent to binding dispute
        resolution. The parties shall share the mediator's fees and costs
        equally. The mediation shall be held in the county where the Project is
        located, unless another location is mutually agreed upon. Agreements
        reached in mediation shall be enforceable as settlement agreements in
        any court having jurisdiction thereof.
      </Paragraph>
      <Paragraph number={"5.5.1"} title={"Arbitration."}>
        Any Claim subject to, but not resolved by, mediation shall be subject to
        arbitration which, unless the parties mutually agree otherwise, shall be
        administered by the American Arbitration Association in accordance with
        its Construction Industry Arbitration Rules in effect on the date of the
        Agreement. The foregoing agreement to arbitrate and other agreements to
        arbitrate with an additional person or entity duly consented to by
        parties to the Agreement shall be specifically enforceable under
        applicable law in any court having jurisdiction thereof.
      </Paragraph>
      <Paragraph number={"5.5.2"} title={" Arbitration Demand."}>
        A demand for arbitration shall be made in writing, delivered to the
        other party to the Contract, and filed with the person or entity
        administering the arbitration. The party filing a notice of demand for
        arbitration must assert in the demand all Claims then known to that
        party on which arbitration is permitted to be demanded. A demand for
        arbitration shall be made no earlier than concurrently with the filing
        of a request for mediation, but in no event shall it be made after the
        date when the institution of legal or equitable proceedings based on the
        Claim would be barred by the applicable statute of limitations. For
        statute of limitations purposes, receipt of a written demand for
        arbitration by the person or entity administering the arbitration shall
        constitute the institution of legal or equitable proceedings based on
        the Claim.
      </Paragraph>
      <Paragraph number={"5.5.3"} title={"Arbitration Joinder."}>
        Either party, at its sole discretion, may consolidate an arbitration
        conducted under this Contract with any other arbitration to which it is
        a party provided that (1) the arbitration agreement governing the other
        arbitration permits consolidation, (2) the arbitrations to be
        consolidated substantially involve common questions of law or fact, and
        (3) the arbitrations employ materially similar procedural rules and
        methods for selecting arbitrator(s). Either party, at its sole
        discretion, may include by joinder persons or entities substantially
        involved in a common question of law or fact whose presence is required
        if complete relief is to be accorded in arbitration, provided that the
        party sought to be joined consents in writing to such joinder.
      </Paragraph>

      {/* ARTICLE 6 */}
      <Article title="ARTICLE 6" subtitle="INSURANCE - RISK OF LOSS" />
      <Paragraph number={"6.1"} title={"Contractor's Insurance."}>
        Contractor shall purchase and maintain at its expense comprehensive
        public liability insurance in the amount of $1,000,000-$2,000,000 for
        combined single limit bodily injury and property damage, including but
        not limited to construction operation, including deletion of “XCU
        exclusion”, completed operations, products, personal injury and auto
        insurance for vehicles owned, non-owned and hired. In addition,
        Contractor shall purchase and maintain at its expense all workmen's
        compensation insurance required by law and all other insurance required
        by federal, state or local law or by the provisions of the Contract
        Documents. Certificates of Insurance acceptable to Owner with respect to
        each policy required hereunder shall be filed with Owner prior to
        commencement of the work. The Certificates shall contain a provision
        that coverage afforded under the policies will not be cancelled or
        limited until at least fifteen (15) days prior written notice has been
        given to Owner. Owner must be named as additional insured by written
        endorsement on Contractor's comprehensive public liability insurance
        policy. In addition to the above, Contractor shall purchase and maintain
        during the construction period a broad form all risk builder's insurance
        policy (minimum coverage of so-called marine type) at Contractor's
        expense in the full insurable value of the Work not to exceed
        $3,000,000. The proceeds shall be payable to Owner and Contractor as
        their interests may appear, subject to the requirements of any
        applicable mortgage to Lender. Contractor shall have the right to
        negotiate with the insurance carrier for settlement of any claim for
        loss arising under such insurance. Contractor shall furnish to Owner
        prior to commencement of Work a Certificate of Insurance indicating the
        existence of the insurance required under this paragraph.
      </Paragraph>
      <Paragraph number={"6.2"} title={"Risk of Loss."}>
        Owner shall be solely responsible for obtaining any homeowner's
        insurance coverage, or additional Builder's Risk coverage (over and
        above that provided by Contractor herein), on such terms and conditions
        as may be determined by the Owner. The Owner shall bear the sole risk of
        loss of or damages to all materials delivered to the Property, and the
        Contractor is hereby relieved of any and all liability for any such loss
        and damages, except to the extent any such loss or damage is covered by
        an insurance policy owned by the Contractor. Under no circumstances will
        Contractor be liable for any claim covered in any home warranty program
        or product.
      </Paragraph>

      {/* ARTICLE 7 */}
      <Article title="ARTICLE 7" subtitle="WARRANTIES" />
      <Paragraph number={"7.1"} title={"General Warranty."}>
        Contractor hereby warranties all workmanship and materials employed in
        the Work will be of good quality for one (1) year following the date of
        substantial completion. Contractor shall furnish Owner with (a) a
        written warranty agreeing to promptly correct, at Contractor's expense,
        any defects in material or workmanship which arise during the one (1)
        year period, and (b) all other warranties and guarantees furnished by
        material and equipment manufacturers relating to the Project. The
        Contractor's warranty excludes remedy for damage or defect caused by
        abuse, alterations to the Work not executed by the Contractor, improper
        or insufficient maintenance, improper operation or normal wear and tear
        under normal usage.
      </Paragraph>
      <Paragraph number={"7.3"} title={"Disclaimer."}>
        CONTRACTOR HEREBY DISCLAIMS ANY AND ALL WARRANTIES OR GUARANTEES WHETHER
        EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES OR
        GUARANTEES OF FITNESS AND MERCHANTABILITY OR FITNESS FOR A PARTICULAR
        PURPOSE. THE ONLY WARRANTIES ARE THOSE AS STATED IN PARAGRAPHS 7.1 AND
        7.2 (the “Limited Warranty”).
      </Paragraph>

      {/* ARTICLE 8 */}
      <Article
        title="ARTICLE 8"
        subtitle="COMPLIANCE WITH GOVERNMENT REGULATIONS"
      />
      <Paragraph number={"8.1"} title={"Government Regulation."}>
        Contractor shall comply with all laws and regulations of the United
        States Government relating to equal employment opportunity, affirmative
        action and minimum wage rates and shall diligently complete and return
        all forms, reports and statements required of Contractor there under, if
        any. If required by law, Contractor shall incorporate said documents in
        his subcontract agreements and will perform all requirements thereof in
        connection with equal opportunity for minority groups.
      </Paragraph>
      <Paragraph number={"8.2"} title={"Code Compliance."}>
        Contractor shall comply with all state, county and federal rules,
        regulations and codes and shall be responsible to build in accordance
        with same. However, Contractor may rely on Owner's representations that
        any plans prepared by Owner's designers meet all code requirements.
        Should Contractor perform Work in substantial accordance with a design
        provided by Owner, and should the local authorities identify code
        violations in the as-built conditions, the financial responsibility to
        modify the installed conditions to become code compliant rests with the
        Owner and are subject to a change order under paragraph 2.6.
      </Paragraph>

      {/* ARTICLE 9 */}
      <Article title="ARTICLE 9" subtitle="EXISTING VEGETATION" />
      <Paragraph number={"9.1"} title={"Existing Vegetation."}>
        Contractor reserves the right to remove any existing vegetation and/or
        trees on the property. Contractor shall not be responsible for any
        damage to trees and/or vegetation remaining following construction and
        shall not be responsible for any such trees or vegetation that do not
        survive.
      </Paragraph>

      {/* ARTICLE 10 */}
      <Article title="ARTICLE 10" subtitle="INSULATION" />
      <Paragraph number={"10.1"} title={"Insulation."}>
        Owner hereby acknowledges pursuant to the applicable rules of the
        Federal Trade Commission regarding labeling and advertising home
        insulation, that the following types, thicknesses and R-Values of
        insulation shall be installed in the following locations of the
        Residence: Perimeter walls - type AL Foil, and{" "}
        <StyledText isBold isUnderlined>
          R 4.3
        </StyledText>
        ; Flat ceiling - type blown, thickness{" "}
        <StyledText isBold isUnderlined>
          11.25
        </StyledText>
        , and{" "}
        <StyledText isBold isUnderlined>
          R-30
        </StyledText>
        ; Vaulted ceiling - type Batts, thickness 9.25 , and{" "}
        <StyledText isBold isUnderlined>
          R-30
        </StyledText>
        . R-value means the resistance of insulation to heat flow. The higher
        the R-value, the greater the insulating power.
      </Paragraph>

      {/* ARTICLE 11 */}
      <Article title="ARTICLE 11" subtitle="BROKERS" />
      <Paragraph number={"11.1"} title={"Brokers."}>
        The parties hereto represent, warrant and agree that there have been no
        brokers, salesmen or finders engaged for this transaction or entitled to
        a fee or commission for this transaction. Owner shall indemnify and hold
        Contractor harmless against any fees or commissions, liabilities, costs
        or expenses claimed by or as a result of any broker, salesmen or finder
        claiming through or on behalf of Owner. This representation and
        indemnification shall survive the execution of this Contract. Owner
        further acknowledges that Contractor's on-site sales representative is
        acting as an agent for Contractor and has a fiduciary duty to
        Contractor.
      </Paragraph>

      {/* ARTICLE 12 */}
      <Article title="ARTICLE 12" subtitle="RADON GAS DISCLOSURE" />
      <Paragraph number={"12.1"} title={"Radon Gas Disclosure."}>
        Radon is a naturally occurring radioactive gas that, when it has
        accumulated in a building in sufficient quantities, may present health
        risks to persons who are exposed to it over time. Levels or radon gas
        that exceed federal and state guidelines have been found in buildings in
        Florida. Additional information regarding radon and radon testing may be
        obtained from your county health department.
      </Paragraph>

      {/* ARTICLE 13 */}
      <Article
        title="ARTICLE 13"
        subtitle="PUBLICITY AND INTELLECTUAL PROPERTY"
      />
      <Paragraph number={"13.1"} title={"Publicity."}>
        Owner agrees to allow Contractor to place signs on Owner's property
        detailing Contractor's name and contact information for the duration of
        the construction period and until the Project is complete. Owner further
        agrees that Contractor may use photographs, drawings, or pictures of the
        Project, as well as the Project's address and floor plan, in
        Contractor's advertising materials (in hard copy and electronic form)
        without the Owner's further consent.
      </Paragraph>
      <Paragraph number={"13.2"} title={"Intellectual Property."}>
        If the Contractor supplies, purchases, or otherwise pays for the
        provision of plans, blueprints, illustrations or specifications utilized
        in connection with the construction of the Project, then Contractor is
        deemed the owner of the plans, blueprints, illustrations or
        specifications and such documents shall be and remain the sole and
        exclusive property of Contractor.
      </Paragraph>

      {/* ARTICLE 14 */}
      <Article title="ARTICLE 14" subtitle="FINISH MATERIALS" />
      <Paragraph number={"14.1"} title={"Finish Materials."}>
        Owner reserves the right to make the final decision on finish material
        choices including, but not limited to wall and ceiling paint colors,
        tile colors, grout colors, carpet colors and texture, appliance colors,
        countertop colors, cabinet colors, exterior paint colors, and
        landscaping choices. Owner agrees to furnish the finish material choices
        to the Contractor by no later than five (5) business days after
        Contractor requests the Owner's selection. Owner's failure to furnish
        the finish material choices within this time frame will permit the
        Contractor to extend the time of completion for the Project by twice as
        many days as the Owner delays turning in the finish material choices.
      </Paragraph>

      {/* ARTICLE 15 */}
      <Article title="ARTICLE 15" subtitle="RECOVERY FUND" />
      <Paragraph
        number={"15.1"}
        title={"Florida Homeowners' Construction Recovery Fund."}
      >
        OWNER IS HEREBY ADVISED THAT PAYMENT MAY BE MADE FROM THE FLORIDA
        HOMEOWNERS' CONSTRUCTION RECOVERY FUND IF OWNER LOSES MONEY ON A PROJECT
        PERFORMED UNDER CONTRACT, WHERE THE LOSS RESULTS FROM SPECIFIED
        VIOLATIONS OF FLORIDA LAW BY A STATE LICENSED CONTRACTOR. FOR
        INFORMATION ABOUT THE RECOVERY FUND AND FILING A CLAIM, OWNER MAY
        CONTACT THE FLORIDA CONSTRUCTION INDUSTRY LICENSING BOARD AT THE
        FOLLOWING NUMBER AND ADDRESS: CONSTRUCTION INDUSTRY LICENSING BOARD,
        7960 ARLINGTON EXPRESSWAY, ROOM 300, JACKSONVILLE, FLORIDA 32211-7467,
        TEL.: (904) 727-6530.
      </Paragraph>

      {/* ARTICLE 16 */}
      <Article
        title="ARTICLE 16"
        subtitle="OWNER'S ACKNOWLEDGEMENTS/RECEIPT OF ADDENDA/EXHIBITS"
      />
      <Paragraph number={"16.1"} title={"Owner's Acknowledgements"}>
        Owner certifies that he has read each and every part of this Contract
        and addenda, if any, and that this Contract constitutes the entire
        Contract between Owner and Contractor. No agreements, promises,
        representations or warranties, whether oral, written or otherwise, made
        by Contractor, its agents or employees, Owner, or by any broker,
        contractor or any other person, are binding upon the parties hereto
        unless stated in this Contract. {"\n\n"}Owner acknowledges receipt of
        copies of the following Addenda/Exhibits, which are attached hereto and
        incorporated into this Agreement by this reference: {"\n\n\t\t"}Exhibit
        “A” - Plans/Specifications {"\n\n\t\t"}Exhibit “C” - Draw Schedule
      </Paragraph>

      {/* ARTICLE 17 */}
      <Article title="ARTICLE 17" subtitle="ADJACENT PROPERTY" />
      <Paragraph number={"17.1"} title={"Adjacent Property."}>
        Contractor has informed Owner and Owner acknowledges and agrees that
        Contractor has no control over any land which is adjacent to or in the
        vicinity of the property owned by Owner and upon which the residence is
        being built. As such, Contractor makes no representations as to what may
        or may not be built upon said property or for what purpose the property
        may ultimately be used.
      </Paragraph>

      {/* ARTICLE 18 */}
      <Article title="ARTICLE 18" subtitle="TIME OF THE ESSENCE" />
      <Paragraph number={"18.1"} title={"Time."}>
        Time is of the essence of this Contract as to both parties.
      </Paragraph>
      <Paragraph number={"18.2"} title={"Delay."}>
        If Contractor is delayed at any time in the progress of the work by any
        act or neglect of Owner, or by any agent or employee of Owner, or by any
        separate contractor employed by Owner, or by labor disputes, fire,
        unusual delay in transportation, adverse weather conditions not
        reasonably anticipatable, unavoidable casualties, or any causes beyond
        Contractor's control, or by delay authorized by Owner, or by any other
        cause which may justify the delay, including material shortages, then
        the contract time shall be automatically extended for such reasonable
        time. Both Contractor and Owner shall endeavor to minimize any delays
        incurred. Contractor shall be entitled to any claims for consequential,
        direct or indirect monetary damages resulting from any delay, other than
        those excluded under paragraph 5.3 of this Contract.
      </Paragraph>

      {/* ARTICLE 19 */}
      <Article title="ARTICLE 19" subtitle="BINDING EFFECT" />
      <Paragraph number={"19.1"} title={"Binding Effect."}>
        Subject to the restrictions against assignment, this Contract shall be
        binding upon and shall inure to the benefit of the heirs, successors,
        and assigns of the parties.
      </Paragraph>

      {/* ARTICLE 20 */}
      <Article title="ARTICLE 20" subtitle="AUDIT" />
      <Paragraph number={"20.1"} title={"Audit."}>
        This Contract is not subject to an Audit.
      </Paragraph>

      {/* ARTICLE 21 */}
      <Article
        title="ARTICLE 21"
        subtitle="ATTORNEY'S FEES/NON-DISPARAGEMENT"
      />
      <Paragraph number={"21.1"} title={"Attorney's Fees."}>
        In the event of a dispute arising under this Agreement, whether or not a
        lawsuit, arbitration, or other proceeding is filed, the prevailing party
        shall be entitled to recover its reasonable attorneys' fees and costs,
        including attorneys' fees and costs incurred in litigating entitlement
        to attorneys' fees and costs, as well as in determining or quantifying
        the amount of recoverable attorneys' fees and costs. The reasonable
        costs to which the prevailing part is entitled shall include costs that
        are taxable under any applicable statute, rule, or guideline, as well as
        non-taxable costs, including, but not limited to, costs of
        investigation, copying costs, electronic discovery costs, telephone
        charges, mailing and delivery charges, information technology support
        charges, consultant and expert witness fees, travel expenses, court
        reporter fees, and mediator fees, regardless of whether such costs are
        otherwise taxable.
      </Paragraph>

      {/* ARTICLE 22 */}
      <Article title="ARTICLE 22" subtitle="CONFLICT" />
      <Paragraph number={"22.1"} title={"Conflict."}>
        Pursuant to paragraph 2.2 of this Contract, if there is a conflict
        between the construction plan and Specifications, then the
        Specifications (Exhibit A) control. The Owner agrees to review the
        Specifications and construction drawings and plans to determine if the
        Specifications deviate from the plans in a manner that is unacceptable
        to the Owner. If there is a difference between the construction plans
        and the Specifications and the Owner wants the Project constructed in a
        manner different from the Specifications, the Owner will notify the
        Contractor in writing of the difference and the Contractor will adjust
        the Specifications accordingly.
      </Paragraph>

      {/* ARTICLE 23 */}
      <Article title="ARTICLE 23" subtitle="APPLICABLE LAWS" />
      <Paragraph number={"23.1"} title={"Venue."}>
        This Contract shall be deemed entered into in the State of Florida and
        shall be interpreted and governed with the laws of said State. Venue for
        any action instituted by either party shall be in Pinellas County,
        Florida.
      </Paragraph>
      <Paragraph number={"23.2"} title={"Severability."}>
        In the event any portion of this Contract should be held to be invalid
        or unenforceable, such invalidity or unenforceability shall not affect
        in any way the remainder of this Contract, which shall continue in full
        force and effect.
      </Paragraph>
      <Paragraph number={"23.3"} title={"Prohibition Against Assignment."}>
        This Contract shall not be assigned by Owner without prior written
        consent of Contractor.
      </Paragraph>
      <Paragraph number={"23.4"} title={"Counterparts."}>
        This Contract may be signed in any number of counterparts each of which
        shall be deemed an original, but all of which together shall constitute
        a single instrument.
      </Paragraph>

      {/* ARTICLE 24 */}
      <Article title="ARTICLE 24" subtitle="NOTICE" />
      <Paragraph number={"24.1"} title={"Notice."}>
        All notices required by this Contract shall be delivered by certified
        mail to the address listed on the first page of this Contract. Should
        any mailing addresses change, the parties whose address has changed
        shall notify the other party within two (2) business days of the new
        mailing address.
      </Paragraph>

      {/* ARTICLE 25 */}
      <Article title="ARTICLE 25" subtitle="MERGER CLAUSE" />
      <Paragraph number={"25.1"} title={"Merger Clause."}>
        This Contract, along with the Specifications and the construction plans,
        represent the entire integrated agreement between the Owner and the
        Contractor and supersedes all prior negotiations, representations or
        agreements, either written or oral. This Contract may be amended only by
        written instrument signed by both Owner and Contractor. Oral
        representations, regardless of who made them or how they were made,
        cannot be relied upon to alter the Contract.
      </Paragraph>

      {/* ARTICLE 26 */}
      <Article title="ARTICLE 26" subtitle="TITLE TO PROPERTY, ETC." />
      <Paragraph
        number={"26.1"}
        title={
          "Title to Property, Survey, Placement of Improvements and Building Site."
        }
      >
        Owner warrants and represents to Contractor that Owner has fee simple
        marketable title to the Property, free and clear of all liens or
        encumbrances, and Owner shall submit proof of same to Contractor
        immediately upon request. Owner further warrants that Owner has legal
        right of access to the Property and all rights of title, including
        easements necessary for the construction, use and occupancy of the
        structure. It shall be the sole responsibility of Owner to mark the
        boundaries of the Property and the location on such Property where the
        structure is to be constructed. Any loss or damage suffered by
        Contractor or any third party (including any agent, subcontractor or
        consultant of Contractor) as the result of a defect in title or
        incorrect designation of the boundaries or location of the structure
        shall be borne solely by Owner.
      </Paragraph>

      {/* ARTICLE 27 */}
      <Article title="ARTICLE 27" subtitle="INDEMNITY" />
      <Paragraph number={"27.1"} title={"Contractor's Indemnity."}>
        To the fullest extent permitted by law the Contractor shall defend,
        indemnify and hold harmless the Owner and their agents from and against
        claims, damages, losses and expenses, including but not limited to
        attorneys' fees, arising out of or resulting from performance of the
        Work, provided that such claim, damage, loss or expense is attributable
        to bodily injury or death, or to injury to or destruction of tangible
        property (other than the Work itself), but only to the extent caused by
        the negligent acts or omissions of the Contractor, a Subcontractor,
        anyone directly or indirectly employed by them.
      </Paragraph>
      <Paragraph number={"27.2"} title={"Owner's Indemnity."}>
        To the fullest extent permitted by law the Owner shall defend, indemnify
        and hold harmless the Contractor and its agents, subcontractors,
        employees, managers, and members from and against claims, damages,
        losses and expenses, including but not limited to attorneys' fees,
        arising out of or resulting from Owner's negligent acts or omissions of
        Owner or their agents with respect to the Project.
      </Paragraph>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  title: {
    textAlign: "center",
    width: "100%",
    marginBottom: 20,
  },
  justify: {
    textAlign: "justify",
  },
  firstTitle: {
    textAlign: "center",
    marginTop: 20,
  },
  firstSubtitle: {
    textAlign: "center",
    marginBottom: 20,
  },
  marginBottom: {
    marginBottom: 20,
  },
});
