import React from "react";
import { useFuzzy } from "react-use-fuzzy";
import {
  StyledText,
  StyledTextInput,
  StyledTouchableOpacity,
} from "./controls";
import { FlatList, StyleSheet, View } from "react-native";
import { Hoverable } from "react-native-web-hooks";
import { Palette } from "./styles";
import { UserInfo } from "./UserInfo";

export interface SearchItem {
  userId: number | string;
  name?: string;
  email?: string;
}

interface Props {
  searchList: SearchItem[];
  onSelect: (invite: SearchItem) => void;
  variant?: string;
}

export const FuzzySearch: React.FunctionComponent<Props> = ({
  variant,
  searchList,
  onSelect,
}) => {
  const { result, keyword, search } = useFuzzy<SearchItem>(searchList, {
    keys: ["name", "email"],
  });

  const handleSelect = (item: SearchItem) => {
    search("");
    onSelect(item);
  };

  const renderItem = (item: SearchItem, index: number) => {
    return (
      <Hoverable>
        {(isHovered) => (
          <StyledTouchableOpacity
            style={[
              styles.suggestionContainer,
              isHovered && styles.suggestionHover,
            ]}
            onPress={() => handleSelect(item)}
          >
            <View style={styles.itemContainer}>
              {variant === "contractor" ? (
                <UserInfo user={item as any} colorIndex={index} />
              ) : (
                <StyledText>
                  {item.name} {item.email && item.email}
                </StyledText>
              )}
            </View>
          </StyledTouchableOpacity>
        )}
      </Hoverable>
    );
  };

  return (
    <>
      <StyledTextInput
        iconRight={{ name: "search" }}
        placeholder="Search"
        variant="tertiary"
        value={keyword}
        style={styles.input}
        onChangeText={(text) => search(text)}
      />
      {!!keyword && (
        <View style={styles.listContainer}>
          <FlatList
            data={result}
            style={styles.flatList}
            renderItem={({ item, index }) => renderItem(item, index)}
            keyExtractor={(item) => `${item.userId}`}
          />
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  item: {
    color: "red",
  },
  listContainer:{
    width: "100%",
    zIndex: 10000,
  },
  flatList: {
    borderRadius: 5,
    width: "100%",
    position: "absolute",
  },
  input: {
    width: "100%",
  },
  itemContainer: {
    flexDirection: "row",
    width: "100%",
  },
  inviteIcon: {
    marginTop: 6,
    marginRight: 10,
  },
  suggestionContainer: {
    padding: 24,
    backgroundColor: Palette.Primary5Pct,
    borderColor: Palette.Primary10Pct,
    borderWidth: 1,
  },
  suggestionHover: {
    backgroundColor: Palette.White,
  },
});
