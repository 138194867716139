import { Inject } from "@not-the-droids/exco-ts-inject";
import { observer } from "mobx-react";
import { computed, makeObservable } from "mobx";
import React from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";
import { Icon, StyledText } from "../components/controls";
import { Palette } from "./styles";
import { AutoSaveManager } from "../managers/AutoSaveManager";
import { withInjectedProps } from "../InjectorContext";

interface Props {
  autoSaveManager: AutoSaveManager;
}

@observer
class SaveIndicator extends React.Component<Props> {
  static inject: Inject<React.ReactElement> = (injector) => {
    return () => (
      <SaveIndicator
        autoSaveManager={injector.get(AutoSaveManager)()}
      />
    );
  };

  @computed public get saveString(): string {
    const { autoSaveManager: { isSaving, timeSaved } } = this.props;
    return isSaving ? (
      "Saving..."
    ) : (
      `Saved at ${timeSaved.toLocaleTimeString()}`
    )
  };

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  };

  render() {
    return (
      <View style={styles.saveIndicator}>
        {this.props.autoSaveManager.isSaving ? (
          <ActivityIndicator size="small" color={Palette.Primary100Pct} />
        ) : (
          <Icon name="check" />
        )}
        <StyledText>{this.saveString}</StyledText>
      </View>
    );
  };
}

const styles = StyleSheet.create({
  saveIndicator: {
    flexDirection: "row",
    alignItems: "center",
    gap: 7,
  },
});

export const InjectedSaveIndicator = withInjectedProps(SaveIndicator);
