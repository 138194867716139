import React, { ReactElement } from "react";
import {
  StyleSheet,
  View,
} from "react-native";
import { Comment, User } from "../../../data-model";
import {
  StyledText,
} from "./controls";
import { Palette } from "./styles";
import { UserThumbnailPhoto } from "./UserThumbnailPhoto";

interface Props {
  comment: Comment;
  isNested?: boolean;
  category?: string;
  currentUser?: User;
  alternateBody?: ReactElement;
  alternateTime?: ReactElement;
}

export const ProjectWidgetComment: React.FunctionComponent<Props> = (props) => {
  const { alternateBody, alternateTime, comment, category, isNested } = props;
  return (
    <View style={[styles.container, isNested && styles.nestedContainer]}>
      <View style={styles.commentHeader}>
        <View style={styles.row}>
          <UserThumbnailPhoto name={comment.creatorName} photoURL={comment.creatorPhotoUrl} />
          <View style={styles.commentHeaderText}>
            <StyledText variant="body2" isBold={true}>{comment.creatorName}</StyledText>
            <StyledText variant="body2" colorMode={category ? "accent" : "gray"}>{category || "General"}</StyledText>
          </View>
        </View>
        {!!alternateTime ? (
          alternateTime
        ) : (
          <StyledText variant="body2">{(new Date(comment.createdAt)).toDateString()}</StyledText>
        )}
      </View>
      <View style={styles.commentBody}>
        {!!alternateBody ? (
          alternateBody
        ) : (
          <StyledText variant="body2">{comment.text}</StyledText>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 24,
  },
  nestedContainer: {
    marginTop: -24,
    paddingTop: 24,
    paddingLeft: 20,
    borderLeftWidth: 1,
    borderLeftColor: Palette.Primary50Pct,
  },
  row: {
    flexDirection: "row",
  },
  commentHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  commentHeaderText: {
    marginLeft: 8,
    justifyContent: "center",
  },
  commentBody: {
    marginTop: 8,
  },
});