import React from "react";
import { StyleSheet, View } from "react-native";
import { computed, makeObservable, observable, toJS } from "mobx";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";
import { Inject } from "@not-the-droids/exco-ts-inject";
import { BidModel } from "../../../data-model/BidModel";
import {
  Project,
  ProjectModel,
  UserModel,
  WorkCategoryAreas,
} from "../../../data-model";
import { formatCurrencyToString, sanitizePriceInput } from "../utils/Numbers";
import { CommentBubble } from "./CommentBubble";
import {
  Icon,
  StyledButton,
  StyledText,
  StyledTextArea,
  StyledTextInput,
  StyledTouchableOpacity,
} from "./controls";
import {
  BidInput,
  BidLineInput,
  mapBidInputToBid,
  mapBidToBidInput,
  ProjectDetailsFlow,
} from "../flows/ProjectDetailsFlow";
import { ProjectWidgetManager } from "../managers/ProjectWidgetManager";
import { Palette } from "./styles";
import { withInjectedFactory } from "../InjectorContext";
import { UserViewModel } from "../viewModels/UserViewModel";
import { noop } from "../utils/Hooks";
import { uploadFromBlobAsync } from "../utils/Storage";
import { FileDropzone } from "./controls/FileDropzone";
import { StyledModal } from "./Modal";
import { FirebaseFile } from "../../../data-model/File";
import { FuzzySearchModular } from "./FuzzySearchModular";
import { modifySearchResult } from "../utils/Search";
import { categoryDefinitions } from "../constants/CategoryDefinitions";
import { InjectedYoutubeTooltip } from "./YoutubeTooltip/YoutubeTooltip";


type BidEditableColumnProperty = "scope" | "cost" | "category";

interface Props {
  projectWidgetManager: ProjectWidgetManager;
  projectDetailsFlow: ProjectDetailsFlow;
  bidModel: BidModel;
  projectModel: ProjectModel;
  userModel: UserModel;
  userViewModel: UserViewModel;
}

interface CreateProps {
  changeBidView: () => void;
  projectId: string;
}

export class ProjectBidEditViewFactory {
  static inject: Inject<ProjectBidEditViewFactory> = (injector) => {
    return () =>
      new ProjectBidEditViewFactory({
        projectWidgetManager: injector.get(ProjectWidgetManager)(),
        projectDetailsFlow: injector.get(ProjectDetailsFlow)(),
        bidModel: injector.get(BidModel)(),
        projectModel: injector.get(ProjectModel)(),
        userModel: injector.get(UserModel)(),
        userViewModel: injector.get(UserViewModel)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ProjectBidEditView {...this.props} {...props} />;
  }
}

@observer
class ProjectBidEditView extends React.Component<Props & CreateProps> {
  @observable private _showPredeterminedPricingModal = false;
  private clearInterval: Function = noop;

  constructor(props: Props & CreateProps) {
    super(props);
    makeObservable(this);
  };

  @computed get disclosureFiles(): any[] {
    return this.props.projectDetailsFlow.bid?.files || [];
  };

  @computed get userType(): "contractor" | "owner" {
    return this.props.userViewModel.isContractor ? "contractor" : "owner";
  };

  @computed get isBidApproved(): boolean {
    return !!this.props.projectDetailsFlow.bid?.approved;
  };

  readonly componentDidMount = async () => {
    const {
      projectId,
      projectDetailsFlow,
      projectWidgetManager
    } = this.props;

    await this.loadBids();

    if (!!projectId) {
      await projectWidgetManager.loadComments(
        projectId,
        "bid",
        projectDetailsFlow.bid?.id
      );
    }
  };

  readonly loadBids = async () => {
    const { projectDetailsFlow, projectId, projectModel, bidModel } =
      this.props;
    const project: Project = await projectModel.getProjectById(projectId);
    const bidResult = await bidModel.getBidByProjectId(projectId).catch(noop);
    const bid = bidResult ?? (await bidModel.createBid({ projectId }));
    const bidInputMapped = mapBidToBidInput(bid);
    const bidInput: BidInput = {
      ...bid,
      lines: bidInputMapped.lines,
    };
    const workCategories = [...project.workCategories];

    if (!bidInput.completed && bidInput.lines.length < 1) {
      // const workCategories = [...project.workCategories];
      workCategories
        .sort(this.sortWorkOrderIndex)
        .forEach((workCategory: WorkCategoryAreas, index: number) => {
          bidInput.lines.push({
            id: uuidv4(),
            bidId: bid.id,
            orderIndex: index,
            category: workCategory.workCategory,
            scopeOfWork: workCategory.workAreas.join(", "),
            cost: "",
            workAreas: workCategory.workAreas,
          });
        });
    } else {
      bidInputMapped.lines.sort(this.sortLineOrderIndex).forEach((line) => {
        const foundCategory = workCategories.find(
          (workCategory) => workCategory.workCategory === line.category
        );
        if (foundCategory) {
          line.workAreas = foundCategory.workAreas;
        }
      });
    }

    projectDetailsFlow.bid = bid;
    projectDetailsFlow.bidInput = bidInput;
  };

  readonly sortLineOrderIndex = (a: BidLineInput, b: BidLineInput) => {
    if (a.orderIndex < b.orderIndex) {
      return -1;
    }
    if (a.orderIndex > b.orderIndex) {
      return 1;
    }
    return 0;
  };

  readonly sortWorkOrderIndex = (a: WorkCategoryAreas, b: WorkCategoryAreas) => {
    if (a.workCategory < b.workCategory) {
      return -1;
    }
    if (a.workCategory > b.workCategory) {
      return 1;
    }
    return 0;
  };

  readonly handleCommentBubblePress = (
    bubbleIndex: number,
    tagName: string,
    parentId: string,
    itemId: string
  ) => {
    const { projectWidgetManager } = this.props;
    if (projectWidgetManager.activeCommentTag !== tagName) {
      projectWidgetManager.openChatWidget(parentId, tagName);
      this.props.projectWidgetManager.activeCommentIndex = bubbleIndex;
    } else {
      projectWidgetManager.closeWidget();
    }
  };

  readonly resetProjectWidget = () => {
    const { projectWidgetManager } = this.props;
    projectWidgetManager.isOpen = false;
    projectWidgetManager.setActiveCommentInfo();
  };

  readonly handleDisclosureFileDrop = async (
    files: File[],
    onUploadFinishedCallback: () => void
  ) => {
    const { bidModel, projectDetailsFlow } = this.props;
    if (!projectDetailsFlow.bid) return;

    const newFiles: any[] = [];
    const bidId = projectDetailsFlow.bid.id;

    const uploadFile = async (file: File) => {
      try {
        const url = await uploadFromBlobAsync({
          blobUrl: URL.createObjectURL(file),
          name: `/bid-files/${file.name}_${Date.now()}`,
        });

        const newFile = {
          id: uuidv4(),
          fileName: file.name,
          type: "file",
          url: url,
        };

        await bidModel.addFile(bidId, newFile);
        newFiles.push(newFile);
      } catch (e) {
        console.error(e);
      }
    };

    await Promise.all(files.map((file) => uploadFile(file)));

    if (!projectDetailsFlow.bid?.files)
      projectDetailsFlow.bid.files = [...newFiles];
    else projectDetailsFlow.bid.files.push(...newFiles);
    onUploadFinishedCallback();
  };

  readonly handleRemoveDisclosureFile = (file: FirebaseFile) => {
    const { bidModel, projectDetailsFlow } = this.props;
    if (!projectDetailsFlow.bid?.files) return;
    const newFiles = projectDetailsFlow.bid.files.filter(
      (f) => f.fileId !== file.fileId
    );
    projectDetailsFlow.bid!.files = newFiles;
    bidModel.deleteFile(projectDetailsFlow.bid!.id, file.fileId);
  };

  readonly handleChangeCellText = (
    id: number,
    value: string,
    type: BidEditableColumnProperty,
    mutableBidInput: BidLineInput[]
  ) => {
    if (type === "cost") {
      mutableBidInput[id].cost = sanitizePriceInput(value);
    } else if (type === "scope") {
      mutableBidInput[id].scopeOfWork = value;
    } else if (type === "category") {
      mutableBidInput[id].category = value;
    }
  };

  readonly handleRowDelete = (
    index: number,
    mutableBidInput: BidLineInput[],
    newlyAddedLine: boolean
  ) => {
    const { projectDetailsFlow } = this.props;
    if (!newlyAddedLine) {
      projectDetailsFlow.bidLinesDeleted.push(mutableBidInput[index]);
    }
    mutableBidInput.splice(index, 1);
  };

  readonly renderInputRows = (
    row: BidLineInput[],
    newlyAddedLines: boolean
  ) => {
    return row?.map((item: BidLineInput, index) => {
      return (
        <View 
          key={index} 
          style={[
            styles.row,
            styles.inputRow,
            styles.alignItems,
            styles.upperZ,
          ]}
        >
          <View
            style={[
              styles.row,
              styles.categoryCol,
              styles.justifyContent,
            ]}
          >
            <StyledTouchableOpacity
              onPress={() => {
                this.handleRowDelete(index, row, newlyAddedLines);
              }}
              style={[styles.deleteIcon, styles.justifyContent]}
            >
              <Icon name="x" size={14} />
            </StyledTouchableOpacity>
            {newlyAddedLines ? (
              <FuzzySearchModular
                value={item.category}
                inputStyle={styles.categoryColSearch}
                flatListStyle={styles.categoryColFlatList}
                modifyResult={modifySearchResult("category")}
                onSelect={(topic) => this.addStaticCategory(topic.category)}
                onChangeText={(value) =>
                  this.handleChangeCellText(index, value, "category", row)
                }
                searchList={categoryDefinitions} 
                searchKeys={["category"]}
                iconLeft={{ name: "search", type: "primary" }}
                iconRight={{
                  name: "chevron-down",
                  nameClosed: "chevron-left",
                  type: "primary"
                }}
              />
            ) : (
              <StyledText style={styles.categoryColSearch}>{item.category}</StyledText>
            )}
          </View>
          <View style={[styles.column, styles.scopeCol, styles.justifyContent]}>
            <StyledTextArea
              placeholder={`${item.category} scope...`}
              value={item.scopeOfWork}
              onChangeText={(value) =>
                this.handleChangeCellText(index, value, "scope", row)
              }
            />
          </View>
          <View style={[styles.column, styles.columnLastChild, styles.costCol]}>
            <StyledTextInput
              placeholder="$00.00"
              keyboardType="numeric"
              value={`${item.cost}`}
              onChangeText={(value) =>
                this.handleChangeCellText(index, value, "cost", row)
              }
            />
          </View>
          {!newlyAddedLines ? (
            <CommentBubble
              isAlert={this.props.projectWidgetManager.determineTaggedCommentsUnread(
                this.props.userViewModel.currentUser!.id,
                item.category
              )}
              isFilled={
                this.props.projectWidgetManager.activeCommentIndex === index
              }
              onPress={() =>
                this.handleCommentBubblePress(
                  index,
                  item.category,
                  item.bidId,
                  item.id!
                )
              }
              style={styles.commentBubble}
            />
          ) : (
            <View style={styles.noCommentBubble} />
          )}
        </View>
      );
    });
  };

  readonly addNewCategory = async () => {
    const { projectDetailsFlow } = this.props;
    const addedLineAmount = projectDetailsFlow.bidLinesAdded.length;
    const existingLineAmount = projectDetailsFlow.bid?.lines.length ?? 0;
    const deletedLineAmount = projectDetailsFlow.bidLinesDeleted.length;
    let newBidInput: BidLineInput = {
      bidId: projectDetailsFlow.bid?.id as string,
      scopeOfWork: "",
      category: "",
      cost: "",
      orderIndex: existingLineAmount + addedLineAmount - deletedLineAmount,
    };
    projectDetailsFlow.bidLinesAdded.push(newBidInput);
  };

  readonly addStaticCategory = async (category: string) => {
    const { projectDetailsFlow: { bid, bidInput, bidLinesAdded, bidLinesDeleted } } = this.props;
    const addedLineAmount = bidLinesAdded.length;
    const existingLineAmount = bid?.lines.length ?? 0;
    const deletedLineAmount = bidLinesDeleted.length;
    let newBidInput: BidLineInput = {
      bidId: bid?.id as string,
      scopeOfWork: "",
      category: category,
      cost: "",
      orderIndex: existingLineAmount + addedLineAmount - deletedLineAmount,
    };
    bidInput?.lines.push(newBidInput);
    bidLinesAdded.splice(bidLinesAdded.length - 1);
  };

  readonly applyPredetermingPricing = async () => {
    const { projectDetailsFlow, userModel } = this.props;
    this._showPredeterminedPricingModal = false;
    const categoryPrices = await userModel.getCategoryPrices();
    projectDetailsFlow.bidInput?.lines.forEach((line) => {
      const foundPrice = categoryPrices.find(
        (categoryPrice) => categoryPrice.category === line.category
      );
      if (foundPrice) {
        line.scopeOfWork = foundPrice.scope
          ? `${foundPrice.scope} (${line.workAreas?.join(", ")})`
          : line.scopeOfWork;
        line.cost = foundPrice.budget || line.cost;
      }
    });
  };

  readonly onDisclosureChangeText = (value: string) => {
    const { projectDetailsFlow } = this.props;
    if (projectDetailsFlow.bidInput) {
      projectDetailsFlow.bidInput.disclosure = value;
    }
  };

  readonly handleEstimateSave = async () => {
    const { bidModel, projectDetailsFlow } = this.props;
    if (projectDetailsFlow.bidInput === undefined) {
      return;
    }

    let added = toJS(projectDetailsFlow.bidLinesAdded);

    // check if we have new input
    if (added.length > 0) {
      // TODO: this is a simple fix to prevent empty category fields from entering into the database.
      // Ideally move this concept to an existing field inside the BidInput that renders an error on a
      // conditional flag which is tied to input validation.
      added = added.filter((item: BidLineInput) => item.category.length > 0);
      projectDetailsFlow.bidLinesAdded = [];
    }

    // deleted lines...
    if (projectDetailsFlow.bidLinesDeleted.length > 0) {
      await bidModel.deleteBidLines({
        bidLines: projectDetailsFlow.bidLinesDeleted as any
      });
      projectDetailsFlow.bidLinesDeleted = [];
    }

    // do update of bid.
    projectDetailsFlow.bidInput.lines = [
      ...projectDetailsFlow.bidInput.lines,
      ...added,
    ].sort(this.sortLineOrderIndex);

    await bidModel.updateBid(mapBidInputToBid(projectDetailsFlow.bidInput));

    // query on update.
    const updatedBid = await bidModel.getBidById(
      projectDetailsFlow.bidInput.id
    );

    projectDetailsFlow.bid = updatedBid;
    projectDetailsFlow.bidInput = mapBidToBidInput(updatedBid);
  };

  readonly handleEstimateSubmit = async () => {
    try {
      await this.handleEstimateSave();
      this.props.changeBidView();
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { projectDetailsFlow } = this.props;
    return (
      <View>
        <StyledModal
          visible={this._showPredeterminedPricingModal}
          onClose={() => (this._showPredeterminedPricingModal = false)}
          title={"Are you sure?"}
          text={
            "This will replace the scope, tasks, and costs for all existing milestones, are you sure?"
          }
          confirmButtonText={"Apply"}
          closeButtonText={"Cancel"}
          onConfirm={this.applyPredetermingPricing}
        />
        <View style={[styles.sectionLarge, styles.upperZ]}>
          <View style={styles.section}>
            <StyledText variant="heading3" isBold={true}>
              Estimate
            </StyledText>
            <View style={[styles.row, styles.spaceBetween, styles.gap]}>
              <StyledText>
                Build out your back of the napkin estimate for the customer to 
                review, but know this is designed to get a feasibility study 
                with the clients prior to deep diving into creating a full 
                budget and contract. Protect your time.
              </StyledText>
              {/* TODO: add prederminded pricing back in when we know how it works.  */}
              <StyledButton
                variant="secondary"
                style={{ right: 30, width: 260, height: 34 }}
                text="Apply predetermined pricing"
                onPress={() => (this._showPredeterminedPricingModal = true)}
                />
            </View>
            <InjectedYoutubeTooltip
              videoId={"f0c56cXpWzc"}
              text={"Estimate Tool Tutorial Video"}
              userType="contractor"
              style={styles.tooltip}
            />
          </View>
          <View style={styles.row}>
            <StyledText
              variant="body2"
              isBold={true}
              style={[styles.columnTitle, styles.categoryCol]}
            >
              Category
            </StyledText>
            <StyledText
              variant="body2"
              isBold={true}
              style={[styles.columnTitle, styles.scopeCol]}
            >
              Scope of Work
            </StyledText>
            <StyledText
              variant="body2"
              isBold={true}
              style={[styles.columnTitle, styles.costCol, {marginRight: 34}]}
            >
              Cost
            </StyledText>
          </View>
          {projectDetailsFlow.bidInput &&
            this.renderInputRows(projectDetailsFlow.bidInput.lines, false)}
          {this.renderInputRows(projectDetailsFlow.bidLinesAdded, true)}
          <StyledButton
            alignSelf
            variant="secondary"
            text="Add Categories"
            onPress={this.addNewCategory}
          />
        </View>
        <View style={[styles.sectionLarge, styles.lowerZ]}>
          <View
            style={[
              styles.row,
              styles.spaceBetween,
              styles.divider,
              styles.section,
            ]}
          >
            <StyledText style={styles.projectTotal}>Projected Total</StyledText>
            <StyledText variant="heading2">
              {formatCurrencyToString(projectDetailsFlow.projectedTotal)}
            </StyledText>
          </View>
          <StyledText
            variant="heading3"
            isBold={true}
            style={styles.sectionHeader}
          >
            Disclosures
          </StyledText>
          <StyledText style={styles.sectionBody}>
            Write in disclosures here, or upload a PDF file containing
            disclosures for this estimate.
          </StyledText>

          <View style={styles.sectionSmall}>
            <StyledTextArea
              value={projectDetailsFlow.bidInput?.disclosure}
              onChangeText={(value) => {
                this.onDisclosureChangeText(value);
              }}
              minHeight={100}
            />
          </View>
          <FileDropzone
            files={this.disclosureFiles}
            onDrop={this.handleDisclosureFileDrop}
            onRemove={this.handleRemoveDisclosureFile}
            editable={this.userType === "contractor" && !this.isBidApproved}
          />

          {/* TODO: insert file drop interface here - EY */}
          {/* TODO: Add fucntionalities below - EY */}
          {/* <View>
            <FileUploadItem onCancelPress={() => {}}/>
            <FileUploadItem onCancelPress={() => {}}/>
            <FileUploadItem onCancelPress={() => {}} isLastChild={true}/>
          </View> */}
        </View>
        <View style={styles.submitButton}>
          <StyledButton
            onPress={this.handleEstimateSave}
            text={"Save"}
            alignSelf
          />
          <StyledButton 
            alignSelf
            text="Save and Preview"
            onPress={this.handleEstimateSubmit}
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  gap: {
    flexDirection: 'row',
    gap: 50,
  },
  column: {
    marginRight: 24,
  },
  columnLastChild: {
    marginRight: 0,
  },
  section: {
    marginBottom: 32,
  },
  sectionLarge: {
    marginBottom: 64,
  },
  sectionSmall: {
    marginBottom: 24,
  },
  upperZ: {
    position: "relative",
    zIndex: 2,
  },
  lowerZ: {
    position: "relative",
    zIndex: 1,
  },
  columnTitle: {
    marginBottom: 8,
    textTransform: "uppercase",
  },
  sectionHeader: {
    marginBottom: 4,
  },
  sectionBody: {
    marginBottom: 8,
  },
  tooltip: {
    marginTop: 16,
  },
  categoryTitle: {
    marginTop: 6,
  },
  inputRow: {
    marginBottom: 16,
  },
  justifyContent: {
    justifyContent: "center",
  },
  alignItems: {
    alignItems: "center",
  },
  deleteIcon: {
    marginRight: 12,
    marginTop: 2,
  },
  categoryColSearch: {
    width: 206,
  },
  categoryColFlatList: {
    width: 206,
  },
  categoryCol: {
    width: 230,
    marginRight: 24,
  },
  scopeCol: {
    flex: 2,
    marginRight: 24,
  },
  costCol: {
    width: 100,
    marginRight: 0,
  },
  projectTotal: {
    fontSize: 32,
    lineHeight: 40,
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: Palette.Primary25Pct,
    marginTop: 16,
  },
  commentBubble: {
    marginLeft: 18,
  },
  noCommentBubble: {
    marginRight: 34,
  },
  submitButton: {
    flexDirection: "row",
    alignItems: "center",
    gap: 24,
  },
});

export const InjectedProjectBidEditView = withInjectedFactory(
  ProjectBidEditViewFactory
);
