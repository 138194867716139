import React, { FunctionComponent } from "react";
import { StyleSheet, View } from "react-native";
import { Card, CircleIcon, StyledText } from "./controls";

interface Props {
  onBackPress: () => void;
  subtitle: string;
  title: string;
  userType?: string;
  backButtonHidden?: boolean;
  footer?: React.ReactNode;
  cardPadding?: number;
}

export const SummaryLayout: FunctionComponent<Props> = ({
  onBackPress,
  backButtonHidden,
  title,
  subtitle,
  children,
  userType,
  footer,
  cardPadding = 32,
}) => {
  return (
    <View style={styles.container}>
      <View style={[styles.header, styles.row]}>
        {!backButtonHidden && userType !== "owner" && (
          <CircleIcon
            icon="chevron-left"
            iconSize={24}
            size={48}
            backgroundColor="accent"
            onPress={onBackPress}
            style={styles.backButton}
          />
        )}
        <View style={styles.fullWidth}>
          <StyledText variant="heading3" isBold={true} style={styles.title}>
            {title}
          </StyledText>
          <StyledText style={styles.fullWidth}>{subtitle}</StyledText>
        </View>
      </View>
      <Card style={{ padding: cardPadding }}>{children}</Card>
      {footer}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: 800,
    marginLeft: 80,
    alignSelf: "center",
    gap: 32,
  },
  row: {
    flexDirection: "row",
  },
  header: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  backButton: {
    position: "absolute",
    left: -70,
  },
  title: {
    marginBottom: 10,
  },
  fullWidth: {
    width: "100%",
  },
});
