import React, { useEffect, useMemo, useState } from "react";
import {
  ActivityIndicator,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
  Image,
} from "react-native";
import { Icon } from "./Icon";
import { Palette } from "../styles";
import Dropzone, { DropEvent, FileRejection } from "react-dropzone";
import { StyledText } from "./StyledText";
import { FirebaseFile } from "../../../../data-model/File";

const ACCEPTED_FILES = [".jpg", ".jpeg", ".png"];
const DEFAULT_URI = process.env.PUBLIC_URL + "/projectPlaceholderPhoto.png";

type FileDropEvent = (
  acceptedFiles: File[],
  fileRejections: FileRejection[],
  event: DropEvent
) => void;

type ImageDropzoneProps = {
  file: FirebaseFile | undefined;
  onDrop: (file: File, onUploadFinishedCallback: () => void) => void;
  alwaysShowPlaceholder?: boolean;
  showEditIcon?: boolean;
  editable?: boolean;
  style?: StyleProp<ViewStyle>;
};

export const ImageDropzone: React.FunctionComponent<ImageDropzoneProps> = ({
  onDrop,
  file,
  alwaysShowPlaceholder = false,
  showEditIcon = false,
  editable = true,
  style,
}) => {
  const [uploading, setUploading] = useState(false);
  const [dropzoneWidth, setDropzoneWidth] = useState(0);
  const [dropzoneHeight, setDropzoneHeight] = useState<number | undefined>(
    undefined
  );

  const source = useMemo(() => ({ uri: file?.fileUrl || DEFAULT_URI }), [file]);

  // In order to show the entire image we need to provide
  // height to the Image component.
  useEffect(() => {
    Image.getSize(source.uri, (width, height) => {
      const aspectRatio = width / height;
      setDropzoneHeight(dropzoneWidth / aspectRatio);
    });
  }, [dropzoneWidth, source]);

  const handleFileDrop: FileDropEvent = (acceptedFiles: File[]) => {
    if (uploading) return;
    setUploading(true);
    onDrop(acceptedFiles[0], onUploadFinished);
  };

  const onUploadFinished = () => {
    setUploading(false);
  };

  const styleProp = StyleSheet.flatten(style);
  const height = styleProp?.maxHeight || styleProp?.height || dropzoneHeight;

  return (
    <View
      style={[
        editable ? styles.editableDropzone : styles.dropzone,
        style,
        { height },
      ]}
      onLayout={(e) => setDropzoneWidth(e.nativeEvent.layout.width)}
    >
      {editable && !file && !uploading && (
        <View style={styles.dropIC}>
          <View style={styles.iconContainer}>
            <Icon style={styles.uploadIcon} name={"upload"} size={24} />
          </View>
          <StyledText>Drop files here or select to upload</StyledText>
        </View>
      )}
      {(!editable || alwaysShowPlaceholder || file) && (
        <Image
          style={{ width: "100%", flex: 1, borderRadius: 5 }}
          resizeMode={"cover"}
          source={source}
          defaultSource={{
            uri: DEFAULT_URI,
          }}
        />
      )}
      {uploading && (
        <View style={styles.loadingContainer}>
          <ActivityIndicator color={Palette.Secondary100Pct} size={"large"} />
        </View>
      )}
      {editable && (
        <Dropzone onDrop={handleFileDrop} accept={ACCEPTED_FILES}>
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
              }}
            >
              <input {...getInputProps()} />
            </div>
          )}
        </Dropzone>
      )}
      {showEditIcon && (
        <Dropzone onDrop={handleFileDrop} accept={ACCEPTED_FILES}>
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              style={{
                position: "absolute",
                height: 25,
                width: 25,
                top: 0,
                right: 0,
              }}
            >
              <input {...getInputProps()} />
              <Icon name={"edit"} size={24} type={"gray"} />
            </div>
          )}
        </Dropzone>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  loadingContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  dropzone: {
    display: "flex",
    minHeight: 200,
    maxWidth: 600,
    height: "auto",
  },
  editableDropzone: {
    display: "flex",
    borderColor: "#a9aaa9",
    borderStyle: "dashed",
    borderRadius: 3,
    borderWidth: 2,
    minHeight: 200,
    maxWidth: 600,
    height: "auto",
    padding: 4,
  },
  dropIC: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  uploadIcon: {
    alignSelf: "center",
    top: 13,
  },
  iconContainer: {
    backgroundColor: Palette.Primary10Pct,
    width: 50,
    height: 50,
    borderRadius: 25,
    marginBottom: 15,
  },
});
