import React from "react";
import { StyleSheet, View } from "react-native";
import { computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { Icon, StyledButton, StyledText, StyledTouchableOpacity } from "./controls";
import { Palette } from "./styles";
import { BudgetMilestone, BudgetPhase, ChangeRequest, ChangeRequestType, MilestoneCompletionType } from "../../../data-model";
import { formatCurrencyToString } from "../utils/Numbers";
import { mapMilestoneToManagementMilestone, ProjectDetailsFlow } from "../flows/ProjectDetailsFlow";
import { Inject } from "@not-the-droids/exco-ts-inject";
import { withInjectedFactory } from "../InjectorContext";
import { ProjectWidgetManager } from "../managers/ProjectWidgetManager";
import { Tag } from "./controls/Tag";
import { formatCompletionTagProps } from "../utils/Strings";
import { UserViewModel } from "../viewModels/UserViewModel";
import { MilestoneManagementProps, MyContext } from "./ProjectMilestonesViewControl";
import { formatPaymentPhaseDateTextAndTag } from "../utils/Budget";


interface CreateProps {
  milestones: Array<BudgetMilestone>;
  onAddMilestonePress: (changeRequestType: ChangeRequestType, selectedPhase: BudgetPhase, selectedMilestone?: BudgetMilestone) => void;
  phase: BudgetPhase;
}

interface Props {
  projectDetailsFlow: ProjectDetailsFlow;
  projectWidgetManager: ProjectWidgetManager;
  userViewModel: UserViewModel;
}

export class ManagementPhaseItemFactory {
  static inject: Inject<ManagementPhaseItemFactory> = (injector) => {
    return () =>
      new ManagementPhaseItemFactory({
        projectDetailsFlow: injector.get(ProjectDetailsFlow)(),
        projectWidgetManager: injector.get(ProjectWidgetManager)(),
        userViewModel: injector.get(UserViewModel)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return (
      <MyContext.Consumer>
        {consumer => (
          <ManagementPhaseItem {...this.props} {...props} {...consumer}/>
        )}
      </MyContext.Consumer>
    );
  }
}

@observer
export class ManagementPhaseItem extends React.Component<CreateProps & Props & MilestoneManagementProps> {
  constructor(props: CreateProps & Props  & MilestoneManagementProps) {
    super(props);
    makeObservable(this);
  }

  @observable private isOpen: boolean = this.props.projectDetailsFlow.selectedPhase?.id === this.props.phase.id;

  @computed private get addChangeRequest() {
    const { phase, projectDetailsFlow: { changeRequests } } = this.props;
    const addChangeRequests = changeRequests.filter(
      (cr) => !cr.milestoneId && cr.currentStatus === "submitted"
    );
    return addChangeRequests.find((cr) => {
      return !!cr.milestones.find((m) => {
        const indices = phase.milestoneIndices;
        return (
          m.changeRequestMilestoneType === "new" &&
          m.orderIndex === indices[indices.length - 1] + 1
        );
      });
    });
  }

  @computed private get milestoneItems() {
    const { milestones, projectDetailsFlow: { changeRequests, selectedMilestone }, projectWidgetManager } = this.props;
    return (
      milestones.map((milestone) => {
        const isSelected = selectedMilestone?.id === milestone.id;
        const tasksCompleted = milestone.tasks.reduce((previousValue, currentTask) => previousValue + (currentTask.completed ? 1 : 0), 0);
        const commentTag = milestone.name;
        const milestoneChangeRequests = changeRequests.filter(cr => cr.milestoneId === milestone.id);
        const numChangeRequestMessages = milestoneChangeRequests.reduce((prevVal, currVal) => {
          const commentsByTag = projectWidgetManager.changeRequestCommentsByTag.get(currVal.id);
          let totalMessages = 0;
          if (commentsByTag) {
            const commentTags = Object.keys(commentsByTag);
            for (let i = 0; i < commentTags.length; i++) {
              const currentCommentTag = commentTags[i];
              const comments = commentsByTag[currentCommentTag]?.comments || [];
              totalMessages += comments.length;
            }
          }

          return prevVal + totalMessages;
        }, 0)
        const numMessages = projectWidgetManager.commentsByTag[commentTag]?.comments.length || 0

        return (
          <StyledTouchableOpacity
            key={"milestonItems" + milestone.orderIndex}
            style={[milestoneStyles.milestoneArea, isSelected && milestoneStyles.milestoneBorderDark]} 
            onPress={() => this.handleSelectedMilestone(milestone)}
          >
            <View style={milestoneStyles.milestoneInfo}>
              {/* Left Container */}
              <View style={styles.itemContainer}>
                <StyledText variant="body" isBold={true}>
                  {milestone.name}
                </StyledText>
              </View>
              
              {/* Right Container */}
              <View style={styles.itemContainer}>
                <View style={[milestoneStyles.iconText, {marginRight: "1.2vw"}]}>
                  <Icon name="checklist" type="gray" size={20}/>
                  <StyledText variant="body" colorMode="gray" isBold>
                    {`${tasksCompleted}/${milestone.tasks.length}`}
                  </StyledText>
                </View>
                <View style={[milestoneStyles.iconText, {marginRight: "1.2vw"}]}>
                  <Icon name="message-circle" type="gray" size={20}/>
                  <StyledText variant="body2" colorMode="gray" isBold>
                    {numMessages + numChangeRequestMessages}
                  </StyledText>
                </View>
                <Tag {...formatCompletionTagProps(milestone.completion)}/>
              </View>
            </View>
          </StyledTouchableOpacity>
        );
      })
    );
  }

  @computed private get handlePhaseCompletion() {
    const { milestones } = this.props;
    const completionSet: Set<MilestoneCompletionType> = new Set();

    for (let i = 0; i < milestones.length; i++) {
      completionSet.add(milestones[i].completion);
    }

    let completionStatus: MilestoneCompletionType = "completed"
    if (completionSet.has("needs-review") || completionSet.has("pending")) {
      completionStatus = "needs-review";
    } else if (completionSet.has("in-progress")) {
      completionStatus = "in-progress";
    } else if (completionSet.has("not-started")) {
      if (completionSet.has("completed")) {
        completionStatus = "in-progress"
      } else {
        completionStatus = "not-started";
      }
    }

    if (this.addChangeRequest) completionStatus = "needs-review";

    return completionStatus;
  }

  @computed private get newMilestone() {
    if (this.addChangeRequest) {
      return (
        <View
          style={milestoneStyles.milestoneAdd} 
        >
          <View style={milestoneStyles.milestoneInfo}>
            {/* Left Container */}
            <View style={styles.itemContainer}>
              <StyledText variant="body" colorMode="secondary" isBold={true}>
                {this.addChangeRequest.milestones[0].name}
              </StyledText>
            </View>
            
            {/* Right Container */}
            <View style={[styles.itemContainer, styles.itemContainerGap]}>
              <Icon name={"alert-circle"} type={"secondary"} size={20}/>
              <StyledText colorMode="secondary" variant="body2">{"Awaiting Change Order Approval"}</StyledText>
              <StyledTouchableOpacity onPress={() => this.handleViewDetails(this.addChangeRequest!)} style={{flexDirection: "row", alignItems: "center"}}>
                <StyledText colorMode="secondary" variant="body2" isBold>{"View Details"}</StyledText>
                <Icon name={"chevron-right"} type={"secondary"} size={14} style={{marginTop: "auto", marginLeft: 5}}/>
              </StyledTouchableOpacity>
            </View>
          </View>
        </View>
      );
    } else {
      return undefined;
    }
  }

  @computed private get phaseTag() {
    const currentPaymentPhase = this.props.projectDetailsFlow.paymentData!.phases.find(
      paymentPhase => paymentPhase.id === this.props.phase.id
    );
    const { fundingTag } =
      formatPaymentPhaseDateTextAndTag(currentPaymentPhase!);
    return (
      <Tag
        type={fundingTag.type}
        text={fundingTag.text}
        style={{marginRight: "1.2vw"}}
      />
    );
  }

  readonly handleSelectedMilestone = (milestone: BudgetMilestone) => {
    const { phase, projectDetailsFlow, projectWidgetManager } = this.props;
    projectWidgetManager.setActiveCommentInfo(projectDetailsFlow.budget?.id, milestone.name);
    projectDetailsFlow.selectedMilestone = mapMilestoneToManagementMilestone(milestone);
    projectDetailsFlow.selectedPhase = phase;
  }

  readonly handleViewDetails = (changeRequest: ChangeRequest) => {
    const { changeView, phase, projectDetailsFlow, projectWidgetManager } = this.props;
    projectDetailsFlow.selectedChangeRequest = changeRequest;
    projectDetailsFlow.selectedMilestone = mapMilestoneToManagementMilestone(changeRequest.milestones[0]);
    projectDetailsFlow.selectedPhase = phase;
    projectWidgetManager.setActiveCommentInfo(projectDetailsFlow.budget?.id, projectDetailsFlow.selectedMilestone?.name);
    changeView();
  }

  render() {
    const { onAddMilestonePress, phase, userViewModel } = this.props;
    const colorMode = this.isOpen ? "light" : "dark";
    const isCompleted = this.handlePhaseCompletion === "completed";
    return (
      <>
        {/* Head */}
        <StyledTouchableOpacity 
          style={[
            styles.phase, isCompleted && styles.phaseInnerDark,
            this.isOpen && styles.phaseBorderDark
          ]}
          onPress={() => this.isOpen = !this.isOpen}
        >
          <View style={styles.phaseInfo}>
            {/* Left Container */}
            <View style={styles.itemContainer}>
              {
                this.isOpen ? (
                  <Icon name="phase-arrow-down" size={20} type={"white"}/>
                ) : (
                  <Icon name="phase-arrow-right" size={20}/>
                )
              }
              <StyledText variant="body" colorMode={colorMode} isBold={true} style={{marginLeft: "0.5vw"}}>
                {phase.name}
              </StyledText>
            </View>
            
            {/* Right Container */}
            <View style={styles.itemContainer}>
              {this.phaseTag}
              {
                isCompleted && (
                  <Tag {...formatCompletionTagProps(this.handlePhaseCompletion)} style={{marginRight: "1.2vw"}}/>
                )
              }
              <StyledText variant="body" colorMode="gray" style={{marginRight: "2vw"}}>
                {phase.numDays !== null ? `${phase.numDays} Days` : `? Days`}
              </StyledText>
              <StyledText variant="body" colorMode={colorMode} isBold={true}>
                {formatCurrencyToString(phase.cost, "$0.00")}
              </StyledText>
            </View>
          </View>
        </StyledTouchableOpacity>

        {/* Body */}
        {
          this.isOpen && (
            <View style={styles.phaseBody}>
              <View style={styles.bodyContainer}>
                <StyledText variant="body" isBold>Milestones</StyledText>
                {this.milestoneItems}
                {
                  this.newMilestone ? (
                    this.newMilestone
                  ) : (
                    userViewModel.isContractor && (
                      <StyledButton 
                        onPress={() => onAddMilestonePress("change", phase)}
                        style={styles.addMilestoneButton}
                        variant={"secondary"}
                        text={"Add Milestone"}
                      />
                    )
                  )
                }
              </View>
            </View>
          )
        }
      </>
    );
  }
}

const styles = StyleSheet.create({
  phase: {
    justifyContent: "center",
    backgroundColor: Palette.White,
    border: "1px solid " + Palette.Primary10Pct,
    borderRadius: 4,
    minHeight: 56,
    zIndex: 1,
  },
  phaseInnerDark: {
    zIndex: 1,
    backgroundColor: Palette.Primary10Pct,
    border: "1px solid " + Palette.Primary25Pct,
  },
  phaseBorderDark: {
    zIndex: 1,
    backgroundColor: Palette.Primary100Pct,
    border: "1px solid " + Palette.Primary50Pct,
  },
  phaseInfo: {
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: 16,
    justifyContent: "space-between",
  },
  itemContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  itemContainerGap: {
    flexDirection: "row",
    gap: 15,
  },
  phaseBody: {
    backgroundColor: Palette.White,
    border: "1px solid " + Palette.Primary50Pct,
    borderRadius: 4,
    top: -56,
    marginBottom: -56,
    zIndex: 0,
  },
  bodyContainer: {
    flexDirection: "column",
    gap: 8,
    justifyContent: "space-between",
    margin: 16,
    marginTop: 72,
  },
  addMilestoneButton: {
    marginTop: 8,
    width: "fit-content",
    paddingHorizontal: 35,
  },
});

const milestoneStyles = StyleSheet.create({
  milestoneArea: {
    justifyContent: "center",
    backgroundColor: Palette.Primary5Pct,
    border: "1px solid " + Palette.Primary10Pct,
    borderRadius: 4,
    minHeight: 56,
    flex: 1,
  },
  milestoneBorderDark: {
    backgroundColor: Palette.Primary5Pct,
    border: "1px solid " + Palette.Primary50Pct,
  },
  milestoneAdd: {
    justifyContent: "center",
    backgroundColor: Palette.Secondary10Pct,
    border: "1px solid " + Palette.Secondary50Pct,
    borderRadius: 4,
    minHeight: 56,
    flex: 1,
  },
  milestoneInfo: {
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: 16,
    justifyContent: "space-between",
  },
  iconText: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    gap: 4,
  },
});

export const InjectedManagementPhaseItem = withInjectedFactory(
  ManagementPhaseItemFactory
);
