import { Inject } from "@not-the-droids/exco-ts-inject";
import React, { useEffect, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { 
  BudgetMilestone,
  BudgetMilestoneTask,
  BudgetModel,
  BudgetPhaseWithMilestones,
  ChangeRequest,
  Project
} from "../../../data-model";
import { withInjectedFactory } from "../InjectorContext";
import { mapMilestonesToPhases } from "../utils/Budget";
import { formatCurrencyToString } from "../utils/Numbers";
import { InjectedChangeRequestView } from "./ChangeRequestView";
import { StyledButton, StyledText, StyledTouchableOpacity } from "./controls";
import { ProjectDetailsFlow } from "../flows/ProjectDetailsFlow";
import { RenderModal } from "./RenderModal";
import { Palette } from "./styles";

interface Props {
  budgetModel: BudgetModel;
  projectDetailsFlow: ProjectDetailsFlow;
}

interface CreateProps {
  isOpen: boolean;
  onCancelPress: () => void;
  project: Project
}

class CompletionManagementModalFactory {
  static inject: Inject<CompletionManagementModalFactory> = (injector) => {
    return () =>
      new CompletionManagementModalFactory({
        budgetModel: injector.get(BudgetModel)(),
        projectDetailsFlow: injector.get(ProjectDetailsFlow)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <CompletionManagementModal {...this.props} {...props} />;
  }
}

const CompletionManagementModal: React.FunctionComponent<CreateProps & Props> = (props) => {
  const { budgetModel, isOpen, onCancelPress, project, projectDetailsFlow } = props;
  const [budget, setBudget] = useState<BudgetPhaseWithMilestones[]>([]);
  const [changeRequest, setChangeRequest] = useState<ChangeRequest | undefined>();
  const [isNotSubmitted, setIsNotSubmitted] = useState<boolean>(true);

  useEffect(() => {
    const budget = projectDetailsFlow.budget;
    setBudget(mapMilestonesToPhases(budget?.phases!, budget?.milestones!));
  }, [projectDetailsFlow.budget])

  const handleCompletionSubmit = async () => {
    try {
      const changeRequestResult = await budgetModel.createProjectCompletionRequest(
        project.id,
        projectDetailsFlow.budget!.id,
      );
      projectDetailsFlow.changeRequests.splice(0, 0, changeRequestResult);
      setChangeRequest(changeRequestResult);
    } catch (e) {
      console.log(e)
    }
    setIsNotSubmitted(false);
  }

  const getTotalMilestonePrice = (milestone: BudgetMilestone) => {
    let totalCost: number = 0;
    milestone.tasks.forEach((task: BudgetMilestoneTask) => {
      totalCost += Number(task.budget);
    });
    return formatCurrencyToString(Math.round((totalCost + Number.EPSILON) * 100) / 100);
  };

  const renderBodyComplete = () => {
    return (
      <ScrollView style={[modalStyles.bodyAlt, modalStyles.bottomBorder, modalStyles.scroll]}>
        {budget.map((phase) => {
          return (
            <View key={`completion-phase-${phase.orderIndex}`} style={modalStyles.enclosingBody}>
              <View style={modalStyles.temp2}>
                <StyledText variant={"body"} isBold={true}>{phase.name}</StyledText>
                <StyledText variant={"body"} isBold={true}>{formatCurrencyToString(phase.cost)}</StyledText>
              </View>
              {phase.milestones.map((milestone) => {
                return (
                  <View key={`completion-milestone-${milestone.orderIndex}`} style={[modalStyles.enclosingBody, modalStyles.gray]}>
                    <View style={modalStyles.temp2}>
                      <StyledText variant={"body"} isBold={true}>{milestone.name}</StyledText>
                      <StyledText variant={"body"} isBold={true}>{getTotalMilestonePrice(milestone)}</StyledText>
                    </View>
                    <View style={[modalStyles.enclosingBody, modalStyles.light]}>
                      <View style={modalStyles.milestoneBlock}>
                        <View style={modalStyles.textBlock}>
                          <StyledText variant={"body"} isBold={true}>{"SCOPE OF WORK"}</StyledText>
                          <StyledText variant={"body"}>{milestone.scopeOfWork}</StyledText>
                        </View>
                        <View key={`completion-milestone-${milestone.orderIndex}`} style={modalStyles.taskBlock}>
                          {
                            milestone.tasks.length > 0 && (
                              <StyledText variant={"body"} isBold={true}>{"TASKS"}</StyledText>
                            )
                          }
                          {milestone.tasks.map((task) => {
                            return (
                              <View key={`completion-task-${task.orderIndex}`} style={modalStyles.task}>
                                <StyledText variant={"body"} colorMode={"dark"}>{task.description}</StyledText>
                                <StyledText variant={"body"} style={{marginLeft: "auto"}}>{formatCurrencyToString(task.budget)}</StyledText>
                              </View>
                            );
                          })}
                        </View>
                      </View>
                    </View>
                  </View>
                );
              })}
            </View>
          );
        })}
      </ScrollView>
    );
  }

  const renderBodyFinal = () => {
    return <InjectedChangeRequestView changeRequest={changeRequest}/>
  }

  return (
    <RenderModal
      onClick={() => {}}
      isOpen={isOpen}
      isHorizontallyCentered={true}
      isVerticallyCentered={true}
      activeOpacity={1}
      style={{padding: 0, width: 650}}
    >
      <View style={modalStyles.container}>
        {/* Header */}
        <View style={[modalStyles.header, modalStyles.bottomBorder]}>
          <View style={modalStyles.headerLeft}>
            <StyledText variant="body2" isBold>{"Complete Project"}</StyledText>
            <StyledText variant="heading3" isBold>{project.name}</StyledText>
          </View>
          {
            isNotSubmitted && (
              <View style={modalStyles.headerRight}>
                <StyledText variant="body" isBold={true}>{"BUDGET"}</StyledText>
                <StyledText>{formatCurrencyToString(projectDetailsFlow.phasesTotalCost)}</StyledText>
              </View>
            )
          }
        </View>

        {/* Body */}
        {isNotSubmitted ? renderBodyComplete() : renderBodyFinal()}

        {/* Footer */}
        <View style={modalStyles.footer}>
          {
            isNotSubmitted ? (
              <StyledButton
                onPress={handleCompletionSubmit}
                text={"Submit Completion"}
                iconRight={{name: "chevron-right", type: true ? "white" : "gray", size: 24}}
              />
            ) : (
              <StyledButton
                disabled={false}
                onPress={onCancelPress}
                text={"Return to Milestone"}
              />
            )
          }
          <StyledTouchableOpacity onPress={onCancelPress}>
            <StyledText variant="body2" isBold style={{color: Palette.Accent, textDecorationLine: "underline"}}>{"Cancel"}</StyledText>
          </StyledTouchableOpacity>
        </View>
      </View>
    </RenderModal>
  );
};

const modalStyles = StyleSheet.create({
  container: {
    flexDirection: "column",
    justifyContent: "flex-end",
    overflow: "hidden",
    maxHeight: "80vh",
  },
  scroll: {
    flex: 1,
    overflow: "hidden",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 32,
    paddingHorizontal: 32,
    paddingBottom: 15,
  },
  headerLeft: {
    flexDirection: "column",
    gap: 4,
  },
  headerRight: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  bodyAlt: {
    flexDirection: "column",
    paddingVertical: 24,
    paddingHorizontal: 32,
  },
  enclosingBody: {
    flex: 1,
    flexDirection: "column",
    gap: 5,
    padding: 10,
    backgroundColor: Palette.Primary5Pct,
    border: "1px solid " + Palette.Primary10Pct,
    borderRadius: 4,
  },
  temp2: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 5,
  },
  gray: {
    backgroundColor: Palette.Primary10Pct,
    border: "1px solid " + Palette.Primary25Pct,
  },
  light: {
    backgroundColor: Palette.Primary5Pct,
    border: "1px solid " + Palette.Primary25Pct,
  },
  milestoneBlock: {
    flexDirection: "column",
    gap: 30,
    padding: 12,
  },
  textBlock: {
    flexDirection: "column",
    gap: 14,
  },
  taskBlock: {
    flexDirection: "column",
    gap: 5,
  },
  task: {
    flexDirection: "row",
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: Palette.Primary10Pct,
    paddingBottom: 5,
  },
  footer: {
    flexDirection: "column",
    gap: 24,
    paddingVertical: 24,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 0,
  },
  bottomBorder: {
    borderBottomWidth: 1,
    borderBottomColor: Palette.Primary10Pct,
  },
});

export const InjectedCompletionManagementModal = withInjectedFactory(
  CompletionManagementModalFactory
);
