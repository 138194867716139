import React, { ReactElement } from "react";
import { StyleSheet, View } from "react-native";

import { Inject } from "@not-the-droids/exco-ts-inject";
import { InjectedSideBar, Page, Screen, StyledText } from "../components/controls";
import { HeaderFactory } from "../components/Header";

import { SideBarMain } from "../components/SideBarMain";
import { History, HistoryInjectable } from "../HistoryInjectable";
import {
  InjectedFactoryComponent,
  withInjectedProps,
} from "../InjectorContext";
import { Palette } from "../components/styles";
import { PdfViewer } from "../components/PdfViewer";

const POLICY_URL = process.env.PUBLIC_URL + "/privacy_policy.pdf";

interface Props {
  history: History;
}

class PrivacyPolicyScreenClass extends React.Component<Props> {
  static inject: Inject<ReactElement> = (injector) => {
    return () => (
      <PrivacyPolicyScreenClass history={injector.get(HistoryInjectable)()} />
    );
  };

  render() {
    return (
      <Screen>
        <InjectedSideBar size="default">
          <SideBarMain />
        </InjectedSideBar>
        <Page variant="main">
          <InjectedFactoryComponent factory={HeaderFactory} props={{}} />
          <View style={styles.header}>
            <StyledText variant="heading2">Privacy Policy</StyledText>
          </View>

          <View style={styles.flex}>
            <PdfViewer file={POLICY_URL} />
          </View>
        </Page>
      </Screen>
    );
  }
}

export const PrivacyPolicyScreen = withInjectedProps(PrivacyPolicyScreenClass);

const styles = StyleSheet.create({
  header: {
    borderBottomWidth: 1,
    marginHorizontal: -84,
    paddingHorizontal: 84,
    marginBottom: 64,
    paddingBottom: 8,
    justifyContent: "space-between",
    borderBottomColor: Palette.Primary10Pct,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  flex: {
    flex: 1,
    width: "100%",
    alignItems: "center",
  },
});
