import { Inject } from "@not-the-droids/exco-ts-inject";
import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import {
  ContractorModel,
  InvitedUser,
  ProjectModel,
} from "../../../data-model/";
import { CurrentUser } from "../../../data-model/CurrentUser";
import { Project } from "../../../data-model/Project";
import { withInjectedFactory } from "../InjectorContext";
import { capitalizeFirstLetter, formatPhoneNumber, structureAddress } from "../utils/Strings";
import { StyledText } from "./controls";
import { ImageDropzone } from "./controls/ImageDropzone";
import { InviteModal } from "./InviteModal";
import { SectionBlock } from "./SectionBlock";
import { Palette } from "./styles";
import { UserInfo } from "./UserInfo";

interface Props {
  contractorModel: ContractorModel;
  projectModel: ProjectModel;
}

interface CreateProps {
  project: Project;
  currentUser: CurrentUser;
  onUpdatePhoto: (photo: File) => Promise<void>;
}

export class ProjectDetailsViewFactory {
  static inject: Inject<ProjectDetailsViewFactory> = (injector) => {
    return () =>
      new ProjectDetailsViewFactory({
        contractorModel: injector.get(ContractorModel)(),
        projectModel: injector.get(ProjectModel)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ProjectDetailsView {...this.props} {...props} />;
  }
}

const ProjectDetailsView: React.FunctionComponent<Props & CreateProps> = ({
  project,
  currentUser,
  projectModel,
  contractorModel,
  onUpdatePhoto,
}) => {
  const [userType, setUserType] = useState("");
  const [contractors, setContractors] = useState<InvitedUser[]>([]);

  const hasImage = project.media.length;

  useEffect(() => {
    !!currentUser?.owner && setUserType("owner");
    !!currentUser?.contractor && setUserType("contractor");
  }, [currentUser, project, contractorModel, projectModel]);

  useEffect(() => {
    const getMembers = async () => {
      const members = await projectModel.getMembersByProjectId(project.id);
      setContractors(members.filter((m) => m.role === "contractor"));
    };
    getMembers();
  }, [project.id, projectModel]);

  const contractorStatusText = () => {
    if (contractors.length > 0) {
      if (!project.bid?.submittedAt) {
        return "Awaiting estimate";
      } else if (!project.bid?.approved) {
        return "Awaiting bid approval from owner";
      } else if (!project.budget.submitted) {
        return "Awaiting budget";
      }
    }
    return "";
  };

  const onAddPhoto = async (
    photo: File,
    onUploadFinishedCallback: () => void
  ) => {
    try {
      await onUpdatePhoto(photo);
    } catch (e) {
      console.log(e);
    }
    onUploadFinishedCallback();
  };

  return (
    <View style={[styles.container, styles.row]}>
      <View style={styles.column}>
        <View style={styles.imageContainer}>
          <ImageDropzone
            style={{maxHeight: 270}}
            editable={false}
            showEditIcon={project.creator.id === currentUser.id}
            file={
              hasImage
                ? {
                    createdAt: new Date(),
                    fileId: "id",
                    fileName: project.media[0].fileName,
                    fileUrl: project.media[0].url,
                    fileType: "image",
                  }
                : undefined
            }
            onDrop={onAddPhoto}
          />
        </View>
        <View style={styles.section}>
          <SectionBlock title="Structure Description">
            <StyledText style={styles.sectionText}>
              {project.description}
            </StyledText>
          </SectionBlock>
        </View>
        {userType === "owner" && (
          <View style={styles.section}>
            <SectionBlock title="Contractor">
              {/* TODO: Once multiple bids are allowed the
               status text should be diplayed for each bid*/}
              <InviteModal
                contractorModel={contractorModel}
                projectId={project.id}
                projectModel={projectModel}
              />
              <View style={styles.selectedInviteStatusContainer}>
                <StyledText variant="body2" colorMode="gray">
                  {contractorStatusText()}
                </StyledText>
              </View>
            </SectionBlock>
          </View>
        )}
      </View>
      <View style={[styles.column, styles.lastColumn]}>
        <View
          style={[styles.section, styles.row, styles.sectionSecondaryTitle]}
        >
          <UserInfo variant="name-email" user={project.creator} />
          {/*       <StyledTouchableOpacity
            style={styles.editButtonContainer}
            onPress={() => {}}
          >
            <Icon name="edit" mode="light" size={14} />
          </StyledTouchableOpacity>*/}
        </View>

        <View style={styles.section}>
          <SectionBlock title="Property Owner">
            <StyledText style={styles.sectionText}>
              {project.owner?.name ?? project.ownerPlaceholderName}
            </StyledText>
            {!!project.owner && (
              <StyledText style={styles.sectionText}>
                {formatPhoneNumber(project.owner?.phone)}
              </StyledText>
            )}
          </SectionBlock>
        </View>
        <View style={styles.section}>
          <SectionBlock title="Project Address">
            <StyledText style={styles.sectionText}>
              {project.address
                ? structureAddress(project.address)
                : "Not Submitted"}
            </StyledText>
          </SectionBlock>
        </View>
        <View style={[styles.section, styles.row]}>
          <View style={{ flex: 1 }}>
            <SectionBlock title="Work Type">
              <StyledText style={styles.sectionText}>
                {!!project.workType && capitalizeFirstLetter(project.workType)}
              </StyledText>
            </SectionBlock>
          </View>
          <View style={{ flex: 2 }}>
            <SectionBlock title="Work Location">
              <StyledText style={styles.sectionText}>
                {!!project.workLocation &&
                  capitalizeFirstLetter(project.workLocation)}
              </StyledText>
            </SectionBlock>
          </View>
        </View>
        {/*        <View style={styles.section}>
          <SectionBlock title="Work Areas">
            <StyledText style={styles.sectionText}>
              {returnWorkAreas()}
            </StyledText>
          </SectionBlock>
        </View>*/}
        <View style={styles.section}>
          <SectionBlock title="Work Categories">
            <StyledText style={styles.sectionText}>
              {project.workCategories
                ?.map((workCat) => workCat.workCategory)
                .join(", ")}
            </StyledText>
          </SectionBlock>
        </View>
        {/*<View style={styles.row}>
          <Icon
            name="calendar"
            mode="dark"
            size={14}
            style={styles.calendarIcon}
          />
          <StyledText style={styles.sectionText}>47 days left</StyledText>
        </View>*/}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  row: {
    flexDirection: "row",
  },
  column: {
    flex: 1,
    marginRight: 48,
  },
  lastColumn: {
    marginRight: 0,
  },
  section: {
    marginBottom: 24,
  },
  sectionSecondaryTitle: {
    justifyContent: "space-between",
  },
  editButtonContainer: {
    width: 48,
    height: 48,
    borderRadius: 25,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Palette.Accent,
  },
  imageContainer: {
    flex: 1,
    maxWidth: 480,
    maxHeight: 270,
    marginBottom: 48,
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: 8,
  },
  calendarIcon: {
    marginRight: 10,
  },
  sectionText: {
    lineHeight: 24,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  selectedInviteStatusContainer: {
    marginTop: 5,
    flexDirection: "row",
  },
});

export const InjectedProjectDetailsView = withInjectedFactory(
  ProjectDetailsViewFactory
);
