import React from "react";
import {
  StyleProp,
  StyleSheet,
  Text,
  TextProps,
  TextStyle,
} from "react-native";
import { Palette } from "../styles";

export type StyledTextVariant =
  | "body"
  | "body2"
  | "caption"
  | "heading"
  | "heading2"
  | "heading3"
  | "inputLabel";

export type ColorVariant =
  | "accent"
  | "affirm"
  | "dark"
  | "gray"
  | "highlight"
  | "light"
  | "secondary"
  | "warning";

interface Props extends TextProps {
  colorMode?: ColorVariant;
  isBold?: boolean;
  isMedium?: boolean;
  isUpperCase?: boolean;
  isUnderlined?: boolean;
  style?: StyleProp<TextStyle>;
  variant?: StyledTextVariant;
}

type Variants = { [V in StyledTextVariant]: StyleProp<TextStyle> };
type ColorStyles = { [V in ColorVariant]: StyleProp<TextStyle> };

export function StyledText(props: React.PropsWithChildren<Props>) {
  const {
    colorMode,
    isBold,
    style,
    variant,
    isUpperCase,
    isMedium,
    isUnderlined,
    ...textProps
  } = props;
  const colorStyle = colorStyles[colorMode || "dark"];
  const variantStyle = variants[variant || "body"];
  const mediumStyle = isMedium && styles.medium;
  const boldStyle = isBold && styles.bold;
  const uppercaseStyle = isUpperCase && styles.uppercase;
  const underlineStyle = isUnderlined && styles.underline;
  return (
    <Text
      style={[
        colorStyle,
        variantStyle,
        mediumStyle,
        boldStyle,
        style,
        uppercaseStyle,
        underlineStyle,
      ]}
      {...textProps}
    >
      {props.children}
    </Text>
  );
}

export type StyledTextProps = Props;

const styles = StyleSheet.create({
  medium: {
    fontWeight: "600",
  },
  bold: {
    fontWeight: "bold",
  },
  uppercase: {
    textTransform: "uppercase",
  },
  underline: {
    textDecorationLine: "underline",
  },
});

const colorStyles: ColorStyles = {
  accent: {
    color: Palette.Accent,
  },
  affirm: {
    color: Palette.Affirm100Pct,
  },
  dark: {
    color: Palette.Primary100Pct,
  },
  gray: {
    color: Palette.Primary50Pct,
  },
  highlight: {
    color: Palette.Secondary100Pct,
  },
  light: {
    color: Palette.White,
  },
  secondary: {
    color: Palette.Secondary100Pct,
  },
  warning: {
    color: Palette.Warning100Pct,
  },
};

const variants: Variants = {
  body: {
    fontSize: 15,
    lineHeight: 19,
  },
  body2: {
    fontSize: 13,
    lineHeight: 16,
  },
  caption: {
    fontSize: 12,
    lineHeight: 15,
  },
  inputLabel: {
    fontSize: 15,
    lineHeight: 24,
    fontWeight: "800",
    textTransform: "uppercase",
  },
  heading: {
    fontSize: 36,
    lineHeight: 44,
  },
  heading2: {
    fontSize: 24,
    lineHeight: 32,
    fontWeight: "bold",
  },
  heading3: {
    fontSize: 20,
    lineHeight: 28,
  },
};
