import React from "react";
import { Card, StyledButton, StyledText } from "./controls";
import { formatArrayToString } from "../utils/Strings";
import { Inject } from "@not-the-droids/exco-ts-inject";
import { Notification } from "../NotificationInjectable";
import { ProjectModel, UserModel } from "../../../data-model";
import { SectionBlock } from "./SectionBlock";
import { StyleSheet, View } from "react-native";
import { UserInfo } from "./UserInfo";

export interface Invitation {
  projectId: string;
  userId: string;
}

interface CreateProps {
  invitation: any; // TODO: implement Invitation type - EY
  key?: any;
  removeInvitation?: (invitation: Invitation) => void;
}

interface Props {
  projectModel: ProjectModel;
  userModel: UserModel;
  notification: Notification;
}

export class InvitationCardFactory {
  static inject: Inject<InvitationCardFactory> = (injector) => {
    return () =>
      new InvitationCardFactory({
        projectModel: injector.get(ProjectModel)(),
        userModel: injector.get(UserModel)(),
        notification: injector.get(Notification)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <InvitationCard {...this.props} {...props} />;
  }
}

export class InvitationCard extends React.Component<Props & CreateProps> {
  handleAcceptPress = async () => {
    const {
      projectModel,
      invitation,
      notification,
      removeInvitation,
    } = this.props;

    try {
      await projectModel.acceptInvitation(invitation.projectId);
      removeInvitation?.({
        projectId: invitation.projectId,
        userId: invitation.userId,
      });
      notification.setNotification("success", "Invitation accepted!");
    } catch (err: any) {
      notification.setNotification("error", err?.message);
    }
  };

  handleRejectPress = async () => {
    //TODO: future -> create removed table to prevent readding invite to a project. 
    const {
      projectModel,
      invitation,
      notification,
      removeInvitation,
    } = this.props;
    try {
      await projectModel.rejectInvitation({
        projectId: invitation.projectId,
      });
    } catch (err: any) {
      notification.setNotification("error", err?.message);
    } finally {
      notification.setNotification("success", "Invitation removed!");
      removeInvitation?.({
        projectId: invitation.projectId,
        userId: invitation.userId,
      });
    }
  };

  render() {
    const { invitation } = this.props;
    const address = formatArrayToString([invitation.projectAddress.city, invitation.projectAddress.state], ", ");
    return (
      <Card
        style={styles.card}
        headerLeft={<UserInfo variant="name-email" user={invitation} />}
        // headerRight={<Icon name="ellipses" mode="dark" size={14}/>} // TODO: implement ellipses functionality - EY
      >
        <View style={styles.sectionSmall}>
          <StyledText variant="body2">
            <View>
              <StyledText variant="body2" isBold={true}>
                { invitation.projectName }
              </StyledText>
              <StyledText variant="body2">
                { address }
              </StyledText>
            </View>
          </StyledText>
        </View>

        <SectionBlock title="Work Type" style={styles.sectionSmall}>
          <StyledText style={styles.sectionText}>
            {invitation.workType}
          </StyledText>
        </SectionBlock>

        {/*      <View style={[styles.sectionBlocks, styles.section]}>
        <View style={styles.buttons}>
          <Icon name="calendar" mode="dark" size={14} style={styles.calendarIcon}/>
          <StyledText style={styles.sectionText}>Dec 2021 - Feb 2022</StyledText>
        </View>
      </View>*/}

        <View style={styles.buttons}>
          <StyledButton
            variant="affirm"
            text="Accept"
            style={styles.button}
            onPress={this.handleAcceptPress}
          />
          <StyledButton
            variant="warning"
            text="Reject"
            style={styles.button}
            onPress={this.handleRejectPress}
          />
        </View>
      </Card>
    );
  }
}

const styles = StyleSheet.create({
  card: {
    width: 312,
    padding: 24,
    marginRight: 24,
    marginBottom: 24,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  sectionBlocks: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  sectionText: {
    lineHeight: 20,
  },
  section: {
    marginBottom: 24,
  },
  sectionSmall: {
    marginBottom: 16,
  },
  calendarIcon: {
    marginRight: 10,
  },
  buttons: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  button: {
    flex: 1,
    maxWidth: 124,
  },
});
