import { RefObject, ChangeEventHandler } from "react";
import { View } from "react-native";

export interface FileEventTarget extends ChangeEventHandler<HTMLInputElement> {
  target: EventTarget & { files: FileList }
}

interface Props {
  inputRef: RefObject<HTMLInputElement>;
  onFileSelect: (file: FileList) => void;
  variant?: string;
}
  
export const FileUpload: React.FunctionComponent<Props> = ({
  inputRef,
  onFileSelect
}) => {
  const handleFileUpload = (e: any) => {
    const { files } = e.target;
    if (files && files.length) {
      onFileSelect(files)
    }
  };

  return (
    <View>
      <input
        style={{ display: "none" }}
        // accept=".zip,.rar"
        ref={inputRef}
        onChange={handleFileUpload}
        type="file"
      />
    </View>
  );
};

export default FileUpload;